import {take, call, put} from 'redux-saga/effects';
import type {Effect} from 'redux-saga/effects';

import {createEditorialUser as createEditorialUserService} from '@services/user';
import * as UserActions from '@actions/user';

export function* createEditorialUser(): Generator<Effect, void, any> {
  while (true) {
    try {
      const {user, onSuccess} = yield take(
        UserActions.CREATE_EDITORIAL_USER.REQUEST,
      );
      const {data: response} = yield call(createEditorialUserService, user);
      yield put(UserActions.createEditorialUser.success([]));
      onSuccess();
    } catch (error) {
      yield put(UserActions.createEditorialUser.failure(error));
    }
  }
}
