import React, {Fragment, useState} from 'react';
import {CButton} from '@coreui/react';
import {WSRModal} from '../../components';

type Props = {
  deletePhotoButton: any;
};

export const DeletePhotoButton: React.FC<Props> = (props) => {
  const {deletePhotoButton} = props;
  const [deleteModal, setDeleteModal] = useState(false);

  const deletePhoto = () => {
    deletePhotoButton();
    setDeleteModal(false);
  };

  return (
    <Fragment>
      <CButton
        className="my-2 mfs-auto"
        style={{width: '100%'}}
        onClick={() => setDeleteModal(true)}
        color="danger">
        Delete Photo
      </CButton>

      <WSRModal
        onShowModal={deleteModal}
        setOnShowModal={setDeleteModal}
        modalTitle={'Warning'}
        modalBody={'Are you sure that you want to delete photo?'}
        operationButton={() => deletePhoto()}
        operationButtonText={' Yes, remove it!'}
        cancelButton={() => setDeleteModal(false)}
        cancelButtonText={'Cancel'}
      />
    </Fragment>
  );
};
