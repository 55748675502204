import {Axios} from '@utils';
import {constants} from '@resources';

export function updatePost(post: any, getfurtherReadings: Array<any>) {
  const furtherReadings = getfurtherReadings?.map((item: any) => item.url);

  let parameters = {};

  if (post.tagIds) {
    parameters = {
      lang: post.lang,
      title: post.title,
      description: post.description,
      note: post.note,
      furtherReadings,
      isHidden: post.isHidden,
      isHiddenNewsFeed: post.isHiddenNewsFeed,
      isHiddenProfile: post.isHiddenProfile,
      // categoryIds: post.categoryIds,
      tagIds: post.tagIds,
    };
  } else {
    parameters = {
      lang: post.lang,
      title: post.title,
      description: post.description,
      note: post.note,
      furtherReadings,
      isHidden: post.isHidden,
      isHiddenNewsFeed: post.isHiddenNewsFeed,
      isHiddenProfile: post.isHiddenProfile,
    };
  }

  if (post.image) {
    parameters = {...parameters, image: post.image};
  }

  return Axios.put(`${constants.MANAGEMENT_BASE}/posts/${post.id}`, parameters);
}
