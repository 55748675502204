import {Axios} from '@utils';
import {constants} from '@resources';

export function getAllPostStats(
  interval: string,
  startDate: string,
  endDate: string,
) {
  return Axios.get(
    `${constants.STATS_BASE}/postCreateStats?interval=${interval}&startDate=${startDate}&endDate=${endDate}`,
  );
}
