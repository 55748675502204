import {Axios} from '@utils';
import {constants} from '../../resources/index';

export function uploadPhoto(photoUri: any) {
  const data: any = new FormData();
  data.append('name', 'image');
  data.append('file', photoUri);
  const config = {
    headers: {
      'content-type': 'multipart/form-data',
    },
  };
  return Axios.post(`${constants.UTILS_BASE}/upload`, data, config);
}
