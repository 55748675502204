import {Axios} from '@utils';
import {constants} from '@resources';

export function createEditorialUser(user: any) {
  if (user.jobTitle.length === 0) {
    delete user.jobTitle;
  }
  if (user.bio.length === 0) {
    delete user.bio;
  }
  if (user.photo.length === 0) {
    delete user.photo;
  }
  if (user.email.length === 0) {
    delete user.email;
  }

  return Axios.post(`${constants.USER_MANAGEMENT_BASE}`, user);
}
