import {
  CNav,
  CNavItem,
  CNavLink,
  CTabContent,
  CTabPane,
  CTabs,
} from '@coreui/react';
import React, {useEffect, useState} from 'react';
import CIcon from '@coreui/icons-react';
import {PostList} from '../../components';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import {searchPostsForUser as searchPostsAction} from '@actions/posts';

type Props = {userName: string; match: any};

export const UsersPostCommentList: React.FC<Props> = (props) => {
  const dispatch = useDispatch();
  const {userName, match} = props;
  const [active, setActive] = useState(0);
  const [page, setPage] = useState(1);
  const [searchFields, setSearchFields] = useState({
    userId: 0,
  });
  const [pageLimit, setPageLimit] = useState(10);

  const {postList} = useSelector(
    (state) => ({
      postList: state.posts.postSearchResultForUser,
    }),
    shallowEqual,
  );

  const pageChange = (newPage: number) => {
    if (searchFields.userId !== 0 || page !== 1) {
      dispatch(searchPostsAction.request(10, postList.page + 10, searchFields));
    }
    setPage(newPage);
  };

  const search = (
    page: number,
    searchFields: {
      userId: number;
    },
  ) => {
    dispatch(searchPostsAction.request(10, page, searchFields));
  };

  useEffect(() => {
    setSearchFields((searchFields) => ({
      ...searchFields,
      userId: match,
    }));
    setSearchFields((prevState) => {
      search(0, {...prevState, userId: match});
      return {...prevState, match};
    });
  }, [match]);

  const data = postList;

  return (
    <>
      <CTabs
        activeTab={active}
        onActiveTabChange={(idx: number) => setActive(idx)}>
        <CNav variant="tabs">
          <CNavItem>
            <CNavLink>
              <CIcon name="cil-calculator" />
              {' Posts'}
            </CNavLink>
          </CNavItem>
          {match !== 'undefined' ? (
            <CNavItem>
              <CNavLink>
                <CIcon name="cil-basket" />
                {' Comments'}
              </CNavLink>
            </CNavItem>
          ) : null}
        </CNav>
        <CTabContent>
          <CTabPane>
            {
              <PostList
                page={page}
                pageLimit={pageLimit}
                setPageLimit={setPageLimit}
                pageChange={pageChange}
                postList={data.posts}
                count={data.codeCount}
                userId={match}
                userName={userName}
              />
            }
          </CTabPane>
          <CTabPane>{/* dont forget to add wsrdatatable */}</CTabPane>
        </CTabContent>
      </CTabs>
    </>
  );
};
