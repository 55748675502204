export const values = {
  fields: [
    {
      key: 'id',
      label: 'Id',
      sorter: false,
      filter: false,
      _style: {width: '5%'},
    },
    {
      key: 'photo',
      label: 'Photo',
      sorter: true,
      filter: false,
      _style: {width: '5%'},
    },
    {
      key: 'name',
      label: 'Name',
      sorter: false,
      filter: false,
      _style: {width: '30%'},
    },
    {
      key: 'curator',
      label: 'Curator',
      sorter: true,
      filter: false,
      _style: {width: '10%'},
    },

    {
      key: 'postCount',
      label: 'Post Count',
      sorter: false,
      filter: false,
      _style: {width: '5%'},
    },
    {
      key: 'followerCount',
      label: 'Follower Count',
      sorter: false,
      filter: false,
      _style: {width: '5%'},
    },
    {
      key: 'clickCount',
      label: 'Click Count',
      sorter: false,
      filter: false,
      _style: {width: '5%'},
    },
    {
      key: 'createdAt',
      label: 'Create Date',
      sorter: false,
      filter: false,
      _style: {width: '5%'},
    },
    {
      key: 'updatedAt',
      label: 'Update Date',
      sorter: false,
      filter: false,
      _style: {width: '5%'},
    },
    {
      key: 'status',
      label: 'Status',
      sorter: false,
      filter: false,
      _style: {width: '5%'},
    },
    {
      key: 'isPublic',
      label: 'Is Public',
      sorter: false,
      filter: false,
      _style: {width: '5%'},
    },
    {
      key: 'addButton',
      label: 'Add',
      sorter: false,
      filter: false,
      _style: {width: '5%'},
    },
  ],
};
