import {take, call, put} from 'redux-saga/effects';
import type {Effect} from 'redux-saga/effects';

import {getCurationUsers as getCurationUsersService} from '@services/curationList';
import * as CurationActions from '@actions/curation';

export function* getCurationUsers(): Generator<Effect, void, any> {
  while (true) {
    try {
      const {page} = yield take(CurationActions.GET_CURATION_USERS.REQUEST);
      const {data: response} = yield call(getCurationUsersService, page);
      yield put(
        CurationActions.getCurationUsers.success(
          response.data,
          page,
          response.count,
        ),
      );
    } catch (error) {
      // Show info
      yield put(CurationActions.getCurationUsers.failure(error));
    }
  }
}
