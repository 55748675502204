import {Axios} from '@utils';
import {constants} from '../../resources';

export async function getCurationStats(
  page: number,
  startDate?: string,
  endDate?: string,
) {
  return await Axios.get(
    `${constants.STATS_BASE}/curationClicks?limit=10&offset=${page}&startDate=` +
      startDate +
      `&endDate=` +
      endDate,
  );
}
