import {take, call, put} from 'redux-saga/effects';
import type {Effect} from 'redux-saga/effects';

import {deleteCurationFromChooseMood as deleteCurationFromChooseMoodService} from '@services/chooseMood';
import * as CurationActions from '@actions/chooseMood';

export function* deleteCurationFromChooseMood(): Generator<Effect, void, any> {
  while (true) {
    try {
      const {curationListId} = yield take(
        CurationActions.REMOVE_CURATION_FROM_MOOD.REQUEST,
      );
      const {data: response} = yield call(
        deleteCurationFromChooseMoodService,
        curationListId,
      );
      yield put(
        CurationActions.getMoodCurations.request(
          1000,
          0,
          false,
          '["tr", "en"]',
        ),
      );
      yield put(CurationActions.removeCurationFromMood.success(response.data));
    } catch (error) {
      // Show info
      yield put(CurationActions.removeCurationFromMood.failure(error));
    }
  }
}
