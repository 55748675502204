import {take, call, put} from 'redux-saga/effects';
import type {Effect} from 'redux-saga/effects';

import {createCategory as createCategoryService} from '@services/categories';
import * as CategoryActions from '@actions/categories';

export function* createCategory(): Generator<Effect, void, any> {
  while (true) {
    try {
      const {categoryData, locale, onSucess} = yield take(
        CategoryActions.CREATE_CATEGORY.REQUEST,
      );
      const {data: response} = yield call(
        createCategoryService,
        categoryData,
        locale,
      );
      onSucess();
      yield put(CategoryActions.createCategory.success([]));
    } catch (error) {
      // Show info
      yield put(CategoryActions.createCategory.failure(error));
    }
  }
}
