import {Axios} from '@utils';
import {constants} from '@resources';

export function updateUserShowOnDiscovery(user: any) {
  const parameters = {
    showOnDiscoveryByLocal: user.showOnDiscoveryByLocal,
  };

  return Axios.put(`${constants.USER_MANAGEMENT_BASE}/${user.id}`, parameters);
}
