// import {PURGE} from 'redux-persist';

import * as ActionTypes from '@actions/user';

import type {UserReducer} from './types';

const initialState: UserReducer = {
  isLoading: false,
  error: false,
  errorDescription: '',
  userId: undefined,
  profile: {
    photo: undefined,
  },
};

export function user(state = initialState, action: any = {}): UserReducer {
  switch (action.type) {
    // case PURGE: {
    //   return initialState;
    // }
    case ActionTypes.SET_USERID.REQUEST: {
      return {
        ...state,
        isLoading: true,
        error: false,
        errorDescription: '',
        userId: action.userId,
      };
    }
    case ActionTypes.GET_USER.REQUEST: {
      return {
        ...state,
        isLoading: true,
        error: false,
        errorDescription: '',
      };
    }
    case ActionTypes.GET_USER.SUCCESS: {
      return {
        ...state,
        isLoading: false,
        error: false,
        errorDescription: '',
        profile: action.profile,
      };
    }
    case ActionTypes.GET_USER.FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: true,
        errorDescription: action.error.data?.message,
      };
    }

    case ActionTypes.CHECK_USERNAME.REQUEST: {
      return {
        ...state,
        isLoading: true,
        error: false,
        errorDescription: '',
      };
    }
    case ActionTypes.CHECK_USERNAME.SUCCESS: {
      return {
        ...state,
        isLoading: false,
        error: false,
        errorDescription: '',
      };
    }
    case ActionTypes.CHECK_USERNAME.FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: true,
        errorDescription: action.error.data?.message,
      };
    }

    case ActionTypes.CLEAN_ERROR_STATE.REQUEST: {
      return {
        ...state,
        isLoading: true,
        error: false,
        errorDescription: '',
      };
    }

    case ActionTypes.CREATE_EDITORIAL_USER.REQUEST: {
      return {
        ...state,
        isLoading: true,
        error: false,
        errorDescription: '',
      };
    }
    case ActionTypes.CREATE_EDITORIAL_USER.SUCCESS: {
      return {
        ...state,
        isLoading: false,
        error: false,
        errorDescription: '',
      };
    }
    case ActionTypes.CREATE_EDITORIAL_USER.FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: true,
        errorDescription: action.error.message,
      };
    }

    default:
      return state;
  }
}
