import {take, call, put} from 'redux-saga/effects';
import type {Effect} from 'redux-saga/effects';

import {getWeeklyUpdatedCurationLists as getWeeklyUpdatedCurationListsService} from '@services/management';
import * as ManagementActions from '@actions/management';

export function* getWeeklyUpdatedTime(): Generator<Effect, void, any> {
  while (true) {
    try {
      yield take(ManagementActions.GET_WEEKLY_UPDATED_TIME.REQUEST);
      const {data: response} = yield call(getWeeklyUpdatedCurationListsService);
      yield put(ManagementActions.getWeeklyUpdatedTime.success(response.data));
    } catch (error) {
      // Show info
      yield put(ManagementActions.getWeeklyUpdatedTime.failure(error));
    }
  }
}
