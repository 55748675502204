import {take, call, put} from 'redux-saga/effects';
import type {Effect} from 'redux-saga/effects';

import {login as loginService} from '@services/auth';
import * as AuthActions from '@actions/auth';
import * as UserActions from '@actions/user';
import {setToken} from '@utils/Axios';

export function* login(): Generator<Effect, void, any> {
  while (true) {
    try {
      const {email, password} = yield take(AuthActions.LOGIN.REQUEST);
      const {data: response} = yield call(loginService, email, password);
      setToken(response.token);
      yield put(UserActions.setUserId.request(response.userId));
      yield put(
        AuthActions.login.success(
          response.token,
          response.status,
          response.role,
        ),
      );
      yield put(UserActions.getUser.request(response.userId));
    } catch (error) {
      // Show info
      yield put(AuthActions.login.failure(error.message));
    }
  }
}
