import React, {ChangeEvent, useEffect, useState} from 'react';
import {useSelector, shallowEqual, useDispatch} from 'react-redux';
import {
  CButton,
  CButtonGroup,
  CCard,
  CCardBody,
  CCol,
  CRow,
  CInput,
  CLabel,
} from '@coreui/react';

import {CChart} from '@coreui/react-chartjs';
import moment from 'moment';

import {getAllPostStats as getAllPostStatsAction} from '@actions/posts';

const statsButtonsData: Array<any> = [
  {
    value: 'Day',
    key: 'day',
  },
  {
    value: 'Week',
    key: 'week',
  },
  {
    value: 'Month',
    key: 'month',
  },
];

let startDate: string;
let endDate: string;
startDate = '';
endDate = '';

export const PostStats: React.FC = React.memo(() => {
  const [selectedStatButton, setSelectedStatButton] = useState(
    statsButtonsData[statsButtonsData.length - 1],
  );

  const dispatch = useDispatch();
  const [label, setLabel] = useState<Array<any>>([]);

  const {postStats} = useSelector(
    (state) => ({
      postStats: state.posts.postStats,
    }),
    shallowEqual,
  );

  const changeStatisticButton = (item: any) => {
    // startDate = '';
    // endDate = '';
    setSelectedStatButton(item);
  };

  const handleStartDateChange = async (
    event: ChangeEvent<HTMLInputElement>,
  ) => {
    startDate = moment(event.target.value).format('YYYY-MM-DD');
    await getData(startDate, endDate);
  };

  const handleEndDateChange = async (event: ChangeEvent<HTMLInputElement>) => {
    endDate = moment(event.target.value).format('YYYY-MM-DD');
    getData(startDate, endDate);
  };

  const getData = async (startDate: string, endDate: string) => {
    if (
      endDate <= startDate ||
      (startDate !== undefined && endDate !== undefined)
    ) {
      dispatch(
        getAllPostStatsAction.request(
          selectedStatButton.key,
          startDate,
          endDate,
        ),
      );
    }
  };

  const bar = {
    labels: postStats.Podcast.map((response) =>
      moment(response.date).format('DD/MM/YYYY'),
    ),
    datasets: [
      {
        label: 'Podcast',
        //backgroundColor: '#50c878',
        borderColor: '#50c878',
        borderWidth: 1,
        hoverBackgroundColor: '#50c878',
        hoverBorderColor: '#50c878',
        data: postStats.Podcast.map((response) => response.count),
      },
      {
        label: 'Video',
        //backgroundColor: '#7df9ff',
        borderColor: '#7df9ff',
        borderWidth: 1,
        hoverBackgroundColor: '#7df9ff',
        hoverBorderColor: '#7df9ff',
        data: postStats.Video.map((response) => response.count),
      },
      {
        label: 'Article',
        //backgroundColor: '#ff6f9c',
        borderColor: '#ff6f9c',
        borderWidth: 1,
        hoverBackgroundColor: '#ff6f9c',
        hoverBorderColor: '#ff6f9c',
        data: postStats.Article.map((response) => response.count),
      },
    ],
  };

  useEffect(() => {
    dispatch(
      getAllPostStatsAction.request(selectedStatButton.key, startDate, endDate),
    );
  }, [selectedStatButton]);

  useEffect(() => {
    postStats.Article.map((response) =>
      setLabel((onLabel) => [
        ...onLabel,
        moment(response.date).format('DD/MM/YYYY'),
      ]),
    );
  }, [selectedStatButton]);

  useEffect(() => {
    postStats.Article.map((response) =>
      setLabel((onLabel) => [
        ...onLabel,
        moment(response.date).format('DD/MM/YYYY'),
      ]),
    );
  }, [selectedStatButton]);

  return (
    <CCard>
      <CCardBody>
        <CRow>
          <CCol>
            <h5 id="traffic" className="card-title mb-2">
              Post Statistics
            </h5>
            <div className="small text-muted">
              {moment().format('DD/MM/YYYY')}
            </div>
          </CCol>

          <CCol>
            <CLabel>Start Date</CLabel>
            <CInput
              className="form-control"
              type="date"
              placeholder="Start Date"
              onChange={handleStartDateChange}
            />
          </CCol>
          <CCol>
            <CLabel>End Date</CLabel>
            <CInput
              className="form-control"
              type="date"
              placeholder="Start Date"
              onChange={handleEndDateChange}
            />
          </CCol>

          <CCol>
            <CButtonGroup>
              {statsButtonsData.map((item) => (
                <CButton
                  color="outline-secondary"
                  key={item.key}
                  active={item.key === selectedStatButton.key}
                  onClick={() => changeStatisticButton(item)}>
                  {item.value}
                </CButton>
              ))}
            </CButtonGroup>
          </CCol>
        </CRow>

        <br />
      </CCardBody>

      <div className="col-md-10">
        <h4>Post Statistics</h4>
        <div className="chart-wrapper">
          <CChart
            style={{width: '100%'}}
            type="line"
            datasets={bar.datasets}
            options={{maintainAspectRatio: true}}
            labels={bar.labels}
          />
        </div>
        <hr />
      </div>
    </CCard>
  );
});

PostStats.displayName = 'PostStats';
