import {Axios} from '@utils';
import {constants} from '@resources';

export function getMostInteractedPosts(dateType?: string) {
  const today = new Date();

  const oneWeekAgo = new Date(today);
  oneWeekAgo.setDate(today.getDate() - 7);

  const oneMonthAgo = new Date(today);
  oneMonthAgo.setMonth(today.getMonth() - 1);

  const formatDate = (date: any) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}.${month}.${day}`;
  };

  const todayDate = formatDate(today);
  const oneWeekAgoDate = formatDate(oneWeekAgo);
  const oneMonthAgoDate = formatDate(oneMonthAgo);

  if (dateType === 'Day') {
    return Axios.get(
      `${constants.STATS_BASE}/postMostInteractedV2?startDate=${todayDate}&endDate=${todayDate}`,
    );
  } else if (dateType === 'Week') {
    return Axios.get(
      `${constants.STATS_BASE}/postMostInteractedV2?startDate=${oneWeekAgoDate}&endDate=${todayDate}`,
    );
  } else if (dateType === 'Month') {
    return Axios.get(
      `${constants.STATS_BASE}/postMostInteractedV2?startDate=${oneMonthAgoDate}&endDate=${todayDate}`,
    );
  } else {
    return Axios.get(
      `${constants.STATS_BASE}/postMostInteractedV2?startDate=2018-01-01&endDate=${todayDate}`,
    );
  }
}
