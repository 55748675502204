import {Axios} from '@utils';
import {constants} from '@resources';

export function createCategory(category: any, locale: string) {
  const parameters = {
    ...category,
    name: category.name_translate[category.locale],
    locale,
  };

  return Axios.post(`${constants.CATEGORY_BASE}`, parameters);
}
