import {take, call, put} from 'redux-saga/effects';
import type {Effect} from 'redux-saga/effects';

import {deleteCurationPosts as deleteCurationPostsService} from '@services/curationList';
import * as CurationActions from '@actions/curation';

export function* deleteCurationPosts(): Generator<Effect, void, any> {
  while (true) {
    try {
      const {deletePostId, curationId, onSucess} = yield take(
        CurationActions.DELETE_CURATION_POSTS.REQUEST,
      );
      const {data: response} = yield call(
        deleteCurationPostsService,
        deletePostId,
        curationId,
      );
      onSucess();
      yield put(CurationActions.deleteCurationPosts.success(deletePostId));
    } catch (error) {
      // Show info
      yield put(CurationActions.deleteCurationPosts.failure(error));
    }
  }
}
