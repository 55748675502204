import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {
  CCard,
  CCardHeader,
  CCol,
  CDataTable,
  CRow,
  CButton,
  CModal,
  CModalBody,
  CModalHeader,
  CModalTitle,
  CModalFooter,
  CInputCheckbox,
} from '@coreui/react';

import {getCategories as getCategoriesAction} from '@actions/categories';

import type {ChangeEvent} from 'react';

type Props = {
  setCategoryModal: Function;
  categoryModal: boolean;
  setCategorie: Function;
};
const AddCategory: React.FC<Props> = (props: Props) => {
  const dispatch = useDispatch();
  const {categoryModal, setCategoryModal, setCategorie} = props;

  const {categoryList, selectedLocale} = useSelector((state) => ({
    categoryList: state.categories.categoriesList,
    selectedLocale: state.management.selectedLocale || {locale: 'gb'},
  }));

  const [selectedCategorie, setSelectedCategorie] = useState<any>();

  const handleOnRowClick = (event: ChangeEvent<HTMLInputElement>) => {
    if (selectedCategorie == event) {
      setSelectedCategorie(undefined);
    } else {
      setSelectedCategorie(event);
    }
  };

  const handleDone = () => {
    setCategorie(selectedCategorie);
    setCategoryModal(false);
  };

  const handleCancel = () => {
    setCategoryModal(false);
  };

  useEffect(() => {
    dispatch(getCategoriesAction.request(1));
  }, []);

  return (
    <CModal show={categoryModal} size="lg" onClose={setCategoryModal}>
      <CModalHeader>
        <CModalTitle>
          All Categories({categoryList.categoriesCount})
        </CModalTitle>
        <CModalTitle>Select 1 Category</CModalTitle>
      </CModalHeader>
      <CModalBody>
        <CRow>
          <CCol xl={12}>
            <CCard>
              <CCardHeader>Categories</CCardHeader>
              <CDataTable
                items={categoryList.categories}
                fields={[
                  'id',
                  {
                    key: 'name',
                    label: 'Name',
                  },
                  'status',
                  {
                    key: 'select_row',
                    label: '',
                    _style: {width: '5%'},
                    sorter: false,
                    filter: false,
                  },
                ]}
                hover
                onRowClick={(event: ChangeEvent<HTMLInputElement>) => {
                  handleOnRowClick(event);
                }}
                border
                size="sm"
                striped
                clickableRows
                scopedSlots={{
                  name: (item: any) => (
                    <td key={item.id} className="py-2">
                      {selectedLocale.locale === 'gb'
                        ? item.name_translate.en
                        : item.name_translate.tr}
                    </td>
                  ),
                  select_row: (item: any) => {
                    // const isChecked = selectedCategorie.id === item.id;
                    return (
                      <td key={item.id} className="py-2">
                        {selectedCategorie &&
                        item.id === selectedCategorie.id ? (
                          <CInputCheckbox
                            key={item.id}
                            name={item.id}
                            disabled={false}
                            className="checkbox"
                            checked={true}
                          />
                        ) : (
                          <CInputCheckbox
                            key={item.id}
                            name={item.id}
                            disabled={false}
                            className="checkbox"
                            checked={false}
                          />
                        )}
                      </td>
                    );
                  },
                }}
              />
            </CCard>
          </CCol>
        </CRow>
      </CModalBody>
      <CModalFooter className="d-flex justify-content-between">
        <CButton className="col-3" onClick={handleCancel} color="danger">
          Cancel
        </CButton>
        <CButton className="col-3" onClick={handleDone} color="success">
          Done
        </CButton>
      </CModalFooter>
    </CModal>
  );
};

export default AddCategory;
