export const values = {
  fields: [
    {
      key: 'id',
      _style: {width: '5%', minWidth: '5%'},
    },
    {
      key: 'photo',
      _style: {width: '5%'},
    },
    {
      key: 'userName',
      _style: {width: '18%'},
    },
    {
      key: 'fullName',
      _style: {width: '17%'},
    },
    {
      key: 'email',
      label: 'Email',
      _style: {width: '18%'},
    },
    {
      key: 'referrer',
      label: 'Referrer',
      _style: {minWidth: '17%'},
    },
    {
      key: 'status',
      label: 'Status',
      _style: {width: '15%'},
    },
    {
      key: 'select_edit_row',
      label: '',
      _style: {width: '5%'},
    },
  ],
};
