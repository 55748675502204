import {Axios} from '@utils';
import {constants} from '@resources';

export function addCategoriesPosts(postId: any, categoryId: number) {
  const parameters = {
    postIds: postId,
  };
  return Axios.post(
    `${constants.CATEGORY_BASE}/${categoryId}/posts`,
    parameters,
  );
}
