import {take, call, put} from 'redux-saga/effects';
import type {Effect} from 'redux-saga/effects';

import {updateCuration as updateCurationService} from '@services/curationList';
import * as CurationActions from '@actions/curation';

export function* updateCuration(): Generator<Effect, void, any> {
  while (true) {
    try {
      const {updateCuration, onSucess} = yield take(
        CurationActions.UPDATE_CURATION.REQUEST,
      );

      const {data: response} = yield call(
        updateCurationService,
        updateCuration,
      );
      yield put(CurationActions.updateCuration.success(response));
      onSucess();
      yield put(CurationActions.getCurationDetail.request(updateCuration.id));
    } catch (error) {
      // Show info
      yield put(CurationActions.updateCuration.failure(error));
    }
  }
}
