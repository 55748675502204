import {take, call, put} from 'redux-saga/effects';
import type {Effect} from 'redux-saga/effects';

import {postMoveContent as postMoveContentService} from '@services/categories';
import * as CategoryActions from '@actions/categories';
import * as NotificationActions from '@actions/notifications';

export function* postMoveContent(): Generator<Effect, void, any> {
  while (true) {
    try {
      const {oldCategoryId, newCategoryId} = yield take(
        CategoryActions.MOVE_CONTENT.REQUEST,
      );
      yield call(postMoveContentService, oldCategoryId, newCategoryId);

      yield put(CategoryActions.moveContent.success());
      yield put(NotificationActions.openSuccessMessage.request());
    } catch (error) {
      // Show info
      yield put(CategoryActions.moveContent.failure(error));
    }
  }
}
