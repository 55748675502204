export const values = {
  fields: [
    {
      key: 'id',
      label: 'Id',
      _style: {width: '4%'},
    },
    {
      key: 'logo',
      label: 'Logo',
      _style: {width: '5%'},
    },
    {
      key: 'title',
      label: 'Title',
      sorter: false,
      filter: false,
    },
    {
      key: 'link',
      label: 'Link',
      // _style: {width: '15%'},
    },
    {
      key: 'date',
      label: 'Date',
    },

    {
      key: 'category',
      label: 'Category',
      _style: {width: '15%'},
    },
    {
      key: 'user',
      label: 'User',
      _style: {width: '10%'},
    },
  ],
};
