import {take, call, put} from 'redux-saga/effects';
import type {Effect} from 'redux-saga/effects';

import {getDynamicLink as getDynamicLinkService} from '@services/curationList';
import * as CurationActions from '@actions/curation';

export function* getDynamicLink(): Generator<Effect, void, any> {
  while (true) {
    try {
      const {curationId, onSuccess} = yield take(
        CurationActions.GET_DYNAMIC_LINK.REQUEST,
      );
      const {data: resnponse} = yield call(getDynamicLinkService, curationId);
      yield put(CurationActions.getDynamicLink.success(resnponse.data));
      onSuccess(resnponse.data.dynamicLink);
    } catch (error) {
      yield put(CurationActions.getDynamicLink.failure(error));
    }
  }
}
