export const values = {
  fields: [
    {
      key: 'name',
      label: '#Tag Name',
      _style: {width: '20%'},
      sorter: true,
      filter: true,
    },
    {
      key: 'count',
      label: 'Post Count',
      sorter: false,
      filter: false,
      _style: {width: '20%'},
    },
    {
      key: 'category',
      label: 'Category',
      _style: {width: '20%'},
      sorter: true,
      filter: true,
    },
    {
      key: 'actions',
      label: 'Actions',
      sorter: true,
      filter: true,
      _style: {width: '20%'},
    },
    {
      key: 'delete',
      label: '',
      sorter: false,
      filter: false,
      _style: {width: '20%'},
    },
  ],
};
