import React, {useState, ChangeEvent, useEffect} from 'react';
import {useHistory} from 'react-router-dom';
import {useDispatch, useSelector, shallowEqual} from 'react-redux';

import {
  CCard,
  CCardBody,
  CCol,
  CRow,
  CInput,
  CButton,
  CFormGroup,
  CLabel,
  CSelect,
  CForm,
  CImg,
  CCardHeader,
} from '@coreui/react';

import {
  updateCategory as updateCategoryAction,
  createCategory as createCategoryAction,
  moveContent as moveContentAction,
  deleteCategory as deleteCategoryAction,
} from '@actions/categories';

import {closeSuccessMessage as closeSuccessMessageAction} from '@actions/notifications';
import {getRandomCover as getRandomCoverAction} from '@actions/curation';
import {getCategories as getCategoriesAction} from '@actions/categories';

import {uploadPhoto} from '../../../../../../services/utils';
import {WSRModal, WSRUploadPhoto} from '../../../../../../components';
import {
  WSRModalNotification,
  WSRModalDelete,
  WSRModalSave,
} from './../../components';
import AddCategory from './../AddCategory';

type Props = {
  categoryInitial: any;
  setCategoryInitial: any;
};

export const Detail: React.FC<Props> = (props) => {
  const {
    categoryInitial = {name_translate: {en: '', tr: ''}},
    setCategoryInitial,
  } = props;

  const history = useHistory();
  const dispatch = useDispatch();

  const {languages, selectedLocale, succesMessage, coverPhoto} = useSelector(
    (state) => ({
      languages: state.management.languages,
      selectedLocale: state.management.selectedLocale || {locale: 'gb'},
      succesMessage: state.notifications.succesMessage,
      coverPhoto: state.curation.randomCover,
    }),
    shallowEqual,
  );

  const [showModal, setShowModal] = useState(false);
  const [saveChangesModal, setSaveChangesModal] = useState(false);
  const [selectedCategoryToMove, setSelectedCategoryToMove] = useState<any>();
  const [sendNotificationModal, setSendNotificationModal] = useState<boolean>(
    false,
  );
  const [categoryModal, setCategoryModal] = useState(false);

  const [photo, setPhoto] = useState('');
  const [photoWarning, setPhotoWarning] = useState(false);
  const [imageModal, setImageModal] = useState(false);
  const [croppedImage, setCroppedImage] = useState('');

  const saveImage = () => {
    uploadPhoto(croppedImage)
      .then((response) => {
        setPhoto(response.data.data.url);
      })
      .catch((err) => {
        console.log('hata', err);
      });
    setImageModal(false);
    setPhotoWarning(false);
  };

  const handleChangeName = (event: ChangeEvent<HTMLInputElement>) => {
    event.persist();
    setCategoryInitial((categoryInitial: any) => ({
      ...categoryInitial,
      name_translate: {
        ...categoryInitial?.name_translate,
        [event.target.name]: event.target.value,
      },
    }));
  };

  const handleChangeStatus = (event: ChangeEvent<HTMLInputElement>) => {
    event.persist();
    setCategoryInitial((categoryInitial: any) => ({
      ...categoryInitial,
      status: event.target.value,
    }));
  };

  const handleDeleteCategory = () => {
    dispatch(
      deleteCategoryAction.request(categoryInitial.id, () => {
        history.push(`/categories`);
      }),
    );
  };

  const handleSubmit = async (event: ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();

    if (categoryInitial.id === -1 || categoryInitial.id === undefined) {
      dispatch(
        createCategoryAction.request(
          {...categoryInitial, photo: photo},
          selectedLocale.locale,
          () => {
            history.push(`/categories`);
          },
        ),
      );
    } else {
      if (selectedCategoryToMove) {
        dispatch(
          moveContentAction.request(
            categoryInitial.id,
            selectedCategoryToMove.id,
          ),
        );
      } else {
        dispatch(
          updateCategoryAction.request(
            {...categoryInitial, photo: photo},
            () => {
              dispatch(getCategoriesAction.request(0));
              setShowModal(true);
            },
          ),
        );
      }
    }
    setSaveChangesModal(false);
    history.push(`/categories`);
  };

  const handleCancel = () => {
    setCategoryInitial(categoryInitial);
    history.push(`/categories`);
  };

  useEffect(() => {
    dispatch(getRandomCoverAction.request());
  }, []);

  return (
    <CRow>
      <CCol sm={12} lg={3} style={{marginTop: 10}}>
        <CCard style={{borderColor: photoWarning ? 'red' : 'white'}}>
          <CCardHeader>
            <div className="my-4">
              <img
                className="c-avatar-curation"
                src={
                  !photo
                    ? !categoryInitial.photo
                      ? coverPhoto
                      : categoryInitial.photo
                    : photo
                }
                alt=""></img>
            </div>
            <span
              style={{
                display: 'flex',
                flexDirection: 'column',
                marginTop: -15,
                gap: 5,
              }}>
              {photoWarning ? (
                <span
                  style={{
                    width: '%100',
                    color: 'red',
                    textAlign: 'end',
                  }}>
                  This field is required*
                </span>
              ) : (
                ''
              )}
              <CButton
                style={{width: 111}}
                color="primary"
                onClick={() => {
                  setImageModal(true);
                }}>
                Add Photo
              </CButton>
            </span>
          </CCardHeader>
        </CCard>
      </CCol>
      <CCol sm={12} lg={9} style={{marginTop: 10}}>
        <CForm onSubmit={handleSubmit}>
          <CCard>
            <CCardBody>
              {languages.map((item) => (
                <CFormGroup key={item.code}>
                  <CLabel htmlFor="name">{item.name}</CLabel>
                  <CInput
                    className="form-control-warning"
                    type="text"
                    value={categoryInitial?.name_translate[item.code] || ''}
                    name={item.code}
                    placeholder={
                      categoryInitial?.name_translate[item.code]
                        ? categoryInitial?.name_translate[item.code]
                        : ''
                    }
                    autoComplete="categoryName"
                    onChange={handleChangeName}
                    // required
                  />
                </CFormGroup>
              ))}
              <CFormGroup>
                <CLabel htmlFor="name">Status</CLabel>
                <CSelect
                  name="status"
                  disabled={categoryInitial?.id !== undefined ? false : true}
                  id="select"
                  value={
                    categoryInitial.status ? categoryInitial.status : false
                  }
                  onChange={handleChangeStatus}>
                  <option value="true">True</option>
                  <option value="false">False</option>
                </CSelect>
              </CFormGroup>
              <WSRModal
                onShowModal={showModal}
                setOnShowModal={setShowModal}
                modalTitle={'Update Operation'}
                modalBody={'Changes saved successfully!'}
                operationButton={() => {
                  setShowModal(false);
                  history.push(`/categories`);
                }}
                operationButtonText="OK"
              />
              <CFormGroup>
                <CLabel htmlFor="categoryNameToMove">Category Name</CLabel>
                <span
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    gap: 22,
                  }}>
                  <CInput
                    style={{width: 355}}
                    className="form-control-warning"
                    type="text"
                    value={
                      selectedCategoryToMove
                        ? selectedLocale.locale === 'gb'
                          ? selectedCategoryToMove.name_translate.en
                          : selectedCategoryToMove.name_translate.tr
                        : ''
                    }
                    name={selectedCategoryToMove}
                    autoComplete="categoryNameToMove"
                    disabled
                  />
                  <CButton
                    onClick={() => {
                      setCategoryModal(true);
                    }}
                    color="primary">
                    Move to Another Category
                  </CButton>
                </span>
              </CFormGroup>
            </CCardBody>
          </CCard>
          <div
            style={{display: 'flex', justifyContent: 'flex-end'}}
            className="my-2 mfs-auto form-actions">
            <span
              onClick={() => setSendNotificationModal(true)}
              style={{
                display: 'flex',
                flexDirection: 'row',
                gap: 5,
                height: 16,
                marginTop: 10,
                marginRight: 26,
                alignItems: 'center',
                justifyContent: 'center',
                cursor: 'grab',
              }}>
              <CImg
                height={15}
                width={13}
                src={'/avatars/delete.png'}
                className=""
                onClick={() => {}}
                alt=" "
              />
              <h6
                style={{
                  color: '#E45353',
                  paddingTop: 8,
                }}>
                Delete Category
              </h6>
            </span>
            <CButton
              onClick={handleCancel}
              style={{backgroundColor: '#9DA5B1', color: '#fff', width: 160}}>
              Cancel
            </CButton>
            <CButton
              onClick={() => {
                //denem
                if (!photo) {
                  setPhotoWarning(true);
                } else {
                  setSaveChangesModal(true);
                }
              }}
              style={{
                backgroundColor: '#2EB85C',
                color: '#fff',
                marginLeft: 14,
                width: 160,
              }}>
              Save changes
            </CButton>
          </div>
        </CForm>
      </CCol>

      <WSRModalDelete
        onShowModal={sendNotificationModal}
        setOnShowModal={setSendNotificationModal}
        modalTitle={'Delete Category'}
        modalBody={`Are you sure want to delete this category?`}
        operationButton={handleDeleteCategory}
        operationButtonText={'Delete'}
        cancelButton={() => {
          setSendNotificationModal(false);
        }}
        cancelButtonText={'Close'}
      />

      <WSRUploadPhoto
        onShowModal={imageModal}
        setModal={setImageModal}
        setUserImage={saveImage}
        croppedImageUrl={croppedImage}
        setCroppedImageUrl={setCroppedImage}
      />

      <WSRModalSave
        onShowModal={saveChangesModal}
        setOnShowModal={setSaveChangesModal}
        modalTitle={'Update Category'}
        modalBody={`Do you want to save these changes?`}
        operationButton={handleSubmit}
        operationButtonText={'Save'}
        cancelButton={() => {
          setSaveChangesModal(false);
        }}
        cancelButtonText={'Close'}
      />

      <AddCategory
        setCategoryModal={setCategoryModal}
        categoryModal={categoryModal}
        setCategorie={setSelectedCategoryToMove}
      />

      <WSRModalNotification
        onShowModal={succesMessage}
        message={`Successfully saved your changes!!`}
        operationButton={() => dispatch(closeSuccessMessageAction.request())}
        operationButtonText={'OK'}
      />
    </CRow>
  );
};
