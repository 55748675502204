import {take, call, put} from 'redux-saga/effects';
import type {Effect} from 'redux-saga/effects';

import {deleteCategory as deleteCategoryService} from '@services/categories';
import * as CategoryActions from '@actions/categories';
import * as NotificationActions from '@actions/notifications';

export function* deleteCategory(): Generator<Effect, void, any> {
  while (true) {
    try {
      const {categoryId, onSucess} = yield take(
        CategoryActions.DELETE_CATEGORY.REQUEST,
      );
      yield call(deleteCategoryService, categoryId);
      onSucess();
      yield put(NotificationActions.openSuccessMessage.request());
      yield put(CategoryActions.deleteCategory.success(categoryId));
    } catch (error) {
      // Show info
      yield put(CategoryActions.deleteCategory.failure(error));
    }
  }
}
