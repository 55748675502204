import {take, call, put} from 'redux-saga/effects';
import type {Effect} from 'redux-saga/effects';

import {updateOnBoardingOrder as updateOnBoardingOrderService} from '@services/users';
import * as UsersActions from '@actions/users';

export function* updateOnBoardingOrder(): Generator<Effect, void, any> {
  while (true) {
    try {
      const {updatedList} = yield take(
        UsersActions.UPDATE_ONBOARDING_ORDER.REQUEST,
      );
      const {data: response} = yield call(
        updateOnBoardingOrderService,
        updatedList,
      );

      yield put(
        UsersActions.getOnBoardingUsers.success(response.data, response.count),
      );
    } catch (error) {
      // Show info
      yield put(UsersActions.updateOnBoardingOrder.failure(error));
    }
  }
}
