import {Axios} from '@utils';
import {constants} from '@resources';

export function addCategoryToOnboardingCategories(
  categoryId: any,
  locale: any,
) {
  const parameters = {
    categoryId: categoryId.id,
    locale: locale.locale,
  };
  return Axios.post(`${constants.ONBOARDING_BASE}/categories`, parameters);
}
