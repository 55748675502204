import React from 'react';
import {CModal, CModalBody, CButton} from '@coreui/react';

type Props = {
  onShowModal: boolean;
  message: string;
  operationButton: Function;
  operationButtonText: string;
};

export const WSRModalNotification: React.FC<Props> = (props) => {
  const {onShowModal, message, operationButton, operationButtonText} = props;

  return (
    <CModal style={{width: 495, height: 93}} show={onShowModal}>
      <CModalBody
        style={{
          alignItems: 'center',
          paddingLeft: 44,
          paddingRight: 18,
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}>
        {message}
        <CButton
          style={{
            width: 81,
            height: 47,
            color: '#fff',
            backgroundColor: '#2EB85C',
          }}
          onClick={operationButton}>
          {operationButtonText}
        </CButton>
      </CModalBody>
    </CModal>
  );
};
