import React from 'react';
import {CAlert} from '@coreui/react';

type Props = {
  isValid: boolean;
  text: string;
};

export const InputWarning: React.FC<Props> = (props) => {
  const {isValid, text} = props;
  return (
    <div>
      {isValid !== undefined ? (
        isValid ? (
          <CAlert color="warning">{text}</CAlert>
        ) : null
      ) : null}
    </div>
  );
};

InputWarning.defaultProps = {
  text: 'Check Your Email Adress!!',
};
