export const images = {
  // icons...
  avatar: require('./images/icons/blank-profile.jpg'),
  spotify: require('./images/icons/spotify-icon.png'),
  youtube: require('./images/icons/youtube-icon.png'),
  other: require('./images/icons/article-icon.png'),
  plusBlack: require('./images/icons/plus-black.png'),
  plusIcon: require('./images/icons/close-icon.png'),
  playIcon: require('./images/icons/play.png'),
  warningIcon: require('./images/icons/warning-icon.png'),
};
