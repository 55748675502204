import React, {ChangeEvent, useEffect, useRef, useState} from 'react';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import {
  CCardBody,
  CCol,
  CImg,
  CInput,
  CLabel,
  CRow,
  CSelect,
  CCard,
  CCardHeader,
  CButton,
} from '@coreui/react';

import {
  getTags as getTagsAction,
  deleteTag as deleteTagAction,
  createTag as createTagAction,
  removeErrorMEssage as removeErrorMessageAction,
} from '@actions/tags';
import {closeSuccessMessage as closeSuccessMessageAction} from '@actions/notifications';

import {
  WSRDataTable,
  AddCategory,
  WSRModal,
  WSRModalNotification,
} from './components';
import {values} from './values';
import {useHistory} from 'react-router-dom';

const TagsPage: React.FC = (match?: any) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const [page, setPage] = useState(() => {
    const params = new URLSearchParams(match.location.search);

    const page = params.get('page');
    return page ? parseInt(page, 10) : 1;
  });
  const [addCategoryModal, setAddCategoryModal] = useState(false);
  const [selectedTagId, setSelectedTagId] = useState(0);
  const [selectedTag, setSelectedTag] = useState();
  const [tagIdToDelete, setTagIdToDelete] = useState(0);
  const [orderTag, setOrderTag] = useState('Any');
  const [tagName, setTagName] = useState('');
  const [sendNotificationModal, setSendNotificationModal] = useState<boolean>(
    false,
  );
  const [searchFields, setSearchFields] = useState({
    name: '',
    orderBy: '',
    orderDirection: '',
    offset: 0,
    limit: 3000,
  });
  const [orderMatches, setOrderMatches] = useState<any>();
  const [createNewTag, setCreateNewTag] = useState(false);
  const [createdInput, setCreatedInput] = useState('');

  const searchFieldsRef = useRef<{
    [key: string]: any;
  }>();

  const {tagList, succesMessage, errorDescription} = useSelector(
    (state) => ({
      errorDescription: state.tags.errorDescription,
      tagList: state.tags.tagList,
      succesMessage: state.notifications.succesMessage,
    }),
    shallowEqual,
  );

  const handleChangeOrder = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.value === 'categoryDescending') {
      setOrderTag(event.target.value);
      pageChange(page, 'category_name', 'DESC');
    } else if (event.target.value === 'categoryAscending') {
      setOrderTag(event.target.value);
      pageChange(page, 'category_name', 'ASC');
    } else if (event.target.value === 'tagNameDescending') {
      setOrderTag(event.target.value);
      pageChange(page, 'name', 'DESC');
    } else if (event.target.value === 'tagNameAscending') {
      setOrderTag(event.target.value);
      pageChange(page, 'name', 'ASC');
    } else if (event.target.value === 'postCountDescending') {
      setOrderTag(event.target.value);
      pageChange(page, 'post_count', 'DESC');
    } else if (event.target.value === 'postCountAscending') {
      setOrderTag(event.target.value);
      pageChange(page, 'post_count', 'ASC');
    } else if (event.target.value === 'any') {
      setOrderTag(event.target.value);
      pageChange(page, 'created_at', 'DESC');
    }
  };

  const scopedSlots = {
    name: (item: any) => (
      <td style={{height: 52}}>
        <div style={{height: 51, display: 'flex', alignItems: 'center'}}>
          {item.name}
        </div>
      </td>
    ),
    count: (item: any) => (
      <td>
        <div style={{height: 51, display: 'flex', alignItems: 'center'}}>
          {item.post_count}
        </div>
      </td>
    ),
    category: (item: any) => (
      <td>
        <div style={{height: 51, display: 'flex', alignItems: 'center'}}>
          {item.category_name}
        </div>
      </td>
    ),
    actions: (item: any) => (
      <td
        onClick={() => {
          setAddCategoryModal(true);
          setSelectedTagId(item.id);
          setSelectedTag(item);
        }}>
        {item.category_name ? (
          <div
            style={{
              height: 51,
              display: 'flex',
              alignItems: 'center',
              color: '#321FDB',
            }}>
            Change category
          </div>
        ) : (
          <div
            style={{
              height: 51,
              display: 'flex',
              alignItems: 'center',
              color: '#321FDB',
            }}>
            Add to category
          </div>
        )}
      </td>
    ),
    delete: (item: any) => (
      <td
        onClick={() => {
          setTagIdToDelete(item.id);
          setSendNotificationModal(true);
        }}>
        <span
          style={{
            height: 51,
            display: 'flex',
            flexDirection: 'row',
            gap: 5,
            alignItems: 'center',
          }}>
          <CImg
            width={13}
            height={15}
            src={'avatars/delete.png'}
            className=""
            alt={'avatars/delete.png'}
          />
          <div style={{color: '#E45353'}}>Delete tag</div>
        </span>
      </td>
    ),
  };

  const pageChange = (
    newPage: number,
    orderBy?: string,
    orderDirection?: string,
    name?: string,
  ) => {
    setPage(newPage);
    let search = `?page=${newPage}`;
    if (orderBy && orderDirection && orderBy !== '' && orderDirection !== '') {
      if (orderBy === 'category_name' && orderDirection === 'DESC') {
        search = search + '&order=categoryDescending';
      } else if (orderBy === 'category_name' && orderDirection === 'ASC') {
        search = search + '&order=categoryAscending';
      } else if (orderBy === 'name' && orderDirection === 'DESC') {
        search = search + '&order=tagNameDescending';
      } else if (orderBy === 'name' && orderDirection === 'ASC') {
        search = search + '&order=tagNameAscending';
      } else if (orderBy === 'post_count' && orderDirection === 'DESC') {
        search = search + '&order=postCountDescending';
      } else if (orderBy === 'post_count' && orderDirection === 'ASC') {
        search = search + '&order=postCountAscending';
      } else {
        search = search + '&order=any';
      }
      setSearchFields({
        ...searchFields,
        orderBy: orderBy,
        orderDirection: orderDirection,
      });
    } else {
      if (orderMatches !== null) {
        search = search + '&order=' + orderTag;
      } else {
        search = search + '&order=' + orderMatches;
      }
    }
    if (name || name === '') {
      search = search + '&tagName=' + name;
      setSearchFields({
        ...searchFields,
        name: name,
      });
    } else {
      search = search + '&tagName=' + tagName;
      setSearchFields({
        ...searchFields,
        name: tagName,
      });
    }

    history.push({pathname: '/tags', search});
  };

  useEffect(() => {
    let searchObject = {
      name: '',
      orderBy: '',
      orderDirection: '',
      offset: 0,
      limit: 3000,
    };
    const params = new URLSearchParams(match.location.search);

    const pageMatch = params.get('page');
    if (pageMatch) setPage(page ? parseInt(pageMatch, 10) : 1);

    const orderMatch = params.get('order');
    setOrderMatches(orderMatch);
    if (orderMatch) {
      if (orderMatch === 'categoryDescending') {
        setOrderTag(orderMatch);
        setSearchFields({
          ...searchFields,
          orderBy: 'category_name',
          orderDirection: 'DESC',
        });
        searchObject = {
          ...searchObject,
          orderBy: 'category_name',
          orderDirection: 'DESC',
        };
      } else if (orderMatch === 'categoryAscending') {
        setOrderTag(orderMatch);
        setSearchFields({
          ...searchFields,
          orderBy: 'category_name',
          orderDirection: 'ASC',
        });
        searchObject = {
          ...searchObject,
          orderBy: 'category_name',
          orderDirection: 'ASC',
        };
      } else if (orderMatch === 'tagNameDescending') {
        setOrderTag(orderMatch);
        setSearchFields({
          ...searchFields,
          orderBy: 'name',
          orderDirection: 'DESC',
        });
        searchObject = {
          ...searchObject,
          orderBy: 'name',
          orderDirection: 'DESC',
        };
      } else if (orderMatch === 'tagNameAscending') {
        setOrderTag(orderMatch);
        setSearchFields({
          ...searchFields,
          orderBy: 'name',
          orderDirection: 'ASC',
        });
        searchObject = {
          ...searchObject,
          orderBy: 'name',
          orderDirection: 'ASC',
        };
      } else if (orderMatch === 'postCountDescending') {
        setOrderTag(orderMatch);
        setSearchFields({
          ...searchFields,
          orderBy: 'post_count',
          orderDirection: 'DESC',
        });
        searchObject = {
          ...searchObject,
          orderBy: 'post_count',
          orderDirection: 'DESC',
        };
      } else if (orderMatch === 'postCountAscending') {
        setOrderTag(orderMatch);
        setSearchFields({
          ...searchFields,
          orderBy: 'post_count',
          orderDirection: 'ASC',
        });
        searchObject = {
          ...searchObject,
          orderBy: 'post_count',
          orderDirection: 'ASC',
        };
      } else if (orderMatch === 'any') {
        setOrderTag(orderMatch);
        setSearchFields({
          ...searchFields,
          orderBy: 'created_at',
          orderDirection: 'DESC',
        });
        searchObject = {
          ...searchObject,
          orderBy: 'created_at',
          orderDirection: 'DESC',
        };
      }
    }

    const tagNameMatch = params.get('tagName');
    if (tagNameMatch) {
      searchObject = {...searchObject, name: tagNameMatch};
      setSearchFields({
        ...searchFields,
        name: tagNameMatch,
      });
      setTagName(tagNameMatch);
    }

    searchFieldsRef.current = searchObject;
    dispatch(getTagsAction.request(searchObject));
  }, [match.location.search]);

  useEffect(() => {
    dispatch(getTagsAction.request());
  }, []);

  return (
    <>
      <CRow>
        <CCol>
          <CCard>
            <CCardHeader
              style={{
                display: 'flex',
                flexDirection: 'column',
                paddingLeft: 30,
                gap: 20,
                paddingBottom: 23,
              }}>
              <h3 style={{display: 'flex', marginTop: 30}}>
                Tags ({tagList.count})
              </h3>
              <span
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}>
                <span
                  style={{
                    gap: 11,
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                  }}>
                  <CLabel style={{marginTop: 4}}>Tag Name</CLabel>
                  <CInput
                    style={{width: 221}}
                    value={tagName}
                    className="form-control"
                    type="text"
                    placeholder="Search"
                    onChange={(e: ChangeEvent<HTMLLIElement>) => {
                      setTagName(e.target.value.toString());
                      pageChange(page, '', '', e.target.value.toString());
                    }}
                  />
                  <CSelect
                    style={{width: 221, marginLeft: 22}}
                    name="tagOrder"
                    id="tagOrder"
                    value={orderTag}
                    onChange={handleChangeOrder}>
                    <option value="any">Any</option>
                    <option value="categoryDescending">
                      Category Descending
                    </option>
                    <option value="categoryAscending">
                      Category Ascending
                    </option>
                    <option value="tagNameDescending">
                      Tag Name Descending
                    </option>
                    <option value="tagNameAscending">Tag Name Ascending</option>
                    <option value="postCountDescending">
                      Post Count Descending
                    </option>
                    <option value="postCountAscending">
                      Post Count Ascending
                    </option>
                  </CSelect>
                </span>
                <span style={{marginTop: '-25px'}}>
                  {createNewTag ? (
                    <span
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        gap: 12,
                      }}>
                      <span>Tag Name:</span>
                      <CInput
                        value={createdInput}
                        onChange={(a: any) => {
                          setCreatedInput(a.target.value);
                        }}
                        placeholder="Type tag name to create..."
                        style={{marginRight: '10px'}}
                      />
                      <CButton
                        onClick={() => {
                          dispatch(createTagAction.request(createdInput));
                          setCreatedInput('');
                          setCreateNewTag(false);
                        }}
                        color="success"
                        style={{backgroundColor: '#2EB85C'}}>
                        Create
                      </CButton>
                      <span
                        style={{cursor: 'grab'}}
                        onClick={() => {
                          setCreateNewTag(false);
                          setCreatedInput('');
                        }}>
                        Cancel
                      </span>
                    </span>
                  ) : (
                    <h4
                      onClick={() => setCreateNewTag(true)}
                      style={{marginTop: 35, cursor: 'grab', color: '#321FDB'}}>
                      + Create New Tag
                    </h4>
                  )}
                </span>
              </span>
            </CCardHeader>
            <CCardBody
              style={{
                marginLeft: '-41px',
                marginTop: '-40px',
                borderRadius: 0,
              }}>
              <CCardBody>
                <WSRDataTable
                  items={tagList.data}
                  fields={values.fields}
                  scopedSlots={scopedSlots}
                  pageLimit={30}
                  page={page}
                  pageChange={pageChange}
                  itemsCount={tagList.count}
                />
              </CCardBody>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
      <AddCategory
        tagID={selectedTagId}
        setCategoryModal={setAddCategoryModal}
        categoryModal={addCategoryModal}
        searchFields={searchFieldsRef.current}
        selectedTag={selectedTag}
      />
      <WSRModal
        onShowModal={sendNotificationModal}
        setOnShowModal={setSendNotificationModal}
        modalTitle={'Delete Post'}
        modalBody={`Are you sure you want to delete this tag?`}
        operationButton={() => {
          dispatch(deleteTagAction.request(tagIdToDelete));
          setSendNotificationModal(false);
        }}
        operationButtonText={'Delete'}
        cancelButton={() => {
          setSendNotificationModal(false);
        }}
        cancelButtonText={'Close'}
      />

      <WSRModalNotification
        onShowModal={succesMessage}
        message={`Successfully deleted!`}
        operationButton={() => dispatch(closeSuccessMessageAction.request())}
        operationButtonText={'OK'}
      />

      <WSRModalNotification
        onShowModal={
          !(errorDescription === '' || errorDescription === undefined)
        }
        message={errorDescription}
        operationButton={() => dispatch(removeErrorMessageAction.request())}
        operationButtonText={'OK'}
      />
    </>
  );
};

export default TagsPage;
