import React, {useState} from 'react';
import {useHistory} from 'react-router-dom';
import {useDispatch} from 'react-redux';
import moment from 'moment';

import {
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CRow,
  CImg,
  CAlert,
} from '@coreui/react';
import {deleteCategoriesPosts as deleteCategoriesPostsAction} from '@actions/posts';
import {WSRModal, WSRDataTable} from '../../components';
import {values} from './values';

type Props = {
  page: number;
  categoryInitial: any;
  pageChange: Function;
  postList: Array<any>;
  count: number;
};

export const Posts: React.FC<Props> = (props) => {
  const {page, categoryInitial, pageChange, postList, count} = props;
  const history = useHistory();
  const [alert, setAlert] = useState(false);
  const [alertMsg, setAlertMsg] = useState('');
  const [modal, setModal] = useState(false);
  const [deletePostId, setDeletePostId] = useState(0);

  const pageLimit = 10;

  const dispatch = useDispatch();

  const scopedSlots = {
    title: (item: any) => (
      <td onClick={() => history.push(`/posts/${item.id}`)}>
        <div className="cut-text">{item.title}</div>
        <div className="small text-muted">
          Published: {moment(item.date).format('DD/MM/YYYY')}
        </div>
      </td>
    ),
    date: (item: any) => <td>{moment(item.createdAt).format('DD/MM/YYYY')}</td>,
    logo: (item: any) => (
      <td>
        <div className="c-avatar">
          <CImg src={item.logo} className="c-avatar-img" alt=" " />
        </div>
      </td>
    ),
    select_row: (item: any) => (
      <td className="py-2">
        <CImg
          src={'avatars/delete.png'}
          className=""
          onClick={() => {
            deleteValidation(item.id);
          }}
          alt={'avatars/delete.png'}
        />
      </td>
    ),
  };

  const deleteValidation = (id: number) => {
    setModal(true);
    setDeletePostId(id);
  };
  const deletePosts = () => {
    setModal(false);
    dispatch(
      deleteCategoriesPostsAction.request(
        deletePostId,
        categoryInitial.id,
        () => {
          setAlert(true);
          setAlertMsg('Post removed from this category successfully!');
        },
      ),
    );
  };

  return (
    <>
      <CRow>
        <CCol xl={12}>
          <CAlert
            show={alert}
            color="info"
            closeButton
            style={{marginTop: '15px'}}>
            {alertMsg}
          </CAlert>

          <CCard>
            <CCardHeader>Posts ({count})</CCardHeader>
            <CCardBody>
              <WSRDataTable
                items={postList}
                fields={values.fields}
                scopedSlots={scopedSlots}
                pageLimit={pageLimit}
                page={page}
                pageChange={pageChange}
                itemsCount={count}></WSRDataTable>
            </CCardBody>
          </CCard>

          <WSRModal
            onShowModal={modal}
            setOnShowModal={setModal}
            modalTitle={'Delete Post'}
            modalBody={`Are you sure you want to remove this post with id ${deletePostId}?`}
            operationButton={() => deletePosts()}
            operationButtonText={'Yes, remove it!'}
            cancelButton={() => {
              setModal(false);
            }}
            cancelButtonText={'Cancel'}
          />
        </CCol>
      </CRow>
    </>
  );
};
