import {take, call, put} from 'redux-saga/effects';
import type {Effect} from 'redux-saga/effects';

import {getOnboardingCategoryUsers as getOnboardingCategoryUsersService} from '@services/onboarding';
import * as OnboardingActions from '@actions/onboarding';

export function* getOnboardingCategoryUsers(): Generator<Effect, void, any> {
  while (true) {
    try {
      const {categoryId} = yield take(
        OnboardingActions.GET_ONBOARDNG_CATEGORY_USERS.REQUEST,
      );
      const {data: response} = yield call(
        getOnboardingCategoryUsersService,
        categoryId,
      );

      yield put(
        OnboardingActions.getOnboardingCategoryUsers.success(response.data),
      );
    } catch (error) {
      // Show info
      yield put(OnboardingActions.getOnboardingCategoryUsers.failure(error));
    }
  }
}
