import {Axios} from '@utils';
import {constants} from '@resources';

export async function updateOnboardingCategoryStatus(updatedCategory: any) {
  const parameters = {
    status: updatedCategory.status,
  };
  return Axios.put(
    `${constants.ONBOARDING_BASE}/categories/${updatedCategory.categoryId}`,
    parameters,
  );
}
