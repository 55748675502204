import {Axios} from '@utils';
import {constants} from '@resources';

export async function updatePublishers(updatedPublishers: any, locale: string) {
  const parameters = {
    locale: locale,
    userIds: updatedPublishers,
  };
  return Axios.put(
    `${constants.USER_MANAGEMENT_BASE}/discovery/order`,
    parameters,
  );
}
