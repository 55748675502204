import {Axios} from '@utils';
import {constants} from '@resources';

export async function getChooseMoodCurations(
  limit?: number,
  offset?: number,
  userCuration?: boolean,
  langSelection?: string,
) {
  if (langSelection === '["all"]')
    return await Axios.get(
      `${constants.CURATION_BASE}/mood/management?limit=${limit}&offset=${offset}&userCuration=${userCuration}&langSelection=["en","tr"]`,
    );
  return await Axios.get(
    `${constants.CURATION_BASE}/mood/management?limit=${limit}&offset=${offset}&userCuration=${userCuration}&langSelection=${langSelection}`,
  );
}
