import {take, call, put} from 'redux-saga/effects';
import type {Effect} from 'redux-saga/effects';

import {postCurationToChooseMood as postCurationToChooseMoodService} from '@services/chooseMood';
import * as CurationActions from '@actions/chooseMood';

export function* postCurationToChooseMood(): Generator<Effect, void, any> {
  while (true) {
    try {
      const {curationListId} = yield take(
        CurationActions.ADD_CURATION_TO_MOOD.REQUEST,
      );
      const {data: response} = yield call(
        postCurationToChooseMoodService,
        curationListId,
      );
      yield put(
        CurationActions.getMoodCurations.request(
          1000,
          0,
          false,
          '["tr", "en"]',
        ),
      );

      yield put(CurationActions.addCurationToMood.success(response.data));
    } catch (error) {
      // Show info
      yield put(CurationActions.addCurationToMood.failure(error));
    }
  }
}
