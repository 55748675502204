import React, {useEffect, ChangeEvent} from 'react';
import {
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CRow,
  CForm,
  CTextarea,
  CButton,
  CFormGroup,
  CLabel,
  CInput,
  CSelect,
  CAlert,
  CImg,
} from '@coreui/react';
import {useState} from 'react';
import {useHistory} from 'react-router-dom';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import {
  deleteUsers as deleteUsersAction,
  updateUsers as updateUsersAction,
  usersRoleChange as usersRoleChangeAction,
  usersStatusChange as usersStatusChangeAction,
  usersOnBoardingChange as usersOnBoardingChangeAction,
  usersPremiumChange as usersPremiumChangeAction,
} from '@actions/users';
import {WSRModal} from '../../components';

type Props = {match: any};

type InitialCurationData = {
  userName: string;
  fullName: string;
  email: string;
  //password: string;
  telephone: string;
  photo: string;
  jobTitle: string;
  bio: string;
  website: string;
  role: string;
  status: string;
  premium: boolean;
  showOnDiscoveryByLocal: Array<any>;
};

const initialCurationData: InitialCurationData = {
  userName: '',
  fullName: '',
  email: '',
  //password: '',
  telephone: '',
  photo: '',
  jobTitle: '',
  bio: '',
  website: '',
  role: '',
  status: '',
  premium: false,
  showOnDiscoveryByLocal: [],
};

const UserUpdatePage: React.FC<Props> = (props: Props) => {
  const {match} = props;
  const [userIn, setUserIn] = useState(initialCurationData);
  const [alert, setAlert] = useState(false);
  const [alertMsg, setAlertMsg] = useState('');
  const [modal, setModal] = useState(false);
  const [deletedWarningModal, setDeletedWarningModal] = useState(false);
  const [changesSavedModal, setChangesSavedModal] = useState(false);

  const dispatch = useDispatch();
  const history = useHistory();

  const {user} = useSelector(
    (state) => ({
      user: state.users.usersDetail,
    }),
    shallowEqual,
  );

  const onSubmit = () => {
    dispatch(
      updateUsersAction.request(userIn, () => {
        setAlert(true);
        setAlertMsg('Changes saved successfully!');
      }),
    );
  };

  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    event.persist();
    setUserIn((userIn) => ({
      ...userIn,
      [event.target.name]: event.target.value,
    }));
  };
  const handleDelete = () => {
    setModal(false);
    dispatch(
      deleteUsersAction.request(match.params.id, () => {
        setDeletedWarningModal(true);
      }),
    );
  };
  const handleRoleChange = () => {
    dispatch(
      usersRoleChangeAction.request(userIn, () => {
        setChangesSavedModal(true);
      }),
    );
  };
  const handleStatusChange = () => {
    dispatch(
      usersStatusChangeAction.request(userIn, () => {
        setChangesSavedModal(true);
      }),
    );
  };

  const handleOnBoardingChange = () => {
    dispatch(
      usersOnBoardingChangeAction.request(userIn, () => {
        setChangesSavedModal(true);
      }),
    );
  };

  const handlePremiumChange = () => {
    dispatch(
      usersPremiumChangeAction.request(
        {...userIn, premium: userIn.premium},
        () => {
          setChangesSavedModal(true);
        },
      ),
    );
  };

  useEffect(() => {
    if (user) {
      const premiumState: boolean = user.premium ?? false;
      setUserIn({...user, premium: premiumState});
    }
  }, [user]);

  return (
    <>
      <CForm onSubmit={onSubmit}>
        <CRow>
          <CCol sm={12} lg={3}>
            <CAlert
              show={alert}
              color="info"
              closeButton
              style={{marginTop: '15px'}}>
              {alertMsg}
            </CAlert>
            <CCard className="user-profile-card">
              <CCardHeader className="user-profile-card">
                <div className="my-4">
                  <img
                    className="c-avatar-user"
                    src={userIn && userIn.photo ? userIn.photo : ''}
                    alt=""></img>
                </div>
                <div className="text-center">
                  <div className="text-uppercase font-weight-bold">
                    {userIn && userIn.fullName}
                    {userIn.premium === true &&
                    !(
                      userIn.role === 'wiser' || userIn.role === 'publisher'
                    ) ? (
                      <CImg
                        style={{marginLeft: 4, marginBottom: 3}}
                        src={'./../avatars/premium.svg'}
                        alt={'premium'}
                        width={10}
                        height={14}
                      />
                    ) : (
                      ''
                    )}
                    {userIn.role === 'wiser' || userIn.role === 'publisher' ? (
                      <CImg
                        style={{marginLeft: 5, marginBottom: 3}}
                        src={'./../avatars/prePremium.svg'}
                        alt={'wiserPremium'}
                        width={16}
                        height={16}
                      />
                    ) : (
                      ''
                    )}
                  </div>
                  <div className="text-muted">{userIn && userIn.jobTitle}</div>
                </div>
              </CCardHeader>
              <CCardBody>
                <small className="text-muted font-weight-bold">Bio</small>
                <br />
                <small className="text-muted">
                  {userIn && userIn.bio ? userIn.bio : ''}
                </small>
              </CCardBody>
            </CCard>
            <CButton
              className="my-2 mfs-auto"
              block
              onClick={() => setModal(true)}
              color="danger">
              Delete
            </CButton>
            <CRow>
              <CCol xs="6">
                <CLabel htmlFor="role">Role</CLabel>
                <CSelect
                  value={userIn && userIn.role ? userIn.role : ''}
                  name="role"
                  id="role"
                  onChange={onChange}>
                  <option value="user">User</option>
                  <option value="wiser">Wiser</option>
                  <option value="publisher">Publisher</option>
                </CSelect>
              </CCol>
              <CCol xs="4">
                <CLabel></CLabel>
                <CButton
                  className="my-2 mfs-auto"
                  block
                  onClick={handleRoleChange}
                  color="primary">
                  Save
                </CButton>
              </CCol>

              <CCol xs="6">
                <CFormGroup>
                  <CLabel htmlFor="status">Status</CLabel>
                  <CSelect
                    //key={userIn.id}
                    value={userIn && userIn.status ? userIn.status : ''}
                    name="status"
                    id="status"
                    onChange={onChange}>
                    <option value="active">Active</option>
                    <option value="passive">Passive</option>
                    <option value="waiting">Waiting</option>
                  </CSelect>
                </CFormGroup>
              </CCol>
              <CCol xs="4">
                <CLabel></CLabel>
                <CButton
                  className="my-2 mfs-auto"
                  block
                  onClick={handleStatusChange}
                  color="primary">
                  Save
                </CButton>
              </CCol>
            </CRow>

            <CRow>
              <CCol xs="6">
                <CFormGroup>
                  <CLabel htmlFor="premium">Premium</CLabel>
                  <CSelect
                    value={userIn?.premium?.toString()}
                    name="premium"
                    id="premium"
                    onChange={onChange}>
                    <option value="true">True</option>
                    <option value="false">False</option>
                  </CSelect>
                </CFormGroup>
              </CCol>
              <CCol xs="4">
                <CLabel></CLabel>
                <CButton
                  className="my-2 mfs-auto"
                  block
                  onClick={handlePremiumChange}
                  color="primary">
                  Save
                </CButton>
              </CCol>
            </CRow>
          </CCol>
          <CCol sm={12} lg={9}>
            <CCard>
              <CCardBody>
                <CFormGroup>
                  <CLabel htmlFor="fullName">Full Name</CLabel>
                  <CInput
                    className="form-control-warning"
                    type="text"
                    value={userIn && userIn.fullName}
                    name="fullName"
                    placeholder="Full Name"
                    autoComplete="fullName"
                    onChange={onChange}
                    disabled
                    required
                  />
                </CFormGroup>
                <CFormGroup>
                  <CLabel htmlFor="email">Email</CLabel>
                  <CInput
                    className="form-control-warning"
                    type="email"
                    value={userIn && userIn.email}
                    name="email"
                    placeholder="Email"
                    autoComplete="email"
                    onChange={onChange}
                    disabled
                    required
                  />
                </CFormGroup>
                <CFormGroup>
                  <CLabel htmlFor="userName">User Name</CLabel>
                  <CInput
                    className="form-control-warning"
                    type="text"
                    value={userIn && userIn.userName}
                    name="userName"
                    placeholder="User Name"
                    onChange={onChange}
                    disabled
                    required
                  />
                </CFormGroup>
                <CFormGroup row className="my-0">
                  <CCol xs="8">
                    <CFormGroup>
                      <CLabel htmlFor="jobTitle">Job Title</CLabel>
                      <CInput
                        className="form-control-warning"
                        type="text"
                        value={userIn && userIn.jobTitle ? userIn.jobTitle : ''}
                        name="jobTitle"
                        placeholder="Job Title"
                        onChange={onChange}
                        disabled
                        required
                      />
                    </CFormGroup>
                  </CCol>
                </CFormGroup>

                <CFormGroup row className="my-0">
                  <CCol xs="4">
                    <CFormGroup>
                      <CLabel htmlFor="phone">Phone</CLabel>
                      <CInput
                        className="form-control-warning"
                        type="phone"
                        value={
                          userIn && userIn.telephone ? userIn.telephone : ''
                        }
                        name="phone"
                        placeholder="Phone"
                        onChange={onChange}
                        disabled
                        required
                      />
                    </CFormGroup>
                  </CCol>
                  <CCol xs="4">
                    <CFormGroup>
                      <CLabel htmlFor="website">Website</CLabel>
                      <CInput
                        className="form-control-warning"
                        type="url"
                        value={userIn && userIn.website ? userIn.website : ''}
                        name="website"
                        placeholder="Website"
                        onChange={onChange}
                        disabled
                      />
                    </CFormGroup>
                  </CCol>
                </CFormGroup>
                <CFormGroup>
                  <CLabel htmlFor="bio">Bio</CLabel>
                  <CTextarea
                    className="form-control-warning"
                    type="textarea-input"
                    value={userIn && userIn.bio ? userIn.bio : ''}
                    name="bio"
                    placeholder="Bio"
                    onChange={onChange}
                    disabled
                  />
                </CFormGroup>
              </CCardBody>
            </CCard>
          </CCol>
        </CRow>
      </CForm>

      <WSRModal
        onShowModal={modal}
        setOnShowModal={setModal}
        modalTitle={'Warning'}
        modalBody={'Are you sure that you want to delete this user?'}
        operationButton={() => handleDelete()}
        operationButtonText={' Yes, remove it!'}
        cancelButton={() => setModal(false)}
        cancelButtonText={'Cancel'}
      />

      <WSRModal
        onShowModal={deletedWarningModal}
        setOnShowModal={setDeletedWarningModal}
        modalTitle={'Delete Action'}
        modalBody={'User has been deleted successfully!'}
        operationButton={() => history.push('/users')}
        operationButtonText={'OK'}
      />

      <WSRModal
        onShowModal={changesSavedModal}
        setOnShowModal={setChangesSavedModal}
        modalTitle={'Succesful Action'}
        modalBody={'Changes saved successfully!'}
        operationButton={() => setChangesSavedModal(false)}
        operationButtonText={'OK'}
      />
    </>
  );
};

export default UserUpdatePage;
