import React, {useEffect} from 'react';

import {getAllUsers as getAllUsersAction} from '@actions/users';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import {PostUsersList} from './components';

const PostUsersPage = () => {
  const dispatch = useDispatch();
  const {selectedLocale} = useSelector(
    (state) => ({
      selectedLocale: state.management.selectedLocale || {locale: 'gb'},
    }),
    shallowEqual,
  );

  useEffect(() => {
    dispatch(
      getAllUsersAction.request(
        10,
        0,
        'postUser',
        '',
        {},
        selectedLocale.locale,
      ),
    );
  }, [selectedLocale]);

  return <PostUsersList></PostUsersList>;
};

export default PostUsersPage;
