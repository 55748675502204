import {take, call, put} from 'redux-saga/effects';
import type {Effect} from 'redux-saga/effects';

import {getLastSharedPosts as getLastSharedPostsService} from '@services/posts';
import * as PostsActions from '@actions/posts';

export function* getLastSharedPosts(): Generator<Effect, void, any> {
  while (true) {
    try {
      yield take(PostsActions.GET_LAST_SHARED_POSTS.REQUEST);
      const {data: response} = yield call(getLastSharedPostsService);
      yield put(PostsActions.getLastSharedPosts.success(response.data));
    } catch (error) {
      // Show info
      yield put(PostsActions.getLastSharedPosts.failure(error));
    }
  }
}
