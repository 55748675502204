import {put, take} from 'redux-saga/effects';
import type {Effect} from 'redux-saga/effects';

import * as AuthActions from '@actions/auth';

import {setToken} from '@utils/Axios';

export function* logout(): Generator<Effect, void, any> {
  while (true) {
    try {
      setToken(undefined);
      yield take(AuthActions.LOG_OUT.REQUEST);
      yield put(AuthActions.logOut.success(undefined));
    } catch (error) {
      // Show info
      yield put(AuthActions.logOut.failure(error));
    }
  }
}
