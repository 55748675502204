// import {PURGE} from 'redux-persist';

import * as ActionTypes from '@actions/users';

import type {UsersReducer} from './types';

const initialState: UsersReducer = {
  isLoading: false,
  error: false,
  errorDescription: '',
  stats: {
    admin: '0',
    curator: '0',
    passive: '0',
    postUser: '0',
    user: '0',
    waiting: '0',
    wiser: '0',
  },
  lastRegisteredUsers: [],
  lastActiveUsers: [],
  admins: {
    data: [],
    page: 0,
    count: 0,
  },
  users: {
    data: [],
    page: 0,
    count: 0,
  },
  usersForOrderPage: {
    data: [],
    page: 0,
    count: 0,
  },
  usersDetail: {
    id: 0,
    userName: '',
    fullName: '',
    firstName: '',
    lastName: '',
    photo: '',
    bio: '',
    telephone: '',
    email: '',
    website: '',
    jobTitle: '',
    role: '',
    registrationMethod: '',
    status: '',
    createdAt: '',
    InvitationCode: {
      id: 0,
      owner: '',
      code: '',
      status: false,
    },
    onboarding: false,
    premium: false,
    showOnDiscoveryByLocal: [],
  },
  wisersPublishersDetail: {
    id: 0,
    userName: '',
    fullName: '',
    firstName: '',
    lastName: '',
    photo: '',
    bio: '',
    telephone: '',
    email: '',
    website: '',
    jobTitle: '',
    role: '',
    registrationMethod: '',
    status: '',
    createdAt: '',
    InvitationCode: {
      id: 0,
      owner: '',
      code: '',
      status: false,
    },
    onboarding: false,
    showOnDiscoveryByLocal: [],
    isFeatured: false,
    order: '',
  },
  selectableUsers: [],
  searchResults: {
    data: [],
    page: 0,
    count: 0,
  },
  onBoardingUsers: {
    data: [],
    count: 0,
  },
  wiserPublisherUsers: {
    data: [],
    page: 0,
    count: 0,
  },
};

export function users(state = initialState, action: any = {}): UsersReducer {
  switch (action.type) {
    // case PURGE: {
    //   return initialState;
    // }
    case ActionTypes.GET_ALL_USER_STATS.REQUEST: {
      return {
        ...state,
        isLoading: true,
        error: false,
        errorDescription: '',
      };
    }
    case ActionTypes.GET_ALL_USER_STATS.SUCCESS: {
      return {
        ...state,
        isLoading: false,
        error: false,
        errorDescription: '',
        stats: action.stats,
      };
    }
    case ActionTypes.GET_ALL_USER_STATS.FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: true,
        errorDescription: action.error.data?.message,
      };
    }
    case ActionTypes.GET_LAST_REGISTERED_USERS.REQUEST: {
      return {
        ...state,
        isLoading: true,
        error: false,
        errorDescription: '',
      };
    }
    case ActionTypes.GET_LAST_REGISTERED_USERS.SUCCESS: {
      return {
        ...state,
        isLoading: false,
        error: false,
        errorDescription: '',
        lastRegisteredUsers: action.users,
      };
    }
    case ActionTypes.GET_LAST_REGISTERED_USERS.FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: true,
        errorDescription: action.error.data?.message,
      };
    }
    case ActionTypes.GET_LAST_ACTIVE_USERS.REQUEST: {
      return {
        ...state,
        isLoading: true,
        error: false,
        errorDescription: '',
      };
    }
    case ActionTypes.GET_LAST_ACTIVE_USERS.SUCCESS: {
      return {
        ...state,
        isLoading: false,
        error: false,
        errorDescription: '',
        lastActiveUsers: action.users,
      };
    }
    case ActionTypes.GET_LAST_ACTIVE_USERS.FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: true,
        errorDescription: action.error.data?.message,
      };
    }
    case ActionTypes.GET_ADMINS.REQUEST: {
      return {
        ...state,
        isLoading: true,
        error: false,
        errorDescription: '',
      };
    }
    case ActionTypes.GET_ADMINS.SUCCESS: {
      return {
        ...state,
        isLoading: false,
        error: false,
        errorDescription: '',
        admins: {
          data: action.data,
          page: action.offset,
          count: action.count,
        },
      };
    }
    case ActionTypes.GET_ADMINS.FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: true,
        errorDescription: action.error.data?.message,
      };
    }
    case ActionTypes.GET_ALL_USERS.REQUEST: {
      return {
        ...state,
        isLoading: true,
        error: false,
        errorDescription: '',
      };
    }
    case ActionTypes.GET_ALL_USERS.SUCCESS: {
      return {
        ...state,
        isLoading: false,
        error: false,
        errorDescription: '',
        users: {
          data:
            action.offset === 0
              ? action.data
              : [...state.users.data, ...action.data],
          page: action.offset,
          count: action.count,
        },
        usersForOrderPage: {
          data: action.data,
          page: action.offset,
          count: action.count,
        },
      };
    }
    case ActionTypes.GET_ALL_USERS.FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: true,
        errorDescription: action.error.data?.message,
      };
    }
    case ActionTypes.GET_USERS_DETAIL.REQUEST: {
      return {
        ...state,
        isLoading: true,
        error: false,
        errorDescription: '',
      };
    }
    case ActionTypes.GET_USERS_DETAIL.SUCCESS: {
      return {
        ...state,
        isLoading: false,
        error: false,
        errorDescription: '',
        usersDetail: action.userDetail,
      };
    }
    case ActionTypes.GET_USERS_DETAIL.FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: true,
        errorDescription: action.error.data?.message,
      };
    }
    case ActionTypes.UPDATE_MANAGEMENT_USERS.REQUEST: {
      return {
        ...state,
        isLoading: true,
        error: false,
        errorDescription: '',
      };
    }
    case ActionTypes.UPDATE_MANAGEMENT_USERS.SUCCESS: {
      return {
        ...state,
        isLoading: false,
        error: false,
        errorDescription: '',
        //usersDetail: action.userDetail,
      };
    }
    case ActionTypes.UPDATE_MANAGEMENT_USERS.FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: true,
        errorDescription: action.error.data?.message,
      };
    }
    case ActionTypes.DELETE_MANAGEMENT_USERS.REQUEST: {
      return {
        ...state,
        isLoading: true,
        error: false,
        errorDescription: '',
      };
    }
    case ActionTypes.DELETE_MANAGEMENT_USERS.SUCCESS: {
      return {
        ...state,
        isLoading: false,
        error: false,
        errorDescription: '',
        //usersDetail: action.userDetail,
      };
    }
    case ActionTypes.DELETE_MANAGEMENT_USERS.FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: true,
        errorDescription: action.error.data?.message,
      };
    }
    case ActionTypes.DELETE_USERS.REQUEST: {
      return {
        ...state,
        isLoading: true,
        error: false,
        errorDescription: '',
      };
    }
    case ActionTypes.DELETE_USERS.SUCCESS: {
      return {
        ...state,
        isLoading: false,
        error: false,
        errorDescription: '',
        //usersDetail: action.userDetail,
      };
    }
    case ActionTypes.DELETE_USERS.FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: true,
        errorDescription: action.error.data?.message,
      };
    }
    case ActionTypes.UPDATE_USERS.REQUEST: {
      return {
        ...state,
        isLoading: true,
        error: false,
        errorDescription: '',
      };
    }
    case ActionTypes.UPDATE_USERS.SUCCESS: {
      return {
        ...state,
        isLoading: false,
        error: false,
        errorDescription: '',
        //usersDetail: action.userDetail,
      };
    }
    case ActionTypes.UPDATE_USERS.FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: true,
        errorDescription: action.error.data?.message,
      };
    }
    case ActionTypes.USERS_ROLE_CHANGE.REQUEST: {
      return {
        ...state,
        isLoading: true,
        error: false,
        errorDescription: '',
      };
    }
    case ActionTypes.USERS_ROLE_CHANGE.SUCCESS: {
      return {
        ...state,
        isLoading: false,
        error: false,
        errorDescription: '',
        //usersDetail: action.userDetail,
      };
    }
    case ActionTypes.USERS_ROLE_CHANGE.FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: true,
        errorDescription: action.error.data?.message,
      };
    }
    case ActionTypes.USERS_STATUS_CHANGE.REQUEST: {
      return {
        ...state,
        isLoading: true,
        error: false,
        errorDescription: '',
      };
    }
    case ActionTypes.USERS_STATUS_CHANGE.SUCCESS: {
      return {
        ...state,
        isLoading: false,
        error: false,
        errorDescription: '',
        //usersDetail: action.userDetail,
      };
    }
    case ActionTypes.USERS_STATUS_CHANGE.FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: true,
        errorDescription: action.error.data?.message,
      };
    }
    case ActionTypes.GET_SELECTABLE_USERS.REQUEST: {
      return {
        ...state,
        isLoading: true,
        error: false,
        errorDescription: '',
      };
    }
    case ActionTypes.GET_SELECTABLE_USERS.SUCCESS: {
      return {
        ...state,
        isLoading: false,
        error: false,
        errorDescription: '',
        selectableUsers: action.selectableUsers,
      };
    }
    case ActionTypes.GET_SELECTABLE_USERS.FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: true,
        errorDescription: action.error.data?.message,
      };
    }

    case ActionTypes.SEARCH_USERS.REQUEST: {
      return {
        ...state,
        isLoading: true,
        error: false,
        errorDescription: '',
      };
    }
    case ActionTypes.SEARCH_USERS.SUCCESS: {
      return {
        ...state,
        isLoading: false,
        error: false,
        errorDescription: '',
        searchResults: {
          data:
            action.page === 0
              ? action.data
              : [...state.searchResults.data, ...action.data],
          page: action.page,
          count: action.count,
        },
      };
    }
    case ActionTypes.SEARCH_USERS.FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: true,
        errorDescription: action.error.data?.message,
      };
    }
    case ActionTypes.USERS_ONBOARDING_CHANGE.REQUEST: {
      return {
        ...state,
        isLoading: true,
        error: false,
        errorDescription: '',
      };
    }
    case ActionTypes.USERS_ONBOARDING_CHANGE.SUCCESS: {
      return {
        ...state,
        isLoading: false,
        error: false,
        errorDescription: '',
        //usersDetail: action.userDetail,
      };
    }
    case ActionTypes.USERS_ONBOARDING_CHANGE.FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: true,
        errorDescription: action.error.data?.message,
      };
    }

    case ActionTypes.USERS_SHOW_ON_DISCOVERY_CHANGE.REQUEST: {
      return {
        ...state,
        isLoading: true,
        error: false,
        errorDescription: '',
      };
    }
    case ActionTypes.USERS_SHOW_ON_DISCOVERY_CHANGE.SUCCESS: {
      return {
        ...state,
        isLoading: false,
        error: false,
        errorDescription: '',
      };
    }
    case ActionTypes.USERS_SHOW_ON_DISCOVERY_CHANGE.FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: true,
        errorDescription: action.error.data?.message,
      };
    }

    case ActionTypes.ADD_USER_TO_ORDER_LIST.REQUEST: {
      return {
        ...state,
        isLoading: true,
        error: false,
        errorDescription: '',
      };
    }
    case ActionTypes.ADD_USER_TO_ORDER_LIST.SUCCESS: {
      return {
        ...state,
        isLoading: false,
        error: false,
        errorDescription: '',
      };
    }
    case ActionTypes.ADD_USER_TO_ORDER_LIST.FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: true,
        errorDescription: action.error.data?.message,
      };
    }

    case ActionTypes.REMOVE_USER_FROM_ORDER_LIST.REQUEST: {
      return {
        ...state,
        isLoading: true,
        error: false,
        errorDescription: '',
      };
    }
    case ActionTypes.REMOVE_USER_FROM_ORDER_LIST.SUCCESS: {
      return {
        ...state,
        isLoading: false,
        error: false,
        errorDescription: '',
      };
    }
    case ActionTypes.REMOVE_USER_FROM_ORDER_LIST.FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: true,
        errorDescription: action.error.data?.message,
      };
    }

    case ActionTypes.GET_ONBOARDING_USERS.REQUEST: {
      return {
        ...state,
        isLoading: true,
        error: false,
        errorDescription: '',
      };
    }
    case ActionTypes.GET_ONBOARDING_USERS.SUCCESS: {
      return {
        ...state,
        isLoading: false,
        error: false,
        errorDescription: '',
        onBoardingUsers: {
          data: action.data,
          count: action.count,
        },
        //usersDetail: action.userDetail,
      };
    }
    case ActionTypes.GET_ONBOARDING_USERS.FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: true,
        errorDescription: action.error.data?.message,
      };
    }

    case ActionTypes.UPDATE_ONBOARDING_ORDER.REQUEST: {
      return {
        ...state,
        isLoading: true,
        error: false,
        errorDescription: '',
      };
    }
    case ActionTypes.UPDATE_ONBOARDING_ORDER.SUCCESS: {
      return {
        ...state,
        isLoading: false,
        error: false,
        errorDescription: '',
        onBoardingUsers: {
          data: action.data,
          count: action.count,
        },
      };
    }
    case ActionTypes.UPDATE_ONBOARDING_ORDER.FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: true,
        errorDescription: action.error.data?.message,
      };
    }

    case ActionTypes.GET_WISER_USERS_BY_LOCALE.REQUEST: {
      return {
        ...state,
        isLoading: true,
        error: false,
        errorDescription: '',
      };
    }
    case ActionTypes.GET_WISER_USERS_BY_LOCALE.SUCCESS: {
      return {
        ...state,
        isLoading: false,
        error: false,
        errorDescription: '',
        wiserPublisherUsers: {
          data:
            action.offset === 0
              ? action.data
              : [...state.wiserPublisherUsers.data, ...action.data],
          page: action.offset,
          count: action.count,
        },
      };
    }
    case ActionTypes.GET_WISER_USERS_BY_LOCALE.FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: true,
        errorDescription: action.error.data?.message,
      };
    }

    case ActionTypes.GET_PUBLISHER_USERS_BY_LOCALE.REQUEST: {
      return {
        ...state,
        isLoading: true,
        error: false,
        errorDescription: '',
      };
    }
    case ActionTypes.GET_PUBLISHER_USERS_BY_LOCALE.SUCCESS: {
      return {
        ...state,
        isLoading: false,
        error: false,
        errorDescription: '',
        wiserPublisherUsers: {
          data:
            action.offset === 0
              ? action.data
              : [...state.wiserPublisherUsers.data, ...action.data],
          page: action.offset,
          count: action.count,
        },
      };
    }
    case ActionTypes.GET_PUBLISHER_USERS_BY_LOCALE.FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: true,
        errorDescription: action.error.data?.message,
      };
    }

    case ActionTypes.UPDATE_WISERS_ORDER.REQUEST: {
      return {
        ...state,
        isLoading: true,
        error: false,
        errorDescription: '',
      };
    }
    case ActionTypes.UPDATE_WISERS_ORDER.SUCCESS: {
      return {
        ...state,
        isLoading: false,
        error: false,
        errorDescription: '',
      };
    }
    case ActionTypes.UPDATE_WISERS_ORDER.FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: true,
        errorDescription: action.error.data?.message,
      };
    }

    case ActionTypes.UPDATE_PUBLISHER_ORDER.REQUEST: {
      return {
        ...state,
        isLoading: true,
        error: false,
        errorDescription: '',
      };
    }
    case ActionTypes.UPDATE_PUBLISHER_ORDER.SUCCESS: {
      return {
        ...state,
        isLoading: false,
        error: false,
        errorDescription: '',
      };
    }
    case ActionTypes.UPDATE_PUBLISHER_ORDER.FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: true,
        errorDescription: action.error.data?.message,
      };
    }

    default:
      return state;
  }
}
