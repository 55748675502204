import React, {useState, useEffect, ChangeEvent} from 'react';
import {useHistory} from 'react-router-dom';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import {DragDropContext, Droppable, Draggable} from 'react-beautiful-dnd';

import {
  CCardHeader,
  CCol,
  CRow,
  CImg,
  CButton,
  CLabel,
  CFormGroup,
  CSelect,
} from '@coreui/react';

import {
  getMoodCurations as getMoodCurationsAction,
  updateMoodOrder as updateMoodOrderAction,
  removeCurationFromMood as removeCurationFromMoodAction,
} from '@actions/chooseMood';

import {updateStatusOfCurations as updateStatusOfCurationsAction} from '@actions/curation';

import {AddCuration} from './components';
import {WSRModal} from '../../components';

const languages = ['all', 'en', 'tr'];

const FrequentlyUpdated = () => {
  const history = useHistory();

  const [curationModal, setCurationModal] = useState<boolean>(false);

  const {moodCurationList} = useSelector(
    (state) => ({
      moodCurationList: state.chooseMood.moodCurationList,
    }),
    shallowEqual,
  );

  const [updateListModal, setUpdateListModal] = useState(false);
  const [deleteCurationModal, setDeleteCurationModal] = useState(false);
  // const [deleteItemId, setDeleteItemId] = useState(0);
  const [curationList, setCurationList] = useState<Array<any>>([]);
  const [onDiscoveryIdList, setOnDiscoveryIdList] = useState<Array<any>>([]);
  const [selectedLanguage, setselectedLanguage] = useState('all');
  const [removeList, setRemoveList] = useState<Array<number>>([]);

  const dispatch = useDispatch();

  function handleOnDragEnd(result: any) {
    setOnDiscoveryIdList([]);
    const items = Array.from(curationList);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    {
      items.map((response) => {
        setOnDiscoveryIdList((onDiscoveryIdList) => [
          ...onDiscoveryIdList,
          response.id,
        ]);
      });
    }
    setCurationList(items);
  }

  const saveAllChange = () => {
    //TODO: removeList'i backend'e gönder 'chooseMood'
    // removeList.map((a) => dispatch(removeCurationFromMoodAction.request(a)));

    if (removeList.length > 0) {
      dispatch(updateStatusOfCurationsAction.request(removeList, 'chooseMood'));
    }
    dispatch(
      getMoodCurationsAction.request(1000, 0, false, `["${selectedLanguage}"]`),
    );
    dispatch(updateMoodOrderAction.request(onDiscoveryIdList));
    setUpdateListModal(false);
  };

  // const removeCurationFromList = () => {
  //   setDeleteCurationModal(false);
  //   dispatch(removeCurationFromMoodAction.request(removeList[0]));
  // };

  const handleChngSrchStatus = (e: ChangeEvent<HTMLLIElement>) => {
    setselectedLanguage(e.target.value.toString());
    dispatch(
      getMoodCurationsAction.request(1000, 0, false, `["${e.target.value}"]`),
    );
  };

  const grid = 8;

  const getListStyle = (isDraggingOver: any) => ({
    //background: isDraggingOver ? 'lightblue' : 'lightgrey',
    // padding: grid,
    width: '100%',
  });

  const getItemStyle = (isDragging: any, draggableStyle: any) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: 'none',
    padding: grid * 2,
    margin: `0 0 ${grid}px 0`,

    // change background colour if dragging
    //background: isDragging ? 'lightgreen' : 'grey',

    // styles we need to apply on draggables
    ...draggableStyle,
  });

  useEffect(() => {
    dispatch(
      getMoodCurationsAction.request(1000, 0, false, `["${selectedLanguage}"]`),
    );
  }, []);

  useEffect(() => {
    const featured: Array<any> = [];
    const initial: Array<any> = [];
    moodCurationList.data.map((response: any) => {
      if (response.featuredCuration) {
        featured.push(response);
      } else {
        initial.push(response);
      }
    });
    setCurationList(moodCurationList.data);
  }, [moodCurationList]);

  return (
    <CRow>
      <CCol>
        <CCardHeader>
          <span
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              width: '100%',
              alignItems: 'center',
            }}>
            <span> Curation Lists ({moodCurationList.count})</span>
            <span
              style={{
                display: 'flex',
                flexDirection: 'row',
                gap: 25,
                width: 'auto',
                alignItems: 'flex-start',
              }}>
              <CFormGroup>
                <span
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    gap: 10,
                    alignItems: 'center',
                  }}>
                  <>Language</>
                  <CSelect
                    style={{width: 206}}
                    placeholder="Location"
                    value={selectedLanguage}
                    onChange={handleChngSrchStatus}>
                    {languages.map((item) => (
                      <option key={item} value={item}>
                        {item}
                      </option>
                    ))}
                  </CSelect>
                </span>
              </CFormGroup>
              <CButton
                style={{width: 160}}
                onClick={() => setCurationModal(true)}
                color="success">
                + Add Curation
              </CButton>
              <CButton
                style={{width: 160}}
                onClick={() => setUpdateListModal(true)}
                color="success">
                Save Changes
              </CButton>
            </span>
          </span>

          <br></br>

          <DragDropContext onDragEnd={handleOnDragEnd}>
            <Droppable droppableId="characters">
              {(provided, snapshot) => (
                <tr
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                  style={getListStyle(snapshot.isDraggingOver)}>
                  <tr>
                    <td style={styles.td1}>Order</td>
                    <td style={styles.td1}>Id</td>
                    <td style={styles.td1}>Curator</td>
                    <td style={styles.td1}>Photo</td>
                    <td style={styles.td1}>Name</td>
                    <td style={styles.td1}>Notify Me</td>
                    <td style={styles.td1}>Post Count</td>
                    <td style={styles.td1}>Save Count</td>
                    <td style={styles.td1}>Click Count</td>
                    <td style={styles.td1}>Status</td>
                  </tr>

                  {curationList?.map((listItem: any, index) => {
                    return (
                      <Draggable
                        key={listItem.id}
                        draggableId={listItem.id.toString()}
                        index={index}>
                        {(provided, snapshot) => (
                          <tr
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            style={getItemStyle(
                              snapshot.isDragging,
                              provided.draggableProps.style,
                            )}>
                            <td
                              style={
                                index % 2 == 0
                                  ? {
                                      ...styles.td1,
                                      backgroundColor: '#f2f2f2',
                                    }
                                  : styles.td1
                              }>
                              <CLabel>{index + 1}</CLabel>
                            </td>
                            <td
                              style={
                                index % 2 == 0
                                  ? {
                                      ...styles.td1,
                                      backgroundColor: '#f2f2f2',
                                    }
                                  : styles.td1
                              }>
                              <CLabel>{listItem.id}</CLabel>
                            </td>
                            <td
                              style={
                                index % 2 == 0
                                  ? {
                                      ...styles.td1,
                                      backgroundColor: '#f2f2f2',
                                    }
                                  : styles.td1
                              }>
                              <CLabel>{listItem.curator?.fullName}</CLabel>
                            </td>
                            <td
                              style={
                                index % 2 == 0
                                  ? {
                                      ...styles.td1,
                                      backgroundColor: '#f2f2f2',
                                    }
                                  : styles.td1
                              }>
                              <CImg
                                style={{cursor: 'pointer'}}
                                src={listItem.photo}
                                className="c-avatar"
                                alt={listItem.photo}
                                onClick={(item) =>
                                  history.push({
                                    pathname: `/curationDetail/${listItem.id}`,
                                    state: {detail: listItem.description},
                                  })
                                }
                              />
                            </td>
                            <td
                              style={
                                index % 2 == 0
                                  ? {
                                      ...styles.td1,
                                      backgroundColor: '#f2f2f2',
                                    }
                                  : styles.td1
                              }>
                              <CLabel>{listItem.name}</CLabel>
                            </td>
                            <td
                              style={
                                index % 2 == 0
                                  ? {
                                      ...styles.td1,
                                      backgroundColor: '#f2f2f2',
                                    }
                                  : styles.td1
                              }>
                              <CLabel>{listItem.post_count}</CLabel>
                            </td>
                            <td
                              style={
                                index % 2 == 0
                                  ? {
                                      ...styles.td1,
                                      backgroundColor: '#f2f2f2',
                                    }
                                  : styles.td1
                              }>
                              <CLabel>{listItem.notification_count}</CLabel>
                            </td>
                            <td
                              style={
                                index % 2 == 0
                                  ? {
                                      ...styles.td1,
                                      backgroundColor: '#f2f2f2',
                                    }
                                  : styles.td1
                              }>
                              <CLabel>{listItem.save_count}</CLabel>
                            </td>
                            <td
                              style={
                                index % 2 == 0
                                  ? {
                                      ...styles.td1,
                                      backgroundColor: '#f2f2f2',
                                    }
                                  : styles.td1
                              }>
                              <CLabel>{listItem.clickCount}</CLabel>
                            </td>
                            <td
                              style={
                                index % 2 == 0
                                  ? {
                                      ...styles.td1,
                                      backgroundColor: '#f2f2f2',
                                    }
                                  : styles.td1
                              }>
                              {
                                <td
                                  style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                  }}
                                  className={'cut-text-user-td'}>
                                  {removeList.includes(listItem.id) ? (
                                    <span
                                      onClick={() => {
                                        const updatedList = removeList.filter(
                                          (item) => item !== listItem.id,
                                        );
                                        setRemoveList(updatedList);
                                      }}
                                      style={{
                                        display: 'flex',
                                        width: 88,
                                        height: 30,
                                        flexDirection: 'row',
                                        borderRadius: 5,
                                      }}>
                                      <span
                                        style={{
                                          width: 44,
                                          display: 'flex',
                                          alignItems: 'center',
                                          justifyContent: 'center',
                                          backgroundColor: '#fff',
                                          color: '#788190',
                                          borderTopLeftRadius: 5,
                                          borderBottomLeftRadius: 5,
                                        }}>
                                        True
                                      </span>
                                      <span
                                        style={{
                                          width: 44,
                                          display: 'flex',
                                          alignItems: 'center',
                                          justifyContent: 'center',
                                          backgroundColor: '#E45353',
                                          color: '#fff',
                                          borderTopRightRadius: 5,
                                          borderBottomRightRadius: 5,
                                        }}>
                                        False
                                      </span>
                                    </span>
                                  ) : (
                                    <span
                                      onClick={() => {
                                        const updatedList = [
                                          ...removeList,
                                          listItem.id,
                                        ];
                                        setRemoveList(updatedList);
                                      }}
                                      style={{
                                        display: 'flex',
                                        width: 88,
                                        height: 30,
                                        flexDirection: 'row',
                                        borderRadius: 5,
                                      }}>
                                      <span
                                        style={{
                                          width: 44,
                                          display: 'flex',
                                          alignItems: 'center',
                                          justifyContent: 'center',
                                          backgroundColor: '#5CB466',
                                          color: '#fff',
                                          borderTopLeftRadius: 5,
                                          borderBottomLeftRadius: 5,
                                        }}>
                                        True
                                      </span>
                                      <span
                                        style={{
                                          width: 44,
                                          display: 'flex',
                                          alignItems: 'center',
                                          justifyContent: 'center',
                                          backgroundColor: '#fff',
                                          color: '#788190',
                                          borderTopRightRadius: 5,
                                          borderBottomRightRadius: 5,
                                        }}>
                                        False
                                      </span>
                                    </span>
                                  )}
                                </td>
                              }
                            </td>
                            {/* <td>
                              <CButton
                                className="my-2 mfs-auto"
                                block
                                onClick={() => {
                                  setDeleteItemId(listItem.id);
                                  setDeleteCurationModal(true);
                                }}
                                color="danger">
                                Remove
                              </CButton>
                            </td> */}
                          </tr>
                        )}
                      </Draggable>
                    );
                  })}
                  {provided.placeholder}
                </tr>
              )}
            </Droppable>
          </DragDropContext>
        </CCardHeader>
      </CCol>

      <WSRModal
        onShowModal={updateListModal}
        setOnShowModal={setUpdateListModal}
        modalTitle={'Warning'}
        modalBody={`Do you want to edit the order?`}
        operationButton={() => saveAllChange()}
        operationButtonText={'Yes, update it!'}
        cancelButton={() => {
          setUpdateListModal(false);
        }}
        cancelButtonText={'Cancel'}
      />
      {/* 
      <WSRModal
        onShowModal={deleteCurationModal}
        setOnShowModal={setDeleteCurationModal}
        modalTitle={'Warning'}
        modalBody={`Do you want to remove this curation from the list?`}
        operationButton={() => removeCurationFromList()}
        operationButtonText={'Remove!'}
        cancelButton={() => {
          setDeleteCurationModal(false);
        }}
        cancelButtonText={'Cancel'}
      /> */}
      <AddCuration
        isOpen={curationModal}
        setIsOpen={setCurationModal}
        onCurationAdded={(curationId) => {}}
      />
    </CRow>
  );
};

export default FrequentlyUpdated;

const styles: any = {
  td1: {
    width: '220px',
    padding: '10px',
    border: '1px solid #d5d5dd',
  },
};
