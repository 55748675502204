import React, {useEffect} from 'react';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import {UsersList} from '../../components';
import {getAllUsers as getAllUsersAction} from '@actions/users';

const PremiumUserListPage = () => {
  const dispatch = useDispatch();
  const {selectedLocale} = useSelector(
    (state) => ({
      selectedLocale: state.management.selectedLocale || {locale: 'gb'},
    }),
    shallowEqual,
  );

  useEffect(() => {
    dispatch(
      getAllUsersAction.request(
        10,
        0,
        'premium',
        '',
        {},
        selectedLocale.locale,
      ),
    );
  }, [selectedLocale]);

  return <UsersList role="premium"></UsersList>;
};

export default PremiumUserListPage;
