import React from 'react';
import {
  CModal,
  CModalHeader,
  CModalTitle,
  CModalBody,
  CButton,
  CModalFooter,
} from '@coreui/react';

type Props = {
  onShowModal: boolean;
  setOnShowModal: Function;
  modalTitle: string;
  modalBody: string;
  operationButton: Function;
  operationButtonText: string;
  cancelButton?: Function;
  cancelButtonText?: string;
};

export const WSRModalSave: React.FC<Props> = (props) => {
  const {
    onShowModal,
    setOnShowModal,
    modalTitle,
    modalBody,
    operationButton,
    operationButtonText,
    cancelButton,
    cancelButtonText,
  } = props;

  return (
    <CModal
      style={{marginTop: 254}}
      show={onShowModal}
      onClose={setOnShowModal}>
      <CModalHeader>
        <CModalTitle>{modalTitle}</CModalTitle>
      </CModalHeader>
      <CModalBody>{modalBody}</CModalBody>
      <CModalFooter>
        <CButton
          style={{color: '#F0F4F7', backgroundColor: '#1DB954'}}
          onClick={operationButton}>
          {operationButtonText}
        </CButton>

        {cancelButtonText === undefined ? null : (
          <CButton
            style={{color: '#374254', backgroundColor: '#9DA5B1'}}
            onClick={cancelButton}>
            {cancelButtonText}
          </CButton>
        )}
      </CModalFooter>
    </CModal>
  );
};
