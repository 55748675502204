import React, {useEffect, ChangeEvent} from 'react';
import {
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CRow,
  CForm,
  CButton,
  CFormGroup,
  CLabel,
  CInput,
  CAlert,
} from '@coreui/react';
import {useState} from 'react';
import {useDispatch} from 'react-redux';
import {TextInput, DeletePhotoButton} from '../../../../components';
import {FormValidation} from '../../../../utils';
import {updateManagementUsers as updateManagementUserAction} from '@actions/users';
import {useHistory} from 'react-router-dom';
import {WSRModal} from '../../../../components';

type Props = {match: any; location: any};

const AdminUpdatePage: React.FC<Props> = (props) => {
  const {
    match,
    location: {
      state: {user},
    },
  } = props;
  const dispatch = useDispatch();

  const [userIn, setUserIn] = useState({
    registrationMethod: '',
    tags: [],
    userName: '',
    fullName: '',
    email: '',
    password: '',
    photo: '',
    jobTitle: '',
    bio: '',
    telephone: '',
    website: '',
    role: '',
  });
  const [isValid, setIsValid] = useState<boolean | typeof undefined>();
  const [alert, setAlert] = useState(false);
  const [alertMsg, setAlertMsg] = useState('');
  const [uptadedWarningModal, setUptadedWarningModal] = useState(false);
  const history = useHistory();
  const onSubmit = (event: ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    dispatch(
      updateManagementUserAction.request(userIn, () => {
        setUptadedWarningModal(true);
      }),
    );
  };

  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    event.persist();
    setUserIn((userIn) => ({
      ...userIn,
      [event.target.name]: event.target.value,
    }));
  };

  const onChangeEmail = (event: ChangeEvent<HTMLInputElement>) => {
    FormValidation.validateEmail(event.target.value)
      ? setIsValid(false)
      : setIsValid(true);
    event.persist();
    setUserIn((user) => ({...user, [event.target.name]: event.target.value}));
  };

  const deletePhotoButton = () => {
    setUserIn((user) => ({...user, photo: ''}));
    //setModal(false);
  };

  const handleCancel = () => {
    history.push({
      pathname: `/admin/${user.id}`,
      state: {user: user},
    });
  };

  useEffect(() => {
    setUserIn(user);
  }, []);

  return (
    <>
      <CForm onSubmit={onSubmit}>
        <CRow>
          <CCol sm={12} lg={3}>
            <CAlert
              show={alert}
              color="info"
              closeButton
              style={{marginTop: '15px'}}>
              {alertMsg}
            </CAlert>
            <CCard className="user-profile-card">
              <CCardHeader className="user-profile-card">
                <div className="my-4">
                  <img
                    className="c-avatar-user"
                    src={
                      userIn.photo ? userIn.photo : 'avatars/blank-profile.jpg'
                    }
                    alt=""></img>
                </div>
                <div className="text-center">
                  <div className="text-uppercase font-weight-bold">
                    {userIn.fullName}
                  </div>
                  <div className="text-muted">{userIn.jobTitle}</div>
                </div>
                {userIn.photo == '' || userIn.photo == null ? null : (
                  <DeletePhotoButton
                    deletePhotoButton={deletePhotoButton}></DeletePhotoButton>
                )}
                <CFormGroup>
                  <CLabel htmlFor="photo-user-up" className="photo-user-label">
                    {/*                      <CIcon width={24} name="cil-cloud-download"/>
                     */}{' '}
                  </CLabel>
                </CFormGroup>
              </CCardHeader>
              <CCardBody>
                <small className="text-muted font-weight-bold">Bio</small>
                <br />
                <small className="text-muted">{userIn.bio}</small>
              </CCardBody>
            </CCard>
          </CCol>
          <CCol sm={12} lg={9}>
            <CCard>
              <CCardHeader>
                User
                <small> Form</small>
              </CCardHeader>
              <CCardBody>
                <CFormGroup>
                  <CLabel htmlFor="fullName">Full Name</CLabel>
                  <CInput
                    className="form-control-warning"
                    type="text"
                    value={userIn.fullName}
                    name="fullName"
                    placeholder="Full Name"
                    autoComplete="fullName"
                    onChange={onChange}
                    required
                  />
                </CFormGroup>
                <TextInput
                  field="email"
                  type="email"
                  value={userIn.email}
                  name="email"
                  placeholder="Email"
                  autoComplete="email"
                  onChange={onChangeEmail}
                  required
                  valid={!isValid}
                  htmlFor="email"
                  htmlForField="Email"
                  warningText="Please check your email"
                />
                <CFormGroup>
                  <CLabel htmlFor="userName">User Name</CLabel>
                  <CInput
                    className="form-control-warning"
                    type="text"
                    value={userIn.userName}
                    name="userName"
                    placeholder="User Name"
                    onChange={onChange}
                    required
                  />
                </CFormGroup>

                <div className="my-2 mfs-auto form-actions">
                  <CButton type="submit" color="primary" disabled={isValid}>
                    Save changes
                  </CButton>
                  <CButton onClick={handleCancel} color="secondary">
                    Cancel
                  </CButton>
                </div>
              </CCardBody>

              <WSRModal
                onShowModal={uptadedWarningModal}
                setOnShowModal={setUptadedWarningModal}
                modalTitle={'Update Action'}
                modalBody={`User has been updated successfullyy!`}
                operationButton={() => setUptadedWarningModal(false)}
                operationButtonText={'OK'}
              />
            </CCard>
          </CCol>
        </CRow>
      </CForm>
    </>
  );
};

export default AdminUpdatePage;
