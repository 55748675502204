import {take, call, put} from 'redux-saga/effects';
import type {Effect} from 'redux-saga/effects';

import {deleteCuration as deleteCurationService} from '@services/curationList';
import * as CurationActions from '@actions/curation';

export function* deleteCuration(): Generator<Effect, void, any> {
  while (true) {
    try {
      const {deleteCurationId, onSucess} = yield take(
        CurationActions.DELETE_CURATION.REQUEST,
      );
      const {data: response} = yield call(
        deleteCurationService,
        deleteCurationId,
      );
      onSucess();
      yield put(CurationActions.deleteCuration.success(deleteCurationId));
    } catch (error) {
      // Show info
      yield put(CurationActions.deleteCuration.failure(error));
    }
  }
}
