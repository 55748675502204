export const values = {
  fields: [
    {
      key: 'id',
      label: 'Id',
      _style: {width: '4%'},
    },
    {
      key: 'logo',
      label: 'Logo',
      _style: {width: '5%'},
    },
    {
      key: 'title',
      label: 'Title',
      sorter: false,
      filter: false,
    },
    {
      key: 'type',
      label: 'Type',
      _style: {width: '10%'},
    },
    {
      key: 'author',
      label: 'Author',
      _style: {width: '10%'},
    },
    {
      key: 'date',
      label: 'Date',
    },
    {
      key: 'user',
      label: 'User',
      _style: {width: '10%'},
    },
    {
      key: 'select_row',
      label: '',
      _style: {width: '5%'},
    },
  ],
};
