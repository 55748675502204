import {take, call, put} from 'redux-saga/effects';
import type {Effect} from 'redux-saga/effects';

import {deleteOnboardingCategoryUsers as deleteOnboardingCategoryUsersService} from '@services/onboarding';
import * as OnboardingActions from '@actions/onboarding';

export function* deleteOnboardingCategoryUsers(): Generator<Effect, void, any> {
  while (true) {
    try {
      const {categoryId, userId, onSucess} = yield take(
        OnboardingActions.DELETE_ONBOARDNG_CATEGORY_USERS.REQUEST,
      );
      yield call(deleteOnboardingCategoryUsersService, categoryId, userId);
      onSucess();
      yield put(
        OnboardingActions.deleteOnboardingCategoryUsers.success(userId),
      );
    } catch (error) {
      // Show info
      yield put(OnboardingActions.deleteOnboardingCategoryUsers.failure(error));
    }
  }
}
