import {take, call, put} from 'redux-saga/effects';
import type {Effect} from 'redux-saga/effects';

import {getTags as getTagsService} from '@services/tags';
import * as CurationActions from '@actions/tags';

export function* getTags(): Generator<Effect, void, any> {
  while (true) {
    try {
      const {searchFields} = yield take(CurationActions.GET_TAGS.REQUEST);

      const {data: response} = yield call(getTagsService, searchFields);

      yield put(CurationActions.getTags.success(response));
    } catch (error) {
      // Show info
      yield put(CurationActions.getTags.failure(error));
    }
  }
}
