import {take, call, put} from 'redux-saga/effects';
import type {Effect} from 'redux-saga/effects';

import {getReferrers as getReferrersService} from '@services/marketing';
import * as ReferrersActions from '@actions/marketing';

export function* getReferrers(): Generator<Effect, void, any> {
  while (true) {
    try {
      const {offset} = yield take(ReferrersActions.GET_REFERRERS.REQUEST);
      const {data: response} = yield call(getReferrersService, offset);
      yield put(
        ReferrersActions.getReferrers.success(
          response.data,
          offset,
          response.count,
        ),
      );
    } catch (error) {
      // Show info
      yield put(ReferrersActions.getReferrers.failure(error));
    }
  }
}
