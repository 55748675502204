import {take, call, put} from 'redux-saga/effects';
import type {Effect} from 'redux-saga/effects';

import {deletePosts as deletePostsService} from '@services/posts';
import * as PostActions from '@actions/posts';

export function* deletePosts(): Generator<Effect, void, any> {
  while (true) {
    try {
      const {codeId, onSucess} = yield take(PostActions.DELETE_POSTS.REQUEST);
      yield call(deletePostsService, codeId);
      onSucess();
      yield put(PostActions.deletePosts.success(codeId));
    } catch (error) {
      // Show info
      yield put(PostActions.deletePosts.failure(error));
    }
  }
}
