// import {PURGE} from 'redux-persist';

import * as ActionTypes from '@actions/categories';

import type {CategoriesReducer} from './types';

const initialState: CategoriesReducer = {
  isLoading: false,
  error: false,
  errorDescription: '',
  categoriesList: {
    categories: [],
    page: 0,
    categoriesCount: 0,
  },
  categoryDetail: {
    id: 0,
    name: '',
    status: false,
    name_translate: {gb: '', tr: ''},
  },
};

export function categories(
  state = initialState,
  action: any = {},
): CategoriesReducer {
  switch (action.type) {
    case ActionTypes.GET_CATEGORIES.REQUEST: {
      return {
        ...state,
        isLoading: true,
        error: false,
        errorDescription: '',
      };
    }
    case ActionTypes.GET_CATEGORIES.SUCCESS: {
      return {
        ...state,
        isLoading: false,
        error: false,
        errorDescription: '',
        categoriesList: {
          categories:
            action.page === 0 || action.page === 1
              ? action.categories
              : [...state.categoriesList.categories, ...action.categories],
          page: action.page,
          categoriesCount: action.categoriesCount,
        },
      };
    }
    case ActionTypes.GET_CATEGORIES.FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: true,
        errorDescription: action.error.data?.message,
      };
    }
    case ActionTypes.DELETE_CATEGORY.REQUEST: {
      return {
        ...state,
        isLoading: true,
        error: false,
        errorDescription: '',
      };
    }
    case ActionTypes.DELETE_CATEGORY.SUCCESS: {
      const filterdCodes = state.categoriesList.categories.filter(
        (item) => item.id !== action.categoryId,
      );
      return {
        ...state,
        isLoading: false,
        error: false,
        errorDescription: '',
        categoriesList: {
          ...state.categoriesList,
          categoriesCount: state.categoriesList.categoriesCount - 1,
          categories: filterdCodes,
        },
      };
    }
    case ActionTypes.DELETE_CATEGORY.FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: true,
        errorDescription: action.error.data?.message,
      };
    }

    case ActionTypes.GET_CATEGORY_DETAIL.REQUEST: {
      return {
        ...state,
        isLoading: true,
        error: false,
        errorDescription: '',
      };
    }
    case ActionTypes.GET_CATEGORY_DETAIL.SUCCESS: {
      return {
        ...state,
        isLoading: false,
        error: false,
        errorDescription: '',
        categoryDetail: action.categoryDetail,
      };
    }
    case ActionTypes.GET_CATEGORY_DETAIL.FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: true,
        errorDescription: action.error.data?.message,
      };
    }

    case ActionTypes.UPDATE_CATEGORY.REQUEST: {
      return {
        ...state,
        isLoading: true,
        error: false,
        errorDescription: '',
      };
    }
    case ActionTypes.UPDATE_CATEGORY.SUCCESS: {
      return {
        ...state,
        isLoading: false,
        error: false,
        errorDescription: '',
        categoryDetail: {
          ...state.categoryDetail,
          name: action.updatedCategory.name,
          status: action.updatedCategory.status.status,
        },
      };
    }
    case ActionTypes.UPDATE_CATEGORY.FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: true,
        errorDescription: action.error.data?.message,
      };
    }

    case ActionTypes.CREATE_CATEGORY.REQUEST: {
      return {
        ...state,
        isLoading: true,
        error: false,
        errorDescription: '',
      };
    }
    case ActionTypes.CREATE_CATEGORY.SUCCESS: {
      return {
        ...state,
        isLoading: false,
        error: false,
        errorDescription: '',
        // categoryDetail: {
        //   ...state.categoryDetail,
        //   name: action.updatedCategory.name,
        //   status: action.updatedCategory.status,
        // },
      };
    }
    case ActionTypes.CREATE_CATEGORY.FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: true,
        errorDescription: action.error.data?.message,
      };
    }

    case ActionTypes.MOVE_CONTENT.REQUEST: {
      return {
        ...state,
        isLoading: true,
        error: false,
        errorDescription: '',
      };
    }
    case ActionTypes.MOVE_CONTENT.SUCCESS: {
      return {
        ...state,
        isLoading: false,
        error: false,
        errorDescription: '',
      };
    }
    case ActionTypes.MOVE_CONTENT.FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: true,
        errorDescription: action.error.data?.message,
      };
    }

    default:
      return state;
  }
}
