import {Axios} from '@utils';
import {constants} from '@resources';

export async function postCurationToChooseMood(curationListId?: number) {
  const parameters = {
    curationListId: curationListId,
  };

  return await Axios.post(`${constants.CURATION_BASE}/mood`, parameters);
}
