import {take, call, put} from 'redux-saga/effects';
import type {Effect} from 'redux-saga/effects';

import {searchCurationList as searchCurationListService} from '@services/curationList';
import * as CurationActions from '@actions/curation';

export function* searchDiscoveryCurations(): Generator<Effect, void, any> {
  while (true) {
    try {
      const {page, searchFields} = yield take(
        CurationActions.SEARCH_DISCOVERY_CURATIONS.REQUEST,
      );

      const {data: response} = yield call(
        searchCurationListService,
        page,
        searchFields,
      );

      yield put(
        CurationActions.searchDiscoveryCurations.success(
          response.data,
          page,
          response.count,
        ),
      );
    } catch (error) {
      console.log('error', error);

      // Show info
      yield put(CurationActions.searchDiscoveryCurations.failure(error));
    }
  }
}
