import {take, call, put} from 'redux-saga/effects';
import type {Effect} from 'redux-saga/effects';

import {getUsersDetail as getUsersDetailService} from '@services/users';
import * as CurationActions from '@actions/users';

export function* getUsersDetail(): Generator<Effect, void, any> {
  while (true) {
    try {
      const {userId} = yield take(CurationActions.GET_USERS_DETAIL.REQUEST);
      const {data: response} = yield call(getUsersDetailService, userId);
      yield put(CurationActions.getUsersDetail.success(response));
    } catch (error) {
      // Show info
      yield put(CurationActions.getUsersDetail.failure(error));
    }
  }
}
