import React, {useEffect, useState} from 'react';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';

import {useHistory} from 'react-router-dom';
import {WSRDataTable} from '../../components';

import {
  CCard,
  CCardHeader,
  CCol,
  CRow,
  CBadge,
  CButton,
  CImg,
} from '@coreui/react';

import {getCategories as getCategoriesAction} from '@actions/categories';
import {closeSuccessMessage as closeSuccessMessageAction} from '@actions/notifications';

import {values} from './values';
import {styles} from './styles';
import {WSRModalNotification} from './pages/CategoryDetailPage/components';

const getBadge = (status: boolean) => {
  switch (status) {
    case true:
      return 'success';
    case false:
      return 'danger';
    default:
      return 'primary';
  }
};

type WSRLocale = 'gb' | 'tr';
type WSRLanguage = 'en' | 'tr';

type WSRCategory = {
  id: number;
  photo: string;
  name: string;
  locale: WSRLocale;
  name_translate: {[language in WSRLanguage]: string};
  status: boolean;
};

const CategoriesPage = () => {
  const {categoriesList, succesMessage} = useSelector(
    (state) => ({
      categoriesList: state.categories.categoriesList,
      succesMessage: state.notifications.succesMessage,
    }),
    shallowEqual,
  );

  const [page, setPage] = useState(1);

  const pageLimit = 10;

  const history = useHistory();
  const dispatch = useDispatch();

  const scopedSlots = {
    photo: (item: WSRCategory) => (
      <td>
        {/* <div className="c-avatar"> */}
        <a
          href={'/categories/' + item.id}
          style={{
            height: 37,
            width: '100%',
            textDecoration: 'none',
            color: 'black',
          }}>
          <CImg
            src={
              item.photo == null ? './avatars/blank-profile.jpg' : item.photo
            }
            style={{height: '40px', width: '100px'}}
            alt={item.id.toString()}
          />
        </a>
      </td>
    ),
    name: (item: WSRCategory) => <td>{item.name_translate.en}</td>,
    status: (item: WSRCategory) => (
      <td>
        <CBadge style={styles.badge} color={getBadge(item.status)}>
          {item.status === true ? 'true' : 'false'}
        </CBadge>
      </td>
    ),
    select_edit_row: (item: WSRCategory) => (
      <td className="py-2">
        <CImg
          src={'avatars/edit.png'}
          className=""
          onClick={() => history.push(`/categories/${item.id}`)}
          alt=" "
          height="20px"
          style={styles.image}
        />
      </td>
    ),
  };

  const pageChange = (newPage: number) => {
    if ((newPage - 1) * 10 >= categoriesList.categories.length) {
      dispatch(getCategoriesAction.request(categoriesList.page + 10));
    }
    setPage(newPage);
  };

  useEffect(() => {
    dispatch(getCategoriesAction.request(0));
  }, []);

  return (
    <>
      <CRow>
        <CCol xl={12}>
          <CCard>
            <CCardHeader>
              <CRow>
                <CCol sm="4" md="6" className="mb-3 mb-xl-0 c-table-header">
                  Categories ({categoriesList.categoriesCount})
                </CCol>
                <CCol sm="4" md="4" className="my-3 mb-xl-0 mfs-auto">
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-around',
                      padding: 0,
                    }}></div>
                </CCol>
                <CCol sm="4" md="2" className="my-3 mb-xl-0 mfs-auto">
                  <CButton
                    block
                    onClick={() => history.push(`/categories/${undefined}`)}
                    color="success">
                    Create
                  </CButton>
                </CCol>
              </CRow>
            </CCardHeader>

            <WSRDataTable
              items={categoriesList.categories}
              fields={values.fields}
              scopedSlots={scopedSlots}
              pageLimit={pageLimit}
              page={page}
              pageChange={pageChange}
              itemsCount={categoriesList.categoriesCount}
            />
          </CCard>
        </CCol>

        <WSRModalNotification
          onShowModal={succesMessage}
          message={`Your category is successfully deleted!`}
          operationButton={() => dispatch(closeSuccessMessageAction.request())}
          operationButtonText={'OK'}
        />
      </CRow>
    </>
  );
};

export default CategoriesPage;
