import {take, call, put} from 'redux-saga/effects';
import type {Effect} from 'redux-saga/effects';

import {getAllUsers as getAllUsersService} from '@services/users';
import * as UsersActions from '@actions/users';

export function* searchUsers(): Generator<Effect, void, any> {
  while (true) {
    try {
      const {limit, page, role, status, searchFields, locale} = yield take(
        UsersActions.SEARCH_USERS.REQUEST,
      );
      const {data: response} = yield call(
        getAllUsersService,
        limit,
        page,
        role,
        status,
        searchFields,
        locale,
      );

      yield put(
        UsersActions.searchUsers.success(response.data, page, response.count),
      );
    } catch (error) {
      // Show info
      yield put(UsersActions.searchUsers.failure(error));
    }
  }
}
