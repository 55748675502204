import {take, call, put} from 'redux-saga/effects';
import type {Effect} from 'redux-saga/effects';

import {
  createPostMetadata as createPostMetadataService,
  getPostURLMetadata as getPostURLMetadataService,
} from '@services/posts';
import * as postActions from '@actions/posts';

export function* createPost(): Generator<Effect, void, any> {
  while (true) {
    try {
      const {post, checkedCLs, checkedPosts, id, onSucess} = yield take(
        postActions.CREATE_POST.REQUEST,
      );
      const metaData = yield call(getPostURLMetadataService, post);

      const data = yield call(
        createPostMetadataService,
        post,
        checkedCLs,
        checkedPosts,
        metaData,
      );
      // create post success action
      yield put(postActions.createPost.success(data.data));
      onSucess();
    } catch (error) {
      // Show info
      yield put(postActions.createPost.failure(error));
    }
  }
}
