export const values = {
  fields: [
    {
      key: 'id',
      label: 'Id',
      sorter: false,
      filter: false,
      _style: {width: '6%'},
    },
    {
      key: 'photo',
      label: 'Photo',
      sorter: true,
      filter: false,
      _style: {width: '10%'},
    },
    {
      key: 'name',
      label: 'Name',
      sorter: false,
      filter: false,
      _style: {width: '70%'},
    },
    {
      key: 'status',
      label: 'Status',
      sorter: false,
      filter: false,
      _style: {width: '10%'},
    },
    {
      key: 'select_edit_row',
      label: '',
      _style: {width: '5%'},
    },
    // Delete action iptal edildi: https://app.clickup.com/t/2r1j6ka
    // {
    //   key: 'select_row',
    //   label: '',
    //   _style: {width: '5%'},
    // },
  ],
};
