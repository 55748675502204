import {take, call, put} from 'redux-saga/effects';
import type {Effect} from 'redux-saga/effects';

import {addPostsToCuration as addPostsToCurationService} from '@services/curationList';
import * as CurationActions from '@actions/curation';

export function* addPostsToCuration(): Generator<Effect, void, any> {
  while (true) {
    try {
      const {postIds, curationId, onSucess} = yield take(
        CurationActions.ADD_POSTS_TO_CURATION.REQUEST,
      );
      const {data: response} = yield call(
        addPostsToCurationService,
        postIds,
        curationId,
      );
      onSucess();

      yield put(CurationActions.addPostsToCuration.success(response.data));
    } catch (error) {
      // Show info
      yield put(CurationActions.addPostsToCuration.failure(error));
    }
  }
}
