import {Axios} from '@utils';
import {constants} from '@resources';

export function usersPremiumChange(users: any) {
  const parameters = {
    assignedPremium: users.premium === 'false' ? false : true,
  };

  return Axios.put(
    `${constants.USER_BASE}/${users.id}/assignedPremium`,
    parameters,
  );
}
