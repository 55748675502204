import React, {useState, useEffect, ChangeEvent} from 'react';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import {DragDropContext, Draggable, Droppable} from 'react-beautiful-dnd';
import {useHistory} from 'react-router-dom';
import {CRow, CImg, CLabel, CSelect, CBadge} from '@coreui/react';
import {getWeeklyUpdatedCurationsList as getWeeklyUpdatedCurationsListAction} from '@actions/curation';
import {getWeeklyUpdatedTime as getWeeklyUpdatedTimeAction} from '@actions/management';
// import {WSRModal} from '@src/components';

const WeeklyUpdatedCurationList = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  // const [updateListModal, setUpdateListModal] = useState(false);
  const [curationList, setCurationList] = useState<Array<any>>([]);
  const [onDiscoveryIdList, setOnDiscoveryIdList] = useState<Array<any>>([]);
  const [updatedTime, setWeeklyUpdatedTime] = useState('None');

  const {weeklyUpdatedCurations, isLoading, weeklyUpdatedTime} = useSelector(
    (state) => ({
      isLoading: state.curation.isLoading,
      weeklyUpdatedCurations: state.curation.weeklyUpdatedCurations,
      weeklyUpdatedTime: state.management.weeklyUpdatedTime,
    }),
    shallowEqual,
  );

  const handleOnDragEnd = (result: any) => {
    setOnDiscoveryIdList([]);
    const items = Array.from(curationList);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    {
      items.map((response) => {
        setOnDiscoveryIdList((onDiscoveryIdList) => [
          ...onDiscoveryIdList,
          response.id,
        ]);
      });
    }
    setCurationList(items);
  };

  const grid = 8;

  const getListStyle = (isDraggingOver: any) => ({
    //background: isDraggingOver ? 'lightblue' : 'lightgrey',
    // padding: grid,
    width: '100%',
  });

  const getItemStyle = (isDragging: any, draggableStyle: any) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: 'none',
    padding: grid * 2,
    margin: `0 0 ${grid}px 0`,

    // change background colour if dragging
    //background: isDragging ? 'lightgreen' : 'grey',

    // styles we need to apply on draggables
    ...draggableStyle,
  });

  // const saveAllChange = () => {
  //   // dispatch(updateMoodOrderAction.request(onDiscoveryIdList));
  //   setUpdateListModal(false);
  // };

  const handleChangeWeeklyUpdatedTime = (e: ChangeEvent<HTMLLIElement>) => {
    setWeeklyUpdatedTime(e.target.value.toString());
  };

  useEffect(() => {
    dispatch(getWeeklyUpdatedCurationsListAction.request(0, 'curation', ''));
    dispatch(getWeeklyUpdatedTimeAction.request());
  }, []);

  useEffect(() => {
    const featured: Array<any> = [];
    const initial: Array<any> = [];
    weeklyUpdatedCurations.data.map((response: any) => {
      if (response.featuredCuration) {
        featured.push(response);
      } else {
        initial.push(response);
      }
    });
    setCurationList(weeklyUpdatedCurations.data);
  }, [weeklyUpdatedCurations]);

  return (
    <>
      <CRow>
        <span
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            marginBottom: 10,
            alignItems: 'center',
          }}>
          <h5 style={{marginTop: 5}}>
            Weekly Updated Curations (
            {updatedTime === 'None'
              ? weeklyUpdatedCurations.count
              : weeklyUpdatedCurations.data.filter(
                  (item) => item.updatedTime === updatedTime,
                ).length}
            )
          </h5>
          <span style={{display: 'flex', flexDirection: 'row', gap: 20}}>
            {/* <span>
              <CButton
                block
                onClick={() => setUpdateListModal(true)}
                color="success">
                Save Changes
              </CButton>
            </span> */}
            <CSelect
              style={{width: 227}}
              name="status"
              id="select"
              value={updatedTime}
              onChange={handleChangeWeeklyUpdatedTime}>
              <option value={'None'}>None</option>
              {weeklyUpdatedTime.map((updDate) => (
                <option key={updDate} value={updDate}>
                  {updDate}
                </option>
              ))}
            </CSelect>
          </span>
        </span>

        <br></br>

        <DragDropContext onDragEnd={handleOnDragEnd}>
          <Droppable droppableId="characters">
            {(provided, snapshot) => (
              <tr
                ref={provided.innerRef}
                {...provided.droppableProps}
                style={getListStyle(snapshot.isDraggingOver)}>
                <tr>
                  <td style={styles.td1}>Order</td>
                  <td style={styles.td1}>Id</td>
                  <td style={styles.td1}>Curator</td>
                  <td style={styles.td1}>Photo</td>
                  <td style={styles.td1}>Name</td>
                  <td style={styles.td1}>Notify Me</td>
                  <td style={styles.td1}>Post Count</td>
                  <td style={styles.td1}>Save Count</td>
                  <td style={styles.td1}>Click Count</td>
                  <td style={styles.td1}>Status</td>
                </tr>

                {updatedTime === 'None'
                  ? weeklyUpdatedCurations?.data.map((listItem: any, index) => {
                      return (
                        <Draggable
                          key={listItem.id}
                          draggableId={listItem.id.toString()}
                          index={index}>
                          {(provided, snapshot) => (
                            <tr
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              style={getItemStyle(
                                snapshot.isDragging,
                                provided.draggableProps.style,
                              )}>
                              <td
                                onClick={(item) => {
                                  history.push({
                                    pathname: `/curationDetail/${listItem.id}`,
                                    state: {detail: listItem.description},
                                  });
                                }}
                                style={
                                  index % 2 == 0
                                    ? {
                                        ...styles.td1,
                                        backgroundColor: '#f2f2f2',
                                      }
                                    : styles.td1
                                }>
                                <CLabel>{index + 1}</CLabel>
                              </td>
                              <td
                                onClick={(item) => {
                                  history.push({
                                    pathname: `/curationDetail/${listItem.id}`,
                                    state: {detail: listItem.description},
                                  });
                                }}
                                style={
                                  index % 2 == 0
                                    ? {
                                        ...styles.td1,
                                        backgroundColor: '#f2f2f2',
                                      }
                                    : styles.td1
                                }>
                                <CLabel>{listItem.id}</CLabel>
                              </td>
                              <td
                                onClick={(item) => {
                                  history.push({
                                    pathname: `/curationDetail/${listItem.id}`,
                                    state: {detail: listItem.description},
                                  });
                                }}
                                style={
                                  index % 2 == 0
                                    ? {
                                        ...styles.td1,
                                        backgroundColor: '#f2f2f2',
                                      }
                                    : styles.td1
                                }>
                                <CLabel>{listItem.curator?.fullName}</CLabel>
                              </td>
                              <td
                                onClick={(item) => {
                                  history.push({
                                    pathname: `/curationDetail/${listItem.id}`,
                                    state: {detail: listItem.description},
                                  });
                                }}
                                style={
                                  index % 2 == 0
                                    ? {
                                        ...styles.td1,
                                        backgroundColor: '#f2f2f2',
                                      }
                                    : styles.td1
                                }>
                                <CImg
                                  style={{cursor: 'pointer'}}
                                  src={listItem.photo}
                                  className="c-avatar"
                                  alt={listItem.photo}
                                />
                              </td>
                              <td
                                onClick={(item) => {
                                  history.push({
                                    pathname: `/curationDetail/${listItem.id}`,
                                    state: {detail: listItem.description},
                                  });
                                }}
                                style={
                                  index % 2 == 0
                                    ? {
                                        ...styles.td1,
                                        backgroundColor: '#f2f2f2',
                                      }
                                    : styles.td1
                                }>
                                <CLabel>{listItem.name}</CLabel>
                              </td>
                              <td
                                onClick={(item) => {
                                  history.push({
                                    pathname: `/curationDetail/${listItem.id}`,
                                    state: {detail: listItem.description},
                                  });
                                }}
                                style={
                                  index % 2 == 0
                                    ? {
                                        ...styles.td1,
                                        backgroundColor: '#f2f2f2',
                                      }
                                    : styles.td1
                                }>
                                <CLabel>{listItem.post_count}</CLabel>
                              </td>
                              <td
                                onClick={(item) => {
                                  history.push({
                                    pathname: `/curationDetail/${listItem.id}`,
                                    state: {detail: listItem.description},
                                  });
                                }}
                                style={
                                  index % 2 == 0
                                    ? {
                                        ...styles.td1,
                                        backgroundColor: '#f2f2f2',
                                      }
                                    : styles.td1
                                }>
                                <CLabel>{listItem.notification_count}</CLabel>
                              </td>
                              <td
                                onClick={(item) => {
                                  history.push({
                                    pathname: `/curationDetail/${listItem.id}`,
                                    state: {detail: listItem.description},
                                  });
                                }}
                                style={
                                  index % 2 == 0
                                    ? {
                                        ...styles.td1,
                                        backgroundColor: '#f2f2f2',
                                      }
                                    : styles.td1
                                }>
                                <CLabel>{listItem.save_count}</CLabel>
                              </td>
                              <td
                                onClick={(item) => {
                                  history.push({
                                    pathname: `/curationDetail/${listItem.id}`,
                                    state: {detail: listItem.description},
                                  });
                                }}
                                style={
                                  index % 2 == 0
                                    ? {
                                        ...styles.td1,
                                        backgroundColor: '#f2f2f2',
                                      }
                                    : styles.td1
                                }>
                                <CLabel>{listItem.clickCount}</CLabel>
                              </td>
                              <td
                                onClick={(item) => {
                                  history.push({
                                    pathname: `/curationDetail/${listItem.id}`,
                                    state: {detail: listItem.description},
                                  });
                                }}
                                style={
                                  index % 2 == 0
                                    ? {
                                        ...styles.td1,
                                        backgroundColor: '#f2f2f2',
                                      }
                                    : styles.td1
                                }>
                                {
                                  <td
                                    onClick={(item) => {
                                      history.push({
                                        pathname: `/curationDetail/${listItem.id}`,
                                        state: {detail: listItem.description},
                                      });
                                    }}
                                    className={'cut-text-user-td'}>
                                    {listItem.status ? (
                                      <CBadge
                                        style={{fontSize: '100%'}}
                                        color="success">
                                        <div className="">
                                          {listItem.status.toString()}
                                        </div>
                                      </CBadge>
                                    ) : (
                                      <CBadge
                                        style={{fontSize: '100%'}}
                                        color="danger">
                                        <div className="">
                                          {listItem.status.toString()}
                                        </div>
                                      </CBadge>
                                    )}
                                  </td>
                                }
                              </td>
                            </tr>
                          )}
                        </Draggable>
                      );
                    })
                  : weeklyUpdatedCurations?.data
                      .filter((item) => item.updatedTime === updatedTime)
                      .map((listItem: any, index) => {
                        return (
                          <Draggable
                            key={listItem.id}
                            draggableId={listItem.id.toString()}
                            index={index}>
                            {(provided, snapshot) => (
                              <tr
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                style={getItemStyle(
                                  snapshot.isDragging,
                                  provided.draggableProps.style,
                                )}>
                                <td
                                  style={
                                    index % 2 == 0
                                      ? {
                                          ...styles.td1,
                                          backgroundColor: '#f2f2f2',
                                        }
                                      : styles.td1
                                  }>
                                  <CLabel>{index + 1}</CLabel>
                                </td>
                                <td
                                  style={
                                    index % 2 == 0
                                      ? {
                                          ...styles.td1,
                                          backgroundColor: '#f2f2f2',
                                        }
                                      : styles.td1
                                  }>
                                  <CLabel>{listItem.id}</CLabel>
                                </td>
                                <td
                                  style={
                                    index % 2 == 0
                                      ? {
                                          ...styles.td1,
                                          backgroundColor: '#f2f2f2',
                                        }
                                      : styles.td1
                                  }>
                                  <CLabel>{listItem.curator?.fullName}</CLabel>
                                </td>
                                <td
                                  style={
                                    index % 2 == 0
                                      ? {
                                          ...styles.td1,
                                          backgroundColor: '#f2f2f2',
                                        }
                                      : styles.td1
                                  }>
                                  <CImg
                                    style={{cursor: 'pointer'}}
                                    src={listItem.photo}
                                    className="c-avatar"
                                    alt={listItem.photo}
                                    onClick={(item) => {
                                      history.push({
                                        pathname: `/curationDetail/${listItem.id}`,
                                        state: {detail: listItem.description},
                                      });
                                    }}
                                  />
                                </td>
                                <td
                                  style={
                                    index % 2 == 0
                                      ? {
                                          ...styles.td1,
                                          backgroundColor: '#f2f2f2',
                                        }
                                      : styles.td1
                                  }>
                                  <CLabel>{listItem.name}</CLabel>
                                </td>
                                <td
                                  style={
                                    index % 2 == 0
                                      ? {
                                          ...styles.td1,
                                          backgroundColor: '#f2f2f2',
                                        }
                                      : styles.td1
                                  }>
                                  <CLabel>{listItem.post_count}</CLabel>
                                </td>
                                <td
                                  style={
                                    index % 2 == 0
                                      ? {
                                          ...styles.td1,
                                          backgroundColor: '#f2f2f2',
                                        }
                                      : styles.td1
                                  }>
                                  <CLabel>{listItem.follower_count}</CLabel>
                                </td>
                                <td
                                  style={
                                    index % 2 == 0
                                      ? {
                                          ...styles.td1,
                                          backgroundColor: '#f2f2f2',
                                        }
                                      : styles.td1
                                  }>
                                  <CLabel>{listItem.clickCount}</CLabel>
                                </td>
                                <td
                                  style={
                                    index % 2 == 0
                                      ? {
                                          ...styles.td1,
                                          backgroundColor: '#f2f2f2',
                                        }
                                      : styles.td1
                                  }>
                                  {
                                    <td className={'cut-text-user-td'}>
                                      {listItem.status ? (
                                        <CBadge
                                          style={{fontSize: '100%'}}
                                          color="success">
                                          <div className="">
                                            {listItem.status.toString()}
                                          </div>
                                        </CBadge>
                                      ) : (
                                        <CBadge
                                          style={{fontSize: '100%'}}
                                          color="danger">
                                          <div className="">
                                            {listItem.status.toString()}
                                          </div>
                                        </CBadge>
                                      )}
                                    </td>
                                  }
                                </td>
                              </tr>
                            )}
                          </Draggable>
                        );
                      })}
                {provided.placeholder}
              </tr>
            )}
          </Droppable>
        </DragDropContext>
      </CRow>
      {/* <WSRModal
        onShowModal={updateListModal}
        setOnShowModal={setUpdateListModal}
        modalTitle={'Warning'}
        modalBody={`Do you want to edit the order?`}
        operationButton={() => saveAllChange()}
        operationButtonText={'Yes, update it!'}
        cancelButton={() => {
          setUpdateListModal(false);
        }}
        cancelButtonText={'Cancel'}
      /> */}
    </>
  );
};

export default WeeklyUpdatedCurationList;

const styles: any = {
  td1: {
    width: '220px',
    padding: '10px',
    border: '1px solid #d5d5dd',
  },
};
