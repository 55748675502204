import React, {useState, useEffect, ChangeEvent, useRef} from 'react';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import moment from 'moment';

import {
  CCard,
  CCol,
  CRow,
  CImg,
  CLabel,
  CInput,
  CBadge,
  CModal,
  CModalBody,
  CModalHeader,
  CButton,
} from '@coreui/react';
import {
  getCurationsList as getCurationsListAction,
  searchDiscoveryCurations as searchDiscoveryCurationsAction,
} from '@actions/curation';
import {addCurationToMood as addCurationToMoodAction} from '@actions/chooseMood';
import {WSRDataTable} from '../WSRDataTable';
import {values} from './values';

type Props = {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  onCurationAdded: (curationId: number) => void;
};

export const AddCuration = (props: Props) => {
  const {isOpen, setIsOpen, onCurationAdded} = props;
  const [page, setPage] = useState(1);

  const [debouncedCurationName, setDebouncedCurationName] = useState('');

  const searchFieldsRef = useRef<{
    [key: string]: any;
  }>();

  const dispatch = useDispatch();

  const scopedSlots = {
    id: (item: any) => (
      <td className={'cut-text-user-td'}>
        <div className="">{item.id != null ? item.id : '-'}</div>
      </td>
    ),
    photo: (item: any) => (
      <td>
        {/* <div className="c-avatar"> */}
        <div className="text-center">
          <CImg
            src={item.photo == null ? 'avatars/blank-profile.jpg' : item.photo}
            style={{height: '40px', width: '100px'}}
            alt={''}
          />
        </div>
      </td>
    ),

    curator: (item: any) => (
      <td className={'cut-text-user-td'}>
        <div className="">
          {item.curator?.fullName != null ? item.curator?.fullName : '-'}
        </div>
      </td>
    ),

    postCount: (item: any) => (
      <td className={'cut-text-user-td'}>
        <div className="">
          {item.post_count != null ? item.post_count : '-'}
        </div>
      </td>
    ),

    followerCount: (item: any) => (
      <td className={'cut-text-user-td'}>
        <div className="">
          {item.follower_count != null ? item.follower_count : '-'}
        </div>
      </td>
    ),
    status: (item: any) => (
      <td className={'cut-text-user-td'}>
        {item.status ? (
          <CBadge style={{fontSize: '100%'}} color="success">
            <div className="">{item.status.toString()}</div>
          </CBadge>
        ) : (
          <CBadge style={{fontSize: '100%'}} color="danger">
            <div className="">{item.status.toString()}</div>
          </CBadge>
        )}
      </td>
    ),
    createdAt: (item: any) => (
      <td className={'cut-text-user-td'}>
        <div>
          {item.createdAt != null
            ? moment(item.createdAt).format('DD/MM/YYYY')
            : '-'}
        </div>
      </td>
    ),
    updatedAt: (item: any) => (
      <td className={'cut-text-user-td'}>
        <div>
          {item.updatedAt != null
            ? moment(item.updatedAt).format('DD/MM/YYYY')
            : ''}
        </div>
      </td>
    ),
    isPublic: (item: any) => (
      <td className={'cut-text-user-td'}>
        {item.isPublic ? (
          <CBadge style={{fontSize: '100%'}} color="success">
            <div className="">{item.isPublic.toString()}</div>
          </CBadge>
        ) : (
          <CBadge style={{fontSize: '100%'}} color="danger">
            <div className="">{item.isPublic.toString()}</div>
          </CBadge>
        )}
      </td>
    ),
    addButton: (item: any) => (
      <CButton
        className="my-2 mfs-auto"
        block
        onClick={() => {
          //dispatch(addCurationToMoodAction.request(item.id));
          onCurationAdded(item);
          setIsOpen(false);
        }}
        color="success">
        Add
      </CButton>
    ),
  };

  const {curationList, searchDiscoveryCurations, isLoading} = useSelector(
    (state) => ({
      isLoading: state.curation.isLoading,
      curationList: state.curation.curationList,
      searchDiscoveryCurations: state.curation.searchDiscoveryCurations,
    }),
    shallowEqual,
  );

  const pageChange = (newPage: number) => {
    if (data.count !== 0) {
      setPage(newPage);
    }

    if (debouncedCurationName !== '') {
      dispatch(
        searchDiscoveryCurationsAction.request(newPage, {
          searchbyCurationName: debouncedCurationName,
        }),
      );
    } else {
      dispatch(getCurationsListAction.request(newPage, 'curation'));
    }
  };

  const onChangeCurationName = (name: string) => {
    dispatch(
      searchDiscoveryCurationsAction.request(page ? page : 1, {
        searchbyCurationName: name,
      }),
    );
  };

  const data =
    debouncedCurationName === '' ? curationList : searchDiscoveryCurations;

  useEffect(() => {
    dispatch(getCurationsListAction.request(1, 'curation'));
  }, []);

  return (
    <CModal show={isOpen} size="xl" onClose={setIsOpen}>
      <CModalHeader>
        <CCol xs="4">
          <div style={{flexDirection: 'row'}}>
            <CLabel>Curation Name</CLabel>
            <CInput
              className="form-control"
              type="text"
              value={debouncedCurationName}
              onChange={(e: ChangeEvent<HTMLLIElement>) => {
                const name = e.target.value.toString();
                setDebouncedCurationName(name);
                onChangeCurationName(name);
              }}
            />
          </div>
        </CCol>
      </CModalHeader>
      <CModalBody>
        <CRow>
          <CCol xl={12}>
            <CCard>
              {isLoading || data.count === 0 ? (
                <div
                  style={{
                    height: 700,
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}>
                  <h1>LOADING...</h1>
                </div>
              ) : (
                <WSRDataTable
                  items={data.data}
                  fields={values.fields}
                  scopedSlots={scopedSlots}
                  pageLimit={50}
                  page={page}
                  pageChange={(newPage: number) => {
                    pageChange(newPage);
                  }}
                  itemsCount={data.count}
                />
              )}
            </CCard>
          </CCol>
        </CRow>
      </CModalBody>
    </CModal>
  );
};
