import {applyMiddleware, createStore} from 'redux';
import createSagaMiddleware from 'redux-saga';
import logger from 'redux-logger';
import {createMigrate, persistStore, persistReducer} from 'redux-persist';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import storage from 'redux-persist/lib/storage';

import sagas from '@sagas';
import {reducer} from './reducerCombiner';
import {migrations} from './migrations';

const sagaMiddleware = createSagaMiddleware();

const persistConfig = {
  key: 'root',
  storage,
  stateReconciler: autoMergeLevel2,
  timeout: 0,
  version: 2,
  blacklist: [
    'categories',
    'chooseMood',
    'curation',
    'management',
    'marketing',
    'notifications',
    'onboarding',
    'posts',
    'tags',
    'user',
    'users',
  ],
  migrate: createMigrate(migrations, {debug: true}),
};
// eslint-disable-next-line
// @ts-ignore
const persistedReducer = persistReducer(persistConfig, reducer);

const store = createStore(
  persistedReducer,
  // Do not open logger on build mode, it's causes memory leaks... // ibocan@joinwiser.com ...
  //applyMiddleware(sagaMiddleware, logger),
  applyMiddleware(sagaMiddleware),
);

sagaMiddleware.run(sagas);

const persistor = persistStore(store);

export {store, persistor};
