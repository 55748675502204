import {Axios} from '@utils';
import {constants} from '@resources';

export async function searchCurationList(
  page: number,
  searchFields?: {[key: string]: any},
) {
  let params = '';

  if (searchFields) {
    Object.keys(searchFields).map((keyValue: string, i = 0) => {
      keyValue !== 'page' &&
      keyValue !== 'searchbyCurationName' &&
      keyValue !== 'showOnDiscoveryByLocal' &&
      keyValue !== 'categoryIds'
        ? (params =
            params + '&' + keyValue + '=' + Object.values(searchFields)[i])
        : (params = params);

      keyValue == 'searchbyCurationName' &&
        (params = params + '&name=' + Object.values(searchFields)[i]);

      if (keyValue == 'sort') {
        if (Object.values(searchFields)[i] === 'pascend') {
          const sortText =
            `&orderBy=` + 'post_count' + `&orderDirection=` + 'ASC';
          params = sortText + params;
        } else if (Object.values(searchFields)[i] === 'pdescend') {
          const sortText =
            `&orderBy=` + 'post_count' + `&orderDirection=` + 'DESC';
          params = sortText + params;
        } else if (Object.values(searchFields)[i] === 'fascend') {
          const sortText =
            `&orderBy=` + 'follower_count' + `&orderDirection=` + 'ASC';
          params = sortText + params;
        } else if (Object.values(searchFields)[i] === 'fdescend') {
          const sortText =
            `&orderBy=` + 'follower_count' + `&orderDirection=` + 'DESC';
          params = sortText + params;
        } else if (Object.values(searchFields)[i] === 'cascend') {
          const sortText =
            `&orderBy=` + 'clickCount' + `&orderDirection=` + 'ASC';
          params = sortText + params;
        } else if (Object.values(searchFields)[i] === 'cdescend') {
          const sortText =
            `&orderBy=` + 'clickCount' + `&orderDirection=` + 'DESC';
          params = sortText + params;
        } else if (Object.values(searchFields)[i] === 'uascend') {
          const sortText =
            `&orderBy=` + 'updatedAt' + `&orderDirection=` + 'ASC';
          params = sortText + params;
        } else if (Object.values(searchFields)[i] === 'udescend') {
          const sortText =
            `&orderBy=` + 'updatedAt' + `&orderDirection=` + 'DESC';
          params = sortText + params;
        } else if (Object.values(searchFields)[i] === 'crtascend') {
          const sortText =
            `&orderBy=` + 'createdAt' + `&orderDirection=` + 'ASC';
          params = sortText + params;
        } else if (Object.values(searchFields)[i] === 'crtdescend') {
          const sortText =
            `&orderBy=` + 'createdAt' + `&orderDirection=` + 'DESC';
          params = sortText + params;
        }
      }

      if (keyValue == 'showOnDiscoveryByLocal') {
        if (searchFields?.showOnDiscoveryByLocal.length === 1) {
          const showOnDiscoveryByLocalStr = `&showOnDiscoveryByLocal=["${searchFields?.showOnDiscoveryByLocal}"]`;
          params = showOnDiscoveryByLocalStr + params;
        } else if (searchFields?.showOnDiscoveryByLocal.length === 2) {
          const showOnDiscoveryByLocalStr =
            `&showOnDiscoveryByLocal=["` +
            searchFields?.showOnDiscoveryByLocal[0] +
            `","` +
            searchFields?.showOnDiscoveryByLocal[1] +
            `"]`;
          params = showOnDiscoveryByLocalStr + params;
        }
      }

      if (keyValue == 'categoryIds' && searchFields?.categoryIds[0]) {
        const categoryIdsStr = `&categoryIds=[${searchFields?.categoryIds}]`;
        params = categoryIdsStr + params;
      }
      i++;
    });
  }

  if (searchFields?.sort) {
    return Axios.get(
      `${constants.CURATION_BASE}/management?limit=50&offset=${
        (page - 1) * 50
      }` + params,
    );
  } else {
    return Axios.get(
      `${constants.CURATION_BASE}/management?limit=50&offset=${
        (page - 1) * 50
      }&orderBy=createdAt&orderDirection=DESC` + params,
    );
  }
}
