import {take, call, put} from 'redux-saga/effects';
import type {Effect} from 'redux-saga/effects';

import {deleteTag as deleteTagService} from '@services/tags';
import * as CurationActions from '@actions/tags';
import * as NotificationsActions from '@actions/notifications';

export function* deleteTag(): Generator<Effect, void, any> {
  while (true) {
    try {
      const {tagId} = yield take(CurationActions.DELETE_TAG.REQUEST);
      const {data: response} = yield call(deleteTagService, tagId);
      yield put(CurationActions.deleteTag.success(response.data));
      yield put(CurationActions.getTags.request());
      yield put(NotificationsActions.sendSuccessMessage.request());
    } catch (error) {
      // Show info
      yield put(CurationActions.deleteTag.failure(error));
    }
  }
}
