import {constants} from '@resources';

// Creates request type for a giving base...
const createRequestTypes = (base: any): any => {
  return [constants.REQUEST, constants.SUCCESS, constants.FAILURE].reduce(
    (acc, type) => {
      // @ts-ignore
      acc[type] = `${base}_${type}`;
      return acc;
      // return {
      //   type: `${base}_${type}`,
      // };
    },
    {},
  );
};

// Creates an action for giving type...
const action = (type: any, payload: any = {}): any => {
  return {type, ...payload};
};

export const Action = {
  createRequestTypes,
  action,
};
