import {constants} from '@resources';
import {Action} from '@utils';

export const POST_BULK_NOTIFICATION = Action.createRequestTypes(
  'POST_BULK_NOTIFICATION',
);
export const postBulkNotification = {
  request: (notification: any) =>
    Action.action(POST_BULK_NOTIFICATION[constants.REQUEST], {notification}),
  success: (stats: any) =>
    Action.action(POST_BULK_NOTIFICATION[constants.SUCCESS], {stats}),
  failure: (error: Error) =>
    Action.action(POST_BULK_NOTIFICATION[constants.FAILURE], {error}),
};

export const CLOSE_SUCCESS_MESSAGE = Action.createRequestTypes(
  'CLOSE_SUCCESS_MESSAGE',
);
export const closeSuccessMessage = {
  request: () => Action.action(CLOSE_SUCCESS_MESSAGE[constants.REQUEST], {}),
};

export const SEND_SUCCESS_MESSAGE = Action.createRequestTypes(
  'SEND_SUCCESS_MESSAGE',
);
export const sendSuccessMessage = {
  request: () => Action.action(SEND_SUCCESS_MESSAGE[constants.REQUEST], {}),}
export const OPEN_SUCCESS_MESSAGE = Action.createRequestTypes(
  'OPEN_SUCCESS_MESSAGE',
);
export const openSuccessMessage = {
  request: () => Action.action(OPEN_SUCCESS_MESSAGE[constants.REQUEST], {}),
};
