import React, {useEffect} from 'react';
import {useDispatch, useSelector, shallowEqual} from 'react-redux';
import {UsersList} from '../../components';
import {getAllUsers as getAllUsersAction} from '@actions/users';

const UsersPage = () => {
  const dispatch = useDispatch();
  const {selectedLocale} = useSelector(
    (state) => ({
      selectedLocale: state.management.selectedLocale || {locale: 'gb'},
    }),
    shallowEqual,
  );

  useEffect(() => {
    dispatch(
      getAllUsersAction.request(10, 0, 'user', '', {}, selectedLocale.locale),
    );
  }, [selectedLocale]);

  return <UsersList role="user"></UsersList>;
};

export default UsersPage;
