import React, {ChangeEvent} from 'react';

import {CCard, CCardBody, CCol, CDataTable, CRow} from '@coreui/react';

type Props = {
  items?: Array<any>;
  fields: Array<any>;
  pageChange?: Function;
  scopedSlots: any;
  onClick?: Function;
};

export const WSRDataTable: React.FC<Props> = (props) => {
  const {items, fields, scopedSlots, onClick} = props;

  return (
    <>
      <CRow>
        <CCol xl={12}>
          <CCard>
            <CCardBody>
              <CDataTable
                items={items}
                itemsPerPage={200}
                fields={fields}
                hover
                border
                size="sm"
                striped
                outlined
                activePage={1}
                clickableRows
                scopedSlots={scopedSlots}
                onRowClick={(event: ChangeEvent<HTMLInputElement>) => {
                  onClick?.(event);
                }}
              />
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
    </>
  );
};
