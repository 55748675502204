import React from 'react';
import {CFormGroup, CLabel, CInput} from '@coreui/react';

import {InputWarning} from '../InputWarning';

type Props = {
  htmlFor: string;
  htmlForField: string;
  field: string;
  type: string;
  value: string;
  name: string;
  placeholder: string;
  autoComplete: string;
  onChange: any;
  required: boolean;
  valid: boolean | undefined;
  warningText: string;
};

export const TextInput: React.FC<Props> = (props) => {
  const {
    htmlFor,
    htmlForField,
    field,
    type,
    value,
    name,
    placeholder,
    autoComplete,
    onChange,
    required,
    valid,
    warningText,
  } = props;
  return (
    <CFormGroup>
      <CLabel htmlFor={htmlFor}>{htmlForField}</CLabel>
      <CInput
        type={type}
        value={value}
        name={name}
        placeholder={placeholder}
        autoComplete={autoComplete}
        onChange={onChange}
        required={required}
        valid={valid === undefined || !valid ? false : true}
      />
      {valid !== undefined && (
        <InputWarning isValid={!valid} text={warningText} />
      )}
    </CFormGroup>
  );
};
