import {take, call, put} from 'redux-saga/effects';
import type {Effect} from 'redux-saga/effects';

import {getLastActiveUsers as getLastActiveUsersService} from '@services/users';
import * as UsersActions from '@actions/users';

export function* getLastActiveUsers(): Generator<Effect, void, any> {
  while (true) {
    try {
      yield take(UsersActions.GET_LAST_ACTIVE_USERS.REQUEST);
      const {data: response} = yield call(getLastActiveUsersService);
      yield put(UsersActions.getLastActiveUsers.success(response.data));
    } catch (error) {
      // Show info
      yield put(UsersActions.getLastActiveUsers.failure(error));
    }
  }
}
