import {constants} from '@resources';
import {Action} from '@utils';

export const GET_REFERRERS = Action.createRequestTypes('GET_REFERRERS');
export const getReferrers = {
  request: (offset: number) =>
    Action.action(GET_REFERRERS[constants.REQUEST], {offset}),
  success: (data: any, offset: number, count: number) =>
    Action.action(GET_REFERRERS[constants.SUCCESS], {data, offset, count}),
  failure: (error: Error) =>
    Action.action(GET_REFERRERS[constants.FAILURE], {error}),
};

export const SEARCH_REFERRERS = Action.createRequestTypes('SEARCH_REFERRERS');
export const searchReferrers = {
  request: (
    offset: number,
    searchFields: {id: string; userName: string; fullName: string},
  ) =>
    Action.action(SEARCH_REFERRERS[constants.REQUEST], {
      offset,
      searchFields,
    }),
  success: (data: any, offset: number, count: number) =>
    Action.action(SEARCH_REFERRERS[constants.SUCCESS], {
      data,
      offset,
      count,
    }),
  failure: (error: Error) =>
    Action.action(SEARCH_REFERRERS[constants.FAILURE], {error}),
};
