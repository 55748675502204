import {take, call, put} from 'redux-saga/effects';
import type {Effect} from 'redux-saga/effects';

import {updateManagementUsers as updateManagementUsersService} from '@services/users';
import * as UsersActions from '@actions/users';

export function* updateManagementUsers(): Generator<Effect, void, any> {
  while (true) {
    try {
      const {uptatedData, onSuccess} = yield take(
        UsersActions.UPDATE_MANAGEMENT_USERS.REQUEST,
      );
      const {data: response} = yield call(
        updateManagementUsersService,
        uptatedData,
      );
      onSuccess();
      yield put(UsersActions.updateManagementUsers.success([]));
    } catch (error) {
      // Show info
      yield put(UsersActions.updateManagementUsers.failure(error));
    }
  }
}
