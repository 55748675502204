import * as ActionTypes from '@actions/tags';

import type {TagReducer} from './types';

const initialState: TagReducer = {
  isLoading: false,
  error: false,
  errorDescription: '',
  suggestedTags: {},
  tagList: {
    data: [],
    count: 0,
  },
};

export function tags(state = initialState, action: any = {}): TagReducer {
  switch (action.type) {
    case ActionTypes.GET_TAGS.REQUEST: {
      return {
        ...state,
        isLoading: true,
        error: false,
        errorDescription: '',
      };
    }
    case ActionTypes.GET_TAGS.SUCCESS: {
      return {
        ...state,
        isLoading: false,
        error: false,
        errorDescription: '',
        tagList: {
          data: action.stats.data,
          count: action.stats.count,
        },
      };
    }
    case ActionTypes.GET_TAGS.FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: true,
        errorDescription: action.error.data?.message,
      };
    }

    case ActionTypes.SEARCH_TAGS.REQUEST: {
      return {
        ...state,
        isLoading: true,
        error: false,
        errorDescription: '',
      };
    }
    case ActionTypes.SEARCH_TAGS.SUCCESS: {
      return {
        ...state,
        isLoading: false,
        error: false,
        errorDescription: '',
        tagList: {
          data: action.stats.data,
          count: action.stats.count,
        },
      };
    }
    case ActionTypes.SEARCH_TAGS.FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: true,
        errorDescription: action.error.data?.message,
      };
    }

    case ActionTypes.DELETE_TAG.REQUEST: {
      return {
        ...state,
        isLoading: true,
        error: false,
        errorDescription: '',
      };
    }
    case ActionTypes.DELETE_TAG.SUCCESS: {
      return {
        ...state,
        isLoading: false,
        error: false,
        errorDescription: '',
      };
    }
    case ActionTypes.DELETE_TAG.FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: true,
        errorDescription: action.error.data?.message,
      };
    }

    case ActionTypes.UPDATE_TAG.REQUEST: {
      return {
        ...state,
        isLoading: true,
        error: false,
        errorDescription: '',
      };
    }
    case ActionTypes.UPDATE_TAG.SUCCESS: {
      return {
        ...state,
        isLoading: false,
        error: false,
        errorDescription: '',
      };
    }
    case ActionTypes.UPDATE_TAG.FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: true,
        errorDescription: action.error.data?.message,
      };
    }

    case ActionTypes.CREATE_TAG.REQUEST: {
      return {
        ...state,
        isLoading: true,
        error: false,
        errorDescription: '',
      };
    }
    case ActionTypes.CREATE_TAG.SUCCESS: {
      return {
        ...state,
        isLoading: false,
        error: false,
        errorDescription: '',
      };
    }
    case ActionTypes.CREATE_TAG.FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: true,
        errorDescription: action.error.message,
      };
    }

    case ActionTypes.REMOVE_CATEGORY_FROM_TAG.REQUEST: {
      return {
        ...state,
        isLoading: true,
        error: false,
        errorDescription: '',
      };
    }
    case ActionTypes.REMOVE_CATEGORY_FROM_TAG.SUCCESS: {
      return {
        ...state,
        isLoading: false,
        error: false,
        errorDescription: '',
      };
    }
    case ActionTypes.REMOVE_CATEGORY_FROM_TAG.FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: true,
        errorDescription: action.error.data?.message,
      };
    }

    case ActionTypes.REMOVE_ERROR_MESSAGE.REQUEST: {
      return {
        ...state,
        isLoading: false,
        error: false,
        errorDescription: '',
      };
    }

    default:
      return state;
  }
}
