import {Axios} from '@utils';
import {constants} from '../../resources';

export function getCategories(offset: number) {
  if (offset === 1) {
    return Axios.get(
      `${constants.CATEGORY_MANAGEMENT_BASE}?limit=1000&offset=${0}`,
    );
  } else {
    return Axios.get(
      `${constants.CATEGORY_MANAGEMENT_BASE}?limit=10&offset=${offset}`,
    );
  }
}
