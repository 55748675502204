import {take, call, put} from 'redux-saga/effects';
import type {Effect} from 'redux-saga/effects';

import {postBulkNotification as postBulkNotificationService} from '@services/notifications';
import * as NotificationActions from '@actions/notifications';

export function* postBulkNotification(): Generator<Effect, void, any> {
  while (true) {
    try {
      const {notification} = yield take(
        NotificationActions.POST_BULK_NOTIFICATION.REQUEST,
      );
      const {data: response} = yield call(
        postBulkNotificationService,
        notification,
      );

      yield put(
        NotificationActions.postBulkNotification.success(response.data),
      );
    } catch (error) {
      // Show info
      yield put(NotificationActions.postBulkNotification.failure(error));
    }
  }
}
