import {Axios} from '@utils';
import {constants} from '@resources';

export async function removeCurationsFromMoodAndWeekly(
  updateCurationIds: any,
  pageName: string,
) {
  if (pageName === 'editorialPicks') {
    return Axios.post(`${constants.CURATION_BASE}/bulkDisableEditorialPick`, {
      curationListIds: updateCurationIds,
    });
  } else if (pageName === 'chooseMood') {
    return Axios.post(`${constants.CURATION_BASE}/mood/bulkdelete`, {
      curationListIds: updateCurationIds,
    });
  }
}
