import {Axios} from '@utils';
import {constants} from '@resources';

export async function updateWisers(updatedWisers: any, locale: string) {
  const parameters = {
    locale: locale,
    userIds: updatedWisers,
  };
  return Axios.put(
    `${constants.USER_MANAGEMENT_BASE}/discovery/order`,
    parameters,
  );
}
