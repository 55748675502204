import {take, call, put} from 'redux-saga/effects';
import type {Effect} from 'redux-saga/effects';

import {addUserToOnboardingCategory as addUserToOnboardingCategoryService} from '@services/onboarding';
import * as OnboardingActions from '@actions/onboarding';

export function* addUserToOnboardingCategory(): Generator<Effect, void, any> {
  while (true) {
    try {
      const {categoryId, userId, onSucess} = yield take(
        OnboardingActions.ADD_USER_TO_ONBOARDINGCATEGORY.REQUEST,
      );

      const {data: response} = yield call(
        addUserToOnboardingCategoryService,
        categoryId,
        userId,
      );

      yield put(
        OnboardingActions.addUserToOnboardingCategory.success(categoryId),
      );
      onSucess();
    } catch (error) {
      // Show info
      yield put(OnboardingActions.addUserToOnboardingCategory.failure(error));
    }
  }
}
