import {take, call, put} from 'redux-saga/effects';
import type {Effect} from 'redux-saga/effects';

import {updatePost as updatePostService} from '@services/posts';
import * as PostActions from '@actions/posts';

export function* updatePost(): Generator<Effect, void, any> {
  while (true) {
    try {
      const {updatePost, furtherReadings, onSucess} = yield take(
        PostActions.UPDATE_POST.REQUEST,
      );
      const {data: response} = yield call(
        updatePostService,
        updatePost,
        furtherReadings,
      );
      yield put(PostActions.updatePost.success(response));
      yield put(PostActions.getPostDetail.request(updatePost.id));
      onSucess();
    } catch (error) {
      // Show info
      yield put(PostActions.updatePost.failure(error));
    }
  }
}
