import {take, call, put} from 'redux-saga/effects';
import type {Effect} from 'redux-saga/effects';

import {getPostDetail as getPostDetailService} from '@services/posts';
import * as PostActions from '@actions/posts';

export function* getPostDetail(): Generator<Effect, void, any> {
  while (true) {
    try {
      const {postId} = yield take(PostActions.GET_POST_DETAIL.REQUEST);
      const {data: response} = yield call(getPostDetailService, postId);
      yield put(PostActions.getPostDetail.success(response.post));
    } catch (error) {
      // Show info
      yield put(PostActions.getPostDetail.failure(error));
    }
  }
}
