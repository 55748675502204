import {take, call, put} from 'redux-saga/effects';
import type {Effect} from 'redux-saga/effects';

import {getLocales as getLocalesService} from '@services/management';
import * as ManagementActions from '@actions/management';

export function* getAllLocales(): Generator<Effect, void, any> {
  while (true) {
    try {
      yield take(ManagementActions.GET_ALL_LOCALES.REQUEST);
      const {data: response} = yield call(getLocalesService);
      yield put(ManagementActions.getAllLocales.success(response.data));
    } catch (error) {
      // Show info
      yield put(ManagementActions.getAllLocales.failure(error));
    }
  }
}
