import axios from 'axios';
import {constants} from '../resources';

export const Axios = axios.create({
  baseURL: constants.baseServiceUrl,
  timeout: constants.serviceTimeout,
});

Axios.defaults.headers.post['Content-Type'] = 'application/json';

const reject = (error: any, Promise: any) =>
  Promise.reject({
    code: -10,
    message: error.response ? error.response.data.message : '',
  });

// REQUEST INTERCEPTOR
Axios.interceptors.request.use(
  (config) => config,
  (error) => reject(error, Promise),
);

// RESPONSE INTERCEPTOR
Axios.interceptors.response.use(
  (response) => response,
  (error) => reject(error, Promise),
);

// export function setDefaultHeaders(headers: Array<Record<string, string>>) {
//   Axios.defaults.headers = {...Axios.defaults.headers, ...headers};
// }

export function setToken(accessToken?: string) {
  if (accessToken) {
    Axios.defaults.headers.common.authorization = accessToken;
  } else {
    delete Axios.defaults.headers.common.authorization;
  }
}
