import {PURGE} from 'redux-persist';

import * as ActionTypes from '@actions/auth';

import type {AuthReducer} from './types';

const initialState: AuthReducer = {
  isLoading: false,
  error: false,
  errorDescription: '',
  accessToken: undefined,
  status: 'active',
};

export function auth(state = initialState, action: any = {}): AuthReducer {
  switch (action.type) {
    case PURGE: {
      return initialState;
    }
    case ActionTypes.LOGIN.REQUEST: {
      return {
        ...state,
        isLoading: true,
        error: false,
        errorDescription: '',
      };
    }
    case ActionTypes.LOGIN.SUCCESS: {
      return {
        ...state,
        isLoading: false,
        error: false,
        errorDescription: '',
        accessToken: action.accessToken,
        status: action.status,
      };
    }
    case ActionTypes.LOGIN.FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: true,
        errorDescription: action.error,
        //errorDescription: action.error.data?.message,
      };
    }

    case ActionTypes.LOG_OUT.REQUEST: {
      return {
        ...state,
        isLoading: false,
        error: false,
        errorDescription: '',
      };
    }
    case ActionTypes.LOG_OUT.SUCCESS: {
      return {
        ...state,
        isLoading: false,
        // error: false,
        // errorDescription: '',
        accessToken: action.accessToken,
        //status: action.status,
      };
    }
    case ActionTypes.LOG_OUT.FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: true,
        errorDescription: action.error.data?.message,
      };
    }

    default:
      return state;
  }
}
