// import {PURGE} from 'redux-persist';

import * as ActionTypes from '@actions/marketing';

import type {ReferrersReducer} from './types';

const initialState: ReferrersReducer = {
  isLoading: false,
  error: false,
  errorDescription: '',
  referers: {
    data: [],
    offset: 0,
    count: 0,
  },
  searchResults: {
    data: [],
    offset: 0,
    count: 0,
  },
};

export function referrers(
  state = initialState,
  action: any = {},
): ReferrersReducer {
  switch (action.type) {
    // case PURGE: {
    //   return initialState;
    // }
    case ActionTypes.GET_REFERRERS.REQUEST: {
      return {
        ...state,
        isLoading: true,
        error: false,
        errorDescription: '',
      };
    }
    case ActionTypes.GET_REFERRERS.SUCCESS: {
      return {
        ...state,
        isLoading: false,
        error: false,
        errorDescription: '',
        referers: {
          data:
            action.offset === 0
              ? action.data
              : [...state.referers.data, ...action.data],
          offset: action.offset,
          count: action.count,
        },
      };
    }
    case ActionTypes.GET_REFERRERS.FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: true,
        errorDescription: action.error.data?.message,
      };
    }
    case ActionTypes.SEARCH_REFERRERS.REQUEST: {
      return {
        ...state,
        isLoading: true,
        error: false,
        errorDescription: '',
      };
    }
    case ActionTypes.SEARCH_REFERRERS.SUCCESS: {
      return {
        ...state,
        isLoading: false,
        error: false,
        errorDescription: '',
        searchResults: {
          data:
            action.offset === 0
              ? action.data
              : [...state.searchResults.data, ...action.data],
          offset: action.offset,
          count: action.count,
        },
      };
    }
    case ActionTypes.SEARCH_REFERRERS.FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: true,
        errorDescription: action.error.data?.message,
      };
    }

    default:
      return state;
  }
}
