import React, {ChangeEvent, useEffect, useState} from 'react';
import {CCard, CCardBody, CCol, CRow, CInput, CLabel} from '@coreui/react';
import moment from 'moment';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';

import {getCurationStats as getCurationStatsAction} from '@actions/curation';

import {CurationStatsList} from '../CurationStatsList';

let startDate: string;
let endDate: string;

startDate = '';
endDate = '';

export const CurationListsStats: React.FC = () => {
  const [page, setPage] = useState(1);

  const dispatch = useDispatch();

  const {curationStats} = useSelector(
    (state) => ({
      curationStats: state.curation.curationStats,
    }),
    shallowEqual,
  );

  const handleStartDateChange = async (
    event: ChangeEvent<HTMLInputElement>,
  ) => {
    setPage(1);
    startDate = moment(event.target.value).format('YYYY-MM-DD');
    await getData(startDate, endDate);
  };

  const handleEndDateChange = async (event: ChangeEvent<HTMLInputElement>) => {
    setPage(1);
    endDate = moment(event.target.value).format('YYYY-MM-DD');
    getData(startDate, endDate);
  };

  const getData = async (startDate: string, endDate: string) => {
    if (
      endDate <= startDate ||
      (startDate !== undefined && endDate !== undefined)
    ) {
      dispatch(getCurationStatsAction.request(0, startDate, endDate));
    }
  };

  const onPageChange = (newPage: number) => {
    if ((newPage - 1) * 10 >= curationStats.data.length) {
      dispatch(
        getCurationStatsAction.request(
          curationStats.page + 10,
          startDate,
          endDate,
        ),
      );
    }

    setPage(newPage);
  };

  useEffect(() => {
    dispatch(getCurationStatsAction.request(0, '', ''));
  }, []);

  return (
    <CCard>
      <CCardBody>
        <CRow>
          <CCol>
            <h5 id="traffic" className="card-title mb-2">
              Curation Stats Count : {curationStats.count}
            </h5>
          </CCol>

          <CCol>
            <CLabel>Start Date</CLabel>
            <CInput
              className="form-control"
              type="date"
              placeholder="Start Date"
              onChange={handleStartDateChange}
            />
          </CCol>
          <CCol>
            <CLabel>End Date</CLabel>
            <CInput
              className="form-control"
              type="date"
              placeholder="Start Date"
              onChange={handleEndDateChange}
            />
          </CCol>
        </CRow>
        <br />
        <CurationStatsList
          page={page}
          pageLimit={10}
          pageChange={onPageChange}
          curationList={curationStats.data}
          curationListCount={curationStats.count}
        />
      </CCardBody>
    </CCard>
  );
};
