import React from 'react';
import moment from 'moment';

import {CCard, CCol, CRow, CImg} from '@coreui/react';

import {WSRDataTable} from './../../components';

type Props = {
  page: number;
  pageLimit: number;
  pageChange: Function;
  userList: Array<any>;
};

export const UserList: React.FC<Props> = (props) => {
  const {page, pageLimit, pageChange, userList} = props;

  const fields = [
    {
      key: 'id',
      _style: {width: '5%', minWidth: '5%'},
    },
    {
      key: 'photo',
      // label: <CIcon name="cil-people" />,
      _style: {width: '5%'},
    },
    {
      key: 'userName',
      label: 'User',
      _style: {width: '25%'},
    },
    {
      key: 'InvitationCode',
      label: 'Invitation Code',
      _style: {width: '20%'},
    },
    {
      key: 'invitationCodeOwner',
      label: 'Code Owner',
      _style: {width: '20%'},
    },
    {
      key: 'role',
      label: 'Role',
      _style: {width: '10%'},
    },
    {
      key: 'activity',
      label: 'Activity',
      _style: {width: '15%'},
    },
  ];

  const scopedSlots = {
    userName: (item: any) => (
      <td>
        <div>{item.userName}</div>
        <div className="small text-muted">
          Registered: {moment(item.createdAt).format('DD/MM/YYYY')}
        </div>
      </td>
    ),
    InvitationCode: (item: any) => (
      <td>
        <div>{item.invitationCode ? item.invitationCode : '-'}</div>
      </td>
    ),
    invitationCodeOwner: (item: any) => (
      <td>
        <div>{item.invitationCodeOwner ? item.invitationCodeOwner : '-'}</div>
      </td>
    ),
    photo: (item: any) => (
      <td>
        <div className="c-avatar">
          <CImg
            src={item.photo ? item.photo : 'avatars/blank-profile.jpg'}
            className="c-avatar-img"
            alt=" "
          />
        </div>
      </td>
    ),
    activity: (item: any) => (
      <td>
        <div className="small text-muted">Last update</div>
        <strong>{moment(item.updatedAt).format('DD/MM/YYYY')}</strong>
      </td>
    ),
  };

  return (
    <>
      <CRow>
        <CCol xl={12}>
          <CCard>
            <WSRDataTable
              items={userList}
              fields={fields}
              scopedSlots={scopedSlots}
              pageLimit={10}
              page={page}
              pageChange={pageChange}
              itemsCount={userList.length}></WSRDataTable>
          </CCard>
        </CCol>
      </CRow>
    </>
  );
};
