import {take, call, put} from 'redux-saga/effects';
import type {Effect} from 'redux-saga/effects';

import {getRandomCover as getRandomCoverService} from '@services/curationList';
import * as CurationActions from '@actions/curation';

export function* getRandomCover(): Generator<Effect, void, any> {
  while (true) {
    try {
      yield take(CurationActions.GET_RANDOM_COVER.REQUEST);
      const {data: response} = yield call(getRandomCoverService);
      yield put(CurationActions.getRandomCover.success(response.data));
    } catch (error) {
      // Show info
      yield put(CurationActions.getRandomCover.failure(error));
    }
  }
}
