import React, {useEffect} from 'react';
import {useDispatch} from 'react-redux';
import {AdminsList} from './components';

import {getAdmins as getAdminsAction} from '@actions/users';

const AdminsListPage = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAdminsAction.request(0));
  }, []);

  return <AdminsList></AdminsList>;
};

export default AdminsListPage;
