import {combineReducers} from 'redux';
import storage from 'redux-persist/lib/storage';
import {persistReducer} from 'redux-persist';

import * as reducers from './reducers';
import {user} from './reducers';

const userConfig = {
  key: 'user',
  storage,
  blacklist: ['error', 'errorDescription'],
};

export const reducer = combineReducers({
  ...reducers,
  user: persistReducer(userConfig, user),
});

export type ReducerType = ReturnType<typeof reducer>;
