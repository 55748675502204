import {take, call, put} from 'redux-saga/effects';
import type {Effect} from 'redux-saga/effects';

import {updateWisers as updateWiserUsersService} from '@services/users';
import * as WisersActions from '@actions/users';

export function* updateWisers(): Generator<Effect, void, any> {
  while (true) {
    try {
      const {updateWisers, locale} = yield take(
        WisersActions.UPDATE_WISERS_ORDER.REQUEST,
      );
      const {data: response} = yield call(
        updateWiserUsersService,
        updateWisers,
        locale,
      );

      yield put(
        WisersActions.getPublisherUsersByLocale.request(
          10000,
          0,
          'wiser',
          locale,
        ),
      );

      yield put(WisersActions.updateWiserOrder.success(response));
    } catch (error) {
      // Show info
      yield put(WisersActions.updateWiserOrder.failure(error));
    }
  }
}
