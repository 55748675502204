import {constants} from '@resources';
import {Action} from '@utils';

export const EXAMPLE = Action.createRequestTypes('EXAMPLE');
export const example = {
  request: (data: unknown) => Action.action(EXAMPLE[constants.REQUEST], {data}),
  success: (response: unknown) =>
    Action.action(EXAMPLE[constants.SUCCESS], {response}),
  failure: (error: Error) => Action.action(EXAMPLE[constants.FAILURE], {error}),
};
