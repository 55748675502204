import {Axios} from '@utils';
import {constants} from '@resources';

export function getLastRegisteredUsers(startDate: string, endDate: string) {
  let startDatee = '';
  if (startDate == 'Invalid date') {
    startDatee = '';
  } else {
    startDatee = startDate;
  }

  let endDatee = '';
  if (endDate == 'Invalid date') {
    endDatee = '';
  } else {
    endDatee = endDate;
  }

  if (endDatee === '' && startDatee !== '') {
    return Axios.get(
      `${constants.STATS_BASE}/userLastRegistered?startDate=${startDatee}`,
    );
  } else if (endDatee !== '' && startDatee === '') {
    return Axios.get(
      `${constants.STATS_BASE}/userLastRegistered?endDate=${endDatee}`,
    );
  } else if (endDatee === '' && startDatee === '') {
    return Axios.get(`${constants.STATS_BASE}/userLastRegistered`);
  } else {
    return Axios.get(
      `${constants.STATS_BASE}/userLastRegistered?startDate=${startDatee}&endDate=${endDatee}`,
    );
  }
}
