import {Axios} from '@utils';
import {constants} from '@resources';

export async function updateDiscoveryOrder(
  updatedList: Array<any>,
  locale = 'gb',
) {
  return Axios.put(`${constants.LOCALE_BASE}/${locale}/curation/orders`, {
    curationListIds: updatedList,
  });
}
