import {take, call, put} from 'redux-saga/effects';
import type {Effect} from 'redux-saga/effects';

import {getCurationStats as getCurationStatsService} from '@services/curationList';
import * as CurationActions from '@actions/curation';

export function* getCurationStats(): Generator<Effect, void, any> {
  while (true) {
    try {
      const {page, startDate, endDate} = yield take(
        CurationActions.GET_CURATION_STATS.REQUEST,
      );
      const {data: response} = yield call(
        getCurationStatsService,
        page,
        startDate,
        endDate,
      );

      yield put(
        CurationActions.getCurationStats.success(
          response.data.stats,
          page,
          response.count,
        ),
      );
    } catch (error) {
      // Show info
      yield put(CurationActions.getCurationStats.failure(error));
    }
  }
}
