// import {PURGE} from 'redux-persist';

import * as ActionTypes from '@actions/management';

import type {ManagementReducer} from './types';

const initialState: ManagementReducer = {
  isLoading: false,
  error: false,
  errorDescription: '',
  showSideBar: true,
  locales: [{locale: 'gb'}],
  selectedLocale: {locale: 'gb'},
  languages: [],
  weeklyUpdatedTime: [],
};

export function management(
  state = initialState,
  action: any = {},
): ManagementReducer {
  switch (action.type) {
    // case PURGE: {
    //   return initialState;
    // }
    case ActionTypes.SET_SIDEBAR_SHOW.REQUEST: {
      return {
        ...state,
        isLoading: true,
        error: false,
        errorDescription: '',
        showSideBar: action.show,
      };
    }

    case ActionTypes.GET_ALL_LOCALES.REQUEST: {
      return {
        ...state,
        isLoading: true,
        error: false,
        errorDescription: '',
      };
    }
    case ActionTypes.GET_ALL_LOCALES.SUCCESS: {
      return {
        ...state,
        isLoading: false,
        error: false,
        errorDescription: '',
        locales: action.locales,
      };
    }
    case ActionTypes.GET_ALL_LOCALES.FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: true,
        errorDescription: action.error.data?.message,
      };
    }
    case ActionTypes.SET_SELECTED_LOCALE.REQUEST: {
      return {
        ...state,
        selectedLocale: action.selectedLocale,
      };
    }

    case ActionTypes.GET_WEEKLY_UPDATED_TIME.REQUEST: {
      return {
        ...state,
        isLoading: true,
        error: false,
        errorDescription: '',
      };
    }
    case ActionTypes.GET_WEEKLY_UPDATED_TIME.SUCCESS: {
      return {
        ...state,
        isLoading: false,
        error: false,
        errorDescription: '',
        weeklyUpdatedTime: action.updatedTime,
      };
    }
    case ActionTypes.GET_WEEKLY_UPDATED_TIME.FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: true,
        errorDescription: action.error.data?.message,
      };
    }

    case ActionTypes.GET_ALL_LANGUAGES.REQUEST: {
      return {
        ...state,
        isLoading: true,
        error: false,
        errorDescription: '',
      };
    }
    case ActionTypes.GET_ALL_LANGUAGES.SUCCESS: {
      return {
        ...state,
        isLoading: false,
        error: false,
        errorDescription: '',
        languages: action.languages,
      };
    }
    case ActionTypes.GET_ALL_LANGUAGES.FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: true,
        errorDescription: action.error.data?.message,
      };
    }
    default:
      return state;
  }
}
