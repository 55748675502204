export const migrations = {
  1: (state: any) => {
    // adding new users object to state...
    return {
      ...state,
      users: {
        ...state.users,
        users: {data: [], page: 0},
      },
    };
  },
  2: (state: any) => {
    // adding new users object to state...
    return {
      ...state,
      categories: {
        ...state.categories,
        categoryDetail: {
          ...state.categories.categoryDetail,
          name_translate: {gb: '', tr: ''},
        },
      },
    };
  },
} as const;
