// import {PURGE} from 'redux-persist';

import * as ActionTypes from '@actions/curation';

import type {CurationReducer, CurationList} from './types';

const initialCurationList: CurationList = {
  id: 0,
  name: '',
  description: '',
  photo: '',
  status: false,
  userCuration: false,
  showOnCategory: false,
  featuredCuration: false,
  curatorId: 0,
  clickCount: '',
  createdAt: '',
  updatedAt: '',
  post_count: 0,
  isPublic: false,
  curator: {
    id: 0,
    fullName: '',
    firstName: '',
    lastName: '',
    userName: '',
    photo: '',
    role: '',
    jobTitle: '',
  },
  langCode: '',
  langPercentage: {},
  order: 0,
  showOnDiscovery: false,
  updatedTime: '',
};

const initialWiserEditorialPicks: CurationList = {
  id: 0,
  curatorId: 0,
  clickCount: '',
  post_count: 0,
  order: 0,
  name: '',
  description: '',
  photo: '',
  createdAt: '',
  updatedAt: '',
  langCode: '',
  status: false,
  userCuration: false,
  showOnCategory: false,
  featuredCuration: false,
  showOnDiscovery: false,
  isPublic: false,
  curator: {
    id: 0,
    fullName: '',
    firstName: '',
    lastName: '',
    userName: '',
    photo: '',
    role: '',
    jobTitle: '',
  },
  langPercentage: {},
  updatedTime: '',
};

const initialState: CurationReducer = {
  isLoading: false,
  error: false,
  errorDescription: '',
  curationList: {
    data: [initialCurationList],
    page: 0,
    count: 0,
  },
  weeklyUpdatedCurations: {
    data: [initialCurationList],
    page: 0,
    count: 0,
  },
  featuredCurations: {
    data: [initialWiserEditorialPicks],
    count: 0,
  },
  addedCuration: [],
  curationDetail: {
    id: 0,
    name: '',
    description: '',
    photo: '',
    followed: false,
    dynamicLink: '',
    landingPageLink: '',
    followerCount: 0,
    notification: '',
    categoryIds: [],
    curator: {
      id: 0,
      fullName: '',
      firstName: '',
      lastName: '',
      userName: '',
      photo: '',
      role: '',
      jobTitle: '',
    },
    posts: {
      Article: [],
      Video: [],
      Podcast: [],
    },
    categories: [],
    tags: [],
    showOnDiscoveryByLocal: [],
    showOnCategory: false,
    status: false,
    featuredCuration: false,
    langPercentage: {},
    updatedTime: '',
    isUpdatedTime: false,
  },
  curationPosts: {
    posts: [],
    page: 0,
    count: 0,
  },
  curationUsers: {
    users: [],
    page: 0,
    count: 0,
  },
  searchResults: {
    users: [],
    page: 0,
    count: 0,
  },
  searchDiscoveryCurations: {
    data: [],
    page: 0,
    count: 0,
  },
  curationStats: {
    data: [],
    page: 0,
    count: 0,
  },
  randomCover: '',
};

export function curation(
  state = initialState,
  action: any = {},
): CurationReducer {
  switch (action.type) {
    case ActionTypes.GET_CURATIONS.REQUEST: {
      return {
        ...state,
        isLoading: true,
        error: false,
        errorDescription: '',
      };
    }
    case ActionTypes.GET_CURATIONS.SUCCESS: {
      return {
        ...state,
        isLoading: false,
        error: false,
        errorDescription: '',
        curationList: {
          data:
            action.page === 1
              ? action.curations
              : [...state.curationList.data, ...action.curations],
          page: action.page,
          count: action.curationsCount,
        },
      };
    }
    case ActionTypes.GET_CURATIONS.FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: true,
        errorDescription: action.error.data?.message,
      };
    }

    case ActionTypes.GET_WEEKLY_UPDATED_CURATIONS.REQUEST: {
      return {
        ...state,
        isLoading: true,
        error: false,
        errorDescription: '',
      };
    }
    case ActionTypes.GET_WEEKLY_UPDATED_CURATIONS.SUCCESS: {
      return {
        ...state,
        isLoading: false,
        error: false,
        errorDescription: '',
        weeklyUpdatedCurations: {
          data: action.curations,
          page: action.page,
          count: action.curationsCount,
        },
      };
    }
    case ActionTypes.GET_WEEKLY_UPDATED_CURATIONS.FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: true,
        errorDescription: action.error.data?.message,
      };
    }

    case ActionTypes.GET_CURATION_DETAIL.REQUEST: {
      return {
        ...state,
        isLoading: true,
        error: false,
        errorDescription: '',
      };
    }
    case ActionTypes.GET_CURATION_DETAIL.SUCCESS: {
      return {
        ...state,
        isLoading: false,
        error: false,
        errorDescription: '',
        curationDetail: action.curationDetail,
      };
    }
    case ActionTypes.GET_CURATION_DETAIL.FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: true,
        errorDescription: action.error.data?.message,
      };
    }

    case ActionTypes.GET_CURATION_POSTS.REQUEST: {
      return {
        ...state,
        isLoading: true,
        error: false,
        errorDescription: '',
      };
    }

    case ActionTypes.GET_CURATION_POSTS.SUCCESS: {
      return {
        ...state,
        isLoading: false,
        error: false,
        errorDescription: '',
        curationPosts: {
          posts:
            action.page === 0
              ? action.curationPosts
              : [...state.curationPosts.posts, ...action.curationPosts],
          page: action.page,
          count: action.count,
        },
      };
    }
    case ActionTypes.GET_CURATION_POSTS.FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: true,
        errorDescription: action.error.data?.message,
      };
    }

    case ActionTypes.DELETE_CURATION_POSTS.REQUEST: {
      return {
        ...state,
        isLoading: true,
        error: false,
        errorDescription: '',
      };
    }
    case ActionTypes.DELETE_CURATION_POSTS.SUCCESS: {
      const filterdCodes = state.curationPosts.posts.filter(
        (item) => item.id !== action.deletePostId,
      );
      return {
        ...state,
        isLoading: false,
        error: false,
        errorDescription: '',
        curationPosts: {
          posts: filterdCodes,
          page: state.curationPosts.page,
          count: state.curationPosts.count - 1,
        },
      };
    }
    case ActionTypes.DELETE_CURATION_POSTS.FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: true,
        errorDescription: action.error.data?.message,
      };
    }

    case ActionTypes.DELETE_CURATION.REQUEST: {
      return {
        ...state,
        isLoading: true,
        error: false,
        errorDescription: '',
      };
    }
    case ActionTypes.DELETE_CURATION.SUCCESS: {
      // const filterdCodes = state.curationList.filter(
      //   (item) => item.id !== action.deletePostId,
      // );
      return {
        ...state,
        isLoading: false,
        error: false,
        errorDescription: '',
        // curationList: filterdCodes,
      };
    }
    case ActionTypes.DELETE_CURATION.FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: true,
        errorDescription: action.error.data?.message,
      };
    }

    case ActionTypes.UPDATE_CURATION.REQUEST: {
      return {
        ...state,
        isLoading: true,
        error: false,
        errorDescription: '',
      };
    }
    case ActionTypes.UPDATE_CURATION.SUCCESS: {
      return {
        ...state,
        isLoading: false,
        error: false,
        errorDescription: '',
        curationDetail: {
          ...state.curationDetail,
          name: action.updatedCuration.name,
          description: action.updatedCuration.description,
          photo: action.updatedCuration.photo,
          showOnDiscoveryByLocal: action.updatedCuration.showOnDiscoveryByLocal,
          showOnCategory: action.updatedCuration.showOnCategory,
          status: action.updatedCuration.status,
          featuredCuration: action.updatedCuration.featuredCuration,
          categories: action.updatedCuration.categories,
          categoryIds: action.updatedCuration.categoryIds,
          isUpdatedTime: action.updatedCuration.isUpdatedTime,
          updatedTime: action.updatedCuration.updatedTime,
        },
      };
    }
    case ActionTypes.UPDATE_CURATION.FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: true,
        errorDescription: action.error.data?.message,
      };
    }

    case ActionTypes.ADD_POSTS_TO_CURATION.REQUEST: {
      return {
        ...state,
        isLoading: true,
        error: false,
        errorDescription: '',
      };
    }
    case ActionTypes.ADD_POSTS_TO_CURATION.SUCCESS: {
      return {
        ...state,
        isLoading: false,
        error: false,
        errorDescription: '',
        curationPosts: {
          posts: [...state.curationPosts.posts, ...action.addedPosts],
          page: state.curationPosts.page,
          count: state.curationPosts.count + 1,
        },
      };
    }
    case ActionTypes.ADD_POSTS_TO_CURATION.FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: true,
        errorDescription: action.error.data?.message,
      };
    }

    case ActionTypes.GET_CURATION_USERS.REQUEST: {
      return {
        ...state,
        isLoading: true,
        error: false,
        errorDescription: '',
      };
    }
    case ActionTypes.GET_CURATION_USERS.SUCCESS: {
      return {
        ...state,
        isLoading: false,
        error: false,
        errorDescription: '',
        curationUsers: {
          users:
            action.page === 0
              ? action.curationUsers
              : [...state.curationUsers.users, ...action.curationUsers],
          page: action.page,
          count: action.curationUsersCount,
        },
      };
    }
    case ActionTypes.GET_CURATION_USERS.FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: true,
        errorDescription: action.error.data?.message,
      };
    }

    case ActionTypes.SEARCH_CURATION_USERS.REQUEST: {
      return {
        ...state,
        isLoading: true,
        error: false,
        errorDescription: '',
      };
    }
    case ActionTypes.SEARCH_CURATION_USERS.SUCCESS: {
      return {
        ...state,
        isLoading: false,
        error: false,
        errorDescription: '',
        searchResults: {
          users:
            action.page === 0
              ? action.curationUsers
              : [...state.searchResults.users, ...action.curationUsers],
          page: action.page,
          count: action.searchCount,
        },
      };
    }
    case ActionTypes.SEARCH_CURATION_USERS.FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: true,
        errorDescription: action.error.data?.message,
      };
    }

    case ActionTypes.CREATE_CURATION.REQUEST: {
      return {
        ...state,
        isLoading: true,
        error: false,
        errorDescription: '',
      };
    }
    case ActionTypes.CREATE_CURATION.SUCCESS: {
      return {
        ...state,
        isLoading: false,
        error: false,
        errorDescription: '',
        // curationDetail: action.curationDetail,
      };
    }
    case ActionTypes.CREATE_CURATION.FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: true,
        errorDescription: action.error.data?.message,
      };
    }

    case ActionTypes.CHECK_CURATION_CODE.REQUEST: {
      return {
        ...state,
        isLoading: true,
        error: false,
        errorDescription: '',
      };
    }
    case ActionTypes.CHECK_CURATION_CODE.SUCCESS: {
      return {
        ...state,
        isLoading: false,
        error: false,
        errorDescription: '',
      };
    }
    case ActionTypes.CHECK_CURATION_CODE.FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: true,
        errorDescription: action.error.data?.message,
      };
    }

    case ActionTypes.UPDATE_CURATION_LIST_STATUS.REQUEST: {
      return {
        ...state,
        isLoading: true,
        error: false,
        errorDescription: '',
      };
    }
    case ActionTypes.UPDATE_CURATION_LIST_STATUS.SUCCESS: {
      return {
        ...state,
        isLoading: false,
        error: false,
        errorDescription: '',
        curationList: {
          ...state.curationList,
          data: state.curationList.data.map((item) => {
            if (item.id === action.curation.id) {
              return action.curation;
            }
            return item;
          }),
        },
      };
    }
    case ActionTypes.UPDATE_CURATION_LIST_STATUS.FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: true,
        errorDescription: action.error.data?.message,
      };
    }
    case ActionTypes.UPDATE_DISCOVERY_ORDER.REQUEST: {
      return {
        ...state,
        isLoading: true,
        error: false,
        errorDescription: '',
      };
    }
    case ActionTypes.UPDATE_DISCOVERY_ORDER.SUCCESS: {
      return {
        ...state,
        isLoading: false,
        error: false,
        errorDescription: '',
      };
    }
    case ActionTypes.UPDATE_DISCOVERY_ORDER.FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: true,
        errorDescription: action.error.data?.message,
      };
    }
    case ActionTypes.UPDATE_WISER_EDITORIAL_ORDER.REQUEST: {
      return {
        ...state,
        isLoading: true,
        error: false,
        errorDescription: '',
      };
    }
    case ActionTypes.UPDATE_WISER_EDITORIAL_ORDER.SUCCESS: {
      return {
        ...state,
        isLoading: false,
        error: false,
        errorDescription: '',
      };
    }
    case ActionTypes.UPDATE_WISER_EDITORIAL_ORDER.FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: true,
        errorDescription: action.error.data?.message,
      };
    }

    case ActionTypes.SEARCH_DISCOVERY_CURATIONS.REQUEST: {
      return {
        ...state,
        isLoading: true,
        error: false,
        errorDescription: '',
      };
    }

    case ActionTypes.SEARCH_DISCOVERY_CURATIONS.SUCCESS: {
      return {
        ...state,
        isLoading: false,
        error: false,
        errorDescription: '',
        searchDiscoveryCurations: {
          data: action.data,
          page: action.page,
          count: action.codeCount,
        },
      };
    }
    case ActionTypes.SEARCH_DISCOVERY_CURATIONS.FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: true,
        errorDescription: action.error.data?.message,
      };
    }

    case ActionTypes.GET_CURATION_STATS.REQUEST: {
      return {
        ...state,
        isLoading: true,
        error: false,
        errorDescription: '',
      };
    }
    case ActionTypes.GET_CURATION_STATS.SUCCESS: {
      return {
        ...state,
        isLoading: false,
        error: false,
        errorDescription: '',
        curationStats: {
          data:
            action.page === 0
              ? action.data
              : [...state.curationStats.data, ...action.data],
          page: action.page,
          count: action.statCount,
        },
      };
    }
    case ActionTypes.GET_CURATION_STATS.FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: true,
        errorDescription: action.error.data?.message,
      };
    }

    case ActionTypes.GET_WISEREDITORIAL_PICKS.REQUEST: {
      return {
        ...state,
        isLoading: true,
        error: false,
        errorDescription: '',
      };
    }
    case ActionTypes.GET_WISEREDITORIAL_PICKS.SUCCESS: {
      return {
        ...state,
        isLoading: false,
        error: false,
        errorDescription: '',
        featuredCurations: {
          data: action.curations,
          count: action.curations.length,
        },
      };
    }
    case ActionTypes.GET_WISEREDITORIAL_PICKS.FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: true,
        errorDescription: action.error.data?.message,
      };
    }

    case ActionTypes.GET_RANDOM_COVER.REQUEST: {
      return {
        ...state,
        isLoading: true,
        error: false,
        errorDescription: '',
      };
    }
    case ActionTypes.GET_RANDOM_COVER.SUCCESS: {
      return {
        ...state,
        isLoading: false,
        error: false,
        errorDescription: '',
        randomCover: action.data,
      };
    }
    case ActionTypes.GET_RANDOM_COVER.FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: true,
        errorDescription: action.error.data?.message,
      };
    }

    case ActionTypes.GET_DYNAMIC_LINK.REQUEST: {
      return {
        ...state,
        isLoading: true,
        error: false,
        errorDescription: '',
      };
    }
    case ActionTypes.GET_DYNAMIC_LINK.SUCCESS: {
      return {
        ...state,
        isLoading: false,
        error: false,
        errorDescription: '',
        curationDetail: {
          ...state.curationDetail,
          dynamicLink: action.data.dynamicLink,
          landingPageLink: action.data.landingPageLink,
        },
      };
    }
    case ActionTypes.GET_DYNAMIC_LINK.FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: true,
        errorDescription: action.error.data?.message,
      };
    }

    case ActionTypes.CHANGE_STATUS_OF_CURATIONS.REQUEST: {
      return {
        ...state,
        isLoading: true,
        error: false,
        errorDescription: '',
      };
    }
    case ActionTypes.CHANGE_STATUS_OF_CURATIONS.SUCCESS: {
      return {
        ...state,
        isLoading: false,
        error: false,
        errorDescription: '',
      };
    }
    case ActionTypes.CHANGE_STATUS_OF_CURATIONS.FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: true,
      };
    }

    default:
      return state;
  }
}
