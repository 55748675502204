import React, {useEffect, useState} from 'react';

import {
  CCard,
  CCardBody,
  CCol,
  CDataTable,
  CRow,
  CPagination,
} from '@coreui/react';

type Props = {
  items: Array<any>;
  itemsCount: number;
  fields: Array<any>;
  pageLimit: number;
  page: number;
  pageChange: Function;
  scopedSlots: any;
  onClick?: Function;
};

export const WSRDataTable: React.FC<Props> = (props) => {
  const {
    items,
    fields,
    pageLimit,
    page,
    pageChange,
    scopedSlots,
    itemsCount,
    onClick,
  } = props;

  const [pagesCount, setPagesCount] = useState<number>(0);

  const calculatePages = (limit: number) => {
    let pageCounter = 0;
    if (limit === 50) {
      pageCounter = Math.floor(itemsCount / 50 + 1);
    } else if (limit === 20) {
      pageCounter = Math.floor(itemsCount / 20 + 1);
    } else if (itemsCount < itemsCount) {
      pageCounter = itemsCount / 10 + 1;
    } else if (itemsCount === itemsCount) {
      pageCounter = Math.ceil(itemsCount / 10);
    }

    setPagesCount(pageCounter);
  };

  useEffect(() => {
    calculatePages(itemsCount);
  }, [pageLimit, itemsCount]);

  return (
    <>
      <CRow>
        <CCol xl={12}>
          <CCard>
            <CCardBody>
              <CDataTable
                items={items}
                fields={fields}
                hover
                border
                size="sm"
                striped
                outlined
                itemsPerPage={50}
                activePage={1}
                clickableRows
                scopedSlots={scopedSlots}
                onRowClick={onClick}
              />
              {pagesCount > 0 && (
                <CPagination
                  dots={false}
                  activePage={page}
                  onActivePageChange={(newPage: number) => pageChange(newPage)}
                  pages={pagesCount}
                  doubleArrows={false}
                  align="center"
                />
              )}
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
    </>
  );
};
