import {take, call, put} from 'redux-saga/effects';
import type {Effect} from 'redux-saga/effects';

import {checkUserName as checkUserNameService} from '@services/user';
import * as UserActions from '@actions/user';

export function* checkUserName(): Generator<Effect, void, any> {
  while (true) {
    try {
      const {user} = yield take(UserActions.CHECK_USERNAME.REQUEST);
      // const {data: response} = yield call(checkUserNameService, user);
      yield call(checkUserNameService, user);
      yield put(UserActions.checkUserName.success({}));
    } catch (error) {
      // Show info
      yield put(UserActions.checkUserName.failure(error));
    }
  }
}
