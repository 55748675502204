import {Axios} from '@utils';
import {constants} from '@resources';

export async function updateCuration(updateCuration: any) {
  if (updateCuration.description) {
    const description = updateCuration.description;
    const newCuration = {
      ...updateCuration,
      description: description.slice(0, -1),
    };
    return Axios.put(
      `${constants.CURATION_BASE}/${updateCuration.id}`,
      newCuration,
    );
  } else {
    return Axios.put(
      `${constants.CURATION_BASE}/${updateCuration.id}`,
      updateCuration,
    );
  }
}
