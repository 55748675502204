import {Axios} from '@utils';
import {constants} from '@resources';

export function postMoveContent(oldCategoryId: number, newCategoryId: number) {
  const parameters = {
    oldCategoryId: oldCategoryId,
    newCategoryId: newCategoryId,
  };

  return Axios.post(`${constants.CATEGORY_BASE}/moveContent`, parameters);
}
