import {take, call, put} from 'redux-saga/effects';
import type {Effect} from 'redux-saga/effects';

import {getChooseMoodCurations as getChooseMoodCurationsService} from '@services/chooseMood';
import * as CurationActions from '@actions/chooseMood';

export function* getChooseMoodCurations(): Generator<Effect, void, any> {
  while (true) {
    try {
      const {limit, offset, isUserCuration, langSelection} = yield take(
        CurationActions.GET_MOOD_CURATIONS.REQUEST,
      );

      const {data: response} = yield call(
        getChooseMoodCurationsService,
        limit,
        offset,
        isUserCuration,
        langSelection,
      );

      yield put(CurationActions.getMoodCurations.success(response));
    } catch (error) {
      // Show info
      yield put(CurationActions.getMoodCurations.failure(error));
    }
  }
}
