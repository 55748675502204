import {take, call, put} from 'redux-saga/effects';
import type {Effect} from 'redux-saga/effects';

import {getOnboardingUsers as getOnboardingUsersService} from '@services/onboarding';
import * as OnboardingActions from '@actions/onboarding';

export function* getOnboardingUsers(): Generator<Effect, void, any> {
  while (true) {
    try {
      const {categoryIds, selectedLocale} = yield take(
        OnboardingActions.GET_ONBOARDNG_USERS.REQUEST,
      );
      const {data: response} = yield call(
        getOnboardingUsersService,
        categoryIds,
        selectedLocale,
      );

      yield put(OnboardingActions.getOnboardingUsers.success(response.data));
    } catch (error) {
      // Show info
      yield put(OnboardingActions.getOnboardingUsers.failure(error));
    }
  }
}
