import {constants} from '@resources';
import {Action} from '@utils';

export const SET_USERID = Action.createRequestTypes('SET_USERID');
export const setUserId = {
  request: (userId: number) =>
    Action.action(SET_USERID[constants.REQUEST], {userId}),
};

export const GET_USER = Action.createRequestTypes('GET_USER');
export const getUser = {
  request: (userId: number) =>
    Action.action(GET_USER[constants.REQUEST], {userId}),
  success: (profile: any) =>
    Action.action(GET_USER[constants.SUCCESS], {profile}),
  failure: (error: Error) =>
    Action.action(GET_USER[constants.FAILURE], {error}),
};

export const CHECK_USERNAME = Action.createRequestTypes('CHECK_USERNAME');
export const checkUserName = {
  request: (user: any) =>
    Action.action(CHECK_USERNAME[constants.REQUEST], {user}),
  success: (profile: any) =>
    Action.action(CHECK_USERNAME[constants.SUCCESS], {profile}),
  failure: (error: Error) =>
    Action.action(CHECK_USERNAME[constants.FAILURE], {error}),
};

export const CREATE_EDITORIAL_USER = Action.createRequestTypes(
  'CREATE_EDITORIAL_USER',
);
export const createEditorialUser = {
  request: (user: any, onSuccess: Function) =>
    Action.action(CREATE_EDITORIAL_USER[constants.REQUEST], {user, onSuccess}),
  success: (profile: any) =>
    Action.action(CREATE_EDITORIAL_USER[constants.SUCCESS], {profile}),
  failure: (error: Error) =>
    Action.action(CREATE_EDITORIAL_USER[constants.FAILURE], {error}),
};

export const CLEAN_ERROR_STATE = Action.createRequestTypes('CLEAN_ERROR_STATE');
export const cleanErrorMessageOfUserState = {
  request: () => Action.action(CLEAN_ERROR_STATE[constants.REQUEST], {}),
};
