import React, {useState, useEffect} from 'react';
import {Link} from 'react-router-dom';
import {
  CButton,
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CForm,
  CInput,
  CInputGroup,
  CInputGroupPrepend,
  CInputGroupText,
  CRow,
  CInvalidFeedback,
} from '@coreui/react';
import CIcon from '@coreui/icons-react';
import {useSelector, shallowEqual, useDispatch} from 'react-redux';
import {WSRModal} from '../../components';

import {FormValidation} from '@utils';
import {login as loginAction} from '@actions/auth';

import type {ChangeEvent} from 'react';

type Props = {
  history: any;
};

export const LoginPage: React.FC<Props> = (props) => {
  const {history} = props;
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [errorModal, setErrorModal] = useState(false);

  const dispatch = useDispatch();

  const {isLoading, accessToken, error, errorMessage} = useSelector(
    (state) => ({
      isLoading: state.auth.isLoading,
      accessToken: state.auth.accessToken,
      error: state.auth.error,
      errorMessage: state.auth.errorDescription,
    }),
    shallowEqual,
  );

  const onSubmit = (e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    if (FormValidation.isEmpty(email)) {
      // TODO: show proper error message...
    } else if (!FormValidation.validateEmail(email)) {
      // TODO: show proper error message...
    } else if (FormValidation.isEmpty(password)) {
      // TODO: show proper error message...
    } else {
      dispatch(loginAction.request(email, password));
    }
  };

  useEffect(() => {
    setErrorModal(error);
  }, [error]);

  useEffect(() => {
    if (accessToken) {
      history.push('/');
    }
  }, [accessToken]);

  return (
    <div className="c-app c-default-layout flex-row align-items-center">
      <CContainer>
        <CRow className="justify-content-center">
          <CCol md="8">
            <CCardGroup>
              <CCard className="p-4">
                <CCardBody>
                  <CForm onSubmit={onSubmit}>
                    <h1>Login</h1>
                    <p className="text-muted">Sign In to your account</p>
                    <CInputGroup className="mb-3">
                      <CInputGroupPrepend>
                        <CInputGroupText>
                          <CIcon name="cil-user" />
                        </CInputGroupText>
                      </CInputGroupPrepend>
                      <CInput
                        className="form-control-warning"
                        type="text"
                        value={email}
                        name="email"
                        placeholder="Email"
                        autoComplete="email"
                        onChange={(e: ChangeEvent<HTMLInputElement>) =>
                          setEmail(e.target.value)
                        }
                        required
                      />
                      <CInvalidFeedback className="help-block">
                        {/* {errors.email} */}
                      </CInvalidFeedback>
                    </CInputGroup>
                    <CInputGroup className="mb-4">
                      <CInputGroupPrepend>
                        <CInputGroupText>
                          <CIcon name="cil-lock-locked" />
                        </CInputGroupText>
                      </CInputGroupPrepend>
                      <CInput
                        type="password"
                        name="password"
                        value={password}
                        placeholder="Password"
                        autoComplete="current-password"
                        onChange={(e: ChangeEvent<HTMLInputElement>) =>
                          setPassword(e.target.value)
                        }
                      />
                    </CInputGroup>
                    <CRow>
                      <CCol xs="6">
                        <CButton
                          type="submit"
                          color="primary"
                          className="px-4"
                          disabled={isLoading}>
                          Login
                        </CButton>
                      </CCol>
                      <CCol xs="6" className="text-right">
                        <CButton color="link" className="px-0">
                          Forgot password?
                        </CButton>
                      </CCol>
                    </CRow>
                  </CForm>
                </CCardBody>
              </CCard>
              <CCard
                className="text-white bg-primary py-5 d-md-down-none"
                style={{width: '44%'}}>
                <CCardBody className="text-center">
                  <div>
                    <h2>Sign up</h2>
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipisicing elit,
                      sed do eiusmod tempor incididunt ut labore et dolore magna
                      aliqua.
                    </p>
                    <Link to="/register">
                      <CButton
                        color="primary"
                        className="mt-3"
                        active
                        tabIndex={-1}>
                        Register Now!
                      </CButton>
                    </Link>
                  </div>
                </CCardBody>
              </CCard>
            </CCardGroup>
          </CCol>
        </CRow>
      </CContainer>

      <WSRModal
        onShowModal={errorModal}
        setOnShowModal={setErrorModal}
        modalTitle={'Warning'}
        modalBody={errorMessage}
        operationButton={() => setErrorModal(false)}
        operationButtonText={'OK!'}
      />
    </div>
  );
};
