import {Axios} from '@utils';
import {constants} from '@resources';

export async function getWiserEditorialPicks(langSelection: any) {
  // if (langSelection.length === 1) {
  //   return await Axios.get(
  //     `${constants.CURATION_BASE}/discoveryAutoListedManagement?langSelection=["${langSelection[0]}"]`,
  //   );
  // } else if (langSelection.length === 2) {
  //   return await Axios.get(
  //     `${constants.CURATION_BASE}/discoveryAutoListedManagement?langSelection=["${langSelection[0]}", "${langSelection[1]}"]`,
  //   );
  // }
  if (langSelection === '["all"]')
    return await Axios.get(
      `${constants.CURATION_BASE}/discoveryFeaturedManagement?langSelection=["en","tr"]`,
    );

  return await Axios.get(
    `${constants.CURATION_BASE}/discoveryFeaturedManagement?langSelection=${langSelection}`,
  );
}
