import {Axios} from '@utils';
import {constants} from '@resources';

export function addUserToOnboardingCategory(
  categoryId: number,
  userId: number,
) {
  const parameters = {
    userId: userId,
  };
  return Axios.post(
    `${constants.ONBOARDING_BASE}/categories/${categoryId}/users`,
    parameters,
  );
}
