import {take, call, put} from 'redux-saga/effects';
import type {Effect} from 'redux-saga/effects';

import {getOnboardingCategories as getOnboardingCategoriesService} from '@services/onboarding';
import * as OnboardingActions from '@actions/onboarding';

export function* getOnboardingCategories(): Generator<Effect, void, any> {
  while (true) {
    try {
      const {langCode} = yield take(
        OnboardingActions.GET_ONBOARDNG_CATEGORIES.REQUEST,
      );
      yield take(OnboardingActions.GET_ONBOARDNG_CATEGORIES.REQUEST);
      const {data: response} = yield call(
        getOnboardingCategoriesService,
        langCode,
      );

      yield put(
        OnboardingActions.getOnboardingCategories.success(response.data),
      );
    } catch (error) {
      // Show info
      yield put(OnboardingActions.getOnboardingCategories.failure(error));
    }
  }
}
