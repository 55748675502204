import React, {useEffect} from 'react';
import {Router, Route, Switch} from 'react-router-dom';
import {useSelector, shallowEqual, useDispatch} from 'react-redux';
import {Redirect} from 'react-router';

import {LoginPage} from '@pages';
import {setToken} from '@utils/Axios';
import {
  getAllLocales as getAllLocalesAction,
  getAllLanguages as getAllLanguagesAction,
} from '@actions/management';

import {LoadingComponent} from '../src/views/utilComponents/LoadingComponent';
import './index.css';
import './scss/style.scss';
import History from './components/History';

// Containers
const TheLayout = React.lazy(() => import('./containers/TheLayout'));

// Pages
const Register = React.lazy(() => import('./views/pages/register/Register'));
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'));
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'));

const App = () => {
  const {accessToken} = useSelector(
    (state) => ({
      accessToken: state.auth.accessToken,
    }),
    shallowEqual,
  );

  const dispatch = useDispatch();

  useEffect(() => {
    setToken(accessToken);
    if (accessToken) {
      dispatch(getAllLocalesAction.request());
      dispatch(getAllLanguagesAction.request());
    }
  }, [accessToken]);

  return (
    <Router history={History}>
      <React.Suspense fallback={LoadingComponent}>
        <Switch>
          <Route
            exact
            path="/register"
            name="Register Page"
            render={(props) => <Register {...props} />}
          />
          <Route
            exact
            path="/login"
            name="Login Page"
            render={(props) => <LoginPage {...props} />}
          />
          <Route
            exact
            path="/404"
            name="Page 404"
            render={(props) => <Page404 {...props} />}
          />
          <Route
            exact
            path="/500"
            name="Page 500"
            render={(props) => <Page500 {...props} />}
          />

          <Route
            path="/"
            render={(props) =>
              accessToken ? (
                <TheLayout {...props} />
              ) : (
                <Redirect
                  to={{
                    pathname: '/login',
                  }}
                />
              )
            }
          />
        </Switch>
      </React.Suspense>
    </Router>
  );
};

export default App;
