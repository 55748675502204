import {take, call, put} from 'redux-saga/effects';
import type {Effect} from 'redux-saga/effects';

import {usersPremiumChange as usersPremiumChangeService} from '@services/users';
import * as UsersActions from '@actions/users';

export function* usersPremiumChange(): Generator<Effect, void, any> {
  while (true) {
    try {
      const {users, onSuccess} = yield take(
        UsersActions.USERS_PREMIUM_CHANGE.REQUEST,
      );

      const {data: response} = yield call(usersPremiumChangeService, users);
      onSuccess();
      yield put(UsersActions.usersPremiumChange.success([]));
    } catch (error) {
      // Show info
      yield put(UsersActions.usersPremiumChange.failure(error));
    }
  }
}
