import React from 'react';
import {
  CModal,
  CModalHeader,
  CModalTitle,
  CModalBody,
  CButton,
  CModalFooter,
} from '@coreui/react';

type Props = {
  onShowModal: boolean;
  setOnShowModal: Function;
  modalTitle: string;
  modalBody: string;
  operationButton: Function;
  operationButtonText: string;
  cancelButton?: Function;
  cancelButtonText?: string;
};

export const WSRModal: React.FC<Props> = (props) => {
  const {
    onShowModal,
    setOnShowModal,
    modalTitle,
    modalBody,
    operationButton,
    operationButtonText,
    cancelButton,
    cancelButtonText,
  } = props;

  return (
    <CModal show={onShowModal} onClose={setOnShowModal}>
      <CModalHeader>
        <CModalTitle>{modalTitle}</CModalTitle>
      </CModalHeader>
      <CModalBody>{modalBody}</CModalBody>
      <CModalFooter>
        <CButton color="secondary" onClick={operationButton}>
          {operationButtonText}
        </CButton>

        {cancelButtonText === undefined ? null : (
          <CButton color="secondary" onClick={cancelButton}>
            {cancelButtonText}
          </CButton>
        )}
      </CModalFooter>
    </CModal>
  );
};
