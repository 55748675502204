import {take, call, put} from 'redux-saga/effects';
import type {Effect} from 'redux-saga/effects';

import {createTag as createTagService} from '@services/tags';
import * as CurationActions from '@actions/tags';
// import * as NotificationsActions from '@actions/notifications';

export function* createTag(): Generator<Effect, void, any> {
  while (true) {
    try {
      const {name} = yield take(CurationActions.CREATE_TAG.REQUEST);
      const {data: response} = yield call(createTagService, name.toLowerCase());
      yield put(CurationActions.createTag.success(response.data));
      yield put(CurationActions.getTags.request());
      // yield put(NotificationsActions.sendSuccessMessage.request());
    } catch (error) {
      // Show info
      yield put(CurationActions.createTag.failure(error));
    }
  }
}
