import React from 'react';
import {CButton, CLabel} from '@coreui/react';
import {Regex} from '../../utils/Regex';
import styles from './styles';

type Props = any;
const SmallPostItem: React.FC<Props> = (props) => {
  const {style, postType, image, url, title, onPress} = props;

  const renderSharedName = () => {
    if (postType === 'Video') {
      return <div style={styles.youtubeText}>Youtube </div>;
    }
    if (postType === 'Podcast') {
      return <div style={styles.spotifyText}>Spotify </div>;
    }
    return (
      <div style={styles.articleText}> {Regex.getDomainFromUrl(url)} </div>
    );
  };

  const postStyle =
    postType === 'Podcast'
      ? styles.roundedImageContainer
      : styles.rectangleImageContainer;

  return (
    <CButton
      style={{...styles.container, ...style}}
      onClick={onPress}
      //activeOpacity={onPress ? 0.7 : 1}
    >
      {
        <div
          style={{
            ...postStyle,
            background: `url(${image})`,
            backgroundSize: 'contain',
          }}
          // imageStyle={
          //   postType === 'Podcast' ? styles.roundedImage : styles.rectangleImage
          // }
          //src={image}
        >
          {/* {postType !== 'Article' && (
          <div style={styles.playButton}>
      <CImg src={images.playIcon} style={styles.playIcon} />
          </div>
        )} */}
        </div>
      }

      <CLabel style={styles.sharedContainer}>
        <CLabel style={styles.sharedViaText}> shared via</CLabel>
        <CLabel style={{marginLeft: 4}}> {renderSharedName()}</CLabel>
      </CLabel>
      <div style={styles.titleText}> {title}</div>
    </CButton>
  );
};

SmallPostItem.defaultProps = {
  style: {},
  onPress: () => {},
};

export default SmallPostItem;
