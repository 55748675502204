import {take, call, put} from 'redux-saga/effects';
import type {Effect} from 'redux-saga/effects';

import {getUncategorisedPosts as getUncategorisedPostsService} from '@services/posts';
import * as PostActions from '@actions/posts';

export function* getUncategorisedPosts(): Generator<Effect, void, any> {
  while (true) {
    try {
      yield take(PostActions.GET_UNCATEGORISED_POSTS.REQUEST);
      const {data: response} = yield call(getUncategorisedPostsService);

      yield put(
        PostActions.getUncategorisedPosts.success(
          response.data,
          response.data.length,
        ),
      );
    } catch (error) {
      // Show info
      yield put(PostActions.getUncategorisedPosts.failure(error));
    }
  }
}
