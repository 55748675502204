import React, {ChangeEvent, useEffect, useState} from 'react';
import {
  CModal,
  CModalHeader,
  CModalTitle,
  CModalBody,
  CButton,
  CModalFooter,
  CRow,
  CCol,
} from '@coreui/react';
import {Crop} from 'react-image-crop';
import ReactCrop from 'react-image-crop';

type Props = {
  onShowModal: boolean;
  setModal: Function;
  setUserImage: Function;
  croppedImageUrl: string;
  setCroppedImageUrl: Function;
};

export const WSRUploadPhoto: React.FC<Props> = (props) => {
  const {
    onShowModal,
    setModal,
    setUserImage,
    croppedImageUrl,
    setCroppedImageUrl,
  } = props;

  const [imageSrc, setImageSrc] = useState<string | ArrayBuffer | null>();
  const [imageRef, setImageRef] = useState('');
  const [croppedBase64, setCroppedBase64] = useState('');

  const [crop, setCrop] = useState<Crop>({
    unit: '%',
    width: 100,
    height: 100,
  });

  const handleSelectFile = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener('load', () => {
        setImageSrc(reader.result);
      });
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  const handleImageLoaded = (image: any) => {
    setImageRef(image);
  };

  const handleCropChange = (crop: any) => {
    // You could also use percentCrop:
    // this.setState({ crop: percentCrop });
    setCrop(crop);
  };

  const handleCropComplete = (crop: any) => {
    makeClientCrop(crop);
  };

  const makeClientCrop = async (crop: any) => {
    if (imageRef && crop.width && crop.height) {
      const croppedImgUrl = getCroppedImg(imageRef, crop);
      setCroppedBase64(croppedImgUrl);
      setCroppedImageUrl(croppedImgUrl);
    }
  };

  const getCroppedImg = (image: any, crop: any) => {
    const canvas = document.createElement('canvas');
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = Math.ceil(crop.width * scaleX);
    canvas.height = Math.ceil(crop.height * scaleY);
    const ctx: any = canvas.getContext('2d');
    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width * scaleX,
      crop.height * scaleY,
    );

    canvas.toBlob(
      async (blob) => {
        await setCroppedImageUrl(blob);
      },
      'image/png',
      1,
    );

    return canvas.toDataURL('image/jpeg');
  };

  useEffect(() => {
    makeClientCrop(crop);
  }, [imageRef]);

  return (
    <CModal show={onShowModal} onClose={setModal}>
      <CModalHeader closeButton>
        <CModalTitle>Upload Photo</CModalTitle>
      </CModalHeader>
      <CModalBody>
        <CRow>
          <CCol style={{display: 'flex', justifyContent: 'center'}}>
            <input type="file" accept="image/*" onChange={handleSelectFile} />
          </CCol>
        </CRow>
        <CRow style={{display: 'flex', justifyContent: 'center'}}>
          {imageSrc && (
            <ReactCrop
              src={imageSrc.toString()}
              crop={crop}
              ruleOfThirds
              onImageLoaded={handleImageLoaded}
              onComplete={handleCropComplete}
              onChange={handleCropChange}
              style={{maxHeight: '300px', maxWidth: '100%'}}
            />
          )}
        </CRow>
        <br></br>
        <CRow style={{display: 'flex', justifyContent: 'center'}}>
          {croppedBase64 !== '' && (
            <img alt="Crop" style={{maxWidth: '100%'}} src={croppedBase64} />
          )}
        </CRow>
      </CModalBody>
      <CModalFooter>
        <CButton onClick={() => setUserImage()} color="success">
          Save
        </CButton>{' '}
        <CButton color="secondary" onClick={() => setModal(false)}>
          Cancel
        </CButton>
      </CModalFooter>
    </CModal>
  );
};
