import React, {useState} from 'react';
import {Editor} from 'react-draft-wysiwyg';
import {convertToRaw, ContentState, EditorState, Modifier} from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';

import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import './texteditor.css';

type InitialCurationDataforFecthedData = {
  name: string;
  photo?: any;
  curatorId: number;
  description: string;
  categoryIds: Array<any>;
  categories: Array<any>;
};

type InitialCurationData = {
  id: number;
  name: string;
  description: string;
  photo?: any;
  categoryIds: Array<any>;
  categories: Array<any>;
  showOnDiscoveryByLocal: Array<any>;
  showOnCategory: boolean;
  status: boolean;
  featuredCuration: boolean;
  langPercentage: any;
};

interface ITextEditorProps {
  value: string;
  setFieldValue: (val: string) => void;
  setFetchData?: Function;
  fetchData?: InitialCurationDataforFecthedData;
  setCurationList?: Function;
  curationList?: InitialCurationData;
  setUpdatedData?: Function;
  updatedData?: InitialCurationData;
}

export const TextEditor = ({
  value,
  setFieldValue,
  setFetchData,
  fetchData,
  setCurationList,
  curationList,
  setUpdatedData,
  updatedData,
}: ITextEditorProps) => {
  const prepareDraft = (value: string) => {
    const draft = htmlToDraft(value);
    const contentState = ContentState.createFromBlockArray(draft.contentBlocks);
    const editorState = EditorState.createWithContent(contentState);
    return editorState;
  };

  const [editorState, setEditorState] = useState(
    value ? prepareDraft(value) : EditorState.createEmpty(),
  );

  const handlePastedText = (text: string, html?: string): boolean => {
    return false;
  };

  const onEditorStateChange = (editorState: EditorState) => {
    const forFormik = draftToHtml(
      convertToRaw(editorState.getCurrentContent()),
    );
    setFieldValue(forFormik);
    setEditorState(editorState);
    if (setFetchData) {
      setFetchData(() => ({
        ...fetchData,
        description: `<span>${forFormik}</span>`,
      }));
    }
    if (setCurationList) {
      setCurationList(() => ({
        ...curationList,
        description: `<span>${forFormik}</span>`,
      }));
    }
    if (setUpdatedData) {
      setUpdatedData(() => ({
        ...updatedData,
        description: `<span>${forFormik}</span>`,
      }));
    }
  };

  return (
    <div>
      <Editor
        handlePastedText={handlePastedText}
        toolbar={{
          inline: {
            inDropdown: false,
            className: undefined,
            component: undefined,
            dropdownClassName: undefined,
            options: ['bold', 'italic', 'underline'],
          },
          list: {
            inDropdown: false,
            className: undefined,
            component: undefined,
            dropdownClassName: undefined,
            options: ['unordered', 'ordered'],
          },
          link: {
            inDropdown: false,
            className: undefined,
            component: undefined,
            popupClassName: undefined,
            dropdownClassName: undefined,
            showOpenOptionOnHover: true,
            defaultTargetOption: '_self',
            options: ['link'],
            linkCallback: undefined,
          },
          options: ['inline', 'list', 'link'],
        }}
        editorState={editorState}
        editorClassName="custom-editor"
        onEditorStateChange={onEditorStateChange}
      />
    </div>
  );
};
