import {Axios} from '@utils';
import {constants} from '@resources';

export async function postBulkNotification(notification?: any) {
  // const parameters = {
  //   curationListId: curationListId,
  // };

  return await Axios.post(
    `${constants.NOTIFICATION_BASE}/global/userlang`,
    notification,
  );
}
