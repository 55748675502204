import {Axios} from '@utils';
import {constants} from '@resources';

export function addUserToOrderList(user: any) {
  const parameters = {
    userId: user.userId,
    locale: user.locale,
  };

  return Axios.post(`${constants.USER_MANAGEMENT_BASE}/discovery`, parameters);
}
