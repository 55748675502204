import React from 'react';
import {
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CRow,
  CButton,
} from '@coreui/react';

import moment from 'moment';

type Props = {match: any; history: any; location: any};

const AdminDetailPage: React.FC<Props> = (props) => {
  const {
    match,
    history,
    location: {
      state: {user},
    },
  } = props;
  return (
    <>
      <CRow>
        <CCol sm="4" md="2" className="mb-3 mb-xl-0 mfs-auto">
          <CButton
            className="my-2 mfs-auto"
            block
            onClick={() =>
              history.push({
                pathname: `/admin/adminUpdate/${match.params.id}`,
                state: {user: user},
              })
            }
            color="primary">
            Edit Admin
          </CButton>
        </CCol>
      </CRow>
      <CRow>
        <CCol sm={12} lg={3}>
          <CCard>
            <CCardHeader className="user-profile-card">
              <div className="my-4">
                <img
                  className="c-avatar-user"
                  src={user.photo || 'avatars/blank-profile.jpg'}
                  alt=""></img>
              </div>
              <div className="text-center">
                <div className="text-uppercase font-weight-bold">
                  {user.fullName}
                </div>
                <div className="text-muted">{user.jobTitle}</div>
              </div>
            </CCardHeader>
            <CCardBody>
              <small className="text-muted font-weight-bold">Bio</small>
              <br />
              <small className="text-muted"> {user.bio}</small>
            </CCardBody>
          </CCard>
        </CCol>
        <CCol sm={12} lg={9}>
          <CCard>
            <CCardHeader>
              <div className="text-muted font-weight-bold">Account Details</div>
            </CCardHeader>
            <CCardBody>
              <table className="table table-striped table-hover">
                <tbody>
                  <tr>
                    <td>
                      <div>
                        <strong className="text-muted font-weight-bold">
                          User Name &nbsp;
                        </strong>
                        {user.userName}
                      </div>
                    </td>
                    <td>
                      <div>
                        <strong className="text-muted font-weight-bold">
                          Role &nbsp;
                        </strong>
                        {user.role}
                      </div>
                    </td>
                  </tr>

                  <tr>
                    <td>
                      <div>
                        <strong className="text-muted font-weight-bold">
                          Email &nbsp;
                        </strong>
                        {user.email}
                      </div>
                    </td>
                    <td>
                      <div>
                        <strong className="text-muted font-weight-bold">
                          Created Date &nbsp;
                        </strong>
                        {moment(user.createdAt).format('DD/MM/YYYY')}
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
    </>
  );
};

export default AdminDetailPage;
