import {take, call, put} from 'redux-saga/effects';
import type {Effect} from 'redux-saga/effects';

import {example as exampleService} from '@services/example';
import * as ExampleActions from '@actions/example';

export function* example(): Generator<Effect, void, any> {
  while (true) {
    try {
      const {data} = yield take(ExampleActions.EXAMPLE.REQUEST);
      const {data: response} = yield call(exampleService, data);

      yield put(ExampleActions.example.success(response));
    } catch (error) {
      // Show info
      yield put(ExampleActions.example.failure(error));
    }
  }
}
