export const values = {
  fields: [
    {
      key: 'id',
      label: 'Id',
      _style: {width: '4%'},
      sorter: true,
      filter: true,
    },
    {
      key: 'logo',
      label: 'Logo',
      sorter: false,
      filter: false,
      _style: {width: '5%'},
    },
    {
      key: 'title',
      label: 'Title',
      sorter: true,
      filter: true,
    },
    {
      key: 'type',
      label: 'Type',
      sorter: true,
      filter: true,
      _style: {width: '10%'},
    },
    {
      key: 'author',
      label: 'Author',
      sorter: false,
      filter: false,
      _style: {width: '10%'},
    },
    {
      key: 'date',
      label: 'Date',
      sorter: false,
      filter: false,
    },
    {
      key: 'select_row',
      label: '',
      _style: {width: '5%'},
      sorter: false,
      filter: false,
    },
  ],
};
