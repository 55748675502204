import {take, call, put} from 'redux-saga/effects';
import type {Effect} from 'redux-saga/effects';

import {removeUserFromOrderList as removeUserFromOrderListService} from '@services/users';
import * as UsersActions from '@actions/users';

export function* removeUserFromOrderList(): Generator<Effect, void, any> {
  while (true) {
    try {
      const {selectedLocale, userId, role, onSuccess} = yield take(
        UsersActions.REMOVE_USER_FROM_ORDER_LIST.REQUEST,
      );
      const {data: response} = yield call(
        removeUserFromOrderListService,
        selectedLocale,
        userId,
      );

      yield put(
        UsersActions.getPublisherUsersByLocale.request(
          10000,
          0,
          role,
          selectedLocale,
        ),
      );

      onSuccess();
      yield put(UsersActions.removeUserFromOrderList.success());
    } catch (error) {
      // Show info
      yield put(UsersActions.removeUserFromOrderList.failure(error));
    }
  }
}
