import {take, call, put} from 'redux-saga/effects';
import type {Effect} from 'redux-saga/effects';

import {getPosts as getPostsService} from '@services/posts';
import * as PostsActions from '@actions/posts';

export function* searchPosts(): Generator<Effect, void, any> {
  while (true) {
    try {
      const {limit, page, searchFields, forCurationListId} = yield take(
        PostsActions.SEARCH_POSTS.REQUEST,
      );
      const {data: response} = yield call(
        getPostsService,
        limit,
        page,
        searchFields,
        forCurationListId,
      );
      yield put(
        PostsActions.searchPosts.success(response.data, page, response.count),
      );
    } catch (error) {
      // Show info
      yield put(PostsActions.searchPosts.failure(error));
    }
  }
}
