import {Axios} from '@utils';
import {constants} from '@resources';

export function createPostMetadata(
  post: any,
  checkedCLs: Array<any>,
  checkedPosts: Array<any>,
  metaData: any,
) {
  const curationListIds: any[] = [];
  const categoryIds: any[] = [];

  checkedPosts.forEach((element) => {
    categoryIds.push(element.id);
  });
  checkedCLs.forEach((element) => {
    if (element.id !== undefined) curationListIds.push(element.id);
  });

  const parameters = {
    note: post.note ? post.note : undefined,
    url: metaData.data.url,
    author: metaData.data.author,
    date: metaData.data.date,
    description: metaData.data.description,
    image: metaData.data.image,
    logo: metaData.data.logo,
    publisher: metaData.data.publisher,
    title: metaData.data.title,
    lang: metaData.data.lang,
    tagIds: JSON.stringify(post.tagIds),
    // furtherReadings: JSON.stringify(furtherReadings),
    curationListIds: JSON.stringify(curationListIds),
    isHiddenNewsFeed: post.isHiddenNewsFeed,
    isHiddenProfile: post.isHiddenProfile,
  };

  return Axios.post(
    `${constants.POSTS_BASE}/create?userId=${post.userid}`,
    parameters,
  );
}
