import React, {useState, useEffect, ChangeEvent, useRef} from 'react';
import {useHistory} from 'react-router-dom';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import {WSRDataTable} from '../../components';

import {
  CCard,
  CCardBody,
  CCol,
  CRow,
  CImg,
  CForm,
  CCardHeader,
  CInput,
  CBadge,
  CFormGroup,
  CSelect,
} from '@coreui/react';
import CIcon from '@coreui/icons-react';
import moment from 'moment';
import {
  getAllUsers as getAllUsersAction,
  searchUsers as searchUsersAction,
} from '@actions/users';

import {values} from './values';

type Item = {
  id: number;
  userName: string;
  email: string;
  status: string;
  photo: string;
  referrer: {
    userName: string;
  };
};

type Props = {
  role?: string;
  status?: string;
};

export const UsersList: React.FC<Props> = (props) => {
  const {role, status} = props;
  const history = useHistory();
  const dispatch = useDispatch();

  const [page, setPage] = useState(1);
  const [pageLimit, setPageLimit] = useState<number>(10);

  const [searchFields, setSearchFields] = useState({
    userName: '',
    fullName: '',
    email: '',
    id: '',
    status: '',
    premium: false,
    premiumFilter: '',
  });

  const [debouncedName, setDebouncedName] = useState('');
  const initialNameRef = useRef(true);
  const [debouncedFullName, setDebouncedFullName] = useState('');
  const initialFullNameRef = useRef(true);
  const [debouncedEmail, setDebouncedEmail] = useState('');
  const initialEmailRef = useRef(true);
  const [debouncedId, setDebouncedId] = useState('');
  const initialIdRef = useRef(true);

  const {userList, searchResults} = useSelector(
    (state) => ({
      userList: state.users.users,
      searchResults: state.users.searchResults,
    }),
    shallowEqual,
  );

  const scopedSlots = {
    email: (item: Item) => (
      <td>
        <div className="cut-text-user-td">{item.email}</div>
      </td>
    ),

    userName: (item: any) => (
      <td>
        <div>
          {item.userName}
          {item.premium === true &&
          !(item.role === 'wiser' || item.role === 'publisher') ? (
            <CImg
              style={{marginLeft: 7}}
              src={'avatars/premium.svg'}
              alt={'premium'}
              width={10}
              height={14}
            />
          ) : (
            ''
          )}
          {item.role === 'wiser' || item.role === 'publisher' ? (
            <CImg
              style={{marginLeft: 7}}
              src={'avatars/prePremium.svg'}
              alt={'wiserPremium'}
              width={10}
              height={14}
            />
          ) : (
            ''
          )}
        </div>
        <div className="small text-muted">
          Registered: {moment(item.createdAt).format('DD/MM/YYYY')}
        </div>
      </td>
    ),

    status: (item: Item) => (
      <>
        <td>
          {item.status === 'active' ? (
            <CBadge style={{fontSize: '100%'}} color="success">
              {item.status.toUpperCase()}
            </CBadge>
          ) : (
            <CBadge style={{fontSize: '100%'}} color="danger">
              {item.status.toUpperCase()}
            </CBadge>
          )}
        </td>
      </>
    ),

    photo: (item: Item) => (
      <td>
        <div className="c-avatar">
          <CImg
            src={item.photo == null ? 'avatars/blank-profile.jpg' : item.photo}
            className="c-avatar-img"
            alt={''}
            onError={addDefaultSrc}
          />
        </div>
      </td>
    ),

    select_edit_row: (item: Item) => (
      <>
        <td>
          <CIcon
            src={'avatars/edit.png'}
            className=""
            onClick={() => history.push(`/usersDetail/${item.id}`)}
            alt=" "
            height="20px"
            style={{cursor: 'pointer'}}
          />
        </td>
      </>
    ),

    referrer: (item: Item) => (
      <td>
        <div className="">
          {item.referrer != null ? item.referrer.userName : '-'}
        </div>
      </td>
    ),
  };

  const pageChange = (newPage: number) => {
    if (
      searchFields.userName !== '' ||
      searchFields.fullName !== '' ||
      searchFields.email !== '' ||
      searchFields.id !== '' ||
      searchFields.status !== '' ||
      searchFields.premium !== false ||
      searchFields.premiumFilter !== ''
    ) {
      search(searchResults.page + pageLimit, searchFields);
    } else {
      if ((newPage - 1) * pageLimit >= userList.data.length) {
        if (role) {
          dispatch(
            getAllUsersAction.request(
              pageLimit,
              userList.page + pageLimit,
              role,
              '',
              {},
              '',
            ),
          );
        }
      }
    }
    setPage(newPage);
  };

  const addDefaultSrc = (ev: ChangeEvent<HTMLInputElement>) => {
    ev.target.src = 'avatars/blank-profile.jpg';
  };

  const search = (
    page: number,
    searchFields: {
      userName: string;
      fullName: string;
      email: string;
      id: string;
      status: string;
      premium: boolean;
      premiumFilter: string;
    },
  ) => {
    if (
      role &&
      (searchFields.userName !== '' ||
        searchFields.fullName !== '' ||
        searchFields.email !== '' ||
        searchFields.id !== '' ||
        searchFields.status !== '' ||
        searchFields.premiumFilter !== '' ||
        searchFields.premium !== false)
    ) {
      dispatch(
        searchUsersAction.request(pageLimit, page, searchFields, role, '', ''),
      );
    }
    if (status) {
      dispatch(
        searchUsersAction.request(
          pageLimit,
          page,
          searchFields,
          '',
          status,
          '',
        ),
      );
    }
  };

  const searchUserName = (userName: string) => {
    setPage(1);
    setSearchFields((prevState) => {
      search(0, {...prevState, userName});
      return {...prevState, userName};
    });
  };

  const searchFullName = (fullName: string) => {
    setPage(1);
    setSearchFields((prevState) => {
      search(0, {...prevState, fullName});
      return {...prevState, fullName};
    });
  };

  const searchEmail = (email: string) => {
    setPage(1);
    setSearchFields((prevState) => {
      search(0, {...prevState, email});
      return {...prevState, email};
    });
  };

  const searchById = (id: string) => {
    setPage(1);
    setSearchFields((prevState) => {
      search(0, {...prevState, id});
      return {...prevState, id};
    });
  };

  const searchStatus = (e: ChangeEvent<HTMLLIElement>) => {
    setPage(1);
    const status = e.target.value.toString();
    setSearchFields((prevState) => {
      search(0, {...prevState, status});
      return {...prevState, status};
    });
  };

  const searchPaymentStatus = (e: ChangeEvent<HTMLLIElement>) => {
    setPage(1);
    const premiumFilter = e.target.value.toString();
    setSearchFields((prevState) => {
      search(0, {...prevState, premiumFilter});
      return {...prevState, premiumFilter};
    });
  };

  const onChangeLimit = (e: ChangeEvent<HTMLLIElement>) => {
    setPageLimit(Number(e.target.value));
  };

  useEffect(() => {
    if (initialNameRef.current) {
      initialNameRef.current = false;
      return;
    }
    const timer = setTimeout(() => searchUserName(debouncedName), 1000);
    return () => clearTimeout(timer);
  }, [debouncedName]);

  useEffect(() => {
    if (initialFullNameRef.current) {
      initialFullNameRef.current = false;
      return;
    }
    const timer = setTimeout(() => searchFullName(debouncedFullName), 1000);
    return () => clearTimeout(timer);
  }, [debouncedFullName]);

  useEffect(() => {
    if (initialEmailRef.current) {
      initialEmailRef.current = false;
      return;
    }
    const timer = setTimeout(() => searchEmail(debouncedEmail), 1000);
    return () => clearTimeout(timer);
  }, [debouncedEmail]);

  useEffect(() => {
    if (initialIdRef.current) {
      initialIdRef.current = false;
      return;
    }
    const timer = setTimeout(() => searchById(debouncedId), 1000);
    return () => clearTimeout(timer);
  }, [debouncedId]);

  useEffect(() => {
    setPage(0);
    if (role && pageLimit !== 10) {
      dispatch(getAllUsersAction.request(pageLimit, 0, role, '', {}, ''));
    }
    if (status) {
      dispatch(getAllUsersAction.request(pageLimit, 0, '', status, {}, ''));
    }
  }, [pageLimit]);

  const data =
    searchFields.userName !== '' ||
    searchFields.fullName !== '' ||
    searchFields.email !== '' ||
    searchFields.id !== '' ||
    searchFields.status !== '' ||
    searchFields.premium !== false ||
    searchFields.premiumFilter !== ''
      ? searchResults
      : userList;

  return (
    <>
      <CRow>
        <CCol xl={12}>
          <CForm inline></CForm>

          <CCard>
            <CCardHeader>
              <CRow>
                <CCol sm="4" md="1" className="mb-3 mb-xl-0 c-table-header">
                  Users ({data.count})
                </CCol>

                <CCol sm="4" md="3" className="mb-3 mb-xl-0 c-table-header">
                  <CInput
                    className="form-control"
                    type="text"
                    placeholder="Search by username"
                    onChange={(e: ChangeEvent<HTMLLIElement>) => {
                      return setDebouncedName(e.target.value.toString());
                    }}
                  />
                </CCol>
                <CCol sm="4" md="3" className="mb-3 mb-xl-0 c-table-header">
                  <CInput
                    className="form-control"
                    type="text"
                    placeholder="Search by fullname"
                    onChange={(e: ChangeEvent<HTMLLIElement>) =>
                      setDebouncedFullName(e.target.value.toString())
                    }
                  />
                </CCol>

                <CCol sm="4" md="3" className="mb-3 mb-xl-0 c-table-header">
                  <CInput
                    className="form-control"
                    type="text"
                    placeholder="Search by Email"
                    onChange={(e: ChangeEvent<HTMLLIElement>) =>
                      setDebouncedEmail(e.target.value.toString())
                    }
                  />
                </CCol>
                <CCol sm="3" md="2" className="my-3 mb-xl-0  mfs-auto">
                  <CFormGroup>
                    <CSelect
                      name="showOnCategory"
                      id="showOnCategory"
                      value={pageLimit}
                      onChange={onChangeLimit}>
                      <option value="10">10</option>
                      <option value="20">20</option>
                      <option value="50">50</option>
                    </CSelect>
                  </CFormGroup>
                </CCol>
              </CRow>
              <CRow>
                <CCol
                  sm="4"
                  md="1"
                  className="mb-3 mb-xl-0 c-table-header"></CCol>
                <CCol sm="4" md="3" className="mb-3 mb-xl-0 c-table-header">
                  <CInput
                    className="form-control"
                    type="text"
                    placeholder="Search by Id"
                    onChange={(e: ChangeEvent<HTMLLIElement>) =>
                      setDebouncedId(e.target.value.toString())
                    }
                  />
                </CCol>

                <CCol sm="4" md="3" className="my-3 mb-xl-0 c-table-auto">
                  <CFormGroup>
                    <CSelect
                      value={searchFields.status}
                      onChange={searchStatus}>
                      <option value="">All </option>
                      <option value="active">Active</option>
                      <option value="passive">Passive</option>
                    </CSelect>
                  </CFormGroup>
                </CCol>
                {role === 'premium' ? (
                  <CCol sm="4" md="3" className="my-3 mb-xl-0 c-table-auto">
                    <CFormGroup>
                      <CSelect
                        value={searchFields.premiumFilter}
                        onChange={searchPaymentStatus}>
                        <option value="">Payment Status </option>
                        <option value="paid">With Payment</option>
                        <option value="assigned">Without Payment</option>
                        <option value="publisher">Publisher</option>
                        <option value="wiser">Wiser</option>
                      </CSelect>
                    </CFormGroup>
                  </CCol>
                ) : (
                  ''
                )}
              </CRow>
            </CCardHeader>
            <CCardBody>
              <WSRDataTable
                items={data.data}
                fields={values.fields}
                scopedSlots={scopedSlots}
                pageLimit={pageLimit}
                page={page}
                pageChange={pageChange}
                itemsCount={data.count}></WSRDataTable>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
    </>
  );
};
