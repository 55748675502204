import {Axios} from '@utils';
import {constants} from '@resources';

export function getPosts(
  limit: number,
  offset: number,
  filterFields?: {
    categoryIds?: any;
    tagIds?: any;
    userId?: number;
    startDate?: string;
    endDate?: string;
    type?: string;
  },
  forCurationListId?: number,
) {
  let params = '';

  if (filterFields?.categoryIds) {
    const categoryStr = `&categoryIds=` + filterFields?.categoryIds;
    params = categoryStr + params;
  }
  if (filterFields?.tagIds) {
    const tagStr = `&tagIds=[` + filterFields?.tagIds + ']';
    params = tagStr + params;
  }
  if (filterFields?.userId !== 0 && filterFields?.userId !== undefined) {
    const userStr = `&userId=` + filterFields?.userId;
    params = userStr + params;
  }
  if (filterFields?.startDate) {
    const startDateStr = `&startDate=` + filterFields?.startDate;
    params = startDateStr + params;
  }
  if (filterFields?.endDate) {
    const endDateStr = `&endDate=` + filterFields?.endDate;
    params = endDateStr + params;
  }
  if (filterFields?.type) {
    const typeStr = `&type=` + filterFields?.type;
    if (typeStr !== '&type=[]') params = typeStr + params;
  }
  if (forCurationListId !== 0 && forCurationListId !== undefined) {
    const forCurationList = `&forCurationListId=` + forCurationListId;
    params = forCurationList + params;
  }

  if (
    filterFields?.userId !== 0 ||
    filterFields?.startDate !== '' ||
    filterFields?.endDate !== '' ||
    filterFields?.type !== '' ||
    filterFields?.categoryIds !== '' ||
    (forCurationListId !== 0 && forCurationListId !== undefined)
  ) {
    return Axios.get(
      `${constants.POSTS_BASE}/search?limit=${limit}&offset=${offset}` + params,
    );
  } else {
    return Axios.get(
      // `${constants.POSTS_BASE}/search?limit=${limit}&offset=${offset}&forCurationListId=${forCurationListId}`,
      `${constants.POSTS_BASE}/search?limit=${limit}&offset=${offset}`,
    );
  }
}
