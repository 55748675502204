import {take, call, put} from 'redux-saga/effects';
import type {Effect} from 'redux-saga/effects';

import {getWiserUsersByLocale as getWiserUsersByLocaleService} from '@services/users';
import * as WiserPublishersActions from '@actions/users';

export function* getWiserUsersByLocale(): Generator<Effect, void, any> {
  
  while (true) {
    try {
      const {limit, offset, role, locale} = yield take(
        WiserPublishersActions.GET_WISER_USERS_BY_LOCALE.REQUEST,
      );
      const {data: response} = yield call(
        getWiserUsersByLocaleService,
        limit,
        offset,
        role,
        locale,
      );

      yield put(
        WiserPublishersActions.getWiserUsersByLocale.success(
          response.data,
          offset,
          response.count,
        ),
      );
    } catch (error) {
      // Show info
      yield put(WiserPublishersActions.getWiserUsersByLocale.failure(error));
    }
  }
}
