import {Axios} from '@utils';
import {constants} from '@resources';

export async function updateCurationListStatus(checkedList: any) {
  if (checkedList) {
    const parameters = {
      status: checkedList.value,
    };
    return Axios.put(
      `${constants.CURATION_BASE}/${checkedList.id}`,
      parameters,
    );
  }
}
