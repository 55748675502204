import React, {ChangeEvent, useState} from 'react';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';

import {images} from '@resources';
import {
  CAlert,
  CButton,
  CCard,
  CCardBody,
  CCardFooter,
  CCardHeader,
  CCol,
  CForm,
  CFormGroup,
  CImg,
  CInput,
  CInputCheckbox,
  CLabel,
  CRow,
  CSelect,
  CTextarea,
} from '@coreui/react';

import {
  postBulkNotification as postBulkNotificationAction,
  closeSuccessMessage as closeSuccessMessageAction,
} from '@actions/notifications';

import {
  AddCuration,
  WSRModalNotification,
  WSRModal,
  AddPosts,
  AddUser,
} from './components';

const BulkNotification: React.FC = () => {
  const dispatch = useDispatch();

  const [title, setTitle] = useState('');
  const [titleLength] = useState(50);
  const [titleWarning, setTitleWarning] = useState(false);
  const [message, setMessage] = useState('');
  const [messageLength] = useState(150);
  const [messageWarning, setMessageWarning] = useState(false);
  const [notificationType, setNotificationType] = useState('');
  const [notificationTypeWarning, setNotificationTypeWarning] = useState(false);
  //TODO:notificationtypes=  'postShare','userShare','newCuration','newCurationPost','curationShare','noAction','openScreen',
  const [curation, setCuration] = useState<any>();
  const [curationWarning, setCurationWarning] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState('');
  const [selectedLanguageWarning, setSelectedLanguageWarning] = useState(false);

  const [curationModal, setCurationModal] = useState<boolean>(false);
  const [sendNotificationModal, setSendNotificationModal] = useState<boolean>(
    false,
  );

  const [postModal, setPostModal] = useState<boolean>(false);
  const [selectedPost, setSelectedPost] = useState<any>();
  const [postWarning, setPostWarning] = useState(false);

  const [userModal, setUserModal] = useState<boolean>(false);
  const [selectedUser, setSelectedUser] = useState<any>();
  const [userWarning, setUserWarning] = useState(false);

  const [selectedSetting, setSelectedSetting] = useState<any>();
  const [settingWarning, setSettingWarning] = useState(false);

  const {succesMessage} = useSelector(
    (state) => ({
      succesMessage: state.notifications.succesMessage,
    }),
    shallowEqual,
  );

  const handleChange = () => {};

  const handleSettingsChange = (e: any) => {
    setSelectedSetting(e.target.value);
    if (e.target.value !== 'any') {
      setSettingWarning(false);
    }
  };

  const handlePushTitle = (event: ChangeEvent<HTMLInputElement>) => {
    setTitle(event.target.value);
    setTitleWarning(false);
  };

  const handleMessage = (event: ChangeEvent<HTMLInputElement>) => {
    setMessage(event.target.value);
    setMessageWarning(false);
  };

  const handleChangeLanguage = (event: ChangeEvent<HTMLInputElement>) => {
    if (selectedLanguage === event.target.value) {
      setSelectedLanguage('');
    } else {
      setSelectedLanguage(event.target.value);
    }
    setSelectedLanguageWarning(false);
  };

  const handleChangeNotificationType = (
    event: ChangeEvent<HTMLInputElement>,
  ) => {
    setNotificationType(event.target.value);
    setNotificationTypeWarning(false);
    if (event.target.value === 'any') setCuration('');
  };

  const handleSendNotification = () => {
    if (
      ((curation === undefined || curation === '') &&
        (selectedPost === undefined || selectedPost === '') &&
        (selectedUser === undefined || selectedUser === '') &&
        (selectedSetting === undefined ||
          selectedSetting === '' ||
          selectedSetting === 'any')) ||
      title === '' ||
      message === '' ||
      selectedLanguage === '' ||
      notificationType === '' ||
      notificationType === 'any'
    ) {
      if (title === '') {
        setTitleWarning(true);
      }
      if (message === '') {
        setMessageWarning(true);
      }
      if (notificationType === '' || notificationType === 'any') {
        setNotificationTypeWarning(true);
      }
      if (curation === undefined || curation === '') {
        setCurationWarning(true);
      }
      if (selectedPost === undefined || selectedPost === '') {
        setPostWarning(true);
      }
      if (selectedUser === undefined || selectedUser === '') {
        setUserWarning(true);
      }
      if (
        selectedSetting === undefined ||
        selectedSetting === '' ||
        selectedSetting === 'any'
      ) {
        setSettingWarning(true);
      }
      if (selectedLanguage === '') {
        setSelectedLanguageWarning(true);
      }
    } else {
      setSendNotificationModal(true);
    }
  };

  const handleSubmit = () => {
    if (
      notificationType === 'curationShare' &&
      curation !== '' &&
      curation?.id
    ) {
      const notification = {
        title: title,
        message: message,
        lang: selectedLanguage,
        action: notificationType,
        actionData: curation?.id,
      };
      dispatch(postBulkNotificationAction.request(notification));
    }
    if (
      notificationType === 'postShare' &&
      selectedPost !== '' &&
      selectedPost?.id
    ) {
      const notification = {
        title: title,
        message: message,
        lang: selectedLanguage,
        action: notificationType,
        actionData: selectedPost?.id,
      };
      dispatch(postBulkNotificationAction.request(notification));
    }
    if (
      notificationType === 'userShare' &&
      selectedUser !== '' &&
      selectedUser?.id
    ) {
      const notification = {
        title: title,
        message: message,
        lang: selectedLanguage,
        action: notificationType,
        actionData: selectedUser?.id,
      };
      dispatch(postBulkNotificationAction.request(notification));
    }
    if (
      notificationType === 'openScreen' &&
      selectedSetting !== '' &&
      selectedSetting !== 'any'
    ) {
      const notification = {
        title: title,
        message: message,
        lang: selectedLanguage,
        action: notificationType,
        actionData: selectedSetting,
      };
      dispatch(postBulkNotificationAction.request(notification));
    }
    setSendNotificationModal(false);
  };

  return (
    <>
      <CRow>
        <CCol xl={12}>
          <CForm onSubmit={handleSubmit}>
            <CCard>
              <CCardHeader style={{paddingLeft: 29}}>
                <h5 style={{marginTop: 15}}>Bulk Notification</h5>
              </CCardHeader>
              <CCardBody style={{paddingLeft: 52, paddingRight: 52}}>
                <CFormGroup>
                  <CLabel htmlFor="title">Push Title</CLabel>
                  <CInput
                    style={
                      title.length > titleLength - 5 ? {borderColor: 'red'} : {}
                    }
                    type="text"
                    maxLength={titleLength}
                    value={title ? title : ''}
                    name="title"
                    placeholder="Write title for push notification here..."
                    onChange={handlePushTitle}
                  />
                </CFormGroup>
                <div
                  style={
                    title.length > titleLength - 10
                      ? {
                          width: '100%',
                          textAlign: 'right',
                          marginTop: '-16px',
                          color: 'red',
                        }
                      : {
                          width: '100%',
                          textAlign: 'right',
                          marginTop: '-16px',
                          color: 'black',
                        }
                  }>
                  {`${title.length}/${titleLength}`}
                </div>
                <CAlert
                  color="warning"
                  show={titleWarning}
                  style={{
                    marginTop: '-18px',
                    width: '250px',
                    backgroundColor: 'white',
                    borderColor: 'red',
                  }}
                  onShowChange={() => {
                    if (title !== '') {
                      setTitleWarning(false);
                    }
                  }}>
                  <CImg
                    src={images.warningIcon.toString()}
                    width={25}
                    height={25}
                    className=""
                    alt=" "
                    style={{marginRight: '12.5px'}}
                  />
                  Please enter a title!
                </CAlert>

                <CFormGroup>
                  <CLabel htmlFor="title">Push Message</CLabel>
                  <CTextarea
                    style={
                      messageLength - message.length < 5
                        ? {borderColor: 'red'}
                        : {}
                    }
                    className="form-control-warning"
                    type="textarea-input"
                    value={message}
                    maxLength={messageLength}
                    name="message"
                    rows={3}
                    placeholder="Write the text you want to send here..."
                    onChange={handleMessage}
                  />
                </CFormGroup>
                <div
                  style={
                    message.length > messageLength - 10
                      ? {
                          width: '100%',
                          textAlign: 'right',
                          marginTop: '-16px',
                          color: 'red',
                        }
                      : {
                          width: '100%',
                          textAlign: 'right',
                          marginTop: '-16px',
                          color: 'black',
                        }
                  }>
                  {`${message.length}/${messageLength}`}
                </div>
                <CAlert
                  color="warning"
                  show={messageWarning}
                  style={{
                    marginTop: '-18px',
                    width: '250px',
                    backgroundColor: 'white',
                    borderColor: 'red',
                  }}
                  onShowChange={() => {
                    if (message !== '') {
                      setMessageWarning(false);
                    }
                  }}>
                  <CImg
                    src={images.warningIcon.toString()}
                    width={25}
                    height={25}
                    className=""
                    alt=" "
                    style={{marginRight: '12.5px'}}
                  />
                  Please enter a message!
                </CAlert>

                <CFormGroup>
                  <CLabel htmlFor="title">Notification Type</CLabel>
                  <CSelect
                    style={{width: 546}}
                    name="notificationType"
                    id="notificationType"
                    value={notificationType}
                    placeholder="Choose the page you want to redirect..."
                    onChange={handleChangeNotificationType}>
                    <option value="any">Any</option>
                    <option value="curationShare">Curation Detail</option>
                    <option value="postShare">Post Detail</option>
                    <option value="userShare">User Profile</option>
                    <option value="openScreen">Settings</option>
                  </CSelect>
                </CFormGroup>
                <CAlert
                  color="warning"
                  show={notificationTypeWarning}
                  style={{
                    marginTop: '-12px',
                    width: '285px',
                    backgroundColor: 'white',
                    borderColor: 'red',
                  }}
                  onShowChange={() => {
                    if (notificationType !== '' && notificationType !== 'any') {
                      setNotificationTypeWarning(false);
                    }
                  }}>
                  <CImg
                    src={images.warningIcon.toString()}
                    width={25}
                    height={25}
                    className=""
                    alt=" "
                    style={{marginRight: '12.5px'}}
                  />
                  Please select a notification type!
                </CAlert>
                {notificationType === 'curationShare' ? (
                  <CFormGroup>
                    <CLabel htmlFor="title">Curation</CLabel>
                    <span
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        gap: 10,
                      }}>
                      <CInput
                        style={{width: 546}}
                        name="curation"
                        id="curation"
                        value={curation?.name ?? ''}
                        placeholder="Select the curation"
                        onChange={handleChange}
                      />
                      <CButton
                        onClick={() => {
                          setCurationModal(true);
                        }}
                        color="success">
                        Add Curation
                      </CButton>
                    </span>
                    <CAlert
                      color="warning"
                      show={curationWarning}
                      style={{
                        marginTop: '3px',
                        width: '250px',
                        backgroundColor: 'white',
                        borderColor: 'red',
                      }}
                      onShowChange={() => {
                        if (curation !== '' && curation !== undefined) {
                          setCurationWarning(false);
                        }
                      }}>
                      <CImg
                        src={images.warningIcon.toString()}
                        width={25}
                        height={25}
                        className=""
                        alt=" "
                        style={{marginRight: '12.5px'}}
                      />
                      Please select a curation!
                    </CAlert>
                  </CFormGroup>
                ) : (
                  ''
                )}

                {notificationType === 'postShare' ? (
                  <CFormGroup>
                    <CLabel htmlFor="title">Post</CLabel>
                    <span
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        gap: 10,
                      }}>
                      <CInput
                        style={{width: 546}}
                        name="post"
                        id="post"
                        value={selectedPost?.title ?? ''}
                        placeholder="Select the post"
                        onChange={handleChange}
                      />
                      <CButton
                        onClick={() => {
                          setPostModal(true);
                        }}
                        color="success">
                        Add Post
                      </CButton>
                    </span>
                    <CAlert
                      color="warning"
                      show={postWarning}
                      style={{
                        marginTop: '3px',
                        width: '250px',
                        backgroundColor: 'white',
                        borderColor: 'red',
                      }}
                      onShowChange={() => {
                        if (selectedPost !== '' && selectedPost !== undefined) {
                          setPostWarning(false);
                        }
                      }}>
                      <CImg
                        src={images.warningIcon.toString()}
                        width={25}
                        height={25}
                        className=""
                        alt=" "
                        style={{marginRight: '12.5px'}}
                      />
                      Please select a post!
                    </CAlert>
                  </CFormGroup>
                ) : (
                  ''
                )}

                {notificationType === 'userShare' ? (
                  <CFormGroup>
                    <CLabel htmlFor="title">User</CLabel>
                    <span
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        gap: 10,
                      }}>
                      <CInput
                        style={{width: 546}}
                        name="user"
                        id="user"
                        value={selectedUser?.fullName ?? ''}
                        placeholder="Select the user"
                        onChange={handleChange}
                      />
                      <CButton
                        onClick={() => {
                          setUserModal(true);
                        }}
                        color="success">
                        Add User
                      </CButton>
                    </span>
                    <CAlert
                      color="warning"
                      show={userWarning}
                      style={{
                        marginTop: '3px',
                        width: '250px',
                        backgroundColor: 'white',
                        borderColor: 'red',
                      }}
                      onShowChange={() => {
                        if (selectedUser !== '' && selectedUser !== undefined) {
                          setUserWarning(false);
                        }
                      }}>
                      <CImg
                        src={images.warningIcon.toString()}
                        width={25}
                        height={25}
                        className=""
                        alt=" "
                        style={{marginRight: '12.5px'}}
                      />
                      Please select a user!
                    </CAlert>
                  </CFormGroup>
                ) : (
                  ''
                )}

                {notificationType === 'openScreen' ? (
                  <CFormGroup>
                    <CLabel htmlFor="title">Setting</CLabel>
                    <span
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        gap: 10,
                      }}>
                      <CSelect
                        style={{width: 546}}
                        name="setting"
                        id="setting"
                        value={selectedSetting ?? ''}
                        placeholder="Select the setting"
                        onChange={handleSettingsChange}>
                        <option value="any">any</option>
                        <option value="LikedPostsScreen">
                          {"Posts You've Liked"}
                        </option>
                        <option value="StatisticsScreen">Statistics</option>
                        <option value="InviteScreen">Invite Friends</option>
                        <option value="NotificationScreen">
                          Notifications
                        </option>
                        <option value="LanguageScreen">Language</option>
                        <option value="CountryScreen">Country</option>
                        <option value="PrivacyAndSafetyScreen">
                          {'Privacy & Safety'}
                        </option>
                      </CSelect>
                      {/* <CButton
                        onClick={() => {
                          setCurationModal(true);
                        }}
                        color="success">
                        Add Setting
                      </CButton> */}
                    </span>
                    <CAlert
                      color="warning"
                      show={settingWarning}
                      style={{
                        marginTop: '3px',
                        width: '250px',
                        backgroundColor: 'white',
                        borderColor: 'red',
                      }}
                      onShowChange={() => {
                        if (
                          selectedSetting !== '' &&
                          selectedSetting !== 'any' &&
                          selectedSetting !== undefined
                        ) {
                          setSettingWarning(false);
                        }
                      }}>
                      <CImg
                        src={images.warningIcon.toString()}
                        width={25}
                        height={25}
                        className=""
                        alt=" "
                        style={{marginRight: '12.5px'}}
                      />
                      Please select a setting!
                    </CAlert>
                  </CFormGroup>
                ) : (
                  ''
                )}

                <CFormGroup>
                  <span style={{display: 'flex', flexDirection: 'column'}}>
                    <span>
                      <CLabel>Language</CLabel>
                    </span>
                    <span
                      style={{display: 'flex', flexDirection: 'row', gap: 55}}>
                      <span style={{display: 'flex', gap: 20}}>
                        <span>
                          <CInputCheckbox
                            name={'languageTR'}
                            value="tr"
                            className="checkbox"
                            checked={selectedLanguage === 'tr'}
                            onChange={handleChangeLanguage}
                          />
                        </span>
                        <CLabel>TR</CLabel>
                      </span>
                      <span style={{display: 'flex', gap: 20}}>
                        <span>
                          <CInputCheckbox
                            name={'languageEN'}
                            className="checkbox"
                            value="en"
                            checked={selectedLanguage === 'en'}
                            onChange={handleChangeLanguage}
                          />
                        </span>
                        <CLabel>EN</CLabel>
                      </span>
                    </span>
                  </span>
                </CFormGroup>
                <CAlert
                  color="warning"
                  show={selectedLanguageWarning}
                  style={{
                    marginTop: '-20px',
                    width: '250px',
                    backgroundColor: 'white',
                    borderColor: 'red',
                  }}
                  onShowChange={() => {
                    if (selectedLanguage !== '') {
                      setSelectedLanguageWarning(false);
                    }
                  }}>
                  <CImg
                    src={images.warningIcon.toString()}
                    width={25}
                    height={25}
                    className=""
                    alt=" "
                    style={{marginRight: '12.5px'}}
                  />
                  Please select a language!
                </CAlert>
              </CCardBody>

              <CCardFooter style={{paddingRight: 52}}>
                <CFormGroup>
                  <span
                    style={{
                      display: 'flex',
                      gap: 12,
                      justifyContent: 'end',
                    }}>
                    <CButton
                      style={{color: '#374254', backgroundColor: '#9DA5B1'}}
                      onClick={() => {}}>
                      Cancel
                    </CButton>
                    <CButton
                      style={{color: '#F0F4F7', backgroundColor: '#321FDB'}}
                      onClick={handleSendNotification}>
                      Send Notification
                    </CButton>
                  </span>
                </CFormGroup>
              </CCardFooter>
            </CCard>
          </CForm>
        </CCol>

        <AddCuration
          isOpen={curationModal}
          setIsOpen={setCurationModal}
          onCurationAdded={(curation: any) => {
            setCuration(curation);
            setCurationWarning(false);
          }}
        />

        <AddPosts
          curId={0}
          setPostModal={setPostModal}
          setSelectedPost={(post: any) => {
            setSelectedPost(post);
            setPostWarning(false);
          }}
          postModal={postModal}
        />

        <AddUser
          setUser={(user: any) => {
            setSelectedUser(user);
            setUserModal(false);
          }}
          userModal={userModal}
          setUserModal={setUserModal}
        />

        <WSRModal
          onShowModal={sendNotificationModal}
          setOnShowModal={setSendNotificationModal}
          modalTitle={''}
          modalBody={`Are you sure you want to send this bulk push notification??`}
          operationButton={handleSubmit}
          operationButtonText={'Yes, send'}
          cancelButton={() => {
            setSendNotificationModal(false);
          }}
          cancelButtonText={'Close'}
        />

        <WSRModalNotification
          onShowModal={succesMessage}
          message={`Successfully sent your notification!`}
          operationButton={() => dispatch(closeSuccessMessageAction.request())}
          operationButtonText={'OK'}
        />
      </CRow>
    </>
  );
};

export default BulkNotification;
