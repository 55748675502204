import React, {useState, useEffect, ChangeEvent} from 'react';
import {useLocation} from 'react-router-dom';
import {
  CButton,
  CCard,
  CCardHeader,
  CCol,
  CImg,
  CInput,
  CInputCheckbox,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
  CRow,
} from '@coreui/react';
import {values} from './values';

import {WSRDataTable} from '../../components';
import {
  getCurationUsers as getCurationUsersAction,
  searchCurationUsers as searchCurationUsersAction,
} from '@actions/curation';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';

type Props = {
  setCurationList: Function;
  setCuratorName: Function;
  type: string;
  addCuratorModal: boolean;
  setAddCuratorModal: Function;
  curation?: any;
};

type EventItem = {
  firsName: string;
  fullName: string;
  id: number;
  jobTitle: string;
  lastName: string;
  photo: string;
  role: string;
  userName: string;
};

export const AddCurator: React.FC<Props> = (props: Props) => {
  const {
    setCurationList,
    setCuratorName,
    setAddCuratorModal,
    type,
    addCuratorModal,
    curation,
  } = props;

  const queryPage = useLocation().search.match(/page=([0-9]+)/);
  const currentPage = Number(queryPage && queryPage[1] ? queryPage[1] : 1);
  const [page, setPage] = useState(currentPage);
  const [selectedUser, setSelectedUser] = useState<any>(curation);
  const [selectedCuratorName, setSelectedCuratorName] = useState('');
  const [selectedUserId, setSelectedUserId] = useState<number>();
  const [searchFields, setSearchFields] = useState({
    userName: '',
    fullName: '',
    firstName: '',
    lastName: '',
    role: '',
  });

  const [debouncedUserName, setDebouncedUserName] = useState('');
  const [debouncedFullName, setDebouncedFullName] = useState('');
  const [debouncedFirstName, setDebouncedFirstName] = useState('');
  const [debouncedLastName, setDebouncedLastName] = useState('');

  const dispatch = useDispatch();

  const handleOnRowClick = (event: EventItem) => {
    if (selectedUserId === event.id) {
      setSelectedCuratorName('');
      setCuratorName('');
      setSelectedUserId(0);
    } else {
      setSelectedUser(event);
      setSelectedUserId(event.id);
      setSelectedCuratorName(event.fullName);
    }
  };

  const checkedControl = (item: EventItem) => {
    if (selectedUser?.id === item.id && selectedCuratorName !== '') {
      return true;
    } else {
      return false;
    }
  };

  const scopedSlots = {
    photo: (item: any) => (
      <td key={item.id}>
        <div className="c-avatar">
          <CImg
            src={item.photo || 'avatars/blank-profile.jpg'}
            className="c-avatar-img"
            alt=""
          />
        </div>
      </td>
    ),
    select_row: (item: any, index: any) => (
      <td key={item.id} className="py-2">
        <CInputCheckbox
          key={item.id}
          name={item.id}
          className="checkbox"
          checked={checkedControl(item)}
        />
      </td>
    ),
  };

  const {curationUsers, searchResults} = useSelector(
    (state) => ({
      curationUsers: state.curation.curationUsers,
      searchResults: state.curation.searchResults,
    }),
    shallowEqual,
  );

  const addUserToCuration = () => {
    if (type === 'detail') {
      setCurationList((curationList: any) => ({
        ...curationList,
        curatorId: selectedUserId,
      }));
      setCuratorName(selectedCuratorName);
    } else if (type === 'create') {
      setCurationList(selectedUserId);
      setCuratorName(selectedCuratorName);
    }
    setAddCuratorModal(false);
  };

  const pageChange = (newPage: number) => {
    if (
      searchFields.userName !== '' ||
      searchFields.firstName !== '' ||
      searchFields.fullName !== '' ||
      searchFields.lastName !== '' ||
      searchFields.role !== ''
    ) {
      search(searchResults.page + 10, searchFields);
    } else {
      if ((newPage - 1) * 10 >= curationUsers.users.length) {
        dispatch(getCurationUsersAction.request(curationUsers.page + 10));
      }
    }
    setPage(newPage);
  };

  const searchUserName = (userName: string) => {
    setSearchFields((prevState) => {
      search(0, {...prevState, userName});
      return {...prevState, userName};
    });
  };

  useEffect(() => {
    const timer = setTimeout(() => searchUserName(debouncedUserName), 1000);
    return () => clearTimeout(timer);
  }, [debouncedUserName]);

  const searchFullName = (fullName: string) => {
    setSearchFields((prevState) => {
      search(0, {...prevState, fullName});
      return {...prevState, fullName};
    });
  };

  useEffect(() => {
    const timer = setTimeout(() => searchFullName(debouncedFullName), 1000);
    return () => clearTimeout(timer);
  }, [debouncedFullName]);

  const searchFirstName = (firstName: string) => {
    setSearchFields((prevState) => {
      search(0, {...prevState, firstName});
      return {...prevState, firstName};
    });
  };

  useEffect(() => {
    const timer = setTimeout(() => searchFirstName(debouncedFirstName), 1000);
    return () => clearTimeout(timer);
  }, [debouncedFirstName]);

  const searchLastName = async (lastName: string) => {
    await setSearchFields((prevState) => {
      search(0, {...prevState, lastName});
      return {...prevState, lastName};
    });
  };

  useEffect(() => {
    const timer = setTimeout(() => searchLastName(debouncedLastName), 1000);
    return () => clearTimeout(timer);
  }, [debouncedLastName]);

  const searchRole = (e: ChangeEvent<HTMLLIElement>) => {
    const role = e.target.value.toString();
    setSearchFields((prevState) => {
      search(0, {...prevState, role});
      return {...prevState, role};
    });
  };

  const search = (
    page: number,
    searchFields: {
      userName: string;
      fullName: string;
      firstName: string;
      lastName: string;
      role: string;
    },
  ) => {
    dispatch(searchCurationUsersAction.request(page, searchFields));
  };

  useEffect(() => {
    dispatch(getCurationUsersAction.request(0));
  }, []);

  useEffect(() => {
    setSelectedUser(curation);
  }, [curation]);

  const data =
    searchFields.firstName !== '' ||
    searchFields.fullName !== '' ||
    searchFields.lastName !== '' ||
    searchFields.userName !== '' ||
    searchFields.role !== ''
      ? searchResults
      : curationUsers;

  return (
    <CModal show={addCuratorModal} size="xl" onClose={setAddCuratorModal}>
      <CModalHeader>
        <CModalTitle>Select Curator</CModalTitle>
      </CModalHeader>
      <CModalBody>
        <CRow>
          <CCol xl={16}>
            <CCard>
              <CCardHeader>
                <CRow>
                  <CCol sm="2" md="1" className="mb-3 mb-xl-0 c-table-header">
                    User Count ({data.count})
                  </CCol>

                  <CRow>
                    <CCol sm="4" md="3" className="mb-3 mb-xl-0 c-table-header">
                      <CInput
                        className="form-control"
                        type="text"
                        placeholder="Search by User Name"
                        onChange={(e: ChangeEvent<HTMLLIElement>) =>
                          setDebouncedUserName(e.target.value.toString())
                        }
                      />
                    </CCol>

                    <CCol sm="4" md="3" className="mb-3 mb-xl-0 c-table-header">
                      <CInput
                        className="form-control"
                        type="text"
                        placeholder="Search by Full Name"
                        onChange={(e: ChangeEvent<HTMLLIElement>) =>
                          setDebouncedFullName(e.target.value.toString())
                        }
                      />
                    </CCol>

                    <CCol sm="4" md="3" className="mb-3 mb-xl-0 c-table-header">
                      <CInput
                        className="form-control"
                        type="text"
                        placeholder="Search by First Name"
                        onChange={(e: ChangeEvent<HTMLLIElement>) =>
                          setDebouncedFirstName(e.target.value.toString())
                        }
                      />
                    </CCol>

                    <CCol sm="4" md="3" className="mb-3 mb-xl-0 c-table-header">
                      <CInput
                        className="form-control"
                        type="text"
                        placeholder="Search by Last Name"
                        onChange={(e: ChangeEvent<HTMLLIElement>) =>
                          setDebouncedLastName(e.target.value.toString())
                        }
                      />
                    </CCol>
                  </CRow>

                  <CCol sm="4" md="2" className="mb-3 mb-xl-0 c-table-header">
                    <CInput
                      className="form-control"
                      type="text"
                      placeholder="Search by Role"
                      onChange={searchRole}
                    />
                  </CCol>
                </CRow>
              </CCardHeader>

              <WSRDataTable
                items={data.users}
                fields={values.fields}
                scopedSlots={scopedSlots}
                pageLimit={10}
                page={page}
                pageChange={pageChange}
                itemsCount={data.count}
                onClick={handleOnRowClick}
              />
            </CCard>
          </CCol>
        </CRow>
      </CModalBody>
      <CModalFooter className="d-flex justify-content-between">
        <CButton
          className="col-3"
          color="danger"
          onClick={() => {
            setAddCuratorModal(false);
          }}>
          Close
        </CButton>
        <CButton
          className="col-3"
          block
          onClick={() => {
            addUserToCuration();
          }}
          color="success">
          Add Selected
        </CButton>
      </CModalFooter>
    </CModal>
  );
};
