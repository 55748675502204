import {take, call, put} from 'redux-saga/effects';
import type {Effect} from 'redux-saga/effects';

import {getCategories as getCategoriesService} from '@services/categories';
import * as CategoryActions from '@actions/categories';

export function* getCategories(): Generator<Effect, void, any> {
  while (true) {
    try {
      const {page} = yield take(CategoryActions.GET_CATEGORIES.REQUEST);
      const {data: response} = yield call(getCategoriesService, page);

      yield put(
        CategoryActions.getCategories.success(
          response.data,
          page,
          response.count,
        ),
      );
    } catch (error) {
      // Show info
      yield put(CategoryActions.getCategories.failure(error));
    }
  }
}
