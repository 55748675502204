import * as ActionTypes from '@actions/chooseMood';

import type {MoodCuration, MoodReducer} from './types';

const initialMoodList: MoodCuration = {
  id: 0,
  name: '',
  description: '',
  photo: '',
  status: false,
  isPublic: false,
  userCuration: false,
  showOnDiscovery: false,
  showOnCategory: false,
  featuredCuration: false,
  langCode: '',
  curatorId: 0,
  curatorRole: '',
  categoryIds: [],
  createdAt: '',
  updatedAt: '',
  order: 0,
  clickCount: '',
  post_count: '',
  follower_count: '',
  categories: [],
  curator: {
    id: 0,
    fullName: '',
    firstName: '',
    lastName: '',
    userName: '',
    photo: '',
    role: '',
    jobTitle: '',
  },
};

const initialState: MoodReducer = {
  isLoading: false,
  error: false,
  errorDescription: '',
  moodCurationList: {data: [initialMoodList], count: 0},
};

export function chooseMood(
  state = initialState,
  action: any = {},
): MoodReducer {
  switch (action.type) {
    case ActionTypes.GET_MOOD_CURATIONS.REQUEST: {
      return {
        ...state,
        isLoading: true,
        error: false,
        errorDescription: '',
      };
    }
    case ActionTypes.GET_MOOD_CURATIONS.SUCCESS: {
      return {
        ...state,
        isLoading: false,
        error: false,
        errorDescription: '',
        moodCurationList: {
          data: action.stats.data,
          count: action.stats.count,
        },
      };
    }
    case ActionTypes.GET_MOOD_CURATIONS.FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: true,
        errorDescription: action.error.data?.message,
      };
    }

    case ActionTypes.ADD_CURATION_TO_MOOD.REQUEST: {
      return {
        ...state,
        isLoading: true,
        error: false,
        errorDescription: '',
      };
    }
    case ActionTypes.ADD_CURATION_TO_MOOD.SUCCESS: {
      return {
        ...state,
        isLoading: false,
        error: false,
        errorDescription: '',
      };
    }
    case ActionTypes.ADD_CURATION_TO_MOOD.FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: true,
        errorDescription: action.error.data?.message,
      };
    }

    case ActionTypes.REMOVE_CURATION_FROM_MOOD.REQUEST: {
      return {
        ...state,
        isLoading: true,
        error: false,
        errorDescription: '',
      };
    }
    case ActionTypes.REMOVE_CURATION_FROM_MOOD.SUCCESS: {
      return {
        ...state,
        isLoading: false,
        error: false,
        errorDescription: '',
      };
    }
    case ActionTypes.REMOVE_CURATION_FROM_MOOD.FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: true,
        errorDescription: action.error.data?.message,
      };
    }

    case ActionTypes.UPDATE_MOOD_ORDER.REQUEST: {
      return {
        ...state,
        isLoading: true,
        error: false,
        errorDescription: '',
      };
    }
    case ActionTypes.UPDATE_MOOD_ORDER.SUCCESS: {
      return {
        ...state,
        isLoading: false,
        error: false,
        errorDescription: '',
      };
    }
    case ActionTypes.UPDATE_MOOD_ORDER.FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: true,
        errorDescription: action.error.data?.message,
      };
    }

    default:
      return state;
  }
}
