import {take, call, put} from 'redux-saga/effects';
import type {Effect} from 'redux-saga/effects';

import {getReferrers as getReferrersService} from '@services/marketing';
import * as ReferrersActions from '@actions/marketing';

export function* searchReferrers(): Generator<Effect, void, any> {
  while (true) {
    try {
      const {offset, searchFields} = yield take(
        ReferrersActions.SEARCH_REFERRERS.REQUEST,
      );
      const {data: response} = yield call(
        getReferrersService,
        offset,
        searchFields,
      );
      yield put(
        ReferrersActions.searchReferrers.success(
          response.data,
          offset,
          response.count,
        ),
      );
    } catch (error) {
      // Show info
      yield put(ReferrersActions.searchReferrers.failure(error));
    }
  }
}
