import React, {useEffect, useState} from 'react';
import {useSelector, shallowEqual, useDispatch} from 'react-redux';
import {
  CButton,
  CButtonGroup,
  CCard,
  CCardBody,
  CCol,
  CRow,
} from '@coreui/react';
import moment from 'moment';

import {MostPost} from '../MostPost';

import {getMostInteractedPosts as getMostInteractedPostsAction} from '@actions/posts';

const typeButtonsData: Array<{
  value: string;
  key: 'today' | 'thisWeek' | 'lastMonth' | 'allTime';
}> = [
  {
    value: 'Day',
    key: 'today',
  },
  {
    value: 'Week',
    key: 'thisWeek',
  },
  {
    value: 'Month',
    key: 'lastMonth',
  },
  {
    value: 'All',
    key: 'allTime',
  },
];

export const MostInteractedPosts: React.FC = () => {
  const dispatch = useDispatch();
  const [mostPostPage, setMostPostPage] = useState(1);
  const [currentMostPostPage, setCurrentMostPostPage] = useState(0);
  const [selectedTypeButton, setSelectedTypeButton] = useState(
    typeButtonsData[0],
  );

  const {mostInteractedPosts} = useSelector(
    (state) => ({
      mostInteractedPosts: state.posts.mostInteractedPosts,
    }),
    shallowEqual,
  );

  const mostPostPageChange = (newPage: number) => {
    if (newPage === 0) newPage = 1;
    setCurrentMostPostPage(newPage);
  };

  useEffect(() => {
    dispatch(getMostInteractedPostsAction.request(selectedTypeButton.value));
  }, [selectedTypeButton.value]);

  useEffect(() => {
    if (currentMostPostPage !== undefined)
      currentMostPostPage !== mostPostPage &&
        setMostPostPage(currentMostPostPage);
  }, [currentMostPostPage, mostPostPage]);

  return (
    <CCard>
      <CCardBody>
        <br></br>
        <CRow>
          <CCol>
            <h5 id="traffic" className="card-title mb-2">
              Most Interacted Posts
            </h5>
            <div className="small text-muted">
              {moment().format('DD/MM/YYYY')}
            </div>
            <br />
          </CCol>
          <CCol className="d-none d-md-block">
            <CButtonGroup className="float-right mr-3">
              {typeButtonsData.map((item) => (
                <CButton
                  color="outline-secondary"
                  key={item.key}
                  className="mx-0"
                  active={item.key === selectedTypeButton.key}
                  onClick={() => setSelectedTypeButton(item)}>
                  {item.value}
                </CButton>
              ))}
            </CButtonGroup>
          </CCol>
        </CRow>
        <br />
        <MostPost
          page={mostPostPage}
          pageLimit={10}
          pageChange={mostPostPageChange}
          postList={mostInteractedPosts}
        />
      </CCardBody>
    </CCard>
  );
};
