import {Axios} from '@utils';
import {constants} from '@resources';

export function updateManagementUsers(updatedData: any) {
  if (updatedData.jobTitle === '') {
    updatedData.jobTitle = null;
  }
  if (updatedData.bio === '') {
    updatedData.bio = null;
  }
  if (updatedData.email === '') {
    updatedData.email = null;
  }
  if (updatedData.photo === '') {
    updatedData.photo = null;
  }

  return Axios.put(
    `${constants.USER_MANAGEMENT_BASE}/${updatedData.id}`,
    updatedData,
  );
}
