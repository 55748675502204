import {take, call, put} from 'redux-saga/effects';
import type {Effect} from 'redux-saga/effects';

import {usersOnBoardingChange as usersOnBoardingChangeService} from '@services/users';
import * as UsersActions from '@actions/users';

export function* usersOnBoardingChange(): Generator<Effect, void, any> {
  while (true) {
    try {
      const {onBoardingValue, onSuccess} = yield take(
        UsersActions.USERS_ONBOARDING_CHANGE.REQUEST,
      );
      const {data: response} = yield call(
        usersOnBoardingChangeService,
        onBoardingValue,
      );
      onSuccess();
      yield put(UsersActions.usersOnBoardingChange.success([]));
    } catch (error) {
      // Show info
      yield put(UsersActions.usersOnBoardingChange.failure(error));
    }
  }
}
