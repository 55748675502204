import {constants} from '@resources';
import {Action} from '@utils';

export const LOGIN = Action.createRequestTypes('LOGIN');
export const login = {
  request: (email: string, password: string) =>
    Action.action(LOGIN[constants.REQUEST], {email, password}),
  // FIXME: fix status to enum types ibocan@joinwiser.com ...
  success: (accessToken: string, status: string, role: string) =>
    Action.action(LOGIN[constants.SUCCESS], {accessToken, status, role}),
  failure: (error: Error) => Action.action(LOGIN[constants.FAILURE], {error}),
};

export const LOG_OUT = Action.createRequestTypes('LOG_OUT');
export const logOut = {
  request: () => Action.action(LOG_OUT[constants.REQUEST]),
  // FIXME: fix status to enum types ibocan@joinwiser.com ...
  success: (accessToken?: string) =>
    Action.action(LOG_OUT[constants.SUCCESS], {accessToken}),
  failure: (error: Error) => Action.action(LOG_OUT[constants.FAILURE], {error}),
};
