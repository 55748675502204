import {Axios} from '@utils';
import {constants} from '../../resources';

export function getWiserUsersByLocale(
  limit: number,
  offset: number,
  role: string,
  locale: string,
  searchFields?: {
    userName?: string;
    fullName?: string;
    email?: string;
    id?: string;
    status?: string;
  },
) {
  let params = '';

  if (role) {
    const roleStr = `&role=` + role;
    params = roleStr + params;
  }
  if (locale) {
    const localeStr = `&locale=` + locale;
    params = localeStr + params;
  }

  if (searchFields?.userName) {
    const userName = `&userName=` + searchFields.userName;
    params = userName + params;
  }

  if (searchFields?.fullName) {
    const fullName = `&fullName=` + searchFields.fullName;
    params = fullName + params;
  }

  if (searchFields?.email) {
    const email = `&email=` + searchFields.email;
    params = email + params;
  }

  if (searchFields?.id) {
    const id = `&id=` + searchFields.id;
    params = id + params;
  }

  if (searchFields?.status) {
    const status = `&status=` + searchFields.status;
    params = status + params;
  }

  return Axios.get(`${constants.USER_MANAGEMENT_BASE}/discovery?` + params);
}
