import {take, call, put} from 'redux-saga/effects';
import type {Effect} from 'redux-saga/effects';

import {usersStatusChange as usersStatusChangeService} from '@services/users';
import * as UsersActions from '@actions/users';

export function* usersStatusChange(): Generator<Effect, void, any> {
  while (true) {
    try {
      const {users, onSuccess} = yield take(
        UsersActions.USERS_STATUS_CHANGE.REQUEST,
      );
      const {data: response} = yield call(usersStatusChangeService, users);
      onSuccess();
      yield put(UsersActions.usersStatusChange.success([]));
    } catch (error) {
      // Show info
      yield put(UsersActions.usersStatusChange.failure(error));
    }
  }
}
