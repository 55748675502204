import {take, call, put} from 'redux-saga/effects';
import type {Effect} from 'redux-saga/effects';

import {addUserToOrderList as addUserToOrderListService} from '@services/users';
import * as UsersActions from '@actions/users';

export function* addUserToOrderList(): Generator<Effect, void, any> {
  while (true) {
    try {
      const {updatedValue, onSuccess, role} = yield take(
        UsersActions.ADD_USER_TO_ORDER_LIST.REQUEST,
      );
      const {data: response} = yield call(
        addUserToOrderListService,
        updatedValue,
      );
      onSuccess();

      yield put(
        UsersActions.getPublisherUsersByLocale.request(
          10000,
          0,
          updatedValue.role,
          updatedValue.locale,
        ),
      );

      yield put(UsersActions.addUserToOrderList.success());
    } catch (error) {
      // Show info
      yield put(UsersActions.addUserToOrderList.failure(error));
    }
  }
}
