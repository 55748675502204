import {take, call, put} from 'redux-saga/effects';
import type {Effect} from 'redux-saga/effects';

import {getAdmins as getAdminsService} from '@services/users';
import * as UsersActions from '@actions/users';

export function* getAdmins(): Generator<Effect, void, any> {
  while (true) {
    try {
      const {offset} = yield take(UsersActions.GET_ADMINS.REQUEST);
      const {data: response} = yield call(getAdminsService, offset);

      yield put(
        UsersActions.getAdmins.success(response.data, offset, response.count),
      );
    } catch (error) {
      // Show info
      yield put(UsersActions.getAdmins.failure(error));
    }
  }
}
