import {constants} from '@resources';
import {Action} from '@utils';

// export const GET_ALL_POST_STATS = Action.createRequestTypes(
//   'GET_ALL_POST_STATS',
// );
// export const getAllPostStats = {
//   request: () => Action.action(GET_ALL_POST_STATS[constants.REQUEST], null),
//   success: (stats: any) =>
//     Action.action(GET_ALL_POST_STATS[constants.SUCCESS], {stats}),
//   failure: (error: Error) =>
//     Action.action(GET_ALL_POST_STATS[constants.FAILURE], {error}),
// };

export const GET_ALL_POST_STATS = Action.createRequestTypes(
  'GET_ALL_POST_STATS',
);
export const getAllPostStats = {
  request: (interval: string, startDate: string, endDate: string) =>
    Action.action(GET_ALL_POST_STATS[constants.REQUEST], {
      interval,
      startDate,
      endDate,
    }),
  success: (stats: any) =>
    Action.action(GET_ALL_POST_STATS[constants.SUCCESS], {stats}),
  failure: (error: Error) =>
    Action.action(GET_ALL_POST_STATS[constants.FAILURE], {error}),
};

export const GET_LAST_SHARED_POSTS = Action.createRequestTypes(
  'GET_LAST_SHARED_POSTS',
);
export const getLastSharedPosts = {
  request: () => Action.action(GET_LAST_SHARED_POSTS[constants.REQUEST], null),
  success: (posts: any) =>
    Action.action(GET_LAST_SHARED_POSTS[constants.SUCCESS], {posts}),
  failure: (error: Error) =>
    Action.action(GET_LAST_SHARED_POSTS[constants.FAILURE], {error}),
};

export const GET_MOST_INTERACTED_POSTS = Action.createRequestTypes(
  'GET_MOST_INTERACTED_POSTS',
);
export const getMostInteractedPosts = {
  request: (dateType: string) =>
    Action.action(GET_MOST_INTERACTED_POSTS[constants.REQUEST], {
      dateType,
    }),
  success: (posts: any) =>
    Action.action(GET_MOST_INTERACTED_POSTS[constants.SUCCESS], {posts}),
  failure: (error: Error) =>
    Action.action(GET_MOST_INTERACTED_POSTS[constants.FAILURE], {error}),
};

export const GET_POSTS = Action.createRequestTypes('GET_POSTS');
export const getPosts = {
  request: (
    limit: number,
    page: number,
    searchFields?: any,
    forCurationListId?: number,
  ) =>
    Action.action(GET_POSTS[constants.REQUEST], {
      limit,
      page,
      searchFields,
      forCurationListId,
    }),
  success: (posts: any, page: number, codeCount: number) =>
    Action.action(GET_POSTS[constants.SUCCESS], {
      posts,
      page,
      codeCount,
    }),
  failure: (error: Error) =>
    Action.action(GET_POSTS[constants.FAILURE], {error}),
};

export const DELETE_POSTS = Action.createRequestTypes('DELETE_POSTS');
export const deletePosts = {
  request: (codeId: number, onSucess: Function) =>
    Action.action(DELETE_POSTS[constants.REQUEST], {
      codeId,
      onSucess,
    }),
  success: (codeId: number) =>
    Action.action(DELETE_POSTS[constants.SUCCESS], {codeId}),
  failure: (error: Error) =>
    Action.action(DELETE_POSTS[constants.FAILURE], {error}),
};

export const GET_POST_DETAIL = Action.createRequestTypes('GET_POST_DETAIL');
export const getPostDetail = {
  request: (postId: number) =>
    Action.action(GET_POST_DETAIL[constants.REQUEST], {postId}),
  success: (postDetail: any) =>
    Action.action(GET_POST_DETAIL[constants.SUCCESS], {
      postDetail,
    }),
  failure: (error: Error) =>
    Action.action(GET_POST_DETAIL[constants.FAILURE], {error}),
};

export const UPDATE_POST = Action.createRequestTypes('UPDATE_POST');
export const updatePost = {
  request: (updatePost: any, furtherReadings: Array<any>, onSucess: Function) =>
    Action.action(UPDATE_POST[constants.REQUEST], {
      updatePost,
      furtherReadings,
      onSucess,
    }),
  success: (uptadedPost: any) =>
    Action.action(UPDATE_POST[constants.SUCCESS], {uptadedPost}),
  failure: (error: Error) =>
    Action.action(UPDATE_POST[constants.FAILURE], {error}),
};

export const CREATE_POST = Action.createRequestTypes('CREATE_POST');
export const createPost = {
  request: (
    post: any,
    checkedCLs: Array<any>,
    checkedPosts: Array<any>,
    id: number | undefined,
    onSucess: Function,
  ) =>
    Action.action(CREATE_POST[constants.REQUEST], {
      post,
      checkedCLs,
      checkedPosts,
      id,
      onSucess,
    }),
  success: (createdPost: any) =>
    Action.action(CREATE_POST[constants.SUCCESS], {createdPost}),
  failure: (error: Error) =>
    Action.action(CREATE_POST[constants.FAILURE], {error}),
};

export const GET_CATEGORIES_POSTS = Action.createRequestTypes(
  'GET_CATEGORIES_POSTS',
);
export const getCategoriesPosts = {
  request: (page: number, categoriesId: number) =>
    Action.action(GET_CATEGORIES_POSTS[constants.REQUEST], {
      page,
      categoriesId,
    }),
  success: (posts: any, page: number, postsCount: number) =>
    Action.action(GET_CATEGORIES_POSTS[constants.SUCCESS], {
      posts,
      page,
      postsCount,
    }),
  failure: (error: Error) =>
    Action.action(GET_CATEGORIES_POSTS[constants.FAILURE], {error}),
};

export const DELETE_CATEGORIES_POSTS = Action.createRequestTypes(
  'DELETE_CATEGORIES_POSTS',
);
export const deleteCategoriesPosts = {
  request: (deletePostId: number, categoryId: number, onSucess: Function) =>
    Action.action(DELETE_CATEGORIES_POSTS[constants.REQUEST], {
      deletePostId,
      categoryId,
      onSucess,
    }),
  success: (deletePostId: number) =>
    Action.action(DELETE_CATEGORIES_POSTS[constants.SUCCESS], {deletePostId}),
  failure: (error: Error) =>
    Action.action(DELETE_CATEGORIES_POSTS[constants.FAILURE], {error}),
};

export const ADD_CATEGORIES_POSTS = Action.createRequestTypes(
  'ADD_CATEGORIES_POSTS',
);
export const addCategoriesPosts = {
  request: (postId: any, categoryId: number, onSucess: Function) =>
    Action.action(ADD_CATEGORIES_POSTS[constants.REQUEST], {
      postId,
      categoryId,
      onSucess,
    }),
  success: (addedPost: any) =>
    Action.action(ADD_CATEGORIES_POSTS[constants.SUCCESS], {addedPost}),
  failure: (error: Error) =>
    Action.action(ADD_CATEGORIES_POSTS[constants.FAILURE], {error}),
};

export const GET_POST_COMMENTS = Action.createRequestTypes('GET_POST_COMMENTS');
export const getPostComments = {
  request: (postId: number, page: number) =>
    Action.action(GET_POST_COMMENTS[constants.REQUEST], {
      postId,
      page,
    }),
  success: (postComments: any, page: number) =>
    Action.action(GET_POST_COMMENTS[constants.SUCCESS], {postComments, page}),
  failure: (error: Error) =>
    Action.action(GET_POST_COMMENTS[constants.FAILURE], {error}),
};

export const DELETE_POST_COMMENT = Action.createRequestTypes(
  'DELETE_POST_COMMENT',
);
export const deletePostComment = {
  request: (postId: number, commentId: number, onSucess: Function) =>
    Action.action(DELETE_POST_COMMENT[constants.REQUEST], {
      postId,
      commentId,
      onSucess,
    }),
  success: (commentId: number) =>
    Action.action(DELETE_POST_COMMENT[constants.SUCCESS], {commentId}),
  failure: (error: Error) =>
    Action.action(DELETE_POST_COMMENT[constants.FAILURE], {error}),
};

export const SEARCH_POSTS = Action.createRequestTypes('SEARCH_POSTS');
export const searchPosts = {
  request: (
    limit: number,
    page: number,
    searchFields: {
      categoryIds?: any;
      userId?: number;
      startDate?: string;
      endDate?: string;
      type?: string;
    },
    forCurationListId?: number,
  ) =>
    Action.action(SEARCH_POSTS[constants.REQUEST], {
      limit,
      page,
      searchFields,
      forCurationListId,
    }),
  success: (posts: any, page: number, codeCount: number) =>
    Action.action(SEARCH_POSTS[constants.SUCCESS], {
      posts,
      page,
      codeCount,
    }),
  failure: (error: Error) =>
    Action.action(SEARCH_POSTS[constants.FAILURE], {error}),
};

export const SEARCH_POSTS_FOR_USER = Action.createRequestTypes(
  'SEARCH_POSTS_FOR_USER',
);
export const searchPostsForUser = {
  request: (
    limit: number,
    page: number,
    searchFields: {
      categoryIds?: any;
      userId?: number;
      startDate?: string;
      endDate?: string;
      type?: string;
    },
    forCurationListId?: number,
  ) =>
    Action.action(SEARCH_POSTS_FOR_USER[constants.REQUEST], {
      limit,
      page,
      searchFields,
      forCurationListId,
    }),
  success: (posts: any, page: number, codeCount: number) =>
    Action.action(SEARCH_POSTS_FOR_USER[constants.SUCCESS], {
      posts,
      page,
      codeCount,
    }),
  failure: (error: Error) =>
    Action.action(SEARCH_POSTS_FOR_USER[constants.FAILURE], {error}),
};

export const GET_UNCATEGORISED_POSTS = Action.createRequestTypes(
  'GET_UNCATEGORISED_POSTS',
);
export const getUncategorisedPosts = {
  request: () => Action.action(GET_UNCATEGORISED_POSTS[constants.REQUEST]),
  success: (uncategorisedPosts: any, codeCount: number) =>
    Action.action(GET_UNCATEGORISED_POSTS[constants.SUCCESS], {
      uncategorisedPosts,
      codeCount,
    }),
  failure: (error: Error) =>
    Action.action(GET_UNCATEGORISED_POSTS[constants.FAILURE], {error}),
};
