import {Axios} from '@utils';
import {constants} from '@resources';

export function deleteCategoriesPosts(
  deletePostId: number,
  categoryId: number,
) {
  return Axios.delete(
    `${constants.CATEGORY_BASE}/${categoryId}/posts/${deletePostId}`,
  );
}
