import {Axios} from '@utils';
import {constants} from '@resources';

export function getReferrers(
  offset: number,
  filterFields?: {
    id?: string;
    userName?: string;
    fullName?: string;
  },
) {
  if (filterFields) {
    return Axios.get(
      `${constants.MANAGEMENT_BASE}/referrers?limit=10&offset=${offset}&id=${
        filterFields.id || ''
      }&userName=${filterFields.userName || ''}&fullName=${
        filterFields.fullName
      }`,
    );
  }

  return Axios.get(
    `${constants.MANAGEMENT_BASE}/referrers?limit=10&offset=${offset}`,
  );
}
