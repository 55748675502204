import {take, call, put} from 'redux-saga/effects';
import type {Effect} from 'redux-saga/effects';

// import {checkCurationCode as checkCurationCodeService} from '@services/curationList';
import * as CurationActions from '@actions/curation';

export function* checkCurationCode(): Generator<Effect, void, any> {
  while (true) {
    try {
      const {curationCode, onSucess} = yield take(
        CurationActions.CHECK_CURATION_CODE.REQUEST,
      );
      // const {data: response} = yield call(
      //   checkCurationCodeService,
      //   curationCode.name,
      // );
      yield put(CurationActions.createCuration.request(curationCode, onSucess));
      // if (response.data.usable) {
      // }
    } catch (error) {
      // Show info
      yield put(CurationActions.checkCurationCode.failure(error));
    }
  }
}
