import {take, call, put} from 'redux-saga/effects';
import type {Effect} from 'redux-saga/effects';

import {getCurationDetail as getCurationDetailService} from '@services/curationList';
import * as CurationActions from '@actions/curation';

export function* getCurationDetail(): Generator<Effect, void, any> {
  while (true) {
    try {
      const {curationId} = yield take(
        CurationActions.GET_CURATION_DETAIL.REQUEST,
      );
      const {data: response} = yield call(getCurationDetailService, curationId);
      yield put(CurationActions.getCurationDetail.success(response));
    } catch (error) {
      // Show info
      yield put(CurationActions.getCurationDetail.failure(error));
    }
  }
}
