import React, {ChangeEvent, useState} from 'react';
import {useSelector, shallowEqual, useDispatch} from 'react-redux';
import {CCard, CCardBody, CCol, CInput, CLabel, CRow} from '@coreui/react';
import moment from 'moment';

import {getLastRegisteredUsers as getLastRegisteredUsersAction} from '@actions/users';

import {UserList} from './components/UserList';

let startDate: string;
let endDate: string;

startDate = '';
endDate = '';

export const LastRegisteredUsers: React.FC = () => {
  const dispatch = useDispatch();
  const [registeredUsersPage, setRegisteredUsersPage] = useState(1);

  const registeredUserPageChange = (newPage: number) => {
    if (newPage == 0) newPage = 1;
    else {
      setRegisteredUsersPage(newPage);
    }
  };

  const {lastRegisteredUsers} = useSelector(
    (state) => ({
      lastRegisteredUsers: state.users.lastRegisteredUsers,
    }),
    shallowEqual,
  );

  const handleStartDateChange = async (
    event: ChangeEvent<HTMLInputElement>,
  ) => {
    setRegisteredUsersPage(1);
    startDate = moment(event.target.value).format('YYYY-MM-DD');
    await getData(startDate, endDate);
  };

  const handleEndDateChange = async (event: ChangeEvent<HTMLInputElement>) => {
    setRegisteredUsersPage(1);
    endDate = moment(event.target.value).format('YYYY-MM-DD');
    getData(startDate, endDate);
  };

  const getData = async (startDate: string, endDate: string) => {
    if (
      endDate <= startDate ||
      (startDate !== undefined && endDate !== undefined)
    ) {
      dispatch(getLastRegisteredUsersAction.request(startDate, endDate));
    }
  };

  return (
    <CCard>
      <CCardBody>
        <br />
        <CRow>
          <CCol>
            <h5 id="traffic" className="card-title mb-2">
              Last Registered Users
            </h5>
            <div className="small text-muted">
              {moment().format('DD/MM/YYYY')}
            </div>
          </CCol>
          <CCol>
            <CLabel>Start Date</CLabel>
            <CInput
              className="form-control"
              type="date"
              placeholder="Start Date"
              onChange={handleStartDateChange}
            />
          </CCol>
          <CCol>
            <CLabel>End Date</CLabel>
            <CInput
              className="form-control"
              type="date"
              placeholder="Start Date"
              onChange={handleEndDateChange}
            />
          </CCol>
        </CRow>
        <br />
        <UserList
          page={registeredUsersPage}
          pageChange={registeredUserPageChange}
          userList={lastRegisteredUsers}
        />
      </CCardBody>
    </CCard>
  );
};
