import {Axios} from '@utils';
import {constants} from '@resources';

export function updateUsers(updatedData: any) {
  const parameters = {
    role: updatedData.role,
  };
  return Axios.put(
    `${constants.USER_MANAGEMENT_BASE}/${updatedData.id}`,
    parameters,
  );
}
