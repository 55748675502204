import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {
  CCard,
  CCardHeader,
  CCol,
  CDataTable,
  CRow,
  CButton,
  CModal,
  CModalBody,
  CModalHeader,
  CModalTitle,
  CModalFooter,
  CInputCheckbox,
} from '@coreui/react';

import {getCategories as getCategoriesAction} from '@actions/categories';
import {updatePost as updatePostAction} from '@actions/posts';

import type {ChangeEvent} from 'react';

type Props = {
  setCategoryModal: Function;
  setSelectedCategoriesUP: Function;
  categoryModal: boolean;
  post: any;
};
const AddCategory: React.FC<Props> = (props: Props) => {
  const dispatch = useDispatch();
  const {
    categoryModal,
    setCategoryModal,
    post,
    setSelectedCategoriesUP,
  } = props;

  const {categoryList} = useSelector((state) => ({
    categoryList: state.categories.categoriesList,
  }));

  const [selectedCategories, setSelectedCategories] = useState<Array<any>>([]);

  const handleOnRowClick = (event: ChangeEvent<HTMLInputElement>) => {
    if (selectedCategories && selectedCategories.includes(event)) {
      setSelectedCategories((prevState) =>
        prevState.filter((item) => item !== event),
      );
    } else {
      setSelectedCategories((prevState) =>
        prevState.length < 3 ? [...prevState, event] : prevState,
      );
    }
  };

  const handleDone = () => {
    const selectedCategoriesIDs = selectedCategories.map((i) => i.id);
    const selectedCategoryNames = categoryList.categories
      .filter((item) => selectedCategoriesIDs.includes(item.id))
      .map((n) => n.name_translate.en);
    setSelectedCategoriesUP((prevState: any) => {
      if (prevState) {
        return [
          ...prevState,
          {
            postId: post.id,
            categories: selectedCategoryNames,
          },
        ];
      } else {
        return [
          {
            postId: post.id,
            categories: selectedCategoryNames,
          },
        ];
      }
    });
    dispatch(
      updatePostAction.request(
        {...post, categoryIds: selectedCategoriesIDs},
        [],
        closeModal,
      ),
    );
  };

  const closeModal = () => {
    setSelectedCategories([]);
    setCategoryModal(false);
  };

  const handleCancel = () => {
    setCategoryModal(false);
  };

  // useEffect(() => {
  //   setSelectedCategories(categories);
  // }, [categories]);

  useEffect(() => {
    dispatch(getCategoriesAction.request(1));
  }, []);

  return (
    <CModal show={categoryModal} size="lg" onClose={setCategoryModal}>
      <CModalHeader>
        <CModalTitle>
          All Categories({categoryList.categoriesCount})
        </CModalTitle>
        <CModalTitle>Select up to 3</CModalTitle>
      </CModalHeader>
      <CModalBody>
        <CRow>
          <CCol xl={12}>
            <CCard>
              <CCardHeader>Categories</CCardHeader>
              <CDataTable
                items={categoryList.categories}
                fields={[
                  'id',
                  {
                    key: 'name',
                    label: 'Name',
                  },
                  'status',
                  {
                    key: 'select_row',
                    label: '',
                    _style: {width: '5%'},
                    sorter: false,
                    filter: false,
                  },
                ]}
                hover
                onRowClick={(event: ChangeEvent<HTMLInputElement>) => {
                  handleOnRowClick(event);
                }}
                border
                size="sm"
                striped
                clickableRows
                scopedSlots={{
                  name: (item: any) => (
                    <td key={item.id} className="py-2">
                      {item.name_translate.en}
                    </td>
                  ),
                  select_row: (item: any) => {
                    const isChecked =
                      selectedCategories &&
                      selectedCategories.find((a: any) => a.id === item.id);
                    return (
                      <td key={item.id} className="py-2">
                        <CInputCheckbox
                          key={item.id}
                          name={item.id}
                          disabled={
                            !isChecked &&
                            selectedCategories &&
                            selectedCategories.length > 2
                          }
                          className="checkbox"
                          checked={isChecked}
                        />
                      </td>
                    );
                  },
                }}
              />
            </CCard>
          </CCol>
        </CRow>
      </CModalBody>
      <CModalFooter className="d-flex justify-content-between">
        <CButton className="col-3" onClick={handleCancel} color="danger">
          Cancel
        </CButton>
        <CButton className="col-3" onClick={handleDone} color="success">
          Done
        </CButton>
      </CModalFooter>
    </CModal>
  );
};

export default AddCategory;
