import {take, call, put} from 'redux-saga/effects';
import type {Effect} from 'redux-saga/effects';

import {getAllUsers as getAllUsersService} from '@services/users';
import * as CurationActions from '@actions/users';

export function* getAllUsers(): Generator<Effect, void, any> {
  while (true) {
    try {
      const {limit, offset, role, status, searchFields, locale} = yield take(
        CurationActions.GET_ALL_USERS.REQUEST,
      );

      const {data: response} = yield call(
        getAllUsersService,
        limit,
        offset,
        role,
        status,
        searchFields,
        locale,
      );

      yield put(
        CurationActions.getAllUsers.success(
          response.data,
          offset,
          response.count,
        ),
      );
    } catch (error) {
      // Show info
      yield put(CurationActions.getAllUsers.failure(error));
    }
  }
}
