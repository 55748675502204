import React, {ChangeEvent, useEffect, useState} from 'react';

import {
  CCard,
  CCardBody,
  CCol,
  CDataTable,
  CRow,
  CPagination,
} from '@coreui/react';

type Props = {
  items?: Array<any>;
  itemsCount: number;
  fields: Array<any>;
  pageLimit: number;
  page: number;
  pageChange?: Function;
  scopedSlots: any;
  onClick?: Function;
};

let pagesCountCalculate = 0;
export const WSRDataTable: React.FC<Props> = (props) => {
  const {
    items,
    fields,
    pageLimit,
    page,
    pageChange,
    scopedSlots,
    itemsCount,
    onClick,
  } = props;

  const [pagesCount, setPagesCount] = useState<number>(0);

  const calculatePages = (count: number) => {
    if (pageLimit === 50) {
      pagesCountCalculate = Math.floor(count / 50 + 1);
    } else if (pageLimit === 20) {
      pagesCountCalculate = Math.floor(count / 20 + 1);
    } else if (count < itemsCount) {
      pagesCountCalculate = count / 10 + 1;
    } else if (itemsCount === count) {
      pagesCountCalculate = Math.ceil(count / 10);
    }

    setPagesCount(pagesCountCalculate);
    return pagesCountCalculate;
  };

  useEffect(() => {
    calculatePages(itemsCount);
  }, [pageLimit, itemsCount]);
  return (
    <>
      <CRow>
        <CCol xl={12}>
          <CCard>
            <CCardBody>
              <CDataTable
                items={items}
                fields={fields}
                hover
                border
                size="sm"
                striped
                outlined
                itemsPerPage={pageLimit}
                activePage={1}
                clickableRows
                scopedSlots={scopedSlots}
                onRowClick={(event: ChangeEvent<HTMLInputElement>) => {
                  onClick?.(event);
                }}
              />
              {pagesCount > 0 && (
                <CPagination
                  dots={false}
                  activePage={page}
                  onActivePageChange={pageChange ? pageChange : () => {}}
                  pages={pagesCount}
                  doubleArrows={false}
                  align="center"
                />
              )}
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
    </>
  );
};
