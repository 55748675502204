import React, {useEffect, ChangeEvent} from 'react';
import {
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CRow,
  CForm,
  CTextarea,
  CButton,
  CFormGroup,
  CLabel,
  CInput,
  CSelect,
  CAlert,
  CInputGroup,
  CInputGroupAppend,
  CInputGroupText,
} from '@coreui/react';
import {useState} from 'react';
import {useHistory} from 'react-router-dom';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import {images} from '../../resources';
import {FormValidation} from '../../utils';
import 'react-image-crop/dist/ReactCrop.css';
import {TextInput, DeletePhotoButton} from '../../components';
import CIcon from '@coreui/icons-react';
import {uploadPhoto} from '../../services/utils';

import {
  getUsersDetail as getUsersDetailAction,
  updateManagementUsers as updateManagementUserAction,
  deleteManagementUsers as deleteManagementUsersAction,
} from '@actions/users';
import {WSRModal, WSRUploadPhoto} from '../../components';

const initialUserData = {
  id: 0,
  registrationMethod: '',
  userName: '',
  firstName: '',
  lastName: '',
  email: '',
  //password: '',
  photo: '',
  jobTitle: '',
  bio: '',
  telephone: '',
  role: '',
};

type Props = {match: any};

const EditorialUpdatePage: React.FC<Props> = (props) => {
  const {match} = props;
  const [user, setUser] = useState(initialUserData);
  const [alert, setAlert] = useState(false);
  const [alertMsg, setAlertMsg] = useState('');
  const dispatch = useDispatch();
  const history = useHistory();
  const [croppedImageUrl, setCroppedImageUrl] = useState('');
  const [modal, setModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [deletedWarningModal, setDeletedWarningModal] = useState(false);
  const [uptadedWarningModal, setUptadedWarningModal] = useState(false);
  const [isEmailValid, setIsEmailValid] = useState<
    boolean | typeof undefined
  >();

  const [isValidName, setIsValidName] = useState<boolean | typeof undefined>();
  const [isUserNameValid, setIsUserNameValid] = useState<
    boolean | typeof undefined
  >();
  const [checkSaveButton, setCheckSaveButton] = useState(false);

  const {userDetail} = useSelector(
    (state) => ({
      userDetail: state.users.usersDetail,
    }),
    shallowEqual,
  );

  const onSubmit = (event: ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();

    const updateData = {...user};
    Object.keys(updateData).map((item) => {
      // if (updateData[item] === initialUser[item]) {
      //   delete updateData[item];
      // }
    });

    dispatch(
      updateManagementUserAction.request(updateData, () => {
        setUptadedWarningModal(true);
      }),
    );
  };

  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    event.persist();
    setUser((user) => ({
      ...user,
      [event.target.name]: event.target.value,
    }));
  };

  const onChangeEmail = (event: ChangeEvent<HTMLInputElement>) => {
    FormValidation.validateEmail(event.target.value)
      ? setIsEmailValid(true)
      : setIsEmailValid(false);
    event.persist();
    setUser((user) => ({
      ...user,
      [event.target.name]: event.target.value,
    }));
  };

  const onChangeFullName = (event: ChangeEvent<HTMLInputElement>) => {
    event.target.value.length > 3 ||
    (user.firstName.length > 3 && user.firstName !== ' ')
      ? setIsValidName(true)
      : setIsValidName(false);
    event.persist();
    setUser((user) => ({
      ...user,
      [event.target.name]: event.target.value,
    }));
  };

  const onChangeUserName = (event: ChangeEvent<HTMLInputElement>) => {
    event.target.value.length > 3 ||
    (user.userName.length > 3 && user.userName === ' ')
      ? setIsUserNameValid(true)
      : setIsUserNameValid(false);
    event.persist();
    setUser((user) => ({
      ...user,
      [event.target.name]: event.target.value,
    }));
  };

  const saveImage = () => {
    uploadPhoto(croppedImageUrl)
      .then((response) => {
        // setPhoto(response.data.data.url);
        setUser((user) => ({
          ...user,
          photo: response.data.data.url,
        }));
      })
      .catch((err) => {
        console.log('hata', err);
      });
    setModal(false);
  };

  const deletePhotoButton = () => {
    setUser((user) => ({...user, photo: ''}));
    setModal(false);
  };

  const handleDelete = () => {
    setDeleteModal(false);
    dispatch(
      deleteManagementUsersAction.request(user.id, () => {
        setDeletedWarningModal(true);
      }),
    );
  };

  const handleCancel = () => {
    history.push({
      pathname: `/editorialDetail/${user.id}`,
    });
  };

  useEffect(() => {
    if (match.params.id !== undefined) {
      dispatch(getUsersDetailAction.request(match.params.id));
      setUser(userDetail);
    }
  }, []);

  useEffect(() => {
    user.userName.length > 3 && user.firstName.length > 3
      ? setCheckSaveButton(true)
      : setCheckSaveButton(false);
  });

  return (
    <>
      <CForm onSubmit={onSubmit}>
        <CRow>
          <CCol sm={12} lg={3}>
            <CAlert
              show={alert}
              color="info"
              closeButton
              style={{marginTop: '15px'}}>
              {alertMsg}
            </CAlert>
            <CCard className="user-profile-card">
              <CCardHeader className="user-profile-card">
                <div className="my-4">
                  <img
                    className="c-avatar-user"
                    src={user.photo! || images.avatar.toString()}
                    alt=""></img>
                </div>
                <div className="text-center">
                  <div className="text-uppercase font-weight-bold">
                    {user && user.firstName}
                  </div>
                  <div className="text-muted">{user && user.jobTitle}</div>
                </div>
                <CFormGroup>
                  <br></br>
                  <CButton
                    className="mfs-auto"
                    style={{width: '100%'}}
                    color="secondary"
                    onClick={() => {
                      setModal(true);
                    }}>
                    Change Photo
                  </CButton>
                  {user.photo !== undefined ||
                  user.photo !== '' ||
                  user.photo !== null ? (
                    <DeletePhotoButton
                      deletePhotoButton={deletePhotoButton}></DeletePhotoButton>
                  ) : null}
                </CFormGroup>
              </CCardHeader>
              <CCardBody>
                <small className="text-muted font-weight-bold">Bio</small>
                <br />
                <small className="text-muted">{user.bio}</small>
              </CCardBody>
            </CCard>
            <CButton
              className="my-2 mfs-auto"
              block
              onClick={() => setDeleteModal(true)}
              color="danger">
              Delete this {user.role}
            </CButton>
          </CCol>
          <CCol sm={12} lg={9}>
            <CCard>
              <CCardHeader>
                User
                <small> Form</small>
              </CCardHeader>
              <CCardBody>
                <CFormGroup>
                  <TextInput
                    field="firstName"
                    type="text"
                    value={user.firstName}
                    name="firstName"
                    placeholder="First Name"
                    autoComplete="firstname"
                    valid={isValidName}
                    htmlFor="firstName"
                    htmlForField="First Name"
                    warningText="Please check your name"
                    onChange={onChangeFullName}
                    required
                  />
                </CFormGroup>
                <CFormGroup>
                  <TextInput
                    field="lastName"
                    type="text"
                    value={user.lastName}
                    name="lastName"
                    placeholder="Last Name"
                    autoComplete="lastname"
                    valid={isValidName}
                    htmlFor="lastName"
                    htmlForField="Last Name"
                    warningText="Please check your name"
                    onChange={onChangeFullName}
                    required
                  />
                </CFormGroup>
                {user.role === 'postUser' ? null : (
                  <CFormGroup>
                    <TextInput
                      field="email"
                      type="email"
                      value={user.email}
                      name="email"
                      placeholder="Email"
                      autoComplete="email"
                      onChange={onChangeEmail}
                      valid={undefined}
                      htmlFor="email"
                      htmlForField="Email"
                      warningText="Please check your email"
                      required={false}
                    />
                  </CFormGroup>
                )}

                <CFormGroup>
                  <TextInput
                    field="userName"
                    type="text"
                    value={user.userName}
                    name="userName"
                    placeholder="User Name"
                    autoComplete="username"
                    valid={isUserNameValid}
                    htmlFor="username"
                    htmlForField="User Name"
                    warningText="Please check your username"
                    onChange={onChangeUserName}
                    required
                  />
                </CFormGroup>
                {user.role === 'curator' ? (
                  <CFormGroup>
                    <CLabel htmlFor="password">Password</CLabel>
                    <CInputGroup>
                      <CInput
                        className="form-control-warning"
                        type="password"
                        //value={user.password}
                        name="password"
                        placeholder="Password"
                        onChange={onChange}
                      />
                      <CInputGroupAppend>
                        <CInputGroupText>
                          <CIcon name="cil-asterisk" />
                        </CInputGroupText>
                      </CInputGroupAppend>
                    </CInputGroup>
                  </CFormGroup>
                ) : null}
                <CFormGroup row className="my-0">
                  <CCol xs="8">
                    <CFormGroup>
                      <CLabel htmlFor="jobTitle">Job Title</CLabel>
                      <CInput
                        className="form-control-warning"
                        type="text"
                        value={user.jobTitle}
                        name="jobTitle"
                        placeholder="Job Title"
                        onChange={onChange}
                      />
                    </CFormGroup>
                  </CCol>
                  <CCol xs="4">
                    <CFormGroup>
                      <CLabel htmlFor="role">Role</CLabel>
                      <CSelect
                        value={user.role}
                        name="role"
                        id="role"
                        onChange={onChange}>
                        <option value="postUser">Post User</option>
                        <option value="curator">Curator</option>
                      </CSelect>
                    </CFormGroup>
                  </CCol>
                </CFormGroup>
                <CFormGroup row className="my-0"></CFormGroup>
                <CFormGroup>
                  <CLabel htmlFor="bio">Bio</CLabel>
                  <CTextarea
                    className="form-control-warning"
                    type="textarea-input"
                    value={user.bio}
                    name="bio"
                    placeholder="Bio"
                    onChange={onChange}
                  />
                </CFormGroup>
                <div className="my-2 mfs-auto form-actions">
                  <CButton
                    type="submit"
                    color="primary"
                    disabled={!checkSaveButton}>
                    Save changes
                  </CButton>
                  <CButton onClick={handleCancel} color="secondary">
                    Cancel
                  </CButton>
                </div>
              </CCardBody>
            </CCard>

            <WSRUploadPhoto
              onShowModal={modal}
              setModal={setModal}
              setUserImage={saveImage}
              croppedImageUrl={croppedImageUrl}
              setCroppedImageUrl={setCroppedImageUrl}
            />

            <WSRModal
              onShowModal={deletedWarningModal}
              setOnShowModal={setDeletedWarningModal}
              modalTitle={'Delete Action'}
              modalBody={'User has been deleted successfully!'}
              operationButton={() => history.push(`/${user.role}s`)}
              operationButtonText={'OK'}
            />

            <WSRModal
              onShowModal={uptadedWarningModal}
              setOnShowModal={setUptadedWarningModal}
              modalTitle={'Update Action'}
              modalBody={'User has been updated successfully!'}
              operationButton={() => setUptadedWarningModal(false)}
              operationButtonText={'OK'}
            />

            <WSRModal
              onShowModal={deleteModal}
              setOnShowModal={setDeleteModal}
              modalTitle={'Delete Action'}
              modalBody={`Are you sure that you want to delete this user?`}
              operationButton={() => handleDelete()}
              operationButtonText={'Yes, remove it!'}
              cancelButton={() => setDeleteModal(false)}
              cancelButtonText={'Cancel'}
            />
          </CCol>
        </CRow>
      </CForm>
    </>
  );
};

export default EditorialUpdatePage;
