import {take, call, put} from 'redux-saga/effects';
import type {Effect} from 'redux-saga/effects';

import {updateDiscoveryOrder as updateDiscoveryOrderService} from '@services/curationList';
import * as CurationActions from '@actions/curation';

export function* updateDiscoveryOrder(): Generator<Effect, void, any> {
  while (true) {
    try {
      const {updatedList, locale} = yield take(
        CurationActions.UPDATE_DISCOVERY_ORDER.REQUEST,
      );
      const {data: response} = yield call(
        updateDiscoveryOrderService,
        updatedList,
        locale,
      );

      // yield put(
      //   CurationActions.getCurationsList.success(
      //     response.data,
      //     0,
      //     response.count,
      //   ),
      // );
    } catch (error) {
      // Show info
      yield put(CurationActions.updateDiscoveryOrder.failure(error));
    }
  }
}
