import React, {useState} from 'react';
import {useSelector, shallowEqual} from 'react-redux';
import {CCard, CCardBody, CCol, CRow} from '@coreui/react';
import moment from 'moment';

import {UserList} from '../UserList';

export const LastActiveUsers: React.FC = () => {
  const [activeUsersPage, setActiveUsersPage] = useState(1);

  const {lastActiveUsers} = useSelector(
    (state) => ({
      lastActiveUsers: state.users.lastActiveUsers,
    }),
    shallowEqual,
  );

  const activeUserPageChange = (newPage: number) => {
    if (newPage === 0) newPage = 1;
    else {
      setActiveUsersPage(newPage);
    }
  };

  return (
    <CCard>
      <CCardBody>
        <br />
        <CRow>
          <CCol>
            <h5 id="traffic" className="card-title mb-2">
              Last Active Users
            </h5>
            <div className="small text-muted">
              {moment().format('DD/MM/YYYY')}
            </div>
          </CCol>
        </CRow>
        <br />
        <UserList
          page={activeUsersPage}
          pageLimit={5}
          pageChange={activeUserPageChange}
          userList={lastActiveUsers}
        />
      </CCardBody>
    </CCard>
  );
};
