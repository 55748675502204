import {constants} from '@resources';
import {Action} from '@utils';

export const GET_TAGS = Action.createRequestTypes('GET_TAGS');
export const getTags = {
  request: (searchFields?: any) =>
    Action.action(GET_TAGS[constants.REQUEST], {searchFields}),
  success: (stats: any) => Action.action(GET_TAGS[constants.SUCCESS], {stats}),
  failure: (error: Error) =>
    Action.action(GET_TAGS[constants.FAILURE], {error}),
};

export const SEARCH_TAGS = Action.createRequestTypes('SEARCH_TAGS');
export const searchTags = {
  request: (name: string) => Action.action(SEARCH_TAGS[constants.REQUEST], {}),
  success: (stats: any) =>
    Action.action(SEARCH_TAGS[constants.SUCCESS], {stats}),
  failure: (error: Error) =>
    Action.action(SEARCH_TAGS[constants.FAILURE], {error}),
};

export const DELETE_TAG = Action.createRequestTypes('DELETE_TAG');
export const deleteTag = {
  request: (tagId: number) =>
    Action.action(DELETE_TAG[constants.REQUEST], {tagId}),
  success: (stats: any) =>
    Action.action(DELETE_TAG[constants.SUCCESS], {stats}),
  failure: (error: Error) =>
    Action.action(DELETE_TAG[constants.FAILURE], {error}),
};

export const UPDATE_TAG = Action.createRequestTypes('UPDATE_TAG');
export const updateTag = {
  request: (tagId: number, categoryId: number, searchFields?: any) =>
    Action.action(UPDATE_TAG[constants.REQUEST], {
      tagId,
      categoryId,
      searchFields,
    }),
  success: (stats: any) =>
    Action.action(UPDATE_TAG[constants.SUCCESS], {stats}),
  failure: (error: Error) =>
    Action.action(UPDATE_TAG[constants.FAILURE], {error}),
};

export const CREATE_TAG = Action.createRequestTypes('CREATE_TAG');
export const createTag = {
  request: (name: string) =>
    Action.action(CREATE_TAG[constants.REQUEST], {name}),
  success: (stats: any) =>
    Action.action(CREATE_TAG[constants.SUCCESS], {stats}),
  failure: (error: Error) =>
    Action.action(CREATE_TAG[constants.FAILURE], {error}),
};

export const REMOVE_CATEGORY_FROM_TAG = Action.createRequestTypes(
  'REMOVE_CATEGORY_FROM_TAG',
);
export const removeCategoryFromTag = {
  request: (
    tagId: number,
    categoryId: number,
    searchFields?: any,
    oldCategoryId?: number,
  ) =>
    Action.action(REMOVE_CATEGORY_FROM_TAG[constants.REQUEST], {
      tagId,
      categoryId,
      searchFields,
      oldCategoryId,
    }),
  success: (stats: any) =>
    Action.action(REMOVE_CATEGORY_FROM_TAG[constants.SUCCESS], {stats}),
  failure: (error: Error) =>
    Action.action(REMOVE_CATEGORY_FROM_TAG[constants.FAILURE], {error}),
};

export const REMOVE_ERROR_MESSAGE = Action.createRequestTypes(
  'REMOVE_ERROR_MESSAGE',
);
export const removeErrorMEssage = {
  request: () => Action.action(REMOVE_ERROR_MESSAGE[constants.REQUEST], {}),
};
