import {take, call, put} from 'redux-saga/effects';
import type {Effect} from 'redux-saga/effects';

import {getAllLanguages as getAllLanguagesService} from '@services/management';
import * as ManagementActions from '@actions/management';

export function* getAllLanguages(): Generator<Effect, void, any> {
  while (true) {
    try {
      yield take(ManagementActions.GET_ALL_LANGUAGES.REQUEST);
      const {data: response} = yield call(getAllLanguagesService);
      yield put(ManagementActions.getAllLanguages.success(response.data));
    } catch (error) {
      // Show info
      yield put(ManagementActions.getAllLanguages.failure(error));
    }
  }
}
