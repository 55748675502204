import {constants} from '@resources';
import {Action} from '@utils';
import {update} from 'lodash';

export const GET_CURATIONS = Action.createRequestTypes('GET_CURATIONS');
export const getCurationsList = {
  request: (page: number, listType: string) =>
    Action.action(GET_CURATIONS[constants.REQUEST], {page, listType}),
  success: (curations: any, page: number, curationsCount: number) =>
    Action.action(GET_CURATIONS[constants.SUCCESS], {
      curations,
      page,
      curationsCount,
    }),
  failure: (error: Error) =>
    Action.action(GET_CURATIONS[constants.FAILURE], {error}),
};

export const GET_WEEKLY_UPDATED_CURATIONS = Action.createRequestTypes(
  'GET_WEEKLY_UPDATED_CURATIONS',
);
export const getWeeklyUpdatedCurationsList = {
  request: (page: number, listType: string, updatedTime: string) =>
    Action.action(GET_WEEKLY_UPDATED_CURATIONS[constants.REQUEST], {
      page,
      listType,
      updatedTime,
    }),
  success: (curations: any, page: number, curationsCount: number) =>
    Action.action(GET_WEEKLY_UPDATED_CURATIONS[constants.SUCCESS], {
      curations,
      page,
      curationsCount,
    }),
  failure: (error: Error) =>
    Action.action(GET_WEEKLY_UPDATED_CURATIONS[constants.FAILURE], {error}),
};

export const GET_WISEREDITORIAL_PICKS = Action.createRequestTypes(
  'GET_WISEREDITORIAL_PICKS',
);

export const getWiserEditorialPicks = {
  request: (langSelection: string) => {
    return Action.action(GET_WISEREDITORIAL_PICKS[constants.REQUEST], {
      langSelection,
    });
  },
  success: (curations: any, curationsCount: number) => {
    return Action.action(GET_WISEREDITORIAL_PICKS[constants.SUCCESS], {
      curations,
      curationsCount,
    });
  },
  failure: (error: Error) => {
    return Action.action(GET_WISEREDITORIAL_PICKS[constants.FAILURE], {error});
  },
};

export const GET_CURATION_DETAIL = Action.createRequestTypes(
  'GET_CURATION_DETAIL',
);
export const getCurationDetail = {
  request: (curationId: number) =>
    Action.action(GET_CURATION_DETAIL[constants.REQUEST], {curationId}),
  success: (curationDetail: any) =>
    Action.action(GET_CURATION_DETAIL[constants.SUCCESS], {
      curationDetail,
    }),
  failure: (error: Error) =>
    Action.action(GET_CURATION_DETAIL[constants.FAILURE], {error}),
};

export const GET_CURATION_POSTS = Action.createRequestTypes(
  'GET_CURATION_POSTS',
);
export const getCurationPosts = {
  request: (curationId: number, page: number) =>
    Action.action(GET_CURATION_POSTS[constants.REQUEST], {curationId, page}),
  success: (curationPosts: any, page: number, count: number) =>
    Action.action(GET_CURATION_POSTS[constants.SUCCESS], {
      curationPosts,
      page,
      count,
    }),
  failure: (error: Error) =>
    Action.action(GET_CURATION_POSTS[constants.FAILURE], {error}),
};

export const DELETE_CURATION_POSTS = Action.createRequestTypes(
  'DELETE_CURATION_POSTS',
);
export const deleteCurationPosts = {
  request: (deletePostId: number, curationId: number, onSucess: Function) =>
    Action.action(DELETE_CURATION_POSTS[constants.REQUEST], {
      deletePostId,
      curationId,
      onSucess,
    }),
  success: (deletePostId: number) =>
    Action.action(DELETE_CURATION_POSTS[constants.SUCCESS], {deletePostId}),
  failure: (error: Error) =>
    Action.action(DELETE_CURATION_POSTS[constants.FAILURE], {error}),
};

export const DELETE_CURATION = Action.createRequestTypes('DELETE_CURATION');
export const deleteCuration = {
  request: (deleteCurationId: number, onSucess: Function) =>
    Action.action(DELETE_CURATION[constants.REQUEST], {
      deleteCurationId,
      onSucess,
    }),
  success: (deletedCuration: number) =>
    Action.action(DELETE_CURATION[constants.SUCCESS], {deletedCuration}),
  failure: (error: Error) =>
    Action.action(DELETE_CURATION[constants.FAILURE], {error}),
};

export const UPDATE_CURATION = Action.createRequestTypes('UPDATE_CURATION');
export const updateCuration = {
  request: (updateCuration: any, onSucess: Function) => {
    return Action.action(UPDATE_CURATION[constants.REQUEST], {
      updateCuration,
      onSucess,
    });
  },
  success: (updatedCuration: any) =>
    Action.action(UPDATE_CURATION[constants.SUCCESS], {updatedCuration}),
  failure: (error: Error) =>
    Action.action(UPDATE_CURATION[constants.FAILURE], {error}),
};

export const ADD_POSTS_TO_CURATION = Action.createRequestTypes(
  'ADD_POSTS_TO_CURATION',
);
export const addPostsToCuration = {
  request: (postIds: any, curationId: number, onSucess: Function) =>
    Action.action(ADD_POSTS_TO_CURATION[constants.REQUEST], {
      postIds,
      curationId,
      onSucess,
    }),
  success: (addedPosts: any) =>
    Action.action(ADD_POSTS_TO_CURATION[constants.SUCCESS], {addedPosts}),
  failure: (error: Error) =>
    Action.action(ADD_POSTS_TO_CURATION[constants.FAILURE], {error}),
};

export const GET_CURATION_USERS = Action.createRequestTypes(
  'GET_CURATION_USERS',
);
export const getCurationUsers = {
  request: (page: number) =>
    Action.action(GET_CURATION_USERS[constants.REQUEST], {page}),
  success: (curationUsers: any, page: number, curationUsersCount: number) =>
    Action.action(GET_CURATION_USERS[constants.SUCCESS], {
      curationUsers,
      page,
      curationUsersCount,
    }),
  failure: (error: Error) =>
    Action.action(GET_CURATION_USERS[constants.FAILURE], {error}),
};

export const SEARCH_CURATION_USERS = Action.createRequestTypes(
  'SEARCH_CURATION_USERS',
);
export const searchCurationUsers = {
  request: (
    page: number,
    searchFields: {
      userName: string;
      fullName: string;
      firstName: string;
      lastName: string;
      role: string;
    },
  ) =>
    Action.action(SEARCH_CURATION_USERS[constants.REQUEST], {
      page,
      searchFields,
    }),
  success: (curationUsers: any, page: number, searchCount: number) =>
    Action.action(SEARCH_CURATION_USERS[constants.SUCCESS], {
      curationUsers,
      page,
      searchCount,
    }),
  failure: (error: Error) =>
    Action.action(SEARCH_CURATION_USERS[constants.FAILURE], {error}),
};

export const CREATE_CURATION = Action.createRequestTypes('CREATE_CURATION');
export const createCuration = {
  request: (createData: any, onSucess: Function) =>
    Action.action(CREATE_CURATION[constants.REQUEST], {createData, onSucess}),
  success: (createdData: any) =>
    Action.action(CREATE_CURATION[constants.SUCCESS], {
      createdData,
    }),
  failure: (error: Error) =>
    Action.action(CREATE_CURATION[constants.FAILURE], {error}),
};

export const CHECK_CURATION_CODE = Action.createRequestTypes(
  'CHECK_CURATION_CODE',
);
export const checkCurationCode = {
  request: (curationCode: any, onSucess: Function) =>
    Action.action(CHECK_CURATION_CODE[constants.REQUEST], {
      curationCode,
      onSucess,
    }),
  success: (usable: any) =>
    Action.action(CHECK_CURATION_CODE[constants.SUCCESS], {usable}),
  failure: (error: Error) =>
    Action.action(CHECK_CURATION_CODE[constants.FAILURE], {error}),
};

export const UPDATE_CURATION_LIST_STATUS = Action.createRequestTypes(
  'UPDATE_CURATION_LIST_STATUS',
);
export const updateCurationListStatus = {
  request: (checkedList: any) =>
    Action.action(UPDATE_CURATION_LIST_STATUS[constants.REQUEST], {
      checkedList,
    }),
  success: (curation: any) =>
    Action.action(UPDATE_CURATION_LIST_STATUS[constants.SUCCESS], {
      curation,
    }),
  failure: (error: Error) =>
    Action.action(UPDATE_CURATION_LIST_STATUS[constants.FAILURE], {error}),
};

export const UPDATE_DISCOVERY_ORDER = Action.createRequestTypes(
  'UPDATE_DISCOVERY_ORDER',
);

export const updateDiscoveryOrder = {
  request: (updatedList: any, locale: string) =>
    Action.action(UPDATE_DISCOVERY_ORDER[constants.REQUEST], {
      updatedList,
      locale,
    }),
  success: (curation: any) =>
    Action.action(UPDATE_DISCOVERY_ORDER[constants.SUCCESS], {
      curation,
    }),
  failure: (error: Error) =>
    Action.action(UPDATE_DISCOVERY_ORDER[constants.FAILURE], {error}),
};

export const UPDATE_WISER_EDITORIAL_ORDER = Action.createRequestTypes(
  'UPDATE_WISER_EDITORIAL_ORDER',
);

export const updateWiserEditorialPicksOrder = {
  request: (updatedList: any, langSelection?: any) =>
    Action.action(UPDATE_WISER_EDITORIAL_ORDER[constants.REQUEST], {
      updatedList,
      langSelection,
    }),
  success: (curation: any) =>
    Action.action(UPDATE_WISER_EDITORIAL_ORDER[constants.SUCCESS], {
      curation,
    }),
  failure: (error: Error) =>
    Action.action(UPDATE_WISER_EDITORIAL_ORDER[constants.FAILURE], {error}),
};

export const SEARCH_DISCOVERY_CURATIONS = Action.createRequestTypes(
  'SEARCH_DISCOVERY_CURATIONS',
);
export const searchDiscoveryCurations = {
  request: (page: number, searchFields: {[key: string]: any}) =>
    Action.action(SEARCH_DISCOVERY_CURATIONS[constants.REQUEST], {
      page,
      searchFields,
    }),
  success: (data: any, page: number, codeCount: number) =>
    Action.action(SEARCH_DISCOVERY_CURATIONS[constants.SUCCESS], {
      data,
      page,
      codeCount,
    }),
  failure: (error: Error) =>
    Action.action(SEARCH_DISCOVERY_CURATIONS[constants.FAILURE], {error}),
};

export const GET_CURATION_STATS = Action.createRequestTypes(
  'GET_CURATION_STATS',
);
export const getCurationStats = {
  request: (page: number, startDate: string, endDate: string) =>
    Action.action(GET_CURATION_STATS[constants.REQUEST], {
      page,
      startDate,
      endDate,
    }),
  success: (data: any, page: number, statCount: number) =>
    Action.action(GET_CURATION_STATS[constants.SUCCESS], {
      data,
      page,
      statCount,
    }),
  failure: (error: Error) =>
    Action.action(GET_CURATION_STATS[constants.FAILURE], {error}),
};

export const GET_RANDOM_COVER = Action.createRequestTypes('GET_RANDOM_COVER');
export const getRandomCover = {
  request: () => Action.action(GET_RANDOM_COVER[constants.REQUEST]),
  success: (data: any) =>
    Action.action(GET_RANDOM_COVER[constants.SUCCESS], {data}),
  failure: (error: Error) =>
    Action.action(GET_RANDOM_COVER[constants.FAILURE], {error}),
};

export const GET_DYNAMIC_LINK = Action.createRequestTypes('GET_DYNAMIC_LINK');
export const getDynamicLink = {
  request: (curationId: number, onSuccess: Function) =>
    Action.action(GET_DYNAMIC_LINK[constants.REQUEST], {curationId, onSuccess}),
  success: (data: any) =>
    Action.action(GET_DYNAMIC_LINK[constants.SUCCESS], {data}),
  failure: (error: Error) =>
    Action.action(GET_DYNAMIC_LINK[constants.FAILURE], {error}),
};

export const CHANGE_STATUS_OF_CURATIONS = Action.createRequestTypes(
  'CHANGE_STATUS_OF_CURATION',
);
export const updateStatusOfCurations = {
  request: (curationIds: Array<any>, page: string) => {
    return Action.action(CHANGE_STATUS_OF_CURATIONS[constants.REQUEST], {
      curationIds,
      page,
    });
  },
  success: (data: any) =>
    Action.action(CHANGE_STATUS_OF_CURATIONS[constants.SUCCESS], {data}),
  failure: (error: Error) =>
    Action.action(CHANGE_STATUS_OF_CURATIONS[constants.FAILURE], {error}),
};
