import {take, call, put} from 'redux-saga/effects';
import type {Effect} from 'redux-saga/effects';

import {getAllPostStats as getAllPostStatsService} from '@services/posts';
import * as PostsActions from '@actions/posts';

export function* getAllPostStats(): Generator<Effect, void, any> {
  while (true) {
    try {
      const {interval, startDate, endDate} = yield take(
        PostsActions.GET_ALL_POST_STATS.REQUEST,
      );
      const {data: response} = yield call(
        getAllPostStatsService,
        interval,
        startDate,
        endDate,
      );
      yield put(PostsActions.getAllPostStats.success(response.data));
    } catch (error) {
      // Show info
      yield put(PostsActions.getAllPostStats.failure(error));
    }
  }
}
