import {take, call, put} from 'redux-saga/effects';
import type {Effect} from 'redux-saga/effects';

import {getWiserEditorialPicks as getWiserEditorialPicksService} from '@services/curationList';
import * as CurationActions from '@actions/curation';

export function* getWiserEditorialPicks(): Generator<Effect, void, any> {
  while (true) {
    try {
      const {langSelection} = yield take(
        CurationActions.GET_WISEREDITORIAL_PICKS.REQUEST,
      );
      const {data: response} = yield call(
        getWiserEditorialPicksService,
        langSelection,
      );
      yield put(
        CurationActions.getWiserEditorialPicks.success(
          response.data,
          response.data.length,
        ),
      );
    } catch (error) {
      // Show info
      yield put(CurationActions.getWiserEditorialPicks.failure(error));
    }
  }
}
