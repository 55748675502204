import {take, call, put} from 'redux-saga/effects';
import type {Effect} from 'redux-saga/effects';

import {usersRoleChange as usersRoleChangeService} from '@services/users';
import * as UsersActions from '@actions/users';

export function* usersRoleChange(): Generator<Effect, void, any> {
  while (true) {
    try {
      const {users, onSuccess} = yield take(
        UsersActions.USERS_ROLE_CHANGE.REQUEST,
      );
      const {data: response} = yield call(usersRoleChangeService, users);
      onSuccess();
      yield put(UsersActions.usersRoleChange.success([]));
    } catch (error) {
      // Show info
      yield put(UsersActions.usersRoleChange.failure(error));
    }
  }
}
