import React, {CSSProperties} from 'react';
import {colors} from '../../resources';

export const styles: Record<string, CSSProperties> = {
  container: {
    width: '100%',
    marginTop: 15,
  },
  header: {
    fontFamily: 'SFProText-Regular',
    fontSize: 13,
    fontWeight: 'bold',
    color: colors.black,
    // lineHeight: 18,
  },
  headerDescription: {
    fontFamily: 'SFProText-Regular',
    fontSize: 13,
    fontWeight: 'bold',
    color: colors.gray.gray1,
    // lineHeight: 18,
  },
  furtherReadingContainer: {},
  addButton: {
    width: 150,
    height: 160,
    borderWidth: 1,
    borderStyle: 'dashed',
    borderColor: colors.gray.gray3,
    borderRadius: 12,
    alignItems: 'center',
  },
  plusIcon: {
    width: 24,
    height: 24,
    alignSelf: 'center',
  },
  addText: {
    textAlign: 'center',
    fontFamily: 'SFProText-Regular',
    fontSize: 17,
    //lineHeight: 22,
    color: colors.black,
    marginTop: 20,
  },
  closeButton: {
    width: 26,
    height: 26,
    borderRadius: 13,
    backgroundColor: colors.black,
    alignItems: 'center',
    justifyContent: 'center',
    marginLeft: -58,
    marginTop: 8,
  },
  closeIcon: {
    width: 18,
    height: 18,
    // resizeMode: 'contain',
    marginLeft: -9,
    marginTop: -16,
  },
};
