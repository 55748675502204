import {Axios} from '@utils';
import {constants} from '../../resources';

export function getAllUsers(
  limit: number,
  offset: number,
  role: string,
  status: string,
  searchFields?: {
    userName?: string;
    fullName?: string;
    email?: string;
    id?: string;
    premium?: boolean;
    premiumFilter?: string;
    status?: string;
  },
  locale?: string,
) {
  let params = '';

  if (role) {
    if (role === 'premium') {
      const roleStr = `&role=` + '';
      params = roleStr + params;
      params = params + `&premium=true`;
    } else {
      const roleStr = `&role=` + role;
      params = roleStr + params;
    }
  }
  if (status) {
    const statusStr = `&status=` + status;
    params = statusStr + params;
  }

  if (searchFields?.userName) {
    const userName = `&userName=` + searchFields.userName;
    params = userName + params;
  }

  if (searchFields?.fullName) {
    const fullName = `&fullName=` + searchFields.fullName;
    params = fullName + params;
  }

  if (searchFields?.email) {
    const email = `&email=` + searchFields.email;
    params = email + params;
  }

  if (searchFields?.id) {
    const id = `&id=` + searchFields.id;
    params = id + params;
  }

  if (searchFields?.status) {
    const status = `&status=` + searchFields.status;
    params = status + params;
  }

  if (searchFields?.premiumFilter) {
    const status = `&premiumFilter=` + searchFields.premiumFilter;
    params = status + params;
  }
  return Axios.get(
    `${constants.USER_MANAGEMENT_BASE}/search?limit=${limit}` +
      `&offset=` +
      offset +
      params,
  );
}
