import React, {useState} from 'react';
import {useHistory} from 'react-router-dom';
import {useDispatch} from 'react-redux';
import moment from 'moment';
import {PostLogo} from '..';

import {
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CRow,
  CImg,
  CButton,
} from '@coreui/react';
import {deletePosts as deletePostsAction} from '@actions/posts';
import {WSRModal} from '../../components';
import {values} from './values';
import {WSRDataTable} from './WSRDataTable';

type Props = {
  page: number;
  pageLimit?: number;
  setPageLimit?: Function;
  pageChange: Function;
  postList: Array<any>;
  count: number;
  userId?: number;
  userName?: string;
};
export const PostList: React.FC<Props> = (props) => {
  const {
    page,
    pageLimit,
    setPageLimit,
    pageChange,
    postList,
    count,
    userId,
    userName,
  } = props;
  const history = useHistory();
  const [modal, setModal] = useState(false);
  const [deletePostId, setDeletePostId] = useState(-1);
  const [deletedWarningModal, setDeletedWarningModal] = useState(false);

  const dispatch = useDispatch();
  const deleteValidation = (id: number) => {
    setModal(true);
    setDeletePostId(id);
  };

  const handleDeletePost = () => {
    setModal(false);
    if (deletePostId !== -1) {
      dispatch(
        deletePostsAction.request(deletePostId, () => {
          setDeletedWarningModal(true);
        }),
      );
    }
  };

  const scopedSlots = {
    title: (item: any) => (
      <td onClick={() => history.push(`/posts/${item.id}`)}>
        <div className="cut-text">{item.title}</div>
        <div className="small text-muted">
          {item.title
            ? `Published: ${moment(item.date).format('DD/MM/YYYY')}`
            : '---'}
        </div>
      </td>
    ),
    date: (item: any) => <td>{moment(item.createdAt).format('DD/MM/YYYY')}</td>,
    logo: (item: any) => (
      <td>
        <PostLogo publisher={item.publisher} />
      </td>
    ),
    user: (item: any) => (
      <td>
        <div className="cut-text">{item.user.fullName}</div>
      </td>
    ),
    select_row: (item: any) => (
      <td className="py-2">
        <CImg
          src={'avatars/delete.png'}
          className=""
          onClick={() => {
            deleteValidation(item.id);
          }}
          alt=" "
        />
      </td>
    ),
  };

  // const onChangeLimit = (e: ChangeEvent<HTMLLIElement>) => {
  //   setPageLimit(Number(e.target.value));
  // };

  return (
    <>
      <CRow>
        <CCol xl={12}>
          <CCard>
            <CCardHeader>
              <CRow>
                <CCol sm="4" md="8" className="mb-2 mb-xl-0 c-table-header">
                  Posts ({count})
                </CCol>
                <CCol sm="3" md="2" className="my-2 mb-xl-0  mfs-auto">
                  {/* <CFormGroup>
                    <CSelect
                      name="showOnCategory"
                      id="showOnCategory"
                      value={pageLimit}
                      onChange={onChangeLimit}>
                      <option value="10">10</option>
                      <option value="20">20</option>
                      <option value="50">50</option>
                    </CSelect>
                  </CFormGroup> */}
                </CCol>
                <CCol sm="4" md="2" className="mb-3 mb-xl-0 mfs-auto">
                  <CButton
                    className="my-2 mfs-auto"
                    block
                    onClick={() =>
                      history.push({
                        pathname: '/newPost',
                        state: {
                          userId: userId,
                          userName: userName,
                        },
                      })
                    }
                    color="success">
                    Create
                  </CButton>
                </CCol>
              </CRow>
            </CCardHeader>
            <CCardBody>
              <WSRDataTable
                items={postList}
                fields={values.fields}
                scopedSlots={scopedSlots}
                pageLimit={50}
                page={page}
                pageChange={pageChange}
                itemsCount={count}
              />
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>

      <WSRModal
        onShowModal={modal}
        setOnShowModal={setModal}
        modalTitle={'Delete Post'}
        modalBody={`Are you sure you want to remove this post with iddd ${deletePostId}?`}
        operationButton={() => handleDeletePost()}
        operationButtonText={'Yes, remove it!'}
        cancelButton={() => {
          setModal(false);
          setDeletePostId(-1);
        }}
        cancelButtonText={'Cancel'}
      />

      <WSRModal
        onShowModal={deletedWarningModal}
        setOnShowModal={setDeletedWarningModal}
        modalTitle={'Delete Action'}
        modalBody={`Post has been deleted successfully!`}
        operationButton={() => setDeletedWarningModal(false)}
        operationButtonText={'OK'}
      />
    </>
  );
};
