import {take, call, put} from 'redux-saga/effects';
import type {Effect} from 'redux-saga/effects';

import {updateUserShowOnDiscovery as userShowOnDiscoveryChangeService} from '@services/users';
import * as UsersActions from '@actions/users';

export function* updateUserShowOnDiscovery(): Generator<Effect, void, any> {
  while (true) {
    try {
      const {updatedValue, onSuccess} = yield take(
        UsersActions.USERS_SHOW_ON_DISCOVERY_CHANGE.REQUEST,
      );
      const {data: response} = yield call(
        userShowOnDiscoveryChangeService,
        updatedValue,
      );
      onSuccess();
      yield put(UsersActions.updateUserOnShowOnDiscovery.success([]));
    } catch (error) {
      // Show info
      yield put(UsersActions.updateUserOnShowOnDiscovery.failure(error));
    }
  }
}
