import {Axios} from '@utils';
import {constants} from '@resources';

export function removeUserFromOrderList(
  selectedLocale: string,
  userId: number,
) {
  return Axios.delete(
    `${constants.USER_MANAGEMENT_BASE}/discovery/${selectedLocale}/${userId}`,
  );
}
