// import {PURGE} from 'redux-persist';

import * as ActionTypes from '@actions/posts';

import type {PostsReducer, Posts, UncategorisedPosts} from './types';

const initialPosts: Posts = {
  id: 0,
  url: '',
  title: '',
  image: '',
  logo: '',
  description: '',
  author: '',
  publisher: '',
  lang: '',
  date: '',
  type: '',
  dynamicLink: '',
  note: '',
  isHiddenNewsFeed: false,
  isHiddenProfile: false,
  creatorId: 0,
  createdAt: '',
  updatedAt: '',
  categories: [],
  furtherReadings: [],
  user: {
    id: 0,
    fullName: '',
    firstName: '',
    lastName: '',
    userName: '',
    photo: '',
    jobTitle: '',
    tags: [],
  },
  bookmarked: false,
  commented: false,
  liked: false,
  bookmarkCount: 0,
  commentCount: 0,
  likeCount: 0,
};

const initialUncategorisedPosts: UncategorisedPosts = {
  id: 0,
  url: '',
  title: '',
  image: '',
  logo: '',
  description: '',
  author: '',
  publisher: '',
  lang: '',
  date: '',
  type: '',
  dynamicLink: '',
  note: '',
  isHiddenNewsFeed: false,
  isHiddenProfile: false,
  createdAt: '',
  updatedAt: '',
  userId: 0,
  user: {
    id: 0,
    fullName: '',
    firstName: '',
    lastName: '',
    userName: '',
    photo: '',
    role: '',
    jobTitle: '',
    postUsers: [],
  },
};

const initialState: PostsReducer = {
  isLoading: false,
  error: false,
  errorDescription: '',
  // postStats: {
  //   allTime: initialPostStat,
  //   lastMonth: initialPostStat,
  //   thisWeek: initialPostStat,
  //   today: initialPostStat,
  // },
  postStats: {
    Article: [],
    Podcast: [],
    Video: [],
  },
  lastSharedPosts: {
    podcast: [],
    video: [],
    article: [],
  },
  mostInteractedPosts: [],
  postList: {
    posts: [initialPosts],
    page: 0,
    codeCount: 0,
  },
  postDetail: {
    id: 0,
    url: '',
    title: '',
    image: '',
    logo: '',
    description: '',
    author: '',
    publisher: '',
    lang: '',
    date: '',
    type: '',
    note: '',
    dynamicLink: '',
    createdAt: '',
    updatedAt: '',
    isHiddenNewsFeed: false,
    isHiddenProfile: false,
    viewCount: 0,
    user: {
      id: 0,
      fullName: '',
      firstName: '',
      lastName: '',
      userName: '',
      photo: '',
      role: '',
      tags: [],
    },
    comments: [],
    bookmarked: false,
    liked: false,
    commented: false,
    categories: [],
    tags: [],
    furtherReadings: [],
    bookmarkCount: 0,
    likeCount: 0,
    commentCount: 0,
  },
  categoriesPosts: {
    posts: [],
    page: 0,
    postCount: 0,
  },
  postComments: {
    comments: [],
    page: 0,
  },
  postSearchResult: {
    posts: [],
    page: 0,
    codeCount: 0,
  },
  postSearchResultForUser: {
    posts: [],
    page: 0,
    codeCount: 0,
  },
  uncategorisedPostList: {
    uncategorisedPosts: [initialUncategorisedPosts],
    codeCount: 0,
  },
};

export function posts(state = initialState, action: any = {}): PostsReducer {
  switch (action.type) {
    // case PURGE: {
    //   return initialState;
    // }
    case ActionTypes.GET_ALL_POST_STATS.REQUEST: {
      return {
        ...state,
        isLoading: true,
        error: false,
        errorDescription: '',
      };
    }
    case ActionTypes.GET_ALL_POST_STATS.SUCCESS: {
      return {
        ...state,
        isLoading: false,
        error: false,
        errorDescription: '',
        postStats: action.stats,
      };
    }
    case ActionTypes.GET_ALL_POST_STATS.FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: true,
        errorDescription: action.error.data?.message,
      };
    }
    case ActionTypes.GET_LAST_SHARED_POSTS.REQUEST: {
      return {
        ...state,
        isLoading: true,
        error: false,
        errorDescription: '',
      };
    }
    case ActionTypes.GET_LAST_SHARED_POSTS.SUCCESS: {
      return {
        ...state,
        isLoading: false,
        error: false,
        errorDescription: '',
        lastSharedPosts: action.posts,
      };
    }
    case ActionTypes.GET_LAST_SHARED_POSTS.FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: true,
        errorDescription: action.error.data?.message,
      };
    }
    case ActionTypes.GET_MOST_INTERACTED_POSTS.REQUEST: {
      return {
        ...state,
        isLoading: true,
        error: false,
        errorDescription: '',
      };
    }
    case ActionTypes.GET_MOST_INTERACTED_POSTS.SUCCESS: {
      return {
        ...state,
        isLoading: false,
        error: false,
        errorDescription: '',
        mostInteractedPosts: action.posts,
      };
    }
    case ActionTypes.GET_MOST_INTERACTED_POSTS.FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: true,
        errorDescription: action.error.data?.message,
      };
    }

    case ActionTypes.GET_UNCATEGORISED_POSTS.REQUEST: {
      return {
        ...state,
        isLoading: true,
        error: false,
        errorDescription: '',
      };
    }
    case ActionTypes.GET_UNCATEGORISED_POSTS.SUCCESS: {
      return {
        ...state,
        isLoading: false,
        error: false,
        errorDescription: '',
        uncategorisedPostList: {
          uncategorisedPosts: action.uncategorisedPosts,
          codeCount: action.codeCount,
        },
      };
    }
    case ActionTypes.GET_UNCATEGORISED_POSTS.FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: true,
        errorDescription: action.error.data?.message,
      };
    }

    case ActionTypes.GET_POSTS.REQUEST: {
      return {
        ...state,
        isLoading: true,
        error: false,
        errorDescription: '',
      };
    }
    case ActionTypes.GET_POSTS.SUCCESS: {
      return {
        ...state,
        isLoading: false,
        error: false,
        errorDescription: '',
        postList: {
          posts:
            action.page === 0
              ? action.posts
              : [...state.postList.posts, ...action.posts],
          page: action.page,
          codeCount: action.codeCount,
        },
      };
    }
    case ActionTypes.GET_POSTS.FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: true,
        errorDescription: action.error.data?.message,
      };
    }

    case ActionTypes.DELETE_POSTS.REQUEST: {
      return {
        ...state,
        isLoading: true,
        error: false,
        errorDescription: '',
      };
    }
    case ActionTypes.DELETE_POSTS.SUCCESS: {
      const filterdCodes = state.postList.posts.filter(
        (item) => item.id !== action.codeId,
      );
      const filterdCodesSearch = state.postSearchResult.posts.filter(
        (item) => item.id !== action.codeId,
      );
      return {
        ...state,
        isLoading: false,
        error: false,
        errorDescription: '',
        postList: {
          ...state.postList,
          codeCount: state.postList.codeCount - 1,
          posts: filterdCodes,
        },
        postSearchResult: {
          ...state.postSearchResult,
          codeCount: state.postSearchResult.codeCount - 1,
          posts: filterdCodesSearch,
        },
      };
    }
    case ActionTypes.DELETE_POSTS.FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: true,
        errorDescription: action.error.data?.message,
      };
    }

    case ActionTypes.GET_POST_DETAIL.REQUEST: {
      return {
        ...state,
        isLoading: true,
        error: false,
        errorDescription: '',
      };
    }
    case ActionTypes.GET_POST_DETAIL.SUCCESS: {
      return {
        ...state,
        isLoading: false,
        error: false,
        errorDescription: '',
        postDetail: action.postDetail,
      };
    }
    case ActionTypes.GET_POST_DETAIL.FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: true,
        errorDescription: action.error.data?.message,
      };
    }

    case ActionTypes.UPDATE_POST.REQUEST: {
      return {
        ...state,
        isLoading: true,
        error: false,
        errorDescription: '',
      };
    }
    case ActionTypes.UPDATE_POST.SUCCESS: {
      return {
        ...state,
        isLoading: false,
        error: false,
        errorDescription: '',
        postDetail: {
          ...state.postDetail,
          title: action.uptadedPost.title,
          description: action.uptadedPost.description,
          note: action.uptadedPost.note,
          furtherReadings: action.uptadedPost.furtherReadings,
          isHiddenNewsFeed: action.uptadedPost.isHiddenNewsFeed,
          isHiddenProfile: action.uptadedPost.isHiddenProfile,
        },
      };
    }
    case ActionTypes.UPDATE_POST.FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: true,
        errorDescription: action.error.data?.message,
      };
    }

    case ActionTypes.CREATE_POST.REQUEST: {
      return {
        ...state,
        isLoading: true,
        error: false,
        errorDescription: '',
      };
    }
    case ActionTypes.CREATE_POST.SUCCESS: {
      return {
        ...state,
        isLoading: false,
        error: false,
        errorDescription: '',
      };
    }
    case ActionTypes.CREATE_POST.FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: true,
        errorDescription: action.error.data?.message,
      };
    }

    case ActionTypes.GET_CATEGORIES_POSTS.REQUEST: {
      return {
        ...state,
        isLoading: true,
        error: false,
        errorDescription: '',
      };
    }
    case ActionTypes.GET_CATEGORIES_POSTS.SUCCESS: {
      return {
        ...state,
        isLoading: false,
        error: false,
        errorDescription: '',
        categoriesPosts: {
          posts:
            action.page === 0
              ? action.posts
              : [...state.categoriesPosts.posts, ...action.posts],
          page: action.page,
          postCount: action.postsCount,
        },
      };
    }
    case ActionTypes.GET_CATEGORIES_POSTS.FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: true,
        errorDescription: action.error.data?.message,
      };
    }

    case ActionTypes.DELETE_CATEGORIES_POSTS.REQUEST: {
      return {
        ...state,
        isLoading: true,
        error: false,
        errorDescription: '',
      };
    }
    case ActionTypes.DELETE_CATEGORIES_POSTS.SUCCESS: {
      const filterdCodes = state.categoriesPosts.posts.filter(
        (item) => item.id !== action.deletePostId,
      );
      return {
        ...state,
        isLoading: false,
        error: false,
        errorDescription: '',
        categoriesPosts: {
          ...state.categoriesPosts,
          postCount: state.categoriesPosts.postCount - 1,
          posts: filterdCodes,
        },
      };
    }
    case ActionTypes.DELETE_CATEGORIES_POSTS.FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: true,
        errorDescription: action.error.data?.message,
      };
    }

    case ActionTypes.ADD_CATEGORIES_POSTS.REQUEST: {
      return {
        ...state,
        isLoading: true,
        error: false,
        errorDescription: '',
      };
    }
    case ActionTypes.ADD_CATEGORIES_POSTS.SUCCESS: {
      return {
        ...state,
        isLoading: false,
        error: false,
        errorDescription: '',
        // curationPosts: {
        //   ...state.curationPosts,
        //  action.addedPosts
        // },
      };
    }
    case ActionTypes.ADD_CATEGORIES_POSTS.FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: true,
        errorDescription: action.error.data?.message,
      };
    }

    case ActionTypes.GET_POST_COMMENTS.REQUEST: {
      return {
        ...state,
        isLoading: true,
        error: false,
        errorDescription: '',
      };
    }
    case ActionTypes.GET_POST_COMMENTS.SUCCESS: {
      return {
        ...state,
        isLoading: false,
        error: false,
        errorDescription: '',
        postComments: {
          comments:
            action.page === 0
              ? action.postComments
              : [...state.postComments.comments, ...action.postComments],
          page: action.page,
        },
      };
    }
    case ActionTypes.GET_POST_COMMENTS.FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: true,
        errorDescription: action.error.data?.message,
      };
    }

    case ActionTypes.DELETE_POST_COMMENT.REQUEST: {
      return {
        ...state,
        isLoading: true,
        error: false,
        errorDescription: '',
      };
    }
    case ActionTypes.DELETE_POST_COMMENT.SUCCESS: {
      const filterdCodes = state.postComments.comments.filter(
        (item) => item.id !== action.commentId,
      );
      return {
        ...state,
        isLoading: false,
        error: false,
        errorDescription: '',
        postComments: {
          ...state.postComments,
          //postCount: state.categoriesPosts.postCount - 1,
          comments: filterdCodes,
        },
      };
    }
    case ActionTypes.DELETE_POST_COMMENT.FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: true,
        errorDescription: action.error.data?.message,
      };
    }

    case ActionTypes.SEARCH_POSTS.REQUEST: {
      return {
        ...state,
        isLoading: true,
        error: false,
        errorDescription: '',
      };
    }
    case ActionTypes.SEARCH_POSTS.SUCCESS: {
      return {
        ...state,
        isLoading: false,
        error: false,
        errorDescription: '',
        postSearchResult: {
          posts: action.posts,
          page: action.page,
          codeCount: action.codeCount,
        },
      };
    }
    case ActionTypes.SEARCH_POSTS.FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: true,
        errorDescription: action.error.data?.message,
      };
    }

    case ActionTypes.SEARCH_POSTS_FOR_USER.REQUEST: {
      return {
        ...state,
        isLoading: true,
        error: false,
        errorDescription: '',
      };
    }
    case ActionTypes.SEARCH_POSTS_FOR_USER.SUCCESS: {
      return {
        ...state,
        isLoading: false,
        error: false,
        errorDescription: '',
        postSearchResultForUser: {
          posts: action.posts,
          page: action.page,
          codeCount: action.codeCount,
        },
      };
    }
    case ActionTypes.SEARCH_POSTS_FOR_USER.FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: true,
        errorDescription: action.error.data?.message,
      };
    }

    default:
      return state;
  }
}
