import React from 'react';

import {CCard, CCol, CRow} from '@coreui/react';

import {WSRDataTable} from '../../../../components';

type Props = {
  page: number;
  pageLimit: number;
  pageChange: Function;
  curationList: Array<any>;
  curationListCount: number;
};

export const CurationStatsList: React.FC<Props> = (props) => {
  const {page, pageLimit, pageChange, curationList, curationListCount} = props;

  const fields = [
    {
      key: 'id',
      _style: {width: '5%', minWidth: '5%'},
    },
    {
      key: 'name',
      label: 'Name',
      _style: {width: '25%'},
    },
    {
      key: 'count',
      label: 'Number of Click',
      _style: {width: '20%'},
    },
  ];

  const scopedSlots = {};
  return (
    <>
      <CRow>
        <CCol xl={12}>
          <CCard>
            <WSRDataTable
              items={curationList}
              fields={fields}
              scopedSlots={scopedSlots}
              pageLimit={pageLimit}
              page={page}
              pageChange={pageChange}
              itemsCount={curationListCount}
            />
          </CCard>
        </CCol>
      </CRow>
    </>
  );
};
