import {constants} from '@resources';
import {Action} from '@utils';

export const GET_ALL_USER_STATS = Action.createRequestTypes(
  'GET_ALL_USER_STATS',
);
export const getAllUserStats = {
  request: () => Action.action(GET_ALL_USER_STATS[constants.REQUEST], null),
  success: (stats: any) =>
    Action.action(GET_ALL_USER_STATS[constants.SUCCESS], {stats}),
  failure: (error: Error) =>
    Action.action(GET_ALL_USER_STATS[constants.FAILURE], {error}),
};

export const GET_LAST_REGISTERED_USERS = Action.createRequestTypes(
  'GET_LAST_REGISTERED_USERS',
);
export const getLastRegisteredUsers = {
  request: (startDate: string, endDate: string) =>
    Action.action(GET_LAST_REGISTERED_USERS[constants.REQUEST], {
      startDate,
      endDate,
    }),
  success: (users: Array<any>) =>
    Action.action(GET_LAST_REGISTERED_USERS[constants.SUCCESS], {users}),
  failure: (error: Error) =>
    Action.action(GET_LAST_REGISTERED_USERS[constants.FAILURE], {error}),
};

export const GET_LAST_ACTIVE_USERS = Action.createRequestTypes(
  'GET_LAST_ACTIVE_USERS',
);
export const getLastActiveUsers = {
  request: () => Action.action(GET_LAST_ACTIVE_USERS[constants.REQUEST], null),
  success: (users: Array<any>) =>
    Action.action(GET_LAST_ACTIVE_USERS[constants.SUCCESS], {users}),
  failure: (error: Error) =>
    Action.action(GET_LAST_ACTIVE_USERS[constants.FAILURE], {error}),
};

export const GET_ADMINS = Action.createRequestTypes('GET_ADMINS');
export const getAdmins = {
  request: (offset: number) =>
    Action.action(GET_ADMINS[constants.REQUEST], {offset}),
  success: (data: any, offset: number, count: number) =>
    Action.action(GET_ADMINS[constants.SUCCESS], {data, offset, count}),
  failure: (error: Error) =>
    Action.action(GET_ADMINS[constants.FAILURE], {error}),
};

export const GET_ALL_USERS = Action.createRequestTypes('GET_ALL_USERS');
export const getAllUsers = {
  request: (
    limit: number,
    offset: number,
    role: string,
    status: string,
    searchFields: any,
    locale: string,
  ) =>
    Action.action(GET_ALL_USERS[constants.REQUEST], {
      limit,
      offset,
      role,
      status,
      searchFields,
      locale,
    }),
  success: (data: any, offset: number, count: number) =>
    Action.action(GET_ALL_USERS[constants.SUCCESS], {data, offset, count}),
  failure: (error: Error) =>
    Action.action(GET_ALL_USERS[constants.FAILURE], {error}),
};

export const GET_WISER_USERS_BY_LOCALE = Action.createRequestTypes(
  'GET_WISER_USERS_BY_LOCALE',
);
export const getWiserUsersByLocale = {
  request: (limit: number, offset: number, role: string, locale: string) =>
    Action.action(GET_WISER_USERS_BY_LOCALE[constants.REQUEST], {
      limit,
      offset,
      role,
      locale,
    }),
  success: (data: any, offset: number, count: number) =>
    Action.action(GET_WISER_USERS_BY_LOCALE[constants.SUCCESS], {
      data,
      offset,
      count,
    }),
  failure: (error: Error) =>
    Action.action(GET_WISER_USERS_BY_LOCALE[constants.FAILURE], {error}),
};

export const GET_PUBLISHER_USERS_BY_LOCALE = Action.createRequestTypes(
  'GET_PUBLISHER_USERS_BY_LOCALE',
);
export const getPublisherUsersByLocale = {
  request: (limit: number, offset: number, role: string, locale: string) =>
    Action.action(GET_PUBLISHER_USERS_BY_LOCALE[constants.REQUEST], {
      limit,
      offset,
      role,
      locale,
    }),
  success: (data: any, offset: number, count: number) =>
    Action.action(GET_PUBLISHER_USERS_BY_LOCALE[constants.SUCCESS], {
      data,
      offset,
      count,
    }),
  failure: (error: Error) =>
    Action.action(GET_PUBLISHER_USERS_BY_LOCALE[constants.FAILURE], {error}),
};

export const UPDATE_WISERS_ORDER = Action.createRequestTypes(
  'UPDATE_WISERS_ORDER',
);
export const updateWiserOrder = {
  request: (updateWisers: any, locale: string) =>
    Action.action(UPDATE_WISERS_ORDER[constants.REQUEST], {
      updateWisers,
      locale,
    }),
  success: (data: any) =>
    Action.action(UPDATE_WISERS_ORDER[constants.SUCCESS], {
      data,
    }),
  failure: (error: Error) =>
    Action.action(UPDATE_WISERS_ORDER[constants.FAILURE], {error}),
};

export const UPDATE_PUBLISHER_ORDER = Action.createRequestTypes(
  'UPDATE_PUBLISHER_ORDER',
);
export const updatePublisherOrder = {
  request: (updatePublishers: any, locale: string) =>
    Action.action(UPDATE_PUBLISHER_ORDER[constants.REQUEST], {
      updatePublishers,
      locale,
    }),
  success: (data: any) =>
    Action.action(UPDATE_PUBLISHER_ORDER[constants.SUCCESS], {
      data,
    }),
  failure: (error: Error) =>
    Action.action(UPDATE_PUBLISHER_ORDER[constants.FAILURE], {error}),
};

export const SEARCH_USERS = Action.createRequestTypes('SEARCH_USERS');
export const searchUsers = {
  request: (
    limit: number,
    page: number,
    searchFields: {
      userName: string;
      fullName: string;
      premium?: boolean;
      premiumFilter?: string;
      email?: string;
      id?: string;
      status?: string;
    },
    role: string,
    status: string,
    locale: string,
  ) =>
    Action.action(SEARCH_USERS[constants.REQUEST], {
      limit,
      page,
      searchFields,
      role,
      status,
      locale,
    }),
  success: (data: any, page: number, count: number) =>
    Action.action(SEARCH_USERS[constants.SUCCESS], {
      data,
      page,
      count,
    }),
  failure: (error: Error) =>
    Action.action(SEARCH_USERS[constants.FAILURE], {error}),
};

export const GET_USERS_DETAIL = Action.createRequestTypes('GET_USERS_DETAIL');
export const getUsersDetail = {
  request: (userId: number) =>
    Action.action(GET_USERS_DETAIL[constants.REQUEST], {userId}),
  success: (userDetail: any) =>
    Action.action(GET_USERS_DETAIL[constants.SUCCESS], {userDetail}),
  failure: (error: Error) =>
    Action.action(GET_USERS_DETAIL[constants.FAILURE], {error}),
};

export const UPDATE_MANAGEMENT_USERS = Action.createRequestTypes(
  'UPDATE_MANAGEMENT_USERS',
);
export const updateManagementUsers = {
  request: (uptatedData: any, onSuccess: Function) =>
    Action.action(UPDATE_MANAGEMENT_USERS[constants.REQUEST], {
      uptatedData,
      onSuccess,
    }),
  success: (updatedUser: any) =>
    Action.action(UPDATE_MANAGEMENT_USERS[constants.SUCCESS], {updatedUser}),
  failure: (error: Error) =>
    Action.action(UPDATE_MANAGEMENT_USERS[constants.FAILURE], {error}),
};

export const DELETE_MANAGEMENT_USERS = Action.createRequestTypes(
  'DELETE_MANAGEMENT_USERS',
);
export const deleteManagementUsers = {
  request: (deletedId: number, onSuccess: Function) =>
    Action.action(DELETE_MANAGEMENT_USERS[constants.REQUEST], {
      deletedId,
      onSuccess,
    }),
  success: (updatedUser: any) =>
    Action.action(DELETE_MANAGEMENT_USERS[constants.SUCCESS], {updatedUser}),
  failure: (error: Error) =>
    Action.action(DELETE_MANAGEMENT_USERS[constants.FAILURE], {error}),
};

export const DELETE_USERS = Action.createRequestTypes('DELETE_USERS');
export const deleteUsers = {
  request: (deletedId: number, onSuccess: Function) =>
    Action.action(DELETE_USERS[constants.REQUEST], {
      deletedId,
      onSuccess,
    }),
  success: (deletedUser: any) =>
    Action.action(DELETE_USERS[constants.SUCCESS], {deletedUser}),
  failure: (error: Error) =>
    Action.action(DELETE_USERS[constants.FAILURE], {error}),
};

export const UPDATE_USERS = Action.createRequestTypes('UPDATE_USERS');
export const updateUsers = {
  request: (updatedUsers: any, onSuccess: Function) =>
    Action.action(UPDATE_USERS[constants.REQUEST], {
      updatedUsers,
      onSuccess,
    }),
  success: (updatedUser: any) =>
    Action.action(UPDATE_USERS[constants.SUCCESS], {updatedUser}),
  failure: (error: Error) =>
    Action.action(UPDATE_USERS[constants.FAILURE], {error}),
};

export const USERS_ROLE_CHANGE = Action.createRequestTypes('USERS_ROLE_CHANGE');
export const usersRoleChange = {
  request: (users: any, onSuccess: Function) =>
    Action.action(USERS_ROLE_CHANGE[constants.REQUEST], {
      users,
      onSuccess,
    }),
  success: (changedRole: any) =>
    Action.action(USERS_ROLE_CHANGE[constants.SUCCESS], {changedRole}),
  failure: (error: Error) =>
    Action.action(USERS_ROLE_CHANGE[constants.FAILURE], {error}),
};

export const USERS_STATUS_CHANGE = Action.createRequestTypes(
  'USERS_STATUS_CHANGE',
);
export const usersStatusChange = {
  request: (users: any, onSuccess: Function) =>
    Action.action(USERS_STATUS_CHANGE[constants.REQUEST], {
      users,
      onSuccess,
    }),
  success: (changedStatus: any) =>
    Action.action(USERS_STATUS_CHANGE[constants.SUCCESS], {changedStatus}),
  failure: (error: Error) =>
    Action.action(USERS_STATUS_CHANGE[constants.FAILURE], {error}),
};

export const USERS_PREMIUM_CHANGE = Action.createRequestTypes(
  'USERS_PREMIUM_CHANGE',
);
export const usersPremiumChange = {
  request: (users: any, onSuccess: Function) =>
    Action.action(USERS_PREMIUM_CHANGE[constants.REQUEST], {
      users,
      onSuccess,
    }),
  success: (changedStatus: any) =>
    Action.action(USERS_PREMIUM_CHANGE[constants.SUCCESS], {changedStatus}),
  failure: (error: Error) =>
    Action.action(USERS_PREMIUM_CHANGE[constants.FAILURE], {error}),
};

export const GET_SELECTABLE_USERS = Action.createRequestTypes(
  'GET_SELECTABLE_USERS',
);
export const getSelectableUsers = {
  request: () => Action.action(GET_SELECTABLE_USERS[constants.REQUEST], null),
  success: (selectableUsers: Array<any>) =>
    Action.action(GET_SELECTABLE_USERS[constants.SUCCESS], {selectableUsers}),
  failure: (error: Error) =>
    Action.action(GET_SELECTABLE_USERS[constants.FAILURE], {error}),
};

export const USERS_ONBOARDING_CHANGE = Action.createRequestTypes(
  'USERS_ONBOARDING_CHANGE',
);
export const usersOnBoardingChange = {
  request: (onBoardingValue: any, onSuccess: Function) =>
    Action.action(USERS_ONBOARDING_CHANGE[constants.REQUEST], {
      onBoardingValue,
      onSuccess,
    }),
  success: (changedStatus: any) =>
    Action.action(USERS_ONBOARDING_CHANGE[constants.SUCCESS], {changedStatus}),
  failure: (error: Error) =>
    Action.action(USERS_ONBOARDING_CHANGE[constants.FAILURE], {error}),
};

export const USERS_SHOW_ON_DISCOVERY_CHANGE = Action.createRequestTypes(
  'USERS_SHOW_ON_DISCOVERY_CHANGE',
);
export const updateUserOnShowOnDiscovery = {
  request: (updatedValue: any, onSuccess: Function) =>
    Action.action(USERS_SHOW_ON_DISCOVERY_CHANGE[constants.REQUEST], {
      updatedValue,
      onSuccess,
    }),
  success: (changedStatus: any) =>
    Action.action(USERS_SHOW_ON_DISCOVERY_CHANGE[constants.SUCCESS], {
      changedStatus,
    }),
  failure: (error: Error) =>
    Action.action(USERS_SHOW_ON_DISCOVERY_CHANGE[constants.FAILURE], {error}),
};

export const ADD_USER_TO_ORDER_LIST = Action.createRequestTypes(
  'ADD_USER_TO_ORDER_LIST',
);
export const addUserToOrderList = {
  request: (updatedValue: any, onSuccess: Function) =>
    Action.action(ADD_USER_TO_ORDER_LIST[constants.REQUEST], {
      updatedValue,
      onSuccess,
    }),
  success: () => Action.action(ADD_USER_TO_ORDER_LIST[constants.SUCCESS], {}),
  failure: (error: Error) =>
    Action.action(ADD_USER_TO_ORDER_LIST[constants.FAILURE], {error}),
};

//action yarattık. burdan da reducer'a ordan saga service deyip olayı güncelleyerek tatlıya bağlıcaz
export const GET_ONBOARDING_USERS = Action.createRequestTypes(
  'GET_ONBOARDING_USERS',
);
export const getOnBoardingUsers = {
  request: () => Action.action(GET_ONBOARDING_USERS[constants.REQUEST], {}),
  success: (data: any, count: number) =>
    Action.action(GET_ONBOARDING_USERS[constants.SUCCESS], {data, count}),
  failure: (error: Error) =>
    Action.action(GET_ONBOARDING_USERS[constants.FAILURE], {error}),
};

export const UPDATE_ONBOARDING_ORDER = Action.createRequestTypes(
  'UPDATE_ONBOARDING_ORDER',
);
export const updateOnBoardingOrder = {
  request: (updatedList: any) =>
    Action.action(UPDATE_ONBOARDING_ORDER[constants.REQUEST], {
      updatedList,
    }),
  success: (onBoardingList: any, count: number) =>
    Action.action(UPDATE_ONBOARDING_ORDER[constants.SUCCESS], {
      onBoardingList,
      count,
    }),
  failure: (error: Error) =>
    Action.action(UPDATE_ONBOARDING_ORDER[constants.FAILURE], {error}),
};

export const REMOVE_USER_FROM_ORDER_LIST = Action.createRequestTypes(
  'REMOVE_USER_FROM_ORDER_LIST',
);
export const removeUserFromOrderList = {
  request: (
    selectedLocale: string,
    userId: number,
    role: string,
    onSuccess: () => void,
  ) =>
    Action.action(REMOVE_USER_FROM_ORDER_LIST[constants.REQUEST], {
      selectedLocale,
      userId,
      role,
      onSuccess,
    }),
  success: () =>
    Action.action(REMOVE_USER_FROM_ORDER_LIST[constants.SUCCESS], {}),
  failure: (error: Error) =>
    Action.action(REMOVE_USER_FROM_ORDER_LIST[constants.FAILURE], {error}),
};
