// import {PURGE} from 'redux-persist';

import * as ActionTypes from '@actions/onboarding';

import type {OnboardingReducer} from './types';

const initialState: OnboardingReducer = {
  isLoading: false,
  error: false,
  errorDescription: '',
  onBoardingCategories: {
    data: [],
  },
  onBoardingCategoryUsers: {
    data: [],
  },
  onBoardingUsers: {
    data: [],
  },
};

export function onboarding(
  state = initialState,
  action: any = {},
): OnboardingReducer {
  switch (action.type) {
    // case PURGE: {
    //   return initialState;
    // }
    case ActionTypes.GET_ONBOARDNG_CATEGORIES.REQUEST: {
      return {
        ...state,
        isLoading: true,
        error: false,
        errorDescription: '',
      };
    }
    case ActionTypes.GET_ONBOARDNG_CATEGORIES.SUCCESS: {
      return {
        ...state,
        isLoading: false,
        error: false,
        errorDescription: '',
        onBoardingCategories: {
          data: action.onBoardingCategories,
        },
      };
    }
    case ActionTypes.GET_ONBOARDNG_CATEGORIES.FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: true,
        errorDescription: action.error.data?.message,
      };
    }

    case ActionTypes.DELETE_ONBOARDNG_CATEGORY.REQUEST: {
      return {
        ...state,
        isLoading: true,
        error: false,
        errorDescription: '',
      };
    }
    case ActionTypes.DELETE_ONBOARDNG_CATEGORY.SUCCESS: {
      const filterdCodes = state.onBoardingCategories.data.filter(
        (item) => item.id !== action.categoryId,
      );
      return {
        ...state,
        isLoading: false,
        error: false,
        errorDescription: '',
        onBoardingCategories: {
          data: filterdCodes,
        },
      };
    }
    case ActionTypes.DELETE_ONBOARDNG_CATEGORY.FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: true,
        errorDescription: action.error.data?.message,
      };
    }

    case ActionTypes.ADD_CATEGORY_TO_ONBOARDINGCATEGORIES.REQUEST: {
      return {
        ...state,
        isLoading: true,
        error: false,
        errorDescription: '',
      };
    }
    case ActionTypes.ADD_CATEGORY_TO_ONBOARDINGCATEGORIES.SUCCESS: {
      return {
        ...state,
        isLoading: false,
        error: false,
        errorDescription: '',
        onBoardingCategories: {
          ...state.onBoardingCategories,
          ...action.addedPosts,
        },
      };
    }
    case ActionTypes.ADD_CATEGORY_TO_ONBOARDINGCATEGORIES.FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: true,
        errorDescription: action.error.data?.message,
      };
    }

    case ActionTypes.GET_ONBOARDNG_CATEGORY_USERS.REQUEST: {
      return {
        ...state,
        isLoading: true,
        error: false,
        errorDescription: '',
      };
    }
    case ActionTypes.GET_ONBOARDNG_CATEGORY_USERS.SUCCESS: {
      return {
        ...state,
        isLoading: false,
        error: false,
        errorDescription: '',
        onBoardingCategoryUsers: {
          data: action.onBoardingCategoryUsers,
        },
      };
    }
    case ActionTypes.GET_ONBOARDNG_CATEGORY_USERS.FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: true,
        errorDescription: action.error.data?.message,
      };
    }

    case ActionTypes.DELETE_ONBOARDNG_CATEGORY_USERS.REQUEST: {
      return {
        ...state,
        isLoading: true,
        error: false,
        errorDescription: '',
      };
    }
    case ActionTypes.DELETE_ONBOARDNG_CATEGORY_USERS.SUCCESS: {
      const filteredCodes = state.onBoardingCategoryUsers.data.filter(
        (item) => item.id !== action.userId,
      );
      return {
        ...state,
        isLoading: false,
        error: false,
        errorDescription: '',
        onBoardingCategoryUsers: {
          data: filteredCodes,
        },
      };
    }
    case ActionTypes.DELETE_ONBOARDNG_CATEGORY_USERS.FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: true,
        errorDescription: action.error.data?.message,
      };
    }

    case ActionTypes.ADD_USER_TO_ONBOARDINGCATEGORY.REQUEST: {
      return {
        ...state,
        isLoading: true,
        error: false,
        errorDescription: '',
      };
    }
    case ActionTypes.ADD_USER_TO_ONBOARDINGCATEGORY.SUCCESS: {
      return {
        ...state,
        isLoading: false,
        error: false,
        errorDescription: '',
        // onBoardingCategories: {
        //   ...state.onBoardingCategories,
        //   ...action.addedPosts,
        // },
      };
    }
    case ActionTypes.ADD_USER_TO_ONBOARDINGCATEGORY.FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: true,
        errorDescription: action.error.data?.message,
      };
    }

    case ActionTypes.GET_ONBOARDNG_USERS.REQUEST: {
      return {
        ...state,
        isLoading: true,
        error: false,
        errorDescription: '',
      };
    }
    case ActionTypes.GET_ONBOARDNG_USERS.SUCCESS: {
      return {
        ...state,
        isLoading: false,
        error: false,
        errorDescription: '',
        onBoardingUsers: {
          data: action.onBoardingUsers,
        },
      };
    }
    case ActionTypes.GET_ONBOARDNG_USERS.FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: true,
        errorDescription: action.error.data?.message,
      };
    }

    case ActionTypes.UPDATE_ONBOARDING_CATEGORY_STATUS.REQUEST: {
      return {
        ...state,
        isLoading: true,
        error: false,
        errorDescription: '',
      };
    }
    case ActionTypes.UPDATE_ONBOARDING_CATEGORY_STATUS.SUCCESS: {
      return {
        ...state,
        isLoading: false,
        error: false,
        errorDescription: '',
        onBoardingCategories: {
          ...state.onBoardingCategories,
          data: state.onBoardingCategories.data.map((item) => {
            if (item.id === action.updatedData.id) {
              return {...item, status: action.updatedData.status};
            }
            return item;
          }),
        },
      };
    }

    // onBoardingCategories: {
    //   data: state.onBoardingCategories.data,
    //   id: action.updatedData.categoryId,
    //   status: action.updatedData.status,
    // },
    case ActionTypes.UPDATE_ONBOARDING_CATEGORY_STATUS.FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: true,
        errorDescription: action.error.data?.message,
      };
    }

    default:
      return state;
  }
}

// postDetail: {
//   ...state.postDetail,
//   title: action.uptadedPost.title,
//   description: action.uptadedPost.description,
//   note: action.uptadedPost.note,
//   furtherReadings: action.uptadedPost.furtherReadings,
//   isHiddenNewsFeed: action.uptadedPost.isHiddenNewsFeed,
//   isHiddenProfile: action.uptadedPost.isHiddenProfile,
// },
// };
