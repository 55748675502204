import {Axios} from '@utils';
import {constants} from '@resources';

export async function getCurationLists(page?: number, listType?: string) {
  if (listType === 'curation') {
    return await Axios.get(
      `${constants.CURATION_BASE}/management?limit=10000&offset=${page}&orderBy=createdAt&orderDirection=desc`,
    );
  } else {
    return await Axios.get(
      `${constants.CURATION_BASE}/management?limit=10000&offset=0&orderBy=order&orderDirection=asc&status=true&showOnDiscovery=true`,
    );
  }
}
