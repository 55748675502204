import {take, call, put} from 'redux-saga/effects';
import type {Effect} from 'redux-saga/effects';

import {updateWiserEditorialPicksOrder as updateWiserEditorialPicksOrderService} from '@services/curationList';
import * as CurationActions from '@actions/curation';

export function* updateWiserEditorialPicks(): Generator<Effect, void, any> {
  while (true) {
    try {
      const {updatedList, langSelection} = yield take(
        CurationActions.UPDATE_WISER_EDITORIAL_ORDER.REQUEST,
      );

      const {data: response} = yield call(
        updateWiserEditorialPicksOrderService,
        updatedList,
      );
      if (langSelection)
        yield put(
          CurationActions.getWiserEditorialPicks.request(langSelection),
        );
    } catch (error) {
      // Show info
      yield put(CurationActions.updateWiserEditorialPicksOrder.failure(error));
    }
  }
}
