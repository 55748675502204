import {constants} from '@resources';
import {Action} from '@utils';

export const GET_CATEGORIES = Action.createRequestTypes('GET_CATEGORIES');
export const getCategories = {
  request: (page: number) =>
    Action.action(GET_CATEGORIES[constants.REQUEST], {page}),
  success: (categories: any, page: number, categoriesCount: number) =>
    Action.action(GET_CATEGORIES[constants.SUCCESS], {
      categories,
      page,
      categoriesCount,
    }),
  failure: (error: Error) =>
    Action.action(GET_CATEGORIES[constants.FAILURE], {error}),
};

export const DELETE_CATEGORY = Action.createRequestTypes('DELETE_CATEGORY');
export const deleteCategory = {
  request: (categoryId: number, onSucess: Function) =>
    Action.action(DELETE_CATEGORY[constants.REQUEST], {
      categoryId,
      onSucess,
    }),
  success: (categoryId: number) =>
    Action.action(DELETE_CATEGORY[constants.SUCCESS], {categoryId}),
  failure: (error: Error) =>
    Action.action(DELETE_CATEGORY[constants.FAILURE], {error}),
};

export const GET_CATEGORY_DETAIL = Action.createRequestTypes(
  'GET_CATEGORY_DETAIL',
);
export const getCategoryDetail = {
  request: (categoryId: number) =>
    Action.action(GET_CATEGORY_DETAIL[constants.REQUEST], {
      categoryId,
    }),
  success: (categoryDetail: any) =>
    Action.action(GET_CATEGORY_DETAIL[constants.SUCCESS], {
      categoryDetail,
    }),
  failure: (error: Error) =>
    Action.action(GET_CATEGORY_DETAIL[constants.FAILURE], {error}),
};

export const UPDATE_CATEGORY = Action.createRequestTypes('UPDATE_CATEGORY');
export const updateCategory = {
  request: (categoryData: any, onSucess: Function) =>
    Action.action(UPDATE_CATEGORY[constants.REQUEST], {
      categoryData,
      onSucess,
    }),
  success: (updatedCategory: any) =>
    Action.action(UPDATE_CATEGORY[constants.SUCCESS], {
      updatedCategory,
    }),
  failure: (error: Error) =>
    Action.action(UPDATE_CATEGORY[constants.FAILURE], {error}),
};

export const CREATE_CATEGORY = Action.createRequestTypes('CREATE_CATEGORY');
export const createCategory = {
  request: (categoryData: any, locale: string, onSucess: Function) =>
    Action.action(CREATE_CATEGORY[constants.REQUEST], {
      categoryData,
      locale,
      onSucess,
    }),
  success: (createdCategory: any) =>
    Action.action(CREATE_CATEGORY[constants.SUCCESS], {
      createdCategory,
    }),
  failure: (error: Error) =>
    Action.action(CREATE_CATEGORY[constants.FAILURE], {error}),
};

export const MOVE_CONTENT = Action.createRequestTypes('MOVE_CONTENT');
export const moveContent = {
  request: (oldCategoryId: number, newCategoryId: number) =>
    Action.action(MOVE_CONTENT[constants.REQUEST], {
      oldCategoryId,
      newCategoryId,
    }),
  success: () => Action.action(MOVE_CONTENT[constants.SUCCESS], {}),
  failure: (error: Error) =>
    Action.action(MOVE_CONTENT[constants.FAILURE], {error}),
};
