import {constants} from '@resources';
import {Action} from '@utils';

export const GET_MOOD_CURATIONS = Action.createRequestTypes(
  'GET_MOOD_CURATIONS',
);
export const getMoodCurations = {
  request: (
    limit: number,
    offset: number,
    isUserCuration: boolean,
    langSelection?: string,
  ) =>
    Action.action(GET_MOOD_CURATIONS[constants.REQUEST], {
      limit,
      offset,
      isUserCuration,
      langSelection,
    }),
  success: (stats: any) =>
    Action.action(GET_MOOD_CURATIONS[constants.SUCCESS], {stats}),
  failure: (error: Error) =>
    Action.action(GET_MOOD_CURATIONS[constants.FAILURE], {error}),
};

export const ADD_CURATION_TO_MOOD = Action.createRequestTypes(
  'ADD_CURATION_TO_MOOD',
);
export const addCurationToMood = {
  request: (curationListId: number) =>
    Action.action(ADD_CURATION_TO_MOOD[constants.REQUEST], {
      curationListId,
    }),
  success: (stats: any) =>
    Action.action(ADD_CURATION_TO_MOOD[constants.SUCCESS], {stats}),
  failure: (error: Error) =>
    Action.action(ADD_CURATION_TO_MOOD[constants.FAILURE], {error}),
};

export const REMOVE_CURATION_FROM_MOOD = Action.createRequestTypes(
  'REMOVE_CURATION_FROM_MOOD',
);
export const removeCurationFromMood = {
  request: (curationListId: number) =>
    Action.action(REMOVE_CURATION_FROM_MOOD[constants.REQUEST], {
      curationListId,
    }),
  success: (stats: any) =>
    Action.action(REMOVE_CURATION_FROM_MOOD[constants.SUCCESS], {stats}),
  failure: (error: Error) =>
    Action.action(REMOVE_CURATION_FROM_MOOD[constants.FAILURE], {error}),
};

export const UPDATE_MOOD_ORDER = Action.createRequestTypes('UPDATE_MOOD_ORDER');
export const updateMoodOrder = {
  request: (curationListIds: Array<number>) => {
    return Action.action(UPDATE_MOOD_ORDER[constants.REQUEST], {
      curationListIds,
    });
  },
  success: (stats: any) =>
    Action.action(UPDATE_MOOD_ORDER[constants.SUCCESS], {stats}),
  failure: (error: Error) =>
    Action.action(UPDATE_MOOD_ORDER[constants.FAILURE], {error}),
};
