import {take, call, put} from 'redux-saga/effects';
import type {Effect} from 'redux-saga/effects';

import {updateOnboardingCategoryStatus as updateOnboardingCategoryStatusService} from '@services/onboarding';
import * as OnboardingActions from '@actions/onboarding';

export function* updateOnboardingCategoryStatus(): Generator<
  Effect,
  void,
  any
> {
  while (true) {
    try {
      const {updatedCategory, onSucess} = yield take(
        OnboardingActions.UPDATE_ONBOARDING_CATEGORY_STATUS.REQUEST,
      );
      const {data: response} = yield call(
        updateOnboardingCategoryStatusService,
        updatedCategory,
      );
      onSucess();

      yield put(
        OnboardingActions.updateOnboardingCategoryStatus.success(response.data),
      );
    } catch (error) {
      // Show info
      yield put(
        OnboardingActions.updateOnboardingCategoryStatus.failure(error),
      );
    }
  }
}
