import {Axios} from '@utils';
import {constants} from '@resources';

export async function getWeeklyUpdatedCurationLists(
  page?: number,
  listType?: string,
  updatedTime?: string,
) {
  if (updatedTime !== '') {
    return await Axios.get(
      `${constants.CURATION_BASE}/management?limit=10000&offset=${page}&orderBy=createdAt&orderDirection=desc&isUpdatedTime=true?updatedTime=${updatedTime}`,
    );
  } else {
    return await Axios.get(
      `${constants.CURATION_BASE}/management?limit=10000&offset=${page}&orderBy=createdAt&orderDirection=desc&isUpdatedTime=true`,
    );
  }
}
