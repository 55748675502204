import {take, call, put} from 'redux-saga/effects';
import type {Effect} from 'redux-saga/effects';

import {updatePublishers as updatePublisherUsersService} from '@services/users';
import * as PublishersActions from '@actions/users';

export function* updatePublishers(): Generator<Effect, void, any> {
  while (true) {
    try {
      const {updatePublishers, locale} = yield take(
        PublishersActions.UPDATE_PUBLISHER_ORDER.REQUEST,
      );
      const {data: response} = yield call(
        updatePublisherUsersService,
        updatePublishers,
        locale,
      );

      yield put(
        PublishersActions.getPublisherUsersByLocale.request(
          10000,
          0,
          'publisher',
          locale,
        ),
      );

      yield put(PublishersActions.updatePublisherOrder.success(response));
    } catch (error) {
      // Show info
      yield put(PublishersActions.updatePublisherOrder.failure(error));
    }
  }
}
