import React from 'react';
import {CImg} from '@coreui/react';

import {images} from '../../resources';

type Props = {
  publisher: string;
};

export const PostLogo: React.FC<Props> = (props) => {
  const {publisher} = props;

  const getLogo = () => {
    switch (publisher) {
      case 'Spotify':
        return images.spotify;
      case 'YouTube':
        return images.youtube;
      default:
        return images.other;
    }
  };

  return (
    <div className="c-avatar">
      <CImg src={getLogo().toString()} className="c-avatar-img" alt=" " />
    </div>
  );
};
