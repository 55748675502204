import {take, call, put} from 'redux-saga/effects';
import type {Effect} from 'redux-saga/effects';

import {deleteCategoriesPosts as deleteCategoriesPostsService} from '@services/posts';
import * as PostActions from '@actions/posts';

export function* deleteCategoriesPosts(): Generator<Effect, void, any> {
  while (true) {
    try {
      const {deletePostId, categoryId, onSucess} = yield take(
        PostActions.DELETE_CATEGORIES_POSTS.REQUEST,
      );
      yield call(deleteCategoriesPostsService, deletePostId, categoryId);
      onSucess();
      yield put(PostActions.deleteCategoriesPosts.success(deletePostId));
    } catch (error) {
      // Show info
      yield put(PostActions.deleteCategoriesPosts.failure(error));
    }
  }
}
