import {Axios} from '@utils';
import {constants} from '../../resources';

export function getOnboardingUsers(
  categoryIds?: Array<any>,
  selectedLocale?: any,
) {
  let params = '';

  if (categoryIds?.length !== 0 && categoryIds !== undefined) {
    const categories = `?categoryIds=[${categoryIds}]`;
    params = categories + params;
  }
  if (params.length !== 0) {
    return Axios.get(
      `${constants.ONBOARDING_BASE}/users` +
        params +
        `&locale=` +
        selectedLocale,
    );
  } else {
    return Axios.get(
      `${constants.ONBOARDING_BASE}/users?locale=` + selectedLocale,
    );
  }
}
