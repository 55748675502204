import {Axios} from '@utils';
import {constants} from '@resources';

export async function getTags(searchFields?: any) {
  if (
    searchFields &&
    (searchFields.name !== '' ||
      searchFields.orderBy !== '' ||
      searchFields.orderDirection !== '')
  ) {
    if (searchFields.orderBy === '' || searchFields.orderBy === undefined) {
      if (searchFields.name == undefined) {
        if (searchFields.ignoredTags.length > 0) {
          return Axios.get(
            `${constants.TAG_BASE}/filter?orderBy=created_at&orderDirection=DESC&limit=1000&ignoredTags=[${searchFields.ignoredTags}]`,
          );
        } else {
          return Axios.get(
            `${constants.TAG_BASE}/filter?orderBy=created_at&orderDirection=DESC&limit=1000`,
          );
        }
      } else {
        if (searchFields.ignoredTags && searchFields.ignoredTags.length > 0) {
          return Axios.get(
            `${constants.TAG_BASE}/filter?orderBy=created_at&orderDirection=DESC&limit=1000&name=${searchFields.name}&ignoredTags=[${searchFields.ignoredTags}]`,
          );
        }
        return Axios.get(
          `${constants.TAG_BASE}/filter?orderBy=created_at&orderDirection=DESC&limit=1000&name=${searchFields.name}`,
        );
      }
    } else {
      return Axios.get(
        `${constants.TAG_BASE}/filter?orderBy=${searchFields.orderBy}&orderDirection=${searchFields.orderDirection}&limit=1000&name=${searchFields.name}`,
      );
    }
  }
  return Axios.get(
    `${constants.TAG_BASE}/filter?orderBy=created_at&orderDirection=DESC&limit=1000`,
  );
}
