import {take, call, put} from 'redux-saga/effects';
import type {Effect} from 'redux-saga/effects';

import {getOnBoardingUsers as getOnBoardingUsersService} from '@services/users';
import * as UsersActions from '@actions/users';

export function* getOnBoardingUsers(): Generator<Effect, void, any> {
  while (true) {
    try {
      //const {} =
      yield take(UsersActions.GET_ONBOARDING_USERS.REQUEST);
      const {data: response} = yield call(getOnBoardingUsersService);

      yield put(
        UsersActions.getOnBoardingUsers.success(response.data, response.count),
      );
    } catch (error) {
      // Show info
      yield put(UsersActions.getOnBoardingUsers.failure(error));
    }
  }
}
