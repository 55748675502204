import {take, call, put} from 'redux-saga/effects';
import type {Effect} from 'redux-saga/effects';

import {addCategoryToOnboardingCategories as addCategoryToOnboardingCategoriesService} from '@services/onboarding';
import * as OnboardingActions from '@actions/onboarding';

export function* addCategoryToOnboardingCategories(): Generator<
  Effect,
  void,
  any
> {
  while (true) {
    try {
      const {categoryId, onSucess, locale} = yield take(
        OnboardingActions.ADD_CATEGORY_TO_ONBOARDINGCATEGORIES.REQUEST,
      );

      const {data: response} = yield call(
        addCategoryToOnboardingCategoriesService,
        categoryId,
        locale,
      );

      yield put(
        OnboardingActions.addCategoryToOnboardingCategories.success(categoryId),
      );
      onSucess();
    } catch (error) {
      // Show info
      yield put(
        OnboardingActions.addCategoryToOnboardingCategories.failure(error),
      );
    }
  }
}
