import {Axios} from '@utils';
import {constants} from '@resources';

export async function getCurationUsers(
  offset: number,
  searchFields?: {
    userName: string;
    fullName: string;
    firstName: string;
    lastName: string;
    role: string;
  },
) {
  if (searchFields) {
    return Axios.get(
      `${constants.USER_MANAGEMENT_BASE}/searchShortList?limit=10` +
        `&offset=` +
        offset +
        `&firstName=` +
        searchFields.firstName +
        `&lastName=` +
        searchFields.lastName +
        `&fullName=` +
        searchFields.fullName +
        `&userName=` +
        searchFields.userName +
        `&role=` +
        searchFields.role,
    );
  } else {
    return Axios.get(
      `${constants.USER_MANAGEMENT_BASE}/searchShortList?limit=10&offset=` +
        offset,
    );
  }
}
