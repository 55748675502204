import {Axios} from '@utils';
import {constants} from '@resources';

export function addPostsToCuration(postIds: any, curationId: number) {
  const parameters = {
    postIds: postIds,
  };
  return Axios.post(
    `${constants.CURATION_BASE}/${curationId}/posts`,
    parameters,
  );
}
