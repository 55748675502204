import {take, call, put} from 'redux-saga/effects';
import type {Effect} from 'redux-saga/effects';

import {deleteUsers as deleteUsersService} from '@services/users';
import * as UsersActions from '@actions/users';

export function* deleteUsers(): Generator<Effect, void, any> {
  while (true) {
    try {
      const {deletedId, onSuccess} = yield take(
        UsersActions.DELETE_USERS.REQUEST,
      );
      const {data: response} = yield call(deleteUsersService, deletedId);
      onSuccess();
      yield put(UsersActions.deleteUsers.success([]));
    } catch (error) {
      // Show info
      yield put(UsersActions.deleteUsers.failure(error));
    }
  }
}
