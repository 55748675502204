import React, {useState, useEffect} from 'react';
import {useHistory, useLocation} from 'react-router-dom';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';

import {
  CBadge,
  CCard,
  CCardHeader,
  CCol,
  CRow,
  CImg,
  CButton,
} from '@coreui/react';
import moment from 'moment';
import {getAllUsers as getAllUsersAction} from '@actions/users';
import {WSRDataTable} from '../../../../components';
import {values} from './values';

const getBadge = (status: string) => {
  switch (status) {
    case 'admin':
      return 'success';
    case 'user':
      return 'secondary';
    case 'wiser':
      return 'warning';
    case 'Banned':
      return 'danger';
    default:
      return 'primary';
  }
};

type Item = {
  id: number;
  role: string;
  email: string;
  photo: string;
  phone: string;
  InvitationCode: {
    code: string;
  };
};

export const PostUsersList = () => {
  const history = useHistory();
  const queryPage = useLocation().search.match(/page=([0-9]+)/);
  const currentPage = Number(queryPage && queryPage[1] ? queryPage[1] : 1);
  const [page, setPage] = useState(currentPage);
  const pageLimit = 10;

  const dispatch = useDispatch();

  const {userList, selectedLocale} = useSelector(
    (state) => ({
      userList: state.users.users,
      selectedLocale: state.management.selectedLocale || {locale: 'gb'},
    }),
    shallowEqual,
  );

  const pageChange = (newPage: number) => {
    if ((newPage - 1) * 10 >= userList.data.length) {
      dispatch(
        getAllUsersAction.request(
          10,
          userList.page + 10,
          'postUser',
          '',
          {},
          selectedLocale.locale,
        ),
      );
    }
    if (currentPage !== newPage) {
      history.push(`/postUsers?page=${newPage}`);
    }
  };

  useEffect(() => {
    currentPage !== page && setPage(currentPage);
  }, [currentPage, page]);

  const scopedSlots = {
    role: (item: Item) => (
      <td>
        <CBadge style={{fontSize: '100%'}} color={getBadge(item.role)}>
          {item.role}
        </CBadge>
      </td>
    ),
    photo: (item: Item) => (
      <td>
        <div className="c-avatar">
          <CImg
            src={item.photo == null ? 'avatars/blank-profile.jpg' : item.photo}
            className="c-avatar"
            alt={''}
          />
        </div>
      </td>
    ),
    fullName: (item: any) => (
      <td>
        <div>{item.fullName}</div>
        <div className="small text-muted">
          Registered: {moment(item.createdAt).format('DD/MM/YYYY')}
        </div>
      </td>
    ),
    email: (item: Item) => (
      <td>
        <div className="">{item.email != null ? item.email : '-'}</div>
      </td>
    ),
  };

  const onClick = (item: any) => {
    history.push(`/editorialDetail/${item.id}`);
  };

  useEffect(() => {
    pageChange(1);
  }, [selectedLocale]);

  return (
    <CRow>
      <CCol xl={12}>
        <CCard>
          <CCardHeader>
            <CRow>
              <CCol sm="4" md="2" className="mb-3 mb-xl-0 c-table-header">
                Curators ({userList.count})
              </CCol>
              <CCol sm="4" md="2" className="mb-3 mb-xl-0 mfs-auto">
                <CButton
                  className="my-2 mfs-auto"
                  block
                  onClick={() => history.push(`/editorialCreate?postUser`)}
                  color="success">
                  Create
                </CButton>
              </CCol>
            </CRow>
          </CCardHeader>
          <WSRDataTable
            items={userList.data}
            fields={values.fields}
            scopedSlots={scopedSlots}
            pageLimit={pageLimit}
            page={page}
            pageChange={pageChange}
            itemsCount={userList.count}
            onClick={onClick}></WSRDataTable>
        </CCard>
      </CCol>
    </CRow>
  );
};
