import React, {useState, useEffect} from 'react';
import {useHistory, useLocation} from 'react-router-dom';
import {useSelector} from 'react-redux';
import moment from 'moment';
import {
  CCard,
  CCardHeader,
  CCol,
  CRow,
  CForm,
  CImg,
  CBadge,
} from '@coreui/react';
import {WSRDataTable} from '../../../../components';

type Item = {
  id: number;
  fullName: string;
  photo: string;
  role: string;
  jobTitle: string;
  status: string;
  createdAt: string;
  email: string;
};

const getBadge = (status: string) => {
  switch (status) {
    case 'admin':
      return 'success';
    case 'user':
      return 'secondary';
    case 'wiser':
      return 'warning';
    case 'Banned':
      return 'danger';
    default:
      return 'primary';
  }
};

export const AdminsList = () => {
  const history = useHistory();
  const queryPage = useLocation().search.match(/page=([0-9]+)/);
  const currentPage = Number(queryPage && queryPage[1] ? queryPage[1] : 1);
  const [page, setPage] = useState(currentPage);

  const {admins} = useSelector((state) => ({
    admins: state.users.admins,
  }));

  const fields = [
    {
      key: 'photo',
      sorter: false,
      filter: false,
      _style: {width: '5%'},
    },
    {
      key: 'fullName',
      sorter: true,
      filter: false,
      _style: {width: '10%'},
      _classes: 'font-weight-bold',
    },
    {
      key: 'jobTitle',
      label: 'Job Title',
      sorter: true,
      filter: false,
      _style: {width: '5%'},
    },
    {
      key: 'email',
      label: 'Email',
      sorter: true,
      filter: false,
      _style: {width: '5%'},
    },
    {
      key: 'role',
      label: 'Role',
      sorter: false,
      filter: false,
      _style: {width: '5%'},
    },
  ];

  const scopedSlots = {
    photo: (item: Item) => (
      <td>
        <div className="c-avatar">
          <CImg
            src={item.photo || 'avatars/blank-profile.jpg'}
            className="c-avatar-img"
            alt={''}
          />
        </div>
      </td>
    ),
    fullName: (item: any) => (
      <td>
        <div>{item.fullName}</div>
        <div className="small text-muted">
          Registered: {moment(item.createdAt).format('DD/MM/YYYY')}
        </div>
      </td>
    ),
    role: (item: any) => (
      <td>
        <CBadge style={{fontSize: '100%'}} color={getBadge(item.role)}>
          {item.role}
        </CBadge>
      </td>
    ),
  };

  const onClick = (item: Item) => {
    history.push({
      pathname: `/admin/${item.id}`,
      state: {user: item},
    });
  };

  const pageChange = (newPage: number) => {
    currentPage !== 1 &&
      currentPage !== newPage &&
      history.push(`/admins/?page=${newPage}`);
  };

  useEffect(() => {
    currentPage !== page && setPage(currentPage);
  }, [currentPage, page]);
  return (
    <CRow>
      <CCol xl={12}>
        <CForm inline></CForm>
        <CCard>
          <CCardHeader>
            <CRow>
              <CCol sm="4" md="2" className="mb-3 mb-xl-0 c-table-header">
                Admins ({admins.count})
              </CCol>
            </CRow>
          </CCardHeader>
          <WSRDataTable
            items={admins.data}
            fields={fields}
            scopedSlots={scopedSlots}
            pageLimit={10}
            page={page}
            pageChange={pageChange}
            itemsCount={admins.count}
            onClick={onClick}></WSRDataTable>
        </CCard>
      </CCol>
    </CRow>
  );
};
