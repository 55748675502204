import {constants} from '@resources';
import {Action} from '@utils';

export const SET_SIDEBAR_SHOW = Action.createRequestTypes('SET_SIDEBAR_SHOW');
export const setSideBarShow = {
  request: (show: boolean) =>
    Action.action(SET_SIDEBAR_SHOW[constants.REQUEST], {show}),
};

export const GET_ALL_LOCALES = Action.createRequestTypes('GET_ALL_LOCALES');
export const getAllLocales = {
  request: () => Action.action(GET_ALL_LOCALES[constants.REQUEST], null),
  success: (locales: Array<{locale: string}>) =>
    Action.action(GET_ALL_LOCALES[constants.SUCCESS], {locales}),
  failure: (error: any) =>
    Action.action(GET_ALL_LOCALES[constants.FAILURE], {error}),
};

export const SET_SELECTED_LOCALE = Action.createRequestTypes(
  'SET_SELECTED_LOCALE',
);
export const setSelectedLocale = {
  request: (selectedLocale: {locale: string}) =>
    Action.action(SET_SELECTED_LOCALE[constants.REQUEST], {selectedLocale}),
};

export const GET_WEEKLY_UPDATED_TIME = Action.createRequestTypes(
  'GET_WEEKLY_UPDATED_TIME',
);
export const getWeeklyUpdatedTime = {
  request: () =>
    Action.action(GET_WEEKLY_UPDATED_TIME[constants.REQUEST], null),
  success: (updatedTime: Array<string>) =>
    Action.action(GET_WEEKLY_UPDATED_TIME[constants.SUCCESS], {
      updatedTime,
    }),
  failure: (error: any) =>
    Action.action(GET_WEEKLY_UPDATED_TIME[constants.FAILURE], {error}),
};

export const GET_ALL_LANGUAGES = Action.createRequestTypes('GET_ALL_LANGUAGES');
export const getAllLanguages = {
  request: () => Action.action(GET_ALL_LANGUAGES[constants.REQUEST]),
  success: (languages: any) =>
    Action.action(GET_ALL_LANGUAGES[constants.SUCCESS], {languages}),
  failure: (error: Error) =>
    Action.action(GET_ALL_LANGUAGES[constants.FAILURE], {error}),
};
