export const values = {
  fields: [
    {
      key: 'photo',
      sorter: false,
      filter: false,
      _style: {width: '5%'},
    },
    {
      key: 'fullName',
      sorter: true,
      filter: false,
      _style: {width: '20%'},
      _classes: 'font-weight-bold',
    },
    {
      key: 'jobTitle',
      label: 'Job Title',
      sorter: true,
      filter: false,
      _style: {width: '5%'},
    },
    {
      key: 'role',
      label: 'Role',
      sorter: false,
      filter: false,
      _style: {width: '5%'},
    },
  ],
};
