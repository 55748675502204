import {take, put, call} from 'redux-saga/effects';
import type {Effect} from 'redux-saga/effects';

import {removeCurationsFromMoodAndWeekly as removeCurationsFromMoodAndWeeklyService} from '@services/curationList';
import * as CurationActions from '@actions/curation';

export function* updateStatusOfCurations(): Generator<Effect, void, any> {
  while (true) {
    try {
      const {curationIds, page} = yield take(
        CurationActions.CHANGE_STATUS_OF_CURATIONS.REQUEST,
      );

      const {data: response} = yield call(
        removeCurationsFromMoodAndWeeklyService,
        curationIds,
        page,
      );

      yield put(CurationActions.updateStatusOfCurations.success(response));
    } catch (error) {
      // Show info
      yield put(CurationActions.updateStatusOfCurations.failure(error));
    }
  }
}
