import {take, call, put} from 'redux-saga/effects';
import type {Effect} from 'redux-saga/effects';

import {createCuration as createCurationService} from '@services/curationList';
import * as CurationActions from '@actions/curation';

export function* createCuration(): Generator<Effect, void, any> {
  while (true) {
    try {
      const {createData, onSucess} = yield take(
        CurationActions.CREATE_CURATION.REQUEST,
      );
      const {data: response} = yield call(createCurationService, createData);
      onSucess(response.id);
      yield put(CurationActions.createCuration.success([]));
    } catch (error) {
      // Show info
      yield put(CurationActions.createCuration.failure(error));
    }
  }
}
