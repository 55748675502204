import React, {useEffect, useState} from 'react';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';
import moment from 'moment';

import {getUncategorisedPosts as getUncategorisedPostsAction} from '@actions/posts';

import {values} from './values';
import {PostLogo} from './../../components/PostLogo';
import {WSRDataTable} from './components/WSRDataTable';
import {CCard, CCardBody, CCol, CRow, CButton} from '@coreui/react';
import AddCategory from './components/AddCategory';

const UncategorisedPosts = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [addCategoryModal, setAddCategoryModal] = useState(false);
  const [selectedPost, setSelectedPost] = useState({});
  const [selectedCategories, setSelectedCategories] = useState<Array<any>>([
    {},
  ]);

  const {uncategorisedPostList} = useSelector(
    (state) => ({
      uncategorisedPostList: state.posts.uncategorisedPostList,
    }),
    shallowEqual,
  );

  const addCategoryHandle = (postId: any) => {
    const selectedPost = uncategorisedPostList.uncategorisedPosts.filter(
      (a) => a.id === postId,
    )[0];

    setSelectedPost(selectedPost);
    setAddCategoryModal(true);
  };

  const scopedSlots = {
    title: (item: any) => (
      <td onClick={() => history.push(`/posts/${item.id}`)}>
        <div className="cut-text">{item.title}</div>
        <div className="small text-muted">
          {item.title
            ? `Published: ${moment(item.date).format('DD/MM/YYYY')}`
            : '---'}
        </div>
      </td>
    ),
    date: (item: any) => <td>{moment(item.createdAt).format('DD/MM/YYYY')}</td>,
    logo: (item: any) => (
      <td>
        <PostLogo publisher={item.publisher} />
      </td>
    ),
    user: (item: any) => (
      <td>
        <div className="cut-text">{item.user.fullName}</div>
      </td>
    ),
    category: (item: any) => (
      <span
        style={{
          width: '100%',
          height: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}>
        {selectedCategories
          .filter((a) => a.postId === item.id)
          .map((b: any) => b.categories).length != 0 ? (
          <div>
            {selectedCategories
              .filter((a) => a.postId === item.id)
              .map((b: any) => b.categories)
              .map((c) => (
                <div key={c}>{c + ','} </div>
              ))}
          </div>
        ) : (
          <CButton
            style={{backgroundColor: 'blue', color: 'white'}}
            onClick={() => addCategoryHandle(item.id)}>
            Add Category
          </CButton>
        )}
      </span>
    ),
    link: (item: any) => (
      <span
        style={{
          width: '100%',
          height: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}>
        <CButton
          style={{backgroundColor: 'blue', color: 'white', marginTop: '8px'}}
          onClick={() => window.open(item.url, 'blank')?.focus()}>
          See More
        </CButton>
      </span>
    ),
  };

  useEffect(() => {
    dispatch(getUncategorisedPostsAction.request());
  }, []);

  return (
    <span>
      <CRow>
        <CCol xl={12}>
          <CCard>
            <CCardBody>
              <div style={{marginTop: 10, marginBottom: 25}}>
                <h5>
                  Uncategorised Posts (
                  {uncategorisedPostList.uncategorisedPosts.length})
                </h5>
              </div>
              <WSRDataTable
                items={uncategorisedPostList.uncategorisedPosts}
                fields={values.fields}
                scopedSlots={scopedSlots}
              />
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>

      <AddCategory
        setCategoryModal={setAddCategoryModal}
        setSelectedCategoriesUP={setSelectedCategories}
        categoryModal={addCategoryModal}
        post={selectedPost}
      />
    </span>
  );
};

export default UncategorisedPosts;
