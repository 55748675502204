import React, {useEffect, useState} from 'react';
import {useSelector, shallowEqual} from 'react-redux';
import {
  CButton,
  CButtonGroup,
  CCard,
  CCardBody,
  CCol,
  CRow,
} from '@coreui/react';
import moment from 'moment';

import {PostList} from '../PostList';

type ButtonData = {
  value: string;
  key: 'podcast' | 'video' | 'article';
};
const buttonsData: Array<ButtonData> = [
  {
    value: 'Podcast',
    key: 'podcast',
  },
  {
    value: 'Video',
    key: 'video',
  },
  {
    value: 'Article',
    key: 'article',
  },
];

export const LastSharedPosts: React.FC = () => {
  const [lastSharedPage, setLastSharedPage] = useState(1);
  const [currentLastSharedPost, setCurrentLastSharedPost] = useState(0);
  const [selectedButton, setSelectedButton] = useState(buttonsData[0]);

  const {lastSharedPosts} = useSelector(
    (state) => ({
      lastSharedPosts: state.posts.lastSharedPosts,
    }),
    shallowEqual,
  );

  // FIXME: fix this mess... // ibocan@joinwiser.com
  const lastSharedPostPageChange = (newPage: number) => {
    if (newPage === 0) newPage = 1;
    setCurrentLastSharedPost(newPage);
  };

  useEffect(() => {
    if (currentLastSharedPost !== undefined)
      currentLastSharedPost !== lastSharedPage &&
        setLastSharedPage(currentLastSharedPost);
  }, [currentLastSharedPost, lastSharedPage]);

  return (
    <CCard>
      <CCardBody>
        <CRow>
          <CCol>
            <h5 id="traffic" className="card-title mb-2">
              Last 10 Shared Posts
            </h5>
            <div className="small text-muted">
              {moment().format('DD/MM/YYYY')}
            </div>
            <br />
          </CCol>
          <CCol className="d-none d-md-block">
            <CButtonGroup className="float-right mr-3">
              {buttonsData.map((item) => (
                <CButton
                  color="outline-info"
                  key={item.key}
                  className="mx-0"
                  active={item.key === selectedButton.key}
                  onClick={() => setSelectedButton(item)}>
                  {item.value}
                </CButton>
              ))}
            </CButtonGroup>
          </CCol>
        </CRow>
        <br />
        <PostList
          page={lastSharedPage}
          pageLimit={10}
          pageChange={lastSharedPostPageChange}
          postList={lastSharedPosts[selectedButton.key]}
        />
      </CCardBody>
    </CCard>
  );
};
