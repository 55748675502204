import {take, call, put} from 'redux-saga/effects';
import type {Effect} from 'redux-saga/effects';

import {updateTag as updateTagService} from '@services/tags';
import * as CurationActions from '@actions/tags';

export function* updateTag(): Generator<Effect, void, any> {
  while (true) {
    try {
      const {tagId, categoryId, searchFields} = yield take(
        CurationActions.UPDATE_TAG.REQUEST,
      );

      const {data: response} = yield call(updateTagService, tagId, categoryId);

      yield put(CurationActions.updateTag.success(response.data));

      yield put(CurationActions.getTags.request(searchFields));
    } catch (error) {
      // Show info
      yield put(CurationActions.updateTag.failure(error));
    }
  }
}
