import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {
  CCard,
  CCardHeader,
  CCol,
  CDataTable,
  CRow,
  CButton,
  CModal,
  CModalBody,
  CModalHeader,
  CModalTitle,
  CModalFooter,
  CInputCheckbox,
} from '@coreui/react';

import {getCategories as getCategoriesAction} from '@actions/categories';
import {
  updateTag as updateTagAction,
  removeCategoryFromTag as removeCategoryFromTagAction,
} from '@actions/tags';

import type {ChangeEvent} from 'react';

type Props = {
  tagID: number;
  selectedTag?: any;
  setCategoryModal: Function;
  categoryModal: boolean;
  searchFields?: any;
};
export const AddCategory: React.FC<Props> = (props: Props) => {
  const dispatch = useDispatch();
  const {
    categoryModal,
    setCategoryModal,
    tagID,
    searchFields,
    selectedTag,
  } = props;

  const {categoryList} = useSelector((state) => ({
    categoryList: state.categories.categoriesList,
  }));

  const [selectedCategorie, setSelectedCategorie] = useState<any>();

  const handleOnRowClick = (event: ChangeEvent<HTMLInputElement>) => {
    setSelectedCategorie(event);
  };

  const handleDone = () => {
    const selectedCategorieID = selectedCategorie.id;
    if (selectedTag && selectedTag.category && selectedTag.category.id) {
      dispatch(
        removeCategoryFromTagAction.request(
          tagID,
          selectedCategorieID,
          searchFields,
          selectedTag.category.id,
        ),
      );
    } else {
      dispatch(
        updateTagAction.request(tagID, selectedCategorieID, searchFields),
      );
    }
    closeModal();
  };

  const closeModal = () => {
    setSelectedCategorie([]);
    setCategoryModal(false);
  };

  const handleCancel = () => {
    setSelectedCategorie([]);
    setCategoryModal(false);
  };

  useEffect(() => {
    const modalElement = document.querySelector('.modal');
    if (modalElement) {
      modalElement.scrollTop = 0;
    }
  }, [categoryModal]);

  useEffect(() => {
    dispatch(getCategoriesAction.request(1));
  }, []);

  return (
    <CModal
      className="modal"
      show={categoryModal}
      size="lg"
      onClose={() => {
        setSelectedCategorie([]);
        setCategoryModal(false);
      }}>
      <CModalHeader>
        <CModalTitle>
          All Categories({categoryList.categoriesCount})
        </CModalTitle>
        <CModalTitle>Select 1 Category</CModalTitle>
      </CModalHeader>
      <CModalBody>
        <CRow>
          <CCol xl={12}>
            <CCard>
              <CCardHeader>Categories</CCardHeader>
              <CDataTable
                items={categoryList.categories}
                fields={[
                  'id',
                  {
                    key: 'name',
                    label: 'Name',
                  },
                  'status',
                  {
                    key: 'select_row',
                    label: '',
                    _style: {width: '5%'},
                    sorter: false,
                    filter: false,
                  },
                ]}
                hover
                onRowClick={(event: ChangeEvent<HTMLInputElement>) => {
                  handleOnRowClick(event);
                }}
                border
                size="sm"
                striped
                clickableRows
                scopedSlots={{
                  name: (item: any) => (
                    <td key={item.id} className="py-2">
                      {item.name_translate.en}
                    </td>
                  ),
                  select_row: (item: any) => {
                    let isChecked = false;
                    if (selectedCategorie && selectedCategorie.id == item.id) {
                      if (selectedCategorie.id == item.id) {
                        isChecked = true;
                      } else {
                        isChecked = false;
                      }
                    } else if (
                      selectedTag &&
                      selectedTag.category &&
                      selectedTag.category.id == item.id
                    ) {
                      selectedCategorie && selectedCategorie.id
                        ? (isChecked = false)
                        : (isChecked = true);
                    }

                    return (
                      <td key={item.id} className="py-2">
                        <CInputCheckbox
                          key={item.id}
                          name={item.id}
                          className="checkbox"
                          checked={isChecked}
                        />
                      </td>
                    );
                  },
                }}
              />
            </CCard>
          </CCol>
        </CRow>
      </CModalBody>
      <CModalFooter className="d-flex justify-content-between">
        <CButton className="col-3" onClick={handleCancel} color="danger">
          Cancel
        </CButton>
        <CButton className="col-3" onClick={handleDone} color="success">
          Done
        </CButton>
      </CModalFooter>
    </CModal>
  );
};
