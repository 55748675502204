import {take, call, put} from 'redux-saga/effects';
import type {Effect} from 'redux-saga/effects';

import {getCategoriesPosts as getCategoriesPostsService} from '@services/posts';
import * as PostActions from '@actions/posts';

export function* getCategoriesPosts(): Generator<Effect, void, any> {
  while (true) {
    try {
      const {page, categoriesId} = yield take(
        PostActions.GET_CATEGORIES_POSTS.REQUEST,
      );
      const {data: response} = yield call(
        getCategoriesPostsService,
        page,
        categoriesId,
      );
      yield put(
        PostActions.getCategoriesPosts.success(
          response.data,
          page,
          response.count,
        ),
      );
    } catch (error) {
      // Show info
      yield put(PostActions.getCategoriesPosts.failure(error));
    }
  }
}
