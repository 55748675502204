import {constants} from '@resources';
import {Action} from '@utils';

export const GET_ONBOARDNG_CATEGORIES = Action.createRequestTypes(
  'GET_ONBOARDNG_CATEGORIES',
);
export const getOnboardingCategories = {
  request: (langCode: string) =>
    Action.action(GET_ONBOARDNG_CATEGORIES[constants.REQUEST], {langCode}),
  success: (onBoardingCategories: any) =>
    Action.action(GET_ONBOARDNG_CATEGORIES[constants.SUCCESS], {
      onBoardingCategories,
    }),
  failure: (error: Error) =>
    Action.action(GET_ONBOARDNG_CATEGORIES[constants.FAILURE], {error}),
};

export const DELETE_ONBOARDNG_CATEGORY = Action.createRequestTypes(
  'DELETE_ONBOARDNG_CATEGORY',
);
export const deleteOnboardingCategory = {
  request: (categoryId: number, onSucess: Function) =>
    Action.action(DELETE_ONBOARDNG_CATEGORY[constants.REQUEST], {
      categoryId,
      onSucess,
    }),
  success: (categoryId: number) =>
    Action.action(DELETE_ONBOARDNG_CATEGORY[constants.SUCCESS], {categoryId}),
  failure: (error: Error) =>
    Action.action(DELETE_ONBOARDNG_CATEGORY[constants.FAILURE], {error}),
};

export const ADD_CATEGORY_TO_ONBOARDINGCATEGORIES = Action.createRequestTypes(
  'ADD_CATEGORY_TO_ONBOARDINGCATEGORIES',
);
export const addCategoryToOnboardingCategories = {
  request: (categoryId: any, onSucess: Function, locale: any) =>
    Action.action(ADD_CATEGORY_TO_ONBOARDINGCATEGORIES[constants.REQUEST], {
      categoryId,
      onSucess,
      locale,
    }),
  success: (addedPosts: any) =>
    Action.action(ADD_CATEGORY_TO_ONBOARDINGCATEGORIES[constants.SUCCESS], {
      addedPosts,
    }),
  failure: (error: Error) =>
    Action.action(ADD_CATEGORY_TO_ONBOARDINGCATEGORIES[constants.FAILURE], {
      error,
    }),
};

export const GET_ONBOARDNG_CATEGORY_USERS = Action.createRequestTypes(
  'GET_ONBOARDNG_CATEGORY_USERS',
);
export const getOnboardingCategoryUsers = {
  request: (categoryId: any) =>
    Action.action(GET_ONBOARDNG_CATEGORY_USERS[constants.REQUEST], {
      categoryId,
    }),
  success: (onBoardingCategoryUsers: any) =>
    Action.action(GET_ONBOARDNG_CATEGORY_USERS[constants.SUCCESS], {
      onBoardingCategoryUsers,
    }),
  failure: (error: Error) =>
    Action.action(GET_ONBOARDNG_CATEGORY_USERS[constants.FAILURE], {error}),
};

export const DELETE_ONBOARDNG_CATEGORY_USERS = Action.createRequestTypes(
  'DELETE_ONBOARDNG_CATEGORY_USERS',
);
export const deleteOnboardingCategoryUsers = {
  request: (categoryId: number, userId: number, onSucess: Function) =>
    Action.action(DELETE_ONBOARDNG_CATEGORY_USERS[constants.REQUEST], {
      categoryId,
      userId,
      onSucess,
    }),
  success: (userId: number) =>
    Action.action(DELETE_ONBOARDNG_CATEGORY_USERS[constants.SUCCESS], {
      userId,
    }),
  failure: (error: Error) =>
    Action.action(DELETE_ONBOARDNG_CATEGORY_USERS[constants.FAILURE], {error}),
};

export const ADD_USER_TO_ONBOARDINGCATEGORY = Action.createRequestTypes(
  'ADD_CATEGORY_TO_ONBOARDINGCATEGORIES',
);
export const addUserToOnboardingCategory = {
  request: (categoryId: number, userId: number, onSucess: Function) =>
    Action.action(ADD_USER_TO_ONBOARDINGCATEGORY[constants.REQUEST], {
      categoryId,
      userId,
      onSucess,
    }),
  success: (addedPosts: any) =>
    Action.action(ADD_USER_TO_ONBOARDINGCATEGORY[constants.SUCCESS], {
      addedPosts,
    }),
  failure: (error: Error) =>
    Action.action(ADD_USER_TO_ONBOARDINGCATEGORY[constants.FAILURE], {
      error,
    }),
};

export const GET_ONBOARDNG_USERS = Action.createRequestTypes(
  'GET_ONBOARDNG_USERS',
);
export const getOnboardingUsers = {
  request: (categoryIds?: any, selectedLocale?: any) =>
    Action.action(GET_ONBOARDNG_USERS[constants.REQUEST], {
      categoryIds,
      selectedLocale,
    }),
  success: (onBoardingUsers: any) =>
    Action.action(GET_ONBOARDNG_USERS[constants.SUCCESS], {
      onBoardingUsers,
    }),
  failure: (error: Error) =>
    Action.action(GET_ONBOARDNG_USERS[constants.FAILURE], {error}),
};

export const UPDATE_ONBOARDING_CATEGORY_STATUS = Action.createRequestTypes(
  'UPDATE_ONBOARDING_CATEGORY_STATUS',
);
export const updateOnboardingCategoryStatus = {
  request: (updatedCategory: any, onSucess: Function) =>
    Action.action(UPDATE_ONBOARDING_CATEGORY_STATUS[constants.REQUEST], {
      updatedCategory,
      onSucess,
    }),
  success: (updatedData: any) =>
    Action.action(UPDATE_ONBOARDING_CATEGORY_STATUS[constants.SUCCESS], {
      updatedData,
    }),
  failure: (error: Error) =>
    Action.action(UPDATE_ONBOARDING_CATEGORY_STATUS[constants.FAILURE], {
      error,
    }),
};
