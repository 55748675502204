import React from 'react';
import {useSelector, shallowEqual} from 'react-redux';
import {CCol, CProgress, CRow, CWidgetProgress} from '@coreui/react';

export const UserStats = () => {
  const {userStats} = useSelector(
    (state) => ({
      userStats: state.users.stats,
    }),
    shallowEqual,
  );

  return (
    <CRow>
      <CCol xs="12" sm="6" lg="3">
        <CWidgetProgress
          color="success"
          header={userStats.user}
          text={'Active User'}
          footer={`${userStats.user} user count`}>
          <CProgress
            color="success"
            animated
            size="xs"
            className="my-3"
            value={userStats.user}
            max={30000}
          />
        </CWidgetProgress>
      </CCol>
      <CCol xs="12" sm="6" lg="3">
        <CWidgetProgress
          color="success"
          header={userStats.waiting}
          text={'Waiting User'}
          footer={`${userStats.waiting} user waiting`}>
          <CProgress
            color="warning"
            animated
            size="xs"
            className="my-3"
            value={userStats.waiting}
            max={30}
          />
        </CWidgetProgress>
      </CCol>
      <CCol xs="12" sm="6" lg="3">
        <CWidgetProgress
          color="success"
          header={userStats.passive}
          text={'Passive User'}
          footer={`${userStats.passive} passive user count`}>
          <CProgress
            color="danger"
            animated
            size="xs"
            className="my-3"
            value={userStats.passive}
            max={30}
          />
        </CWidgetProgress>
      </CCol>
      <CCol xs="12" sm="6" lg="3">
        <CWidgetProgress
          color="success"
          header={userStats.wiser}
          text={'Wiser'}
          footer={`${userStats.wiser} wiser count`}>
          <CProgress
            color="info"
            animated
            size="xs"
            className="my-3"
            value={userStats.wiser}
            max={30}
          />
        </CWidgetProgress>
      </CCol>
    </CRow>
  );
};
