import * as ActionTypes from '@actions/notifications';

import type {NotificationReducer} from './types';

const initialState: NotificationReducer = {
  isLoading: false,
  error: false,
  errorDescription: '',
  succesMessage: false,
};

export function notifications(
  state = initialState,
  action: any = {},
): NotificationReducer {
  switch (action.type) {
    case ActionTypes.POST_BULK_NOTIFICATION.REQUEST: {
      return {
        ...state,
        isLoading: true,
        error: false,
        errorDescription: '',
        succesMessage: false,
      };
    }
    case ActionTypes.POST_BULK_NOTIFICATION.SUCCESS: {
      return {
        ...state,
        isLoading: false,
        error: false,
        errorDescription: '',
        succesMessage: true,
      };
    }
    case ActionTypes.POST_BULK_NOTIFICATION.FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: true,
        succesMessage: false,
        errorDescription: action.error.data?.message,
      };
    }

    case ActionTypes.CLOSE_SUCCESS_MESSAGE.REQUEST: {
      return {
        ...state,
        isLoading: false,
        error: false,
        errorDescription: '',
        succesMessage: false,
      };
    }

    case ActionTypes.SEND_SUCCESS_MESSAGE.REQUEST: {
      return {
        ...state,
        isLoading: false,
        error: false,
        errorDescription: '',
        succesMessage: true,
      };
    }
    case ActionTypes.OPEN_SUCCESS_MESSAGE.REQUEST: {
      return {
        ...state,
        isLoading: false,
        error: false,
        errorDescription: '',
        succesMessage: true,
      };
    }

    default:
      return state;
  }
}
