import {take, call, put} from 'redux-saga/effects';
import type {Effect} from 'redux-saga/effects';

import {getCategoryDetail as getCategoryDetailService} from '@services/categories';
import * as CategoryActions from '@actions/categories';

export function* getCategoryDetail(): Generator<Effect, void, any> {
  while (true) {
    try {
      const {categoryId} = yield take(
        CategoryActions.GET_CATEGORY_DETAIL.REQUEST,
      );
      const {data: response} = yield call(getCategoryDetailService, categoryId);
      yield put(CategoryActions.getCategoryDetail.success(response));
    } catch (error) {
      // Show info
      yield put(CategoryActions.getCategoryDetail.failure(error));
    }
  }
}
