import {Axios} from '@utils';
import {constants} from '@resources';

export function deleteOnboardingCategoryUsers(
  categoryId: number,
  userId: number,
) {
  return Axios.delete(
    `${constants.ONBOARDING_BASE}/categories/${categoryId}/users/${userId}`,
  );
}
