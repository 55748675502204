import {take, call, put} from 'redux-saga/effects';
import type {Effect} from 'redux-saga/effects';

import {addCategoriesPosts as addCategoriesPostsService} from '@services/posts';
import * as CurationActions from '@actions/posts';

export function* addCategoriesPosts(): Generator<Effect, void, any> {
  while (true) {
    try {
      const {postId, categoryId, onSucess} = yield take(
        CurationActions.ADD_CATEGORIES_POSTS.REQUEST,
      );
      const {data: response} = yield call(
        addCategoriesPostsService,
        postId,
        categoryId,
      );
      onSucess();
      yield put(CurationActions.addCategoriesPosts.success([]));
    } catch (error) {
      // Show info
      yield put(CurationActions.addCategoriesPosts.failure(error));
    }
  }
}
