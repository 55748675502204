import {take, call, put} from 'redux-saga/effects';
import type {Effect} from 'redux-saga/effects';

import {getUser as getUserService} from '@services/user';
import * as UserActions from '@actions/user';

export function* getUser(): Generator<Effect, void, any> {
  while (true) {
    try {
      const {userId} = yield take(UserActions.GET_USER.REQUEST);
      //const {data: response} = yield call(getUserService, userId);
      yield call(getUserService, userId);
      yield put(UserActions.getUser.success({}));
    } catch (error) {
      // Show info
      yield put(UserActions.getUser.failure(error));
    }
  }
}
