import {take, call, put} from 'redux-saga/effects';
import type {Effect} from 'redux-saga/effects';

import {deleteOnboardingCategory as deleteOnboardingCategoryService} from '@services/onboarding';
import * as OnboardingActions from '@actions/onboarding';

export function* deleteOnboardingCategory(): Generator<Effect, void, any> {
  while (true) {
    try {
      const {categoryId, onSucess} = yield take(
        OnboardingActions.DELETE_ONBOARDNG_CATEGORY.REQUEST,
      );
      yield call(deleteOnboardingCategoryService, categoryId);
      onSucess();
      yield put(OnboardingActions.deleteOnboardingCategory.success(categoryId));
    } catch (error) {
      // Show info
      yield put(OnboardingActions.deleteOnboardingCategory.failure(error));
    }
  }
}
