import {take, call, put} from 'redux-saga/effects';
import type {Effect} from 'redux-saga/effects';

import {deletePostComment as deletePostCommentService} from '@services/posts';
import * as PostActions from '@actions/posts';

export function* deletePostComment(): Generator<Effect, void, any> {
  while (true) {
    try {
      const {postId, commentId, onSucess} = yield take(
        PostActions.DELETE_POST_COMMENT.REQUEST,
      );
      yield call(deletePostCommentService, postId, commentId);
      onSucess();
      yield put(PostActions.deletePostComment.success(commentId));
    } catch (error) {
      // Show info
      yield put(PostActions.deletePostComment.failure(error));
    }
  }
}
