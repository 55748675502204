export const colors = {
  gray: {
    gray1: '#838383',
    gray2: '#BCBCBC',
    gray3: '#D3D3D3',
    gray4: '#DEDEDE',
    gray5: '#F0F0F0',
    gray6: '#F6F6F6',
    border: '#DEDEDE',
    text: '#838383',
    secondaryBorder: '#E5E5E5',
    tertiary: '#E5E5E5',
  },
  red: {
    youtube: '#FF0000',
  },
  blueColors: {
    article: '#0047FF',
  },
  green: {
    spotify: '#1DB954',
  },
  Black: {
    original: '#000000',
  },
  primaryGray: '#CECECE',
  secondaryGray: '#707070',
  tertiaryGray: '#9E9E9E',
  textGray: '#BBBBBB',
  headerGray: '#F2F2F2',
  borderGray: '#DDDDDD',
  white: '#FFFFFF',
  blue: '#758FB5',
  whiteGray: '#F4F1EA',
  black: '#131313',
  textBlack: '#1E1E1E',
};
