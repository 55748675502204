import {Axios} from '@utils';
import {constants} from '@resources';

export function updateCategory(category: any) {
  const parameters = {
    ...category,
    name: category.name_translate[category.locale],
  };

  return Axios.put(`${constants.CATEGORY_BASE}/${category.id}`, parameters);
}
