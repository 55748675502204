import {Axios} from '@utils';
import {constants} from '@resources';

export function checkUserName(user: any) {
  const parameters = {
    userName: user.userName,
  };
  return Axios.post(
    `${constants.USER_MANAGEMENT_BASE}/checkUsername`,
    parameters,
  );
}
