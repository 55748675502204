import React, {CSSProperties} from 'react';
import {colors} from '../../resources';

const generalStyles: Record<string, CSSProperties> = {
  sharedViaText: {
    fontFamily: 'SFProText-Regular',
    //fontWeight: '500',
    fontSize: 11,
  },
};
const styles = {
  container: {
    borderColor: colors.gray.gray3,
    borderWidth: 0.5,
    borderRadius: 12,
    width: 160,
    height: 160,
    // marginRight: 10,
    float: 'left',
    marginRight: 25,
  },
  rectangleImageContainer: {
    borderTopLeftRadius: 12,
    borderTopRightRadius: 12,
    width: 160,
    height: 90,
    marginLeft: -12,
    marginTop: -12,
    // alignItems: 'center',
    // justifyContent: 'center',
  },
  roundedImageContainer: {
    width: 68,
    height: 68,
    borderRadius: 34,
    alignItems: 'center',
    justifyContent: 'center',
    alignSelf: 'center',
    marginVertical: 11,
    marginLeft: 35,
    marginTop: 14,
  },
  rectangleImage: {
    borderTopLeftRadius: 12,
    borderTopRightRadius: 12,
  },
  roundedImage: {
    borderRadius: 34,
    borderWidth: 1,
    borderColor: colors.gray.gray4,
  },
  playButton: {
    alignSelf: 'center',
    width: 32,
    height: 32,
    borderRadius: 16,
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,

    elevation: 5,
  },
  playIcon: {
    width: 32,
    height: 32,
  },
  sharedContainer: {
    // marginTop: 10,
    marginHorizontal: 10,
    maxHeight: 10,
  },
  sharedViaText: {
    ...generalStyles.sharedViaText,
    color: colors.gray.gray1,
  },
  spotifyText: {
    ...generalStyles.sharedViaText,
    color: colors.green.spotify,
  },
  youtubeText: {
    ...generalStyles.sharedViaText,
    color: colors.red.youtube,
  },
  articleText: {
    ...generalStyles.sharedViaText,
    color: colors.blueColors.article,
  },
  titleText: {
    fontFamily: 'SFProText-Regular',
    // fontWeight: 'bold',
    fontSize: 12,
    // lineHeight: 16,
    color: colors.black,
    marginTop: 5,
    marginBottom: 10,
    marginHorizontal: 10,
    maxHeight: 40,
    display: '-webkit-box',
    webkitBoxOrient: 'vertical',
    webkitLineClamp: 2,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
};

export default styles;
