import {take, call, put} from 'redux-saga/effects';
import type {Effect} from 'redux-saga/effects';

import {deleteManagementUsers as deleteManagementUsersService} from '@services/users';
import * as UsersActions from '@actions/users';

export function* deleteManagementUsers(): Generator<Effect, void, any> {
  while (true) {
    try {
      const {deletedId, onSuccess} = yield take(
        UsersActions.DELETE_MANAGEMENT_USERS.REQUEST,
      );
      const {data: response} = yield call(
        deleteManagementUsersService,
        deletedId,
      );
      onSuccess();
      yield put(UsersActions.deleteManagementUsers.success([]));
    } catch (error) {
      // Show info
      yield put(UsersActions.deleteManagementUsers.failure(error));
    }
  }
}
